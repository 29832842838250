import React from "react";
import Assets from "../../constants/images";
import { FaTimes } from "react-icons/fa"
import { HiCheckCircle } from "react-icons/hi"
import { handleBrokenImageOnError } from "../../utils/helper";

const AddToCartPopup = ({image, title, addToCartPopup, closeAddToCartPopup}) => {
    return (
        <div className={`add-to-cart-popup ${addToCartPopup && "animate"}`}>
            <div className="times-container text-end" onClick={()=>closeAddToCartPopup()}>
                <FaTimes />
            </div>
            <div className="d-flex align-items-center">
                <div className="image-container">
                    <img src={image} onError={(e) => handleBrokenImageOnError(e)} />
                </div>
                <div className="ms-2">
                    <h6 className="m-0 p-0">Added to cart <HiCheckCircle className="icons ms-1"/></h6>
                    <small>{title}</small>
                </div>
            </div>
        </div>
    )
}

export default AddToCartPopup