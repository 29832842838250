import React, {useContext, useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import CategoryCard from "../../components/categories-section/CategoryCard";
import ProductCard from "../../components/categories-section/ProductCard";
import Assets from "../../constants/images";
import { NavLink } from "react-router-dom";
import {CategoriesContext} from "../../hooks/GlobalContext";
import {GetProducts} from "../../services/products.service"
import FooterNew from "../../components/app-footer/FooterNew";

const CategoryPage = () => {
    /* Categories Context */
    const [categoriesData, setCategoriesData] = useState([]);
    const categoriesContext = useContext(CategoriesContext)
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setCategoriesData(categoriesContext)
    },[categoriesContext])

    async function fetchProducts() {
        const params = {
            "per-page": 8
        }

        await GetProducts(params).then((result) => {
            if (result.status && result.data.data) {
                setProducts(result.data.data)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])


    return (
        <>
            <AppHeader />
            <div className="category-page category-section py-5">
                <div className="category-container">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                        <li className="breadcrumb-item active" aria-current="page">All Categories</li>
                                    </ol>
                                </nav>

                                <h1>Our 200,000+ Product Catalog</h1>
                            </Col>
                        </Row>

                        <Row className="justify-content-lg-center pb-5">
                            {
                                categoriesData.map((category, i) => (
                                    <CategoryCard key={i} id={category.id} image={category.image_path} title={category.name} />
                                ))
                            }
                        </Row>

                        <Row className="my-5 pb-5 border-top"></Row>

                        <div className="suggested">
                            <Row>
                                { products &&
                                    products.map((v, i) => {
                                        return <ProductCard id={v.id} key={i} index={i}
                                                            total_reviews={v.meta.total_reviews}
                                                            rating={(v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0}
                                                            attachments={v.attachments}
                                                            image={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : Assets.NoImageAvailable}
                                                            title={v.name} buy={v.buy} price={v.price}
                                                            sku={v.sku} weight={v.weight}
                                                            description={v.description}/>
                                    })
                                }
                            </Row>
                        </div>

                    </Container>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default CategoryPage