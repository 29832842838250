import React, {useState} from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AppLogo, AuthLogo, EyeCloseIcon, EyeIcon} from "../../constants/svgs";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {success} from "../../constants/msg";
import {toast, ToastContainer} from "react-toastify";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../constants/app-constants";
import {ResetPasswordApi} from "../../services/auth/auth.service";

const ResetPasswordForm = () => {
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [hidePassword, setHidePassword] = useState(true);
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
    const password = watch("password");

    const onSubmit = (data) => {
        resetPassword(location.state.email, location.state.otp, data.password, data.confirmPassword)
    }


    const resetPassword = async (email, otp, password, password_confirmation) => {

        await ResetPasswordApi(email, otp, password, password_confirmation).then((res) => {
            if (res.status) {
                success("Password has been updated")
                setTimeout(() => {
                    navigate("/login");
                }, 1500);
            }
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    return (
        <div className="auth-page py-5" id="reset-password">
            <ToastContainer/>
            <Container>
                <Row>
                    <Col xs={12} className="mb-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">My Account</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={10}>
                        <div className="form-container login-form-container p-4 px-lg-5 py-lg-4">
                            <Row>
                                <Col xs={{span: 12, order: 2}} md={12} lg={{span: 5, order: 1}} xl={6}>
                                    <span className="d-block d-lg-none"><AppLogo/></span>
                                    <h5 className="mt-4 mt-lg-0 pb-3 border-bottom">Reset Password</h5>
                                    <span className="d-inline-block my-3">Please create a new password</span>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <InputGroup>
                                            <FormControl
                                                name="password"
                                                className="py-2"
                                                placeholder="Password"
                                                type={hidePassword ? "password" : "text"}
                                                {...register("password",
                                                    {
                                                        minLength: {
                                                            value: VALIDATIONS.PASSWORD_MIN,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.PASSWORD_MAX,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                            <InputGroup.Text id="basic-addon2" className="eye-icon"
                                                             onClick={() => setHidePassword(!hidePassword)}>{hidePassword ?
                                                <EyeIcon/> : <EyeCloseIcon/>}</InputGroup.Text>
                                        </InputGroup>
                                        {errors.password &&
                                        <small className='text-red'>{errors.password.message}</small>}

                                        <InputGroup className="mt-2">
                                            <FormControl
                                                name="confirmPassword"
                                                className="py-2"
                                                placeholder="Confirm Password"
                                                type={hideConfirmPassword ? "password" : "text"}
                                                {...register("confirmPassword",
                                                    {
                                                        minLength: {
                                                            value: VALIDATIONS.PASSWORD_MIN,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.PASSWORD_MAX,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                                        },
                                                        validate: (value) =>
                                                            value === password || "The Passwords do not match"
                                                    })
                                                }
                                            />
                                            <InputGroup.Text id="basic-addon2" className="eye-icon"
                                                             onClick={() => setHideConfirmPassword(!hideConfirmPassword)}>{hideConfirmPassword ?
                                                <EyeIcon/> : <EyeCloseIcon/>}</InputGroup.Text>
                                        </InputGroup>
                                        {errors.confirmPassword &&
                                        <small className='text-red'>{errors.confirmPassword.message}</small>}

                                        <Button className="w-100 btn-red my-3 mt-4" type="submit">Update
                                            Password</Button>
                                    </Form>
                                </Col>

                                <Col className="text-center d-none d-lg-block" xs={{span: 12, order: 1}} md={12}
                                     lg={{span: 6, offset: 1, order: 2}} xl={{span: 5, offset: 1}}>
                                    <AuthLogo/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ResetPasswordForm;