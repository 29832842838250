import React from "react";
import {Col, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Assets from "../../constants/images";
import {FaCircle} from "react-icons/fa";
import {constants} from "../../utils/constants";
import {handleBrokenImageOnError} from "../../utils/helper";

const DispatchComponent = ({orders, status}) => {
    return (
        <div className="px-3">
            <div className="order-card">
                <Row>
                    <Col xs={12} className="mb-4">
                        <span>{orders.length} order</span>
                    </Col>
                </Row>

                {
                    orders &&
                    orders.map((order, index) => (

                        <Row key={index}>
                            <Col xs={12} xl={8}>
                                <div>
                                    <div className="mb-3">
                                        <FaCircle className="icon"/>
                                        <span className="ms-2">{ constants.ORDERSTATUSKEY[status].charAt(0) + constants.ORDERSTATUSKEY[status].slice(1).toLowerCase()}</span>
                                    </div>
                                    <div className="bg-text-grey d-sm-flex align-items-sm-center p-4">
                                        <img
                                            src={(order && order.order_products.length > 0 && order.order_products[0].product.attachments.length > 0) ? order.order_products[0].product.attachments[0].mediaUrl : ""}
                                            onError={(e) => handleBrokenImageOnError(e)}
                                            alt="ProductImage"/>
                                        <div className="ps-sm-3 mt-3 mt-sm-0">
                                            <p className="m-0 p-0">Main ECO Compact 25KW Combi ERP Boiler</p>
                                            <span>Arrives tomorrow<br/>{order.delivery_time}</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} xl={4} className="mt-2 mt-xl-5 mb-4">
                                <div className="text-center">
                                    <NavLink className="btn-red mb-2" to="/order-history/order-details"
                                             state={{order_id: order.id, status:status}}>Track Order</NavLink>
                                    <NavLink className="btn-transparent" to="/order-history/order-details" state={{order_id: order.id, status:status}}>View order
                                        details</NavLink>
                                </div>
                            </Col>
                        </Row>

                    ))
                }


            </div>
        </div>
    )
}

export default DispatchComponent