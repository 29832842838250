import {apiUrl} from "../utils/constants";
import {GET} from "./api.service.wrapper";


export const GetProducts = async (args) => {
    args["order-column"] = "name"
    args["order"] = "asc"
    return await GET(apiUrl.products, args);
}

export const GetProductReviews = async (args) => {
    return await GET(apiUrl.product_reviews, args);
}