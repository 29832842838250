import React, {useState} from "react";
import {Button, Col, Container, Form, Nav, Row} from "react-bootstrap";
import {AppLogo, AuthLogo} from "../../constants/svgs";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {success} from "../../constants/msg";
import {toast, ToastContainer} from "react-toastify";
import OtpInput from "react-otp-input";
import {ResendOtp, VerifyOtpApi} from "../../services/auth/auth.service";

const VerificationForm = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [hidePassword, setHidePassword] = useState(true);
    const [error, setError] = useState(false);
    const [otp, setOtp] = useState("");
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange",
    });

    const onSubmit = (data) => {
        if (otp.length < 5) {
            setError(true)
        }

        verifyCode(otp, location.state.email)
    }

    const handleOtpChange = (otp) => {
        setOtp(otp)
    }

    const handleResendOtpOnClick = async () => {
        await ResendOtp({email:location.state.email}).then((res) => {
            if (res.status) {
                setError(false)
                success(res.message)
            }
            else{
                toast.error(res.message);
            }
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    const verifyCode = async (otp, email) => {
        await VerifyOtpApi(otp, email).then((res) => {
            if (res.status) {
                setError(false)
                success("OTP verified")
                setTimeout(() => {
                    navigate("/reset-password", {state: {email: email, otp: otp}});
                }, 1500);
            }
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    return (
        <div className="auth-page py-5" id="verification">
            <ToastContainer/>
            <Container>
                <Row>
                    <Col xs={12} className="mb-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">My Account</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={10}>
                        <div className="form-container login-form-container verification-code-form p-4 px-lg-5 py-lg-4">
                            <Row>
                                <Col xs={{span: 12, order: 2}} md={12} lg={{span: 5, order: 1}} xl={6}>
                                    <span className="d-block d-lg-none"><AppLogo/></span>
                                    <h5 className="mt-4 mt-lg-0 pb-3 border-bottom">Verification</h5>
                                    <span className="d-inline-block my-3">Please enter the OTP you received on {(location.state && location.state.email) ? location.state.email : ""}</span>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <OtpInput
                                            className="w-100 otp-input"
                                            value={otp}
                                            onChange={(otp) => handleOtpChange(otp)}
                                            numInputs={5}
                                            isInputNum={true}
                                            placeholder="-----"
                                            hasErrored={true}
                                            focusStyle="focus"
                                        />
                                        {error && <span className="text-red">Length is 5</span>}
                                        <Nav.Link to="#" className="mx-0 mt-2 mb-2 px-0 pb-0"
                                                  onClick={handleResendOtpOnClick}>Resend code</Nav.Link>
                                        <Button className="w-100 btn-red my-3" type="submit">Verify Now</Button>
                                    </Form>
                                </Col>

                                <Col className="text-center d-none d-lg-block" xs={{span: 12, order: 1}} md={12}
                                     lg={{span: 6, offset: 1, order: 2}} xl={{span: 5, offset: 1}}>
                                    <AuthLogo/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default VerificationForm;