import React, {forwardRef, useEffect, useState, useRef} from "react"
import {Form, Col, Button} from "react-bootstrap"
import {useForm} from "react-hook-form";
import {success, error} from "../../constants/msg";
import {toast, ToastContainer} from "react-toastify";
import {
    VALIDATIONS,
    VALIDATIONS_TEXT,
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    CHARACTER_ARRAY_NUMBER_INPUT,
    CHARACTER_ARRAY_WHOLE_NUMBER_INPUT
} from "../../constants/app-constants";
import {RiDeleteBin5Line} from "react-icons/ri";
import {ImUpload3} from "react-icons/im";
import "react-datepicker/dist/react-datepicker.css";
import {GetProfile, PostBankingDocumentaion} from "../../services/general.service";
import {UploadFileToS3} from "../../utils/helper";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useNavigate} from "react-router-dom";
import {FaTimesCircle} from "react-icons/fa";
import {constants} from "../../utils/constants";

const BankingDocumentationForm = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    //const myFileRef = useRef();
    const [myfile, setMyFile] = useState(null)
    const [myFileName, setMyFileName] = useState(null)
    const [accountTypeError, setAccountTypeError] = useState(false)
    const [accountType, setAccountType] = useState(10)
    const [user, setUser] = useState([])
    const {register, unregister, reset, watch, handleSubmit, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        getProfile()
    }, [])

    async function getProfile() {
        await GetProfile().then((result) => {
            if (result.status && result.data) {
                let user = result?.data?.user;
                setAccountType(user.banking_documentation.account_type)
                setUser(user)
                reset({
                    name: user.banking_documentation.name,
                    account_no: user.banking_documentation.account_no,
                    account_type: user.banking_documentation.account_type,
                    address: user.banking_documentation.address,
                    routing_no: user.banking_documentation.routing_no,
                    location: user.banking_documentation.location,
                })
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    const onSubmit = async (data) => {
        if (data.accountType == "") {
            setAccountTypeError(true);
        }

        let formData = new FormData();
        formData.append("name", data.name);
        formData.append("account_no", data.accountNumber);
        formData.append("account_type", data.accountType);
        formData.append("address", data.address);
        formData.append("routing_no", data.routingNumber);
        formData.append("location", data.location);

        if (data.image && data.image.length > 0) {
            let uploadImage = await UploadFileToS3(data.image[0]);
            if (uploadImage.success) {
                formData.append("image", uploadImage.url);
            }
        }

        await PostBankingDocumentaion(formData).then((data) => {
            if (data.status) {
                console.log("===data===", data)
                toast.success(data.message);

            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate("/");
            } else {
                return toast.error(error.response.data.message);
            }
        })

    }

    const [checkbox1, setCheckbox1] = useState(true);
    const [checkbox2, setCheckbox2] = useState(false);
    const [checkbox3, setCheckbox3] = useState(false);
    const [nineDigitValue, setNineDigitValue] = useState("");

    const handleClickOnCheckbox1 = () => {
        setCheckbox1(true)
        setCheckbox2(false)
        setCheckbox3(false)
        unregister(["amountDollar", "amount"]);
    }

    const handleClickOnCheckbox2 = () => {
        setCheckbox1(false)
        setCheckbox2(true)
        setCheckbox3(false)
        unregister(["amountPercentage", "amount"]);
    }

    const handleClickOnCheckbox3 = () => {
        setCheckbox1(false)
        setCheckbox2(false)
        setCheckbox3(true)
        unregister(["amountPercentage", "amountDollar"]);
    }

    const handleReset = () => {
        setCheckbox1(true)
        setCheckbox2(false)
        setCheckbox3(false)
    }

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const handleMyFileChange = (file) => {
        setMyFile(file.target.files[0]);
        setMyFileName(file.target.files[0].name)
    }

    const resetMyfile = () => {
        //myFileRef.current.value = null;
        setMyFile(null);
        setMyFileName(null);
    }

    const printdiv = (printpage) => {
        var headstr = "<html><head><title></title></head><body>";
        var footstr = "</body>";
        var newstr = document.all.item(printpage).innerHTML;
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = headstr + newstr + footstr;
        window.print();
        document.body.innerHTML = oldstr;
        return false;
    }

    return (
        <div id={"banking-documentation-container-id"}
             className="banking-documentation-container setup-profile-container">
            <ToastContainer/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control
                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                    type="text"
                    className="py-2 mt-2"
                    placeholder="Name"
                    defaultValue={user?.banking_documentation?.name}
                    {...register("name",
                        {
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.NAME_REQUIRED
                            },
                        },
                        {
                            pattern: {
                                value: /^[a-z0-9 ]+$/gi,
                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                            },
                            maxLength: {
                                value: VALIDATIONS.NAME,
                                message: VALIDATIONS_TEXT.NAME_MAX
                            },
                        })
                    }
                />
                {errors.name && <small className='text-red'>{errors.name.message}</small>}
                <Form.Control
                    onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                    type="text"
                    className="py-2 mt-2"
                    placeholder="Address"
                    defaultValue={user?.banking_documentation?.address}
                    {...register("address",
                        {
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.ADDRESS_REQURIED
                            },
                        },
                        {
                            pattern: {
                                value: /^[a-z0-9-, ]+$/gi,
                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                            },
                            maxLength: {
                                value: VALIDATIONS.ADDRESS,
                                message: VALIDATIONS_TEXT.ADDRESS_MAX
                            },
                        })
                    }
                />
                {errors.address && <small className='text-red'>{errors.address.message}</small>}

                <Form.Control
                    onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                    type="text"
                    className="py-2 mt-2"
                    placeholder="City, State, Zip"
                    defaultValue={user?.banking_documentation?.location}
                    {...register("location",
                        {
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.LOCATION_REQUIRED
                            },
                        },
                        {
                            pattern: {
                                value: /^[a-z0-9-, ]+$/gi,
                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                            },
                            maxLength: {
                                value: VALIDATIONS.CITY_STATE_ZIP_MAX,
                                message: VALIDATIONS_TEXT.CITY_STATE_ZIP_MAX
                            },
                        })
                    }
                />
                {errors.location && <small className='text-red'>{errors.location.message}</small>}
                <Form.Control
                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                    type="number"
                    className="py-2 mt-2"
                    placeholder="Account #"
                    defaultValue={user?.banking_documentation?.account_no}
                    {...register("accountNumber",
                        {
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.ACCOUNT_NUMBER_REQUIRED
                            },
                        },
                        {
                            pattern: {
                                value: /^[0-9]+$/,
                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                            },
                            minLength: {
                                value: VALIDATIONS.BANK_ACCOUNT_MIN,
                                message: VALIDATIONS_TEXT.BANK_ACCOUNT_MIN
                            },
                            maxLength: {
                                value: VALIDATIONS.BANK_ACCOUNT_MAX,
                                message: VALIDATIONS_TEXT.BANK_ACCOUNT_MAX
                            },
                        })
                    }
                />
                {errors.accountNumber && <small className='text-red'>{errors.accountNumber.message}</small>}

                <Form.Control
                    onKeyPress={(e) => {
                        if ((e.target.value.length >= VALIDATIONS.ROUTING_NUMBER_MAX) || CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    type="number"
                    maxLength={9}
                    minLength={9}
                    className="py-2 mt-2"
                    placeholder="9-digits routing #"
                    defaultValue={user?.banking_documentation?.routing_no}
                    {...register("routingNumber",
                        {
                            required: {
                                value: true,
                                message: VALIDATIONS_TEXT.DIGIT_ROUTING_REQUIRED
                            },
                        },
                        {
                            pattern: {
                                value: /^[0-9]+$/,
                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                            },
                            minLength: {
                                value: VALIDATIONS.ROUTING_NUMBER_MIN,
                                message: VALIDATIONS_TEXT.ROUTING_NUMBER_MIN
                            },
                            maxLength: {
                                value: VALIDATIONS.ROUTING_NUMBER_MAX,
                                message: VALIDATIONS_TEXT.ROUTING_NUMBER_MAX
                            },
                        })
                    }
                />
                {errors.routingNumber && <small className='text-red'>{errors.routingNumber.message}</small>}

                {/*<div className="form-card p-3 mb-2 mt-2">
                    <h5 className="mb-0">Amount</h5>
                    <div>
                        <div className="d-flex flex-wrap align-items-center">
                            <div>
                                <Form.Label className="mt-2 me-2" htmlFor="radio-1">
                                    <Form.Check
                                        readOnly
                                        checked={checkbox1 ? true : false}
                                        name="amountType"
                                        type="radio"
                                        id="radio-1"
                                        label="$"
                                        onClick={() => handleClickOnCheckbox1()}
                                    />
                                </Form.Label>
                            </div>
                            {
                                checkbox1 === true &&
                                <div className="empty-form">
                                    <Form.Control
                                        onKeyPress={(e) => ((e.target.value.length >= VALIDATIONS.DOLLAR_MAX) || (CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key))) && e.preventDefault()}
                                        name="amountDollar"
                                        type="number"
                                        className="m-0 p-0 border-bottom"
                                        {...register("amountDollar", {
                                            maxLength: {
                                                value: VALIDATIONS.DOLLAR_MAX,
                                                message: VALIDATIONS_TEXT.DOLLAR_MAX
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                            },
                                        })}
                                    />
                                    {errors.amountDollar && <small style={{ position: "absolute" }} className='text-red'>{errors.amountDollar.message}</small>}
                                </div>
                            }

                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                            <div>
                                <Form.Label className="mt-2 me-2" htmlFor="radio-2">
                                    <Form.Check
                                        readOnly
                                        checked={checkbox2 ? true : false}
                                        name="amountType"
                                        type="radio"
                                        id="radio-2"
                                        label="%"
                                        onClick={() => handleClickOnCheckbox2()}
                                    />
                                </Form.Label>
                            </div>
                            <div className="empty-form">
                                {
                                    checkbox2 === true &&
                                    <div className="empty-form">
                                        <Form.Control
                                            onKeyPress={(e) => ((e.target.value.length >= VALIDATIONS.DOLLAR_MAX) || (CHARACTER_ARRAY_WHOLE_NUMBER_INPUT.includes(e.key))) && e.preventDefault()}
                                            name="amountPercentage"
                                            type="number"
                                            className="m-0 p-0 border-bottom"
                                            {...register("amountPercentage", {
                                                maxLength: {
                                                    value: VALIDATIONS.DOLLAR_MAX,
                                                    message: VALIDATIONS_TEXT.DOLLAR_MAX
                                                },
                                                pattern: {
                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                                    message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                },
                                            })}
                                        />
                                        {errors.amountPercentage && <small className='text-red'>{errors.amountPercentage.message}</small>}
                                    </div>
                                }
                            </div>
                        </div>

                        <div>
                            <div>
                                <Form.Label className="mt-2 me-2" htmlFor="radio-3">
                                    <Form.Check
                                        readOnly
                                        checked={checkbox3 ? true : false}
                                        name="amountType"
                                        type="radio"
                                        id="radio-3"
                                        label="Entire Paycheck"
                                        value="Entire Paycheck"
                                        onClick={() => handleClickOnCheckbox3()}
                                    />
                                    {
                                        checkbox3 &&
                                        <Form.Check
                                            readOnly
                                            checked={checkbox3 ? true : false}
                                            name="amount"
                                            type="radio"
                                            id="radio-4"
                                            className="d-none"
                                            value="Entire Paycheck"
                                            {...register("amount")}
                                        />
                                    }
                                </Form.Label>
                            </div>
                        </div>
                    </div>
                </div>*/}

                <div className="form-card p-3 mb-2 mt-2">
                    <h5 className="mb-2">Type of account</h5>
                    <div>
                        <Form.Check
                            readOnly
                            name="accountType"
                            type="radio"
                            id="radio-5"
                            label="Checking"
                            value="10"
                            checked={accountType == 10}
                            onClick={() => {
                                setAccountType(10)
                            }}
                            {...register("accountType")}
                        />

                        <Form.Check
                            readOnly
                            name="accountType"
                            type="radio"
                            id="radio-6"
                            label="Savings"
                            value="20"
                            checked={accountType == 20}
                            onClick={() => {
                                setAccountType(20)
                            }}
                            {...register("accountType")}
                        />
                    </div>
                    {accountTypeError && <small className='text-red'>{VALIDATIONS_TEXT.DIGIT_ROUTING_REQUIRED}</small>}
                </div>

                <div className="form-card p-3 mb-2 mt-2">
                    <div className="mt-2">
                        <p className="p-0 m-0">Attach a voided cheque for each bank account to which funds should be
                            deposited</p>
                        <span className="d-block">(if necessary)</span>
                        <div className="mt-2 upload-btn-wrapper">
                            <button className="btn"><ImUpload3 className="icon"/> Add file</button>
                            <input
                                accept=".pdf, .jpg, .jpeg, .png"
                                type="file"
                                name="image"
                                {...register("image")}
                                onChange={(file) => handleMyFileChange(file)}
                                //ref={myFileRef}
                            />
                            <div className="mt-2">
                                {
                                    myFileName !== null &&
                                    <div className="d-flex align-items-center">
                                    <span className="d-inline-block ms-2"
                                          style={{fontSize: "14px"}}>{myFileName ? truncate(myFileName, 25) : " "}</span>
                                        <FaTimesCircle className="ms-3 text-dark icons" onClick={resetMyfile}/>
                                    </div>
                                }
                            </div>
                        </div>

                        <div>
                            {
                                user?.banking_documentation?.imageUrl ?
                                    <div style={{marginTop: "10px"}}>
                                        <img style={{width: "100px"}}
                                             src={user?.banking_documentation?.imageUrl} alt="-"
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null; // prevents looping
                                                 currentTarget.src = constants.IMAGENOTFOUND;
                                             }} className="truck-img me-3 mb-2"/>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </div>

                <div style={{display: "none"}}>
                    <div className="bottom mt-3 d-flex align-items-center">
                        <Form.Control type="text" className="text-white px-0"/>
                        <span>
                            [Company name] is hereby authorized to directly deposit my pay to the
                            account listed above. This authorization will remain in effect until
                            I modify or cancel it in writing.
                        </span>
                    </div>

                    <div className="bottom mt-3 d-flex align-items-center">
                        <span>Employee's Signature: </span>
                        <Form.Control type="text" className="text-white px-2 mx-2"/>
                    </div>

                    <div className="bottom mt-3 d-flex align-items-center">
                        <span>Date: </span>
                        <Form.Control type="text" className="text-white px-2 mx-2"/>
                    </div>
                </div>

                <Button type="submit" className="btn-print mt-4">Save</Button>
                <Button onClick={() => {
                    //window.print();
                    printdiv("print-banking-documentation")

                }} className="btn-print mt-4" style={{marginLeft: "10px"}}>Print</Button>
            </Form>

            <div id={"print-banking-documentation"} data={user} style={{visibility: "hidden"}}>
                <table style={{marginTop:"10px"}} class={"table"}>
                    <tr>
                        <th>Name</th>
                        <td>{user?.banking_documentation?.name}</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>{user?.banking_documentation?.address}</td>
                    </tr>
                    <tr>
                        <th>Location</th>
                        <td>{user?.banking_documentation?.location}</td>
                    </tr>
                    <tr>
                        <th>Account No</th>
                        <td>{user?.banking_documentation?.account_no}</td>
                    </tr>
                    <tr>
                        <th>Account Type</th>
                        <td>{user?.banking_documentation?.account_type}</td>
                    </tr>
                    <tr>
                        <th>Routing No</th>
                        <td>{user?.banking_documentation?.routing_no}</td>
                    </tr>
                    {
                        user?.banking_documentation?.imageUrl &&
                        <tr>
                            <th>Image</th>
                            <td>{user?.banking_documentation?.imageUrl}</td>
                        </tr>
                    }
                </table>

                <div className="bottom mt-3 d-flex align-items-center">
                        <span>
                            [Company name] is hereby authorized to directly deposit my pay to the
                            account listed above. This authorization will remain in effect until
                            I modify or cancel it in writing.
                        </span>
                    </div>

                    <div className="bottom mt-3 d-flex align-items-center">
                        <span>Employee's Signature: </span>

                    </div>

                    <div className="bottom mt-3 d-flex align-items-center">
                        <span>Date: </span>

                    </div>
            </div>
        </div>
    )
}

export default BankingDocumentationForm
