import React from "react";
import AppHeader from "../../../components/app-header/public-header";
import ForgotPasswordForm from "../../../components/authentication/ForgotPasswordForm";
import Footer from "../../../components/app-footer/Footer";
import FooterNew from "../../../components/app-footer/FooterNew";

export default function ForgotPasswordPage(props) {
  return (
    <>
      <AppHeader />
      <ForgotPasswordForm />
      <FooterNew />
    </>
  );
}
