import {apiUrl} from "../utils/constants";
import {GET} from "./api.service.wrapper";


export const GetPage = async (args) => {
    const response = await GET(apiUrl.pages, args);
    return response;
}

export const GetFaq = async () => {
    const response = await GET(apiUrl.faqs);
    return response;
}
