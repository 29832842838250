import React, { useState } from "react"
import Assets from "../../constants/images"
import { Container, Row, Col } from "react-bootstrap"
import { LazyLoadImage } from "react-lazy-load-image-component";

const BrandSection = () => {
    const [data, setData] = useState([
        Assets.Logo1,
        Assets.Logo2,
        Assets.Logo3,
        Assets.Logo4,
        Assets.Logo5,
        Assets.Logo6,
        Assets.Logo7
    ])
    return (
        <div className="BrandSection py-5">
            <Container>
                <Row>
                    <div className="d-flex flex-wrap flex-md-nowrap align-item-center justify-content-between h-100 my-auto">
                        {
                            data.map((val, i) => (
                                <div className="mx-2 my-2 img-container my-auto" key={i}>
                                    <LazyLoadImage effect="opacity" src={data[i]} alt="" />
                                </div>
                            ))
                        }
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default BrandSection