import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Assets from "../../constants/images";
import { LazyLoadImage } from "react-lazy-load-image-component";

SwiperCore.use([Autoplay]);

const HowItWorks = () => {
    return (
        <div className="HowItWorks">
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className={"mx-auto text-center"}>
                            <h2>How It Works</h2>
                            <p>Very simple and easy steps to get project materials on your construction site</p>
                        </div>
                    </Col>

                    <Col xs={12} className="mt-4">
                        <Swiper
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 3000 }}
                            className="mb-2"
                            spaceBetween={20}
                            breakpoints={{
                                200: {
                                    slidesPerView: 1,
                                },
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 3,
                                },
                                1300: {
                                    slidesPerView: 3,
                                },
                            }}
                        >

                            <SwiperSlide>
                                <div className="box-container">
                                    <div className="img-div">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.CardImg1} alt="" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h1 className="m-0 p-0 me-4">1</h1>
                                        <span>Choose from over 200,000+ Products and Project Essentials</span>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="box-container">
                                    <div className="img-div">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.CardImg2} alt="" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h1 className="m-0 p-0 me-4">2</h1>
                                        <span>Schedule delivery </span>
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="box-container">
                                    <div className="img-div">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.CardImg3} alt="" />
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <h1 className="m-0 p-0 me-4">3</h1>
                                        <span>Get Started on Your Project</span>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default HowItWorks