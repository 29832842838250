import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Nav, Row} from "react-bootstrap";
import {AppLogo, AuthLogo, EyeCloseIcon, EyeIcon} from "../../constants/svgs";
import {FaArrowRight, FaFacebookF, FaGoogle} from "react-icons/fa";
import {RiTruckLine} from "react-icons/ri";
import {FiBell} from "react-icons/fi";
import {AiOutlineStar} from "react-icons/ai";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast, ToastContainer} from "react-toastify";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../constants/app-constants";
import {IsCustomerLogin} from "../../App";
import {EmptyLocalStorage, GetCurrentUser, Login, socialLogin} from "../../services/auth/auth.service"
import {auth, facebookProvider, googleProvider} from "../../config/firebase"
import {FacebookAuthProvider, signInWithPopup} from "firebase/auth";
import {constants} from "../../utils/constants";
import Logo from "../../assets/images/home-page/logo.png"
import jwt_decode from "jwt-decode";
import {useGoogleLogin, GoogleLogin} from "@react-oauth/google";

const LoginForm = () => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const [hidePassword, setHidePassword] = useState(true);
    const [showSignupOverlay, setShowSignupOverlay] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    const handleOnGoogleSuccess = async (response) => {

        let decoded = jwt_decode(response.credential);
        let data = {
            social_platform: "google",
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            image: decoded.picture,
            device_type: "web",
            device_token: "dummy",
            token: decoded.jti,
            client_id: decoded.sub,
            role_id: constants.ROLES.ROLE_USER
        }

        await socialLogin(data).then((result) => {
            if (result.status) {
                toast.success(result.message);
                const _user = GetCurrentUser()
                setIsCustomerLogin(_user.user)
                if (result.data.user.roles[0]?.id == constants.ROLES.ROLE_USER) {
                    navigate("/profile");
                } else {
                    navigate("/driver/profile-setup");
                }
            }
        }).catch((error) => {
            toast.error(error.message)
        })

    }

    const handleOnClick = async (provider) => {
        const response = await signInWithPopup(auth, provider)
        let email = ""

        if (!response) {
            console.log(response, "result from social login")
        }
        console.log(response, "social response-------------")

        if (response.user.email) {
            email = response.user.email
            console.log(response.user.email, "1st step")
        } else if (response._tokenResponse.email) {
            console.log(response._tokenResponse.email, "2nd step")
            email = response._tokenResponse.email
        } else {
            email = response.user.uid + "@cmore.com"
        }

        console.log(email, "email-------------------")

        let data = {
            social_platform: response.providerId,
            email: email,
            first_name: response._tokenResponse.firstName,
            last_name: response._tokenResponse.lastName,
            image: response.user.photoUrl,
            device_type: "web",
            device_token: "dummy",
            token: response.user.accessToken,
            client_id: response.user.uid,
            role_id: constants.ROLES.ROLE_USER
        }

        await socialLogin(data).then((result) => {
            if (result.status && result.data) {
                toast.success(result.message);
                const _user = GetCurrentUser()
                setIsCustomerLogin(_user.user)
                if (result.data.user.roles[0].id === constants.ROLES.ROLE_USER) {
                    navigate("/profile");
                } else {
                    navigate("/driver/profile-setup");
                }
            }
        })

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const credential = FacebookAuthProvider.credentialFromResult(res);
        // const token = credential.accessToken;


    }

    const onSubmit = async (data) => {
        await Login(data.email, data.password, 20, "web", "xyz").then((data) => {
            if (data.status && data.data) {
                toast.success(data.message);
                setIsCustomerLogin(data.data.user)
                if (data.data.user.roles[0].id === constants.ROLES.ROLE_USER) {
                    navigate("/profile")
                } else {
                    navigate("/driver/profile-setup");
                }
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            console.log(error, "login error")
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate("/");
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const openSignupModal = () => {
        document.body.scrollTop = 0
        setShowSignupOverlay(true)
        document.body.classList.add("handleSignupModal");
    }

    const closeSignupModal = () => {
        setShowSignupOverlay(false)
        document.body.classList.remove("handleSignupModal");
    }

    useEffect(() => {
        console.log(isCustomerLogin, "login form")
    }, [])

    const login = useGoogleLogin({
        onSuccess: response => handleOnGoogleSuccess(response),
        onError: () => console.log("Login Failed")
    });

    return (
        <>
            <div className="auth-page py-5" id="signin">
                <ToastContainer/>
                <Container>
                    <Row>
                        <Col xs={12} className="mb-5">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">My Account</li>
                                </ol>
                            </nav>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12} lg={12} xl={11}>
                            <div className="form-container login-form-container p-4 px-lg-5 py-lg-4">
                                <Row>
                                    <Col xs={{span: 12, order: 2}} md={12} lg={{span: 5, order: 1}} xl={6}>
                                        <span className="d-block d-lg-none">
                                            <img className={"img-fluid mb-4"} src={Logo} alt={"CMORE Supply"}/>
                                        </span>
                                        <h5 className="mt-4 mt-lg-0 pb-3 border-bottom">Login</h5>
                                        <span className="d-inline-block my-3">Login to your account</span>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <InputGroup>
                                                <FormControl
                                                    type="email"
                                                    name="email"
                                                    className="py-2"
                                                    placeholder="Email"
                                                    {...register("email",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.EMAIL,
                                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                            }
                                                        })
                                                    }
                                                />
                                            </InputGroup>
                                            {errors.email && <small className='text-red'>{errors.email.message}</small>}

                                            <InputGroup className="mt-2">
                                                <FormControl
                                                    name="password"
                                                    className="py-2"
                                                    placeholder="Password"
                                                    type={hidePassword ? "password" : "text"}
                                                    {...register("password",
                                                        {
                                                            minLength: {
                                                                value: VALIDATIONS.PASSWORD_MIN,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.PASSWORD_MAX,
                                                                message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                                <InputGroup.Text id="basic-addon2" className="eye-icon"
                                                                 onClick={() => setHidePassword(!hidePassword)}>{hidePassword ?
                                                    <EyeIcon/> : <EyeCloseIcon/>}</InputGroup.Text>
                                            </InputGroup>
                                            {errors.password &&
                                            <small className='text-red'>{errors.password.message}</small>}

                                            <div className="mt-3 d-flex align-items-center justify-content-between">
                                                <Form.Check
                                                    className="remember-me-check"
                                                    type="checkbox"
                                                    id="remember"
                                                    label="Remember me"
                                                />
                                                <NavLink style={{color:"#C32126", textDecoration:"none"}} to="/forgot">Forgot?</NavLink>
                                            </div>

                                            <Button className="w-100 bg-red btn-red my-3" type="submit">Login</Button>

                                            <div className="line-text">
                                                <hr/>
                                                <span className="text">Or login with</span>
                                            </div>

                                            <Button className="w-100 btn-fb mt-3 mb-2"
                                                    onClick={() => handleOnClick(facebookProvider)}><FaFacebookF
                                                className="icon text-white"/><span> Login with Facebook</span></Button>

                                            {/*<Button className="w-100 btn-google mb-4"
                                                onClick={() => login()}><FaGoogle
                                                    className="icon text-red" /><span
                                                        className="d-inline-block mx-2 text-red"> Login with Google</span></Button>*/}

                                            <GoogleLogin
                                                onSuccess={(response) => handleOnGoogleSuccess(response)}
                                                onError={() => {
                                                    console.log("Login Failed");
                                                }}
                                            />
                                            <span className="mt-4 d-flex align-items-center justify-content-center">Not register yet?<Nav.Link
                                                to="#" onClick={() => openSignupModal()}
                                                className="weight-600 text-underline">Sign up now</Nav.Link></span>
                                        </Form>
                                    </Col>

                                    <Col className="d-none d-lg-block" xs={{span: 12, order: 1}} md={12}
                                         lg={{span: 6, offset: 1, order: 2}} xl={{span: 5, offset: 1}}>
                                        {/*<AuthLogo/>*/}
                                        <img class={"img-fluid mb-4"} src={Logo} alt={"CMORE Supply"}/>
                                        <div className="mt-md-0 mt-lg-3">
                                            <h5>Advantages of becoming a member</h5>
                                            <p>
                                                CMORE Buyer Protection has you covered from click to delivery. Sign up
                                                or sign in and you will be able to:
                                            </p>
                                            <ul className="mt-4">
                                                <li>
                                                    <RiTruckLine className="text-red icon me-3"/>Easily track orders,
                                                    hassle free returns
                                                </li>
                                                <li><FiBell className="text-red icon me-3"/>Get relevant alerts and
                                                    suggestions
                                                </li>
                                                <li><AiOutlineStar className="text-red icon me-3"/>Reviews, ratings and
                                                    more.
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                showSignupOverlay &&
                <div className="signup-overlay">
                    <div className="overlay-content w-100">
                        <div className="w-100">
                            <div className="text-end text-white mb-5 custom-margin" style={{cursor: "pointer"}}
                                 onClick={() => closeSignupModal()}>Close
                            </div>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()} to="/signup"
                                state={{role: constants.ROLES.ROLE_USER}}>
                                Sign up as a customer
                                <FaArrowRight
                                    className="icon"/>
                            </NavLink>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup" state={{role: constants.ROLES.ROLE_DRIVER}}>
                                Sign up as a driver
                                <FaArrowRight className="icon"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default LoginForm;