import React from "react";
import { Col } from "react-bootstrap";
import { BsTwitter, BsLinkedin } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const ProfileCard = ({ image, name, post, description, position }) => {
  return (
    <Col xs={12} md={6} lg={12} className="mt-5">
      <div className={position == "left" ? "profile-card d-flex flex-column flex-lg-row" : "profile-card d-flex flex-column flex-lg-row-reverse"}>
        <div className="img-div">
          <img src={image} alt="image" />
        </div>

        <div className="info-div my-auto pt-4 pt-lg-0 px-lg-4">
          <h5>{name}</h5>
          <span>{post}</span>
          <p className="py-3">{description}</p>
          <div>
            <NavLink to="#"><BsTwitter className="icon me-3" /></NavLink>
            <NavLink to="#"><BsLinkedin className="icon me-3" /></NavLink>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default ProfileCard