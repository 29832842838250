import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Assets from "../../constants/images";
import {NavLink} from "react-router-dom";

export default function NewBanner(props) {
    // console.log("rpops at banner", props);
    return (
        <div className="home-page new-banner py-5 py-sm-0 d-sm-flex align-items-sm-center ">
            <Container>
                <Row>
                    <Col xs={12} md={8} lg={8} xl={5}>
                        <div className="banner-container">
                            <h1>Building Supply and Essentials!</h1>
                            <p>Delivering Your Needs On-Demand in 30 Minutes or Less</p>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
