import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Assets from "../../constants/images"
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutCmore = () => {
    return (
        <div className="AboutCmore py-5">
            <Container fluid className="py-5">
                <Row>
                    <Col xs={12} sm={6} className="mb-sm-4 mb-5">
                        <div className="box p-3 bg-grey">
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.CarImage1} alt="" />
                            <div className="text-container">
                                <h6>We have huge stock from all the famous brands</h6>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} className="mb-sm-4 mb-5">
                        <div className="box p-3 bg-grey">
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.CarImage2} alt="" />
                            <div className="text-container">
                                <h6>Fastest Delivery Whenever and Wherever You Want</h6>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} className="mb-4 text-center">
                        <div>
                            <h4><b>About <span>C</span>MORE Supply</b></h4>
                            <p>Equipment and Materials. On Demand!</p>
                        </div>
                    </Col>

                    <Col xs={12} sm={6}>
                        <div>
                            <p>
                                Project development comes with many surprises and costly adjustments. Regardless of project size, take comfort in knowing we are here to assist by supplying the highest quality building materials, tools, and project essentials from brands you have grown to trust, with delivery as quick as 30 minutes.
                                <br /> <br />
                                We were built by and for hardworking individuals with a passion for getting the job done. Countless time and dollars are lost to the sourcing and transport of project essentials. With the CMORE Supply app, we keep your bottom line intact and your team productive.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutCmore