import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import AppHeader from "../../components/app-header/public-header";
import {GetTransactions} from "../../services/orders.service";
import Moment from "moment";
import {constants} from "../../utils/constants";
import FooterNew from "../../components/app-footer/FooterNew";

const columns = [
    {
        field: "date",
        headerName: "Date",
        minWidth: 200,
        flex: 1,
    },
    {
        field: "order_number",
        headerName: "Order Number",
        minWidth: 150,
        flex: 1,
    },
    {
        field: "amount",
        headerName: "Amount",
        minWidth: 150,
        flex: 1,
    },
    {
        field: "payment_method",
        headerName: "Payment method",
        minWidth: 150,
        flex: 1,
    },
];


const ViewAllTransactionsPage = () => {

    const [transactions, setTransactions] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);

    const fetchTransactions = async () => {
        await GetTransactions(page, perPage).then((res) => {
            if (res.status && res.data.data) {
                setPage(page)
                setTotalRows(res.data.meta.total);
                let rows = []
                res.data.data.map((item) => {
                    rows.push({
                        id: item.id,
                        date: Moment(item.created_at).format("YYYY-MM-DD"),
                        order_number: item.order?.order_number,
                        amount: item.amount.toFixed(2),
                        payment_method: item.order?.payment_method_
                    })
                })
                setTransactions(rows)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchTransactions()
    }, [page])

    return (
        <>
            <AppHeader/>

            <div className="transaction-history-page py-5">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Transaction history</li>
                                </ol>
                            </nav>
                            <h1>Transaction history</h1>
                        </Col>

                        <Col xs={12} md={8}>
                            <div style={{height: 350, width: "100%"}}>
                                <DataGrid
                                    className="data-table"
                                    rows={transactions}
                                    rowCount={totalRows}
                                    columns={columns}
                                    // pageSize={perPage}
                                    pagination
                                    page={page}
                                    pageSize={perPage}
                                    paginationMode="server"
                                    onPageChange={(newPage) => {
                                        console.log(newPage, "new page")
                                        setPage(newPage)
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterNew/>
        </>
    )
}

export default ViewAllTransactionsPage;