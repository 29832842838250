import React, {useEffect, useState} from "react";
import { Nav } from "react-bootstrap";
import {useLocation} from "react-router-dom";

const Navigation = () => {
    let location = useLocation()
    const [total_reviews, setTotalReviews] = useState(0)

    useEffect(() => {
        setTotalReviews(location.state.total_reviews)
    },[location])

    return (
        <Nav variant="pills" className="pills-tabs flex-column bg-dark-grey p-4 mb-4">
            <Nav.Item className="border-bottom">
                <Nav.Link eventKey="first">Description</Nav.Link>
            </Nav.Item>
            {/*<Nav.Item className="mt-2">*/}
            {/*    <Nav.Link eventKey="second">Reviews ({total_reviews})</Nav.Link>*/}
            {/*</Nav.Item>*/}
        </Nav>
    )
}

export default Navigation