import React, { useState, useContext } from "react";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import ProductCard from "../../components/categories-section/ProductCard";
import ProductCardListView from "../../components/categories-section/ProductCardListView";
import Assets from "../../constants/images";
import SearchResultHeader from "../../components/cmore-supply/SearchResultHeader";
import Filter from "../../components/cmore-supply/Filter";
import { FiFilter } from "react-icons/fi";
import { FilterSidebar } from "../../App";
import { AllProducts } from "../../App";
import FooterNew from "../../components/app-footer/FooterNew";

const SearchResultPage = ({ heading }) => {
    const [view, setView] = useState("grid");
    const [showFilterSidebar, setShowFilterSidebar] = useContext(FilterSidebar);
    const [product, setProduct] = useContext(AllProducts);

    const handleChange = (views) => {
        setView(views);
    }

    // const product = [
    //     { id:"0", image: Assets.ProductImage1, title: "DeWalt DCK266P2T 18V XR Brushless Combi & Impact Driver", rating: 5, buy: "312", price: "134.90" },
    //     { id:"1", image: Assets.ProductImage2, title: "Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black", rating: 5, buy: "312", price: "134.90" },
    //     { id:"2", image: Assets.ProductImage3, title: "DeWalt DCG412N-XJ 18V XR Grinder Bare Unit", rating: 5, buy: "312", price: "134.90" },
    //     { id:"3", image: Assets.ProductImage1, title: "Paslode IM65 F16 Brad Nailer 1 X Li-Ion Battery & Accessories", rating: 5, buy: "312", price: "134.90" },
    //     { id:"4", image: Assets.ProductImage2, title: "DeWalt DCK266P2T 18V XR Brushless Combi & Impact Driver", rating: 5, buy: "312", price: "134.90" },
    //     { id:"5", image: Assets.ProductImage3, title: "Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black", rating: 5, buy: "312", price: "134.90" },
    //     { id:"6", image: Assets.ProductImage1, title: "DeWalt DCG412N-XJ 18V XR Grinder Bare Unit", rating: 5, buy: "312", price: "134.90" },
    //     { id:"7", image: Assets.ProductImage2, title: "Paslode IM65 F16 Brad Nailer 1 X Li-Ion Battery & Accessories", rating: 5, buy: "312", price: "134.90" },
    // ]

    return (
        <>
            <div className="cmore-supply category-page category-section search-result-page">
                <AppHeader />
                <div className="cmore-supply-container category-container py-5">
                    <Container>
                        <Row>
                            <SearchResultHeader onClick={handleChange} heading={heading} />
                        </Row>

                        <Row className="d-block d-md-none">
                            <Col xs={12}>
                                <div style={{ cursor: "pointer" }} className="mb-md-3" onClick={() => setShowFilterSidebar(true)}>
                                    <FiFilter className="text-white" />
                                    <span className="text-white ms-3 d-inline-block">Filters</span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={3} className="filter mb-4">
                                <Filter />
                            </Col>

                            <Col xs={12} md={9} className="mt-0 mt-lg-4">
                                <Row>
                                    {
                                        view === "grid" ?
                                            product.map((v, i) => {
                                                return <ProductCard id={v.id} key={i} index={i} image={v.image} title={v.title} rating={v.rating} buy={v.buy} price={v.price} weight={v.weight} />
                                            }) :
                                            product.map((v, i) => {
                                                return <ProductCardListView id={v.id} key={i} index={i} image={v.image} title={v.title} rating={v.rating} buy={v.buy} price={v.price} weight={v.weight} />
                                            })
                                    }
                                </Row>

                                <Row className="text-center justify-content-center mb-5 mt-5">
                                    <Col xs={12} sm={3}>
                                        <Pagination>
                                            <Pagination.Prev className="btn-prev" />
                                            <Pagination.Item className="first">{1}</Pagination.Item>
                                            <Pagination.Item className="last">{10}</Pagination.Item>
                                            <Pagination.Next className="btn-next" />
                                        </Pagination>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterNew />
            </div>
        </>
    )
}

export default SearchResultPage