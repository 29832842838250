import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Assets from "../../constants/images"
import { ArrowIcon } from "../../constants/svgs"
import { LazyLoadImage } from "react-lazy-load-image-component";

const Blog = () => {
    return (
        <div className="OurBlog py-5">
            <Container fluid>
                <Row>
                    <Col xs={12} className="mb-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="m-0 me-3"><b>Who We Serve</b></h4>
                            {/*<Button>Read All <ArrowIcon /></Button>*/}
                        </div>
                    </Col>

                    <Col xs={12} sm={6} xl={4} className="cutom-margin-bottom">
                        <div className="box">
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.BlogImage1} alt="" />
                            <div className="text-container">
                                <small className="d-inline-block mb-2">Do-It-Yourself Projects</small>
                                <h6>Relax, place your order, and have your project items delivered directly to your doorstep.</h6>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} xl={4} className="cutom-margin-bottom">
                        <div className="box">
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.BlogImage2} alt="" />
                            <div className="text-container">
                                <small className="d-inline-block mb-2">Residential Projects</small>
                                <h6>Material runs should not cost your project extra time and money. Take advantage of our 30-minute delivery.</h6>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={6} xl={4} className="cutom-margin-bottom">
                        <div className="box">
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.BlogImage3} alt="" />
                            <div className="text-container">
                                <small className="d-inline-block mb-2">Commercial Projects</small>
                                <h6>Large project? No Problem. At no charge you will receive a designated Project Liaison that will manage your projects materials and essentials.</h6>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default Blog