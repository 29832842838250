import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { DataGrid } from "@mui/x-data-grid";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";
import FooterNew from "../../components/app-footer/FooterNew";

const ProductTablePage = () => {
    const rows = [
        {
            id: "1",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: "",
        },
        {
            id: "2",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""
        },
        {
            id: "3",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""

        },
        {
            id: "4",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""
        },
        {
            id: "1",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: "",
        },
        {
            id: "2",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""
        },
        {
            id: "3",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""

        },
        {
            id: "4",
            title: "ESSENTIALS CFSEWH18 50 cm Electric Solid Plate Cooker - White",
            product_slug: "Cooker",
            is_essential: "0",
            description: "90 x 60 x 60 cm (H x W x D) Two electric ovens / Integrated electric grill",
            weight: "30",
            price: "$179.00",
            sku: "C1ME-01",
            category_name: "Cooking",
            type: "Cooker",
            brand_name: "ESSENTIALS",
            product_image: ""
        },
    ]

    const columns = [
        {
            field: "title",
            headerName: "Title",
            minWidth: 500,
            flex: 1,
            align:"left",
        },
        {
            field: "product_slug",
            headerName: "Product Slug",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "is_essential",
            headerName: "Is Essential",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 500,
            flex: 1,
            align:"left",
        },
        {
            field: "weight",
            headerName: "Weight(KG)",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "price",
            headerName: "Price",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "sku",
            headerName: "sku",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "category_name",
            headerName: "Category Name",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "type",
            headerName: "Type",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "brand_name",
            headerName: "BrandName",
            minWidth: 150,
            flex: 1,
            align:"left",
        },
        {
            field: "product_image",
            headerName: "Product Image",
            minWidth: 200,
            flex: 1,
            align:"left",
            renderCell: (params) => (
                <Button className="btnUpload">Photo</Button>
            ),
        },
    ];

    return (
        <>
            <NewDriverHeader />
            <div className="ProductProductTable text-center">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="mt-4 d-flex align-items-center justify-content-between">
                                <h1 className="m-0 p-0">Products</h1>
                                <label className="btnUpload p-2" htmlFor="csv">
                                <input className="d-none" id="csv" type="file"  />
                                + Upload CSV
                                </label>                               
                            </div>

                            <div className="mt-4" style={{ height: 530, width: "100%" }}>
                                <DataGrid
                                    disableColumnMenu
                                    disableColumnFilter
                                    disableColumnSelector
                                    className="text-white data-table"
                                    rows={rows}
                                    columns={columns}
                                    pageSize={10}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterNew />
        </>
    )
}

export default ProductTablePage

