import React, {useContext, useState, useEffect, useRef} from "react";
import {Button, Col, Row, Container} from "react-bootstrap"
import FooterNew from "../../../components/app-footer/FooterNew"
import NewDriverHeader from "../../../components/app-header/NewDriverHeader"
import Assets from "../../../constants/images"
import DriverProfileModal from "../../../components/profile-modal/DriverProfileModal";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../../App";
import {BsChevronLeft} from "react-icons/bs";
import {GetOrderDetail, UpdateOrderStatus} from "../../../services/orders.service";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {constants, orderStatus} from "../../../utils/constants";
import {handleBrokenImageOnError} from "../../../utils/helper";
import Moment from "moment";

const pageTitle = {
    [orderStatus.ORDERED]: "New Orders",
    [orderStatus.ACCEPTED]: "Current Orders",
    [orderStatus.DISPATCHED]: "Past Orders",
    [orderStatus.DELIVERED]: "Completed Orders"
}

const SingleCurrentOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [data, setData] = useState(null);
    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    async function getOrderDetails(id) {
        await GetOrderDetail(id).then(async (result) => {
            if (result.status && result.data) {
                await setData(result.data)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    async function updateOrderStatus(id, status) {
        await UpdateOrderStatus(id, {status: status}).then(async (result) => {
            if (result.status) {
                toast.success(result.message);
                setTimeout(function () {
                    navigate("/driver/orders", {state: {page: orderStatus.ORDERED}});
                }, 1000)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    const changeOrderStatus = (id, status) => {
        updateOrderStatus(id, status)
    }

    useEffect(() => {
        getOrderDetails(location.state?.id)
    }, [])


    return (
        <>
            <NewDriverHeader/>
            <div className="summary-trips">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right py-5">
                        <Container>
                            <Row>
                                <Col className="d-block d-md-none" xs={12}>
                                    <div className="text-end">
                                        <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                      className="text-white icon-setting"/>
                                        {
                                            showDriverSidebar == false &&
                                            <hr/>
                                        }
                                    </div>
                                </Col>

                                <Col xs={12}>
                                    <h1><BsChevronLeft className="chiv-icon-left"/> {pageTitle[location.state?.status]}
                                    </h1>
                                </Col>

                                <Col xs={12} sm={12} md={12} lg={10} xl={8} className="mt-4">
                                    <div className="order-summary-modal OrderSummaryNew text-white">
                                        <div className="px-3 pb-3">
                                            <Row className="mx-1 border-row">
                                                <Col xs={12} className="border-bottom">
                                                    <div className="py-3">
                                                        <h5 className="m-0">Order Details</h5>
                                                    </div>
                                                </Col>

                                                <Col xs={12} className="border-bottom">
                                                    <Row>
                                                        <Col xs={6}>
                                                            <div className="py-3">
                                                                <h5 className="m-0">Order ID</h5>
                                                            </div>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <div className="py-3">
                                                                <h5 className="m-0 text-red">{data?.order_number}</h5>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={12} className="mb-4">
                                                    <div className="d-none d-sm-block cart-table pt-3 px-4">
                                                        <Row>
                                                            <Col xs={5}>
                                                                <h5>Item summary</h5>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <h5 className="text-center">Price</h5>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <h5 className="text-center">Qty</h5>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <h5 className="text-center">Total price</h5>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {
                                                        data?.order_products && data?.order_products.map((item, key) => (
                                                            <Row className="mb-2">
                                                                <Col xs={12} sm={5}>
                                                                    <div
                                                                        className="mt-2 mt-sm-0 d-flex flex-column flex-sm-row align-items-sm-center">
                                                                        <img
                                                                            src={item.product?.attachments[0]?.mediaUrl}
                                                                            className="img-order"
                                                                            onError={(e) => handleBrokenImageOnError(e)}
                                                                            alt="Product Image"/>
                                                                        <span
                                                                            className="ms-sm-2 description mt-2 mt-sm-0 text-grey">{item.product?.name}</span>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12} sm={2}>
                                                                    <div className="text-sm-center mt-2 mt-sm-0">
                                                                        <span className="text-grey"><span
                                                                            className="text-white d-inline-block d-sm-none">Price:</span> ${item.price}</span>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={12} sm={2}>
                                                                    <div className="text-sm-center mt-2 mt-sm-0">
                                                                        <span className="text-grey"><span
                                                                            className="text-white d-inline-block d-sm-none">Qty:</span>  x{item.quantity}</span>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={6} sm={2} className="text-grey">
                                                                    <div className="text-sm-center mt-2 mt-sm-0">
                                                                        <span className="text-grey"><span
                                                                            className="d-inline-block d-sm-none text-white">Total price:</span> ${item.total}</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        ))
                                                    }

                                                </Col>
                                            </Row>

                                            <Row className="mt-4">
                                                <Col xs={12} md={6} className="mb-4">
                                                    <div className="p-2">
                                                        <h5>Customer & order details</h5>
                                                        <div>
                                                            <div
                                                                className="border-bottom border-top py-2 d-flex align-items-center justify-content-between">
                                                                <span className="text-grey heading">Customer name</span>
                                                                <span
                                                                    className="text-grey">{data?.billing_first_name + " " + data?.billing_last_name}</span>
                                                            </div>

                                                            <div
                                                                className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                                <span className="text-grey heading">Phone number</span>
                                                                <span className="text-grey">{data?.billing_phone}</span>
                                                            </div>

                                                            <div
                                                                className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                                <span className="text-grey heading">Weight</span>
                                                                <span className="text-grey">{data?.weight} lbs</span>
                                                            </div>

                                                            <div
                                                                className="py-2 d-flex align-items-center justify-content-between">
                                                                <span className="heading text-grey">Truck Type</span>
                                                                <span
                                                                    className="text-grey">{data?.vehicle?.name || "-"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xs={12} md={6} className="mb-4">
                                                    <div className="p-2">
                                                        <h5>Order summary</h5>
                                                        <div>
                                                            <div
                                                                className="border-bottom border-top py-2 d-flex align-items-center justify-content-between">
                                                                <span className="text-grey heading">Order created</span>
                                                                <span
                                                                    className="text-grey">{Moment(data?.created_at).format("YYYY-MM-DD")}</span>
                                                            </div>

                                                            <div
                                                                className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                                <span className="heading text-grey">Sub total</span>
                                                                <span
                                                                    className="text-grey">${data?.sub_total_amount}</span>
                                                            </div>

                                                            <div
                                                                className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                                <span className="heading text-grey">Shipping</span>
                                                                <span
                                                                    className="text-grey">${data?.shipping_fees}</span>
                                                            </div>

                                                            <div
                                                                className="py-2 d-flex align-items-center justify-content-between">
                                                                <span className="heading text-grey">Total</span>
                                                                <span className="text-grey">${data?.total_amount}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs={12}>
                                                    <div className="p-2">
                                                        <h5>Delivery address</h5>
                                                        <div
                                                            className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                            <span className="text-grey heading">Address line:</span>
                                                            <span
                                                                className="text-grey">{data?.shipping_address?.street + ", " + data?.shipping_address?.city + ", " + data?.shipping_address?.country}</span>
                                                        </div>

                                                        <div
                                                            className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                            <span
                                                                className="text-grey heading">Flat/building name</span>
                                                            <span
                                                                className="text-grey">{data?.shipping_address?.street}</span>
                                                        </div>

                                                        <div
                                                            className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                            <span className="text-grey heading">Street name</span>
                                                            <span
                                                                className="text-grey">{data?.shipping_address?.street}</span>
                                                        </div>

                                                        <div
                                                            className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                                            <span className="text-grey heading">Post code</span>
                                                            <span
                                                                className="text-grey">{data?.shipping_address?.zip}</span>
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col xs={12} className="my-4">
                                                    {
                                                        (orderStatus.ORDERED === location.state?.status) ?

                                                            <div>
                                                                <Button className="btnAccept me-2"
                                                                        onClick={() => changeOrderStatus(data?.id, constants.ORDERSTATUS.ACCEPTED)}>Accept</Button>
                                                                <Button className="btnAccept me-2"
                                                                        onClick={() => changeOrderStatus(data?.id, constants.ORDERSTATUS.CANCELED)}>Reject</Button>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        (orderStatus.ACCEPTED === location.state?.status) ?

                                                            <div>
                                                                <Button className="btnAccept me-2"
                                                                        onClick={() => changeOrderStatus(data?.id, constants.ORDERSTATUS.DISPATCHED)}>Dispatch</Button>
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        (orderStatus.DISPATCHED === location.state?.status) ?

                                                            <div>
                                                                <Button className="btnAccept me-2"
                                                                        onClick={() => changeOrderStatus(data?.id, constants.ORDERSTATUS.DELIVERED)}>Complete</Button>
                                                            </div>
                                                            : ""
                                                    }

                                                    {/*<Button className="btnReject" onClick={()=>changeOrderStatus(data?.id, constants.ORDERSTATUS.CANCELED)}>Reject</Button>*/}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <FooterNew/>
        </>

    )
}

export default SingleCurrentOrder