import {apiUrl} from "../utils/constants";
import {GET, POST} from "./api.service.wrapper";


export const GetOrderHistory = async (args) => {
    return await GET(apiUrl.orders, args);
}

export const GetOrderDetail = async (id) => {
    return await GET(apiUrl.orders +"/"+ id);
}

export const Checkout = async (args) => {
    return await POST(apiUrl.checkout, args);
}

export const ReturnOrder = async (args) => {
    return await POST(apiUrl.return_order, args);
}

export const GetDriverOrders = async (args) => {
    return await GET(apiUrl.driver_orders, args);
}

export const UpdateOrderStatus = async (id, args) => {
    return await GET(apiUrl.update_order_status +"/"+ id, args);
}

export const GetShippingCharges = async (args) => {
    return await GET(apiUrl.get_shipping_charges, args);
}

export const GetTransactions = async (page = "", perPage = "") => {
    let params ={}

    if (page) {
        params["page"] = page;
    }

    if (perPage) {
        params["per-page"] = perPage;
    }
    params["is_mine"] = true
    return await GET(apiUrl.transactions, params);
}
