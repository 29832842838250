import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Nav, Row} from "react-bootstrap";
import {AppLogo, AuthLogo, EyeCloseIcon, EyeIcon} from "../../constants/svgs";
import {FaFacebookF, FaGoogle} from "react-icons/fa";
import {RiTruckLine} from "react-icons/ri";
import {FiBell} from "react-icons/fi";
import {AiOutlineStar} from "react-icons/ai";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast, ToastContainer} from "react-toastify";
import {
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT
} from "../../constants/app-constants";
import {EmptyLocalStorage, GetCurrentUser, Register, socialLogin} from "../../services/auth/auth.service";
import {auth, facebookProvider, googleProvider} from "../../config/firebase";
import {signInWithPopup} from "firebase/auth";
import {IsCustomerLogin} from "../../App";
import {constants} from "../../utils/constants";
import {GetCities, GetCountries, GetStates, GetZipCodes} from "../../services/states.service";
import Logo from "../../assets/images/home-page/logo.png";
import {GoogleLogin} from "@react-oauth/google";
import jwt_decode from "jwt-decode";


const SignupFrom = () => {
    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate();
    const location = useLocation();
    const [hidePassword, setHidePassword] = useState(true);
    const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
    const password = watch("password");
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [isLoading, setIsLoading] = useState(false)
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [zipCodes, setZipCodes] = useState([])

    const handleOnGoogleSuccess = async (response) => {

        let decoded = jwt_decode(response.credential);
        let data = {
            social_platform: "google",
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            image: decoded.picture,
            device_type: "web",
            device_token: "dummy",
            token: decoded.jti,
            client_id: decoded.sub,
            role_id: location.state.role
        }

        await socialLogin(data).then((result) => {
            if (result.status) {
                toast.success(result.message);
                const _user = GetCurrentUser()
                setIsCustomerLogin(_user.user)
                if (result.data.user.roles[0]?.id == constants.ROLES.ROLE_USER) {
                    navigate("/profile");
                } else {
                    navigate("/driver/profile-setup");
                }
            }
        }).catch((error) => {
            toast.error(error.message)
        })

    }

    const handleOnClick = async (provider) => {
        const response = await signInWithPopup(auth, provider)
        let email = ""

        if (!response) {
            console.log(response, "result from social login")
        }

        if (response.user.email) {
            email = response.user.email
        } else if (response._tokenResponse.email) {
            email = response._tokenResponse.email
        } else {
            email = response.user.uid + "@cmore.com"
        }

        let data = {
            social_platform: response.providerId,
            email: email,
            first_name: response._tokenResponse.firstName,
            last_name: response._tokenResponse.lastName,
            image: response.user.photoUrl,
            device_type: "web",
            device_token: "dummy",
            token: response.user.accessToken,
            client_id: response.user.uid,
            role_id: location.state.role
        }

        await socialLogin(data).then((result) => {
            if (result.status) {
                toast.success(result.message);
                const _user = GetCurrentUser()
                setIsCustomerLogin(_user.user)
                if (result.data.user.roles[0]?.id == constants.ROLES.ROLE_USER) {
                    navigate("/profile");
                } else {
                    navigate("/driver/profile-setup");
                }
            }
        })

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // const credential = FacebookAuthProvider.credentialFromResult(res);
        // const token = credential.accessToken;


    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        let dt = {
            role_id: location.state.role,
            company_name: data.company_name,
            device_token: data.device_token,
            device_type: data.device_type,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            latitude: data.latitude,
            location: data.location,
            longitude: data.longitude,
            password: data.password,
            password_confirmation: data.password_confirmation,
            phone: data.phone,
        }

        if (data.country_id) {
            dt.country_id = data.country_id
        }
        if (data.state_id) {
            dt.state_id = data.state_id
        }
        if (data.city_id) {
            dt.city_id = data.city_id
        }
        if (data.zip_id) {
            dt.zip_id = data.zip_id
        }

        await Register(dt).then((data) => {
            setIsLoading(false)
            if (data.status) {
                toast.success(data.message);
                if (data.data.user.roles[0]?.id == constants.ROLES.ROLE_USER) {
                    navigate("/profile");
                } else {
                    navigate("/driver/profile-setup");
                }

            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            setIsLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                navigate("/");
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    const getCountries = async () => {
        await GetCountries().then((res) => {
            if (res.status) {
                setCountries(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStates = async (country_id) => {
        await GetStates({country_id: country_id}).then((res) => {
            if (res.status) {
                setStates(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getCities = async (state_id) => {
        await GetCities({state_id: state_id}).then((res) => {
            if (res.status) {
                setCities(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getZipCodes = async (city_id) => {
        await GetZipCodes({city_id: city_id}).then((res) => {
            if (res.status) {
                setZipCodes(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleOnChangeCountry = (e) => {
        getStates(e.target.value)
    }

    const handleOnChangeState = (e) => {
        getCities(e.target.value)
    }

    const handleOnChangeCity = (e) => {
        getZipCodes(e.target.value)
    }

    useEffect(() => {
        getCountries()
    }, [])

    return (
        <div className="auth-page py-5" id="signup">
            <ToastContainer/>
            <Container>
                <Row>
                    <Col xs={12} className="mb-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">My Account</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} lg={12} xl={11}>
                        <div className="form-container login-form-container p-4 px-lg-5 py-lg-4">
                            <Row>
                                <Col xs={{span: 12, order: 2}} md={12} lg={{span: 5, order: 1}} xl={6}>
                                    <span className="d-block d-lg-none"><AppLogo/></span>
                                    <h5 className="mt-4 mt-lg-0 pb-3 border-bottom">Sign Up</h5>
                                    <span className="d-inline-block my-3">Create an account</span>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <InputGroup>
                                            <FormControl
                                                onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                type="text"
                                                name="first_name"
                                                className="py-2"
                                                placeholder="First Name*"
                                                {...register("first_name",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.NAME,
                                                            message: VALIDATIONS_TEXT.NAME_MAX
                                                        },
                                                        pattern: {
                                                            value: /^[a-z0-9]+$/gi,
                                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.first_name &&
                                        <small className='text-red'>{errors.first_name.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <FormControl
                                                onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                type="text"
                                                name="last_name"
                                                className="py-2"
                                                placeholder="Last Name*"
                                                {...register("last_name",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.NAME,
                                                            message: VALIDATIONS_TEXT.NAME_MAX
                                                        },
                                                        pattern: {
                                                            value: /^[a-z0-9 ]+$/gi,
                                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.NAME_REQUIRED
                                                        },
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.last_name &&
                                        <small className='text-red'>{errors.last_name.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <FormControl
                                                type="email"
                                                name="email"
                                                className="py-2"
                                                placeholder="Email Address*"
                                                {...register("email",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.EMAIL,
                                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                        },
                                                        pattern: {
                                                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                        }
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.email && <small className='text-red'>{errors.email.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <Form.Select
                                                className="py-2"

                                                {...register("country_id", {})}
                                                onChange={handleOnChangeCountry}
                                            >
                                                <option value="" disabled={true} selected={true}>Select Country</option>
                                                {
                                                    countries && countries.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                        {errors.country &&
                                        <small className='text-red'>{errors.country.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <Form.Select
                                                className="py-2"
                                                {...register("state_id", {})}
                                                onChange={handleOnChangeState}
                                            >
                                                <option value="" disabled={true} selected={true}>Select State</option>
                                                {
                                                    states && states.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                        {errors.state &&
                                        <small className='text-red'>{errors.state.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <Form.Select
                                                className="py-2"
                                                {...register("city_id", {})}
                                                onChange={handleOnChangeCity}
                                            >
                                                <option value="" disabled={true} selected={true}>Select City</option>
                                                {
                                                    cities && cities.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                        {errors.city &&
                                        <small className='text-red'>{errors.city.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <Form.Select
                                                className="py-2"
                                                {...register("zip_id", {})}
                                            >
                                                <option value="" disabled={true} selected={true}>Select Zip Code
                                                </option>
                                                {
                                                    zipCodes && zipCodes.map((item) => (
                                                        <option value={item.id}>{item.zip}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </InputGroup>
                                        {errors.zip_id &&
                                        <small className='text-red'>{errors.zip_id.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <FormControl
                                                onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                type="text"
                                                className="py-2"
                                                placeholder="Street*"
                                                {...register("location",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.ADDRESS,
                                                            message: VALIDATIONS_TEXT.ADDRESS_MAX
                                                        },
                                                        pattern: {
                                                            value: /^[a-z0-9-, ]+$/gi,
                                                            message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.ADDRESS_REQURIED
                                                        },
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.location &&
                                        <small className='text-red'>{errors.location.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <FormControl
                                                type="number"
                                                name="phone"
                                                className="py-2"
                                                placeholder="Contact Number*"
                                                {...register("phone",
                                                    {
                                                        pattern: {
                                                            value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                            message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                        },
                                                        minLength: {
                                                            value: VALIDATIONS.PHONE_MIN,
                                                            message: VALIDATIONS_TEXT.PHONE_MIN
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.PHONE_MAX,
                                                            message: VALIDATIONS_TEXT.PHONE_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.PHONE_REQURIED
                                                        },
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                                        <InputGroup className="mt-2">
                                            <FormControl
                                                name="password"
                                                className="py-2"
                                                placeholder="Password*"
                                                type={hidePassword ? "password" : "text"}
                                                {...register("password",
                                                    {
                                                        minLength: {
                                                            value: VALIDATIONS.PASSWORD_MIN,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.PASSWORD_MAX,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.PASSWORD_REQUIRED
                                                        },
                                                    })
                                                }

                                            />
                                            <InputGroup.Text id="basic-addon2" className="eye-icon"
                                                             onClick={() => setHidePassword(!hidePassword)}>{hidePassword ?
                                                <EyeIcon/> : <EyeCloseIcon/>}</InputGroup.Text>
                                        </InputGroup>
                                        {errors.password &&
                                        <small className='text-red'>{errors.password.message}</small>}


                                        <InputGroup className="mt-2">
                                            <FormControl
                                                name="password_confirmation"
                                                className="py-2"
                                                placeholder="Confirm Password*"
                                                type={hideConfirmPassword ? "password" : "text"}
                                                {...register("password_confirmation",
                                                    {
                                                        minLength: {
                                                            value: VALIDATIONS.PASSWORD_MIN,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MIN
                                                        },
                                                        maxLength: {
                                                            value: VALIDATIONS.PASSWORD_MAX,
                                                            message: VALIDATIONS_TEXT.PASSWORD_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.CONFIRM_PASSWORD_REQUIRED
                                                        },
                                                        validate: (value) =>
                                                            value === password || "The Passwords do not match"
                                                    })
                                                }

                                            />
                                            <InputGroup.Text id="basic-addon2" className="eye-icon"
                                                             onClick={() => setHideConfirmPassword(!hideConfirmPassword)}>{hideConfirmPassword ?
                                                <EyeIcon/> : <EyeCloseIcon/>}</InputGroup.Text>
                                        </InputGroup>
                                        {errors.password_confirmation &&
                                        <small className='text-red'>{errors.password_confirmation.message}</small>}

                                        <InputGroup className=" mt-2">
                                            <FormControl
                                                onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()}
                                                type="text"
                                                name="company_name"
                                                className="py-2"
                                                placeholder="Company Name"
                                                {...register("company_name",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.NAME,
                                                            message: VALIDATIONS_TEXT.NAME_MAX
                                                        },
                                                        // required: {
                                                        //     value: true,
                                                        //     message: VALIDATIONS_TEXT.COMPANY_NAME_REQUIRED
                                                        // },
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.company_name &&
                                        <small className='text-red'>{errors.company_name.message}</small>}

                                        {
                                            !isLoading ?
                                                <Button className="w-100 btn-red my-3 mt-4" type="submit">Sign
                                                    Up</Button>
                                                :
                                                <Button className="w-100 btn-red my-3 mt-4">Submitting...</Button>
                                        }


                                        <div className="line-text">
                                            <hr/>
                                            <span className="text">Or login with</span>
                                        </div>

                                        <Button className="w-100 btn-fb mt-3 mb-2"
                                                onClick={() => handleOnClick(facebookProvider)}><FaFacebookF
                                            className="icon text-white"/><span> Login with Facebook</span></Button>
                                        {/*<Button className="w-100 btn-google mb-4"*/}
                                        {/*        onClick={() => handleOnClick(googleProvider)}><FaGoogle*/}
                                        {/*    className="icon text-red"/><span className="d-inline-block mx-2 text-red"> Login with Google</span>*/}
                                        {/*</Button>*/}
                                        <GoogleLogin
                                            onSuccess={(response) => handleOnGoogleSuccess(response)}
                                            onError={() => {
                                                console.log("Login Failed");
                                            }}
                                        />
                                        <span className="mt-4 d-flex align-items-center justify-content-center">Already registerd?<NavLink
                                            to="/login"
                                            className="weight-600 text-underline">Login now</NavLink></span>
                                    </Form>
                                </Col>

                                <Col className="d-none d-lg-block" xs={{span: 12, order: 1}} md={12}
                                     lg={{span: 6, offset: 1, order: 2}} xl={{span: 5, offset: 1}}>
                                    {/*<AuthLogo/>*/}
                                    <img className={"img-fluid mb-4"} src={Logo} alt={"CMORE Supply"}/>
                                    <div className="mt-md-0 mt-lg-3">
                                        <h5>Become a member.</h5>
                                        <p>
                                            CMORE Buyer Protection has you covered from click to delivery. Sign up or
                                            sign in and you will be able to:
                                        </p>
                                        <ul className="mt-4">
                                            <li>
                                                <RiTruckLine className="text-red icon me-3"/>Easily track orders, hassle
                                                free returns
                                            </li>
                                            <li><FiBell className="text-red icon me-3"/>Get relevant alerts and
                                                suggestions
                                            </li>
                                            <li><AiOutlineStar className="text-red icon me-3"/>Reviews, ratings and
                                                more.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignupFrom;