import React from "react";
import Assets from "../../constants/images";
import {NavLink} from "react-router-dom";

const Notification = ({notifications}) => {
    return (
        <div className="notification">
            {
                notifications &&
                notifications.map((notification, index) => (
                    <NavLink to="/order-history/order-details" state={{order_id: notification.ref_id}} key={index}>
                        <div className="notification-dropdown d-flex p-3">
                            <img src={Assets.ProductImage2} alt="ProductImage1"/>
                            <div
                                className="ms-3 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                                {/*<p className="title m-0 p-0">Your order #12345678 has arrived</p>*/}
                                <p className="title m-0 p-0">{notification.message}</p>
                                <p className="p-0 m-0 me-2 status">{notification.created_ago}</p>
                            </div>
                        </div>
                    </NavLink>
                ))
            }

        </div>
    )
}

export default Notification