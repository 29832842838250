import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Work = ({Icon1, Icon2, Icon3, text1, text2, text3}) => {
    return (
        <div className="home-page work-section py-5">
            <div className="work-container text-center py-5">
                <Container>
                    <Row className="justify-content-lg-center">
                        <Col xs={12}>
                            <h1>How It Works</h1>
                            <p>Proudly featuring over 20,000+ products from our partners</p>
                        </Col>
                    </Row>

                    <Row className="mt-4 mt-sm-5">
                        <Col xs={12} md={6} sm={6} lg={4} className="mb-4" >
                            <div className="text-center">
                                <Icon1 />
                                <div className="text-center mx-auto py-3">
                                    <span className="d-inline-block">1</span>
                                </div>
                                <p>{text1}</p>
                            </div>
                        </Col>

                        <Col xs={12} md={6} sm={6} lg={4} className="mb-4">
                            <div className="text-center">
                                <Icon2 />
                                <div className="text-center mx-auto py-3">
                                    <span className="d-inline-block">2</span>
                                </div>
                                <p>{text2}</p>
                            </div>
                        </Col>

                        <Col xs={12} md={6} sm={6} lg={4} className="mb-4" >
                            <div className="text-center">
                                <Icon3 />
                                <div className="text-center mx-auto py-3">
                                    <span className="d-inline-block">3</span>
                                </div>
                                <p>{text3}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Work