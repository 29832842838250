import React from "react";
import {Row, Col} from "react-bootstrap";

const CartCardHeader = () => {
    return (
        <div className="d-none d-sm-block text-center cart-table pt-3 px-4">
            <Row>
                <Col xs={6} className="text-center">
                    <h5>Product</h5>
                </Col>
                <Col xs={2}>
                    <h5 className="text-center">Quantity</h5>
                </Col>
                <Col xs={2}>
                    <h5 className="text-center">Price</h5>
                </Col>
                <Col xs={2}>
                    <h5 className="text-center">Total Price</h5>
                </Col>
            </Row>
        </div>
    )
}

export default CartCardHeader