import React, { useContext, useEffect, useRef, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { CHARACTER_ARRAY_NUMBER_INPUT, VALIDATIONS, VALIDATIONS_TEXT } from "../../constants/app-constants";
import { ImUpload3 } from "react-icons/im";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import { FaTimesCircle } from "react-icons/fa";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css"
import { IMask, IMaskMixin } from "react-imask";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { EmptyLocalStorage, UpdateProfile } from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { GetCities, GetCountries, GetStates, GetZipCodes } from "../../services/states.service";
import {
    GetCompanyTypes,
    GetEndorsements,
    GetLicenseTypes,
    GetProfile,
    GetTrailerTypes
} from "../../services/general.service";
import { UploadFileToS3 } from "../../utils/helper";
import Moment from "moment";
import moment from "moment";
import { IsCustomerLogin } from "../../App";

const PLACEHOLDER = "Social security number";
const MASK = [
    { mask: "" },
    {
        mask: "[000]-[00]-[0000]",
    }
];

const IMaskInput = IMaskMixin(({ inputRef, field }) => (
    <input
        placeholder="Social security number"
        className="py-2 form-control w-100 border-none"
        ref={inputRef}
        field={field}
    />
));

const MaskedInput = ({ defaultValue = "", field }) => {
    const [inputString, setInputString] = useState(defaultValue?.toString() || "");
    // const [value, setValue] = useState(defaultValue?.toString() || "");

    const handleAccept = (v) => {
        setInputString(v);
        field.onChange(v);
    };

    return (
        <IMaskInput
            field={field}
            mask={MASK}
            unmask
            onAccept={handleAccept}
            placeholder={PLACEHOLDER}
            value={inputString}
        />
    );
};

const SetupProfileForm = () => {
    const licenseFileRef = useRef();
    const insuranceFileRef = useRef();
    const profileFileRef = useRef();
    const { register, unregister, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [value, setValues] = useState({
        values: 1
    });
    const [startDate, setStartDate] = useState();
    const [dateOfBirth, setDateOfBirth] = useState();
    const years = range(1960, getYear(new Date()) + 1, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedLicenseType, setSelectedLicenseType] = useState(0)
    const [licenseTypeFileName, setLicenseTypeFileName] = useState(null)
    const [insuranceFileName, setInsuranceFileName] = useState(null)
    const [licenseTypeFile, setLicenseTypeFile] = useState(null)
    const [insuranceFile, setInsuranceFile] = useState(null)
    const [profileFile, setProfileFile] = useState(null)
    const [profileFileName, setProfileFileName] = useState(null)
    const [selectedCompanyType, setSelectedCompanyType] = useState(0)
    const [selectedTrailerType, setSelectedTrailerType] = useState(0)
    const [licenseTypes, setLicenseTypes] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [zipCodes, setZipCodes] = useState([])
    const [companyTypes, setCompanyTypes] = useState([])
    const [trailerTypes, setTrailerTypes] = useState([])
    const [endorsements, setEndorsements] = useState([])
    const [userData, setUserData] = useState([])
    const [licenceUploadedFile, setLicenceUploadedFile] = useState("")
    const [insuranceUploadedFile, setInsuranceUploadedFile] = useState("")
    const [dobDate, setDobDate] = useState(new Date())
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin)
    const [isLoading, setIsLoading] = useState(false)
    const [termsChecked, setTermsChecked] = useState(false)
    const [showTermsError, setShowTermsError] = useState(false)

    useEffect(() => {
        if (userData) {

            if (userData?.country_id) {
                fetchStates(userData?.country_id)
            }
            if (userData?.state_id) {
                fetchCities(userData?.state_id)
            }
            if (userData?.city_id) {
                fetchZipCodes(userData?.city_id)
            }

            reset({
                dateOfBirth: (userData.dob) ? new Date(userData.dob) : null,
                licenseType: userData?.driver_detail?.license_type,
                companyType: userData?.driver_detail?.company_type,
                travel: userData?.radius,
                street: userData?.location,
                trailerType: userData?.driver_detail?.trailer_type,
                vinNumber: userData?.driver_detail?.vn_number,
                dotNumber: userData?.driver_detail?.dot_number,
                mcNumber: userData?.driver_detail?.mc_number,
                socialSecurityNumber: userData?.driver_detail?.ssn_number,
                endorsement: userData?.driver_detail?.endorsement_id,
                insurance_policy_expiry: userData?.driver_detail?.insurance_policy_expiry,
                insurance_policy_number: userData?.driver_detail?.insurance_policy_number,
            })
        }
    }, [userData])

    useEffect(() => {
        getProfile()
        fetchLicenceTypes()
        fetchCompanyTypes()
        fetchTrailerTypes()
        fetchCountries()
        fetchEndorsements()
    }, [])

    const handleRequestForReg = (e) => {
        setShowTermsError(true)
    }

    async function getProfile() {
        await GetProfile().then((result) => {
            if (result.status && result.data) {
                let user = result?.data?.user;
                setDobDate(new Date(user.dob))
                console.log(user)

                setUserData(user)

                if (user && user.driver_detail) {
                    let driver = user.driver_detail;
                    setSelectedLicenseType(driver.license_type)
                    setSelectedCompanyType(driver.company_type)
                    setSelectedTrailerType(driver.trailer_type)
                    setValues({ values: user.radius })
                    setLicenceUploadedFile(driver.license_file)
                    setInsuranceUploadedFile(driver.insurance_policy_file)
                }


                //console.log("==resultprofiledata==", result.data.user)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    async function fetchStates(country_id) {
        await GetStates({ "order-column": "name", "order": "asc", country_id: country_id }).then((result) => {
            if (result.status && result.data) {
                setStates(result.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchCities(state_id) {
        await GetCities({ "order-column": "name", "order": "asc", state_id: state_id }).then((result) => {
            if (result.status && result.data) {
                setCities(result.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchZipCodes(city_id) {
        await GetZipCodes({ "order-column": "zip", "order": "asc", city_id: city_id }).then((result) => {
            if (result.status && result.data) {
                setZipCodes(result.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchCountries() {
        await GetCountries({ "order-column": "name", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setCountries(result.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchLicenceTypes() {
        await GetLicenseTypes({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setLicenseTypes(result.data.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchCompanyTypes() {
        await GetCompanyTypes({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setCompanyTypes(result.data.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchTrailerTypes() {
        await GetTrailerTypes({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setTrailerTypes(result.data.data)
            }
        }).catch((error) => {
        })
    }

    async function fetchEndorsements() {
        await GetEndorsements({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setEndorsements(result.data.data)
            }
        }).catch((error) => {
        })
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        const userObject = await JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_USER));
        let formData = new FormData();
        if (data?.trailerType) {
            formData.append("trailer_type", data.trailerType);
        }

        if (licenseTypeFile) {
            let uploadImage = await UploadFileToS3(licenseTypeFile);
            if (uploadImage.success) {
                formData.append("license_file", uploadImage.url);
            }
        }

        if (insuranceFile) {
            let uploadImage = await UploadFileToS3(insuranceFile);
            if (uploadImage.success) {
                formData.append("insurance_policy_file", uploadImage.url);
            }
        }

        if (profileFile) {
            let uploadImage = await UploadFileToS3(profileFile);
            if (uploadImage.success) {
                formData.append("image", uploadImage.url);
            }
        }

        if (data?.companyType) {
            formData.append("company_type", data.companyType);
        }
        formData.append("dob", Moment(dobDate).format("YYYY-MM-DD"));
        formData.append("dot_number", data?.dotNumber);
        if (data?.endorsement) {
            formData.append("endorsement_id", data?.endorsement);
        }
        formData.append("insurance_policy_expiry", data.insuranceExpiryDate ? moment(data.insuranceExpiryDate).format("YYYY-MM-DD") : "");
        formData.append("insurance_policy_number", data?.insurancePolicyNumber);
        if (data?.licenseType) {
            formData.append("license_type", data.licenseType);
        }
        if (data?.mcNumber) {
            formData.append("mc_number", data?.mcNumber);
        }
        if (data?.socialSecurityNumber) {
            formData.append("ssn_number", data?.socialSecurityNumber);
        }

        formData.append("radius", data?.travel);

        if (data?.vinNumber) {
            formData.append("vn_number", data?.vinNumber);
        }

        if (data.country_id) {
            formData.append("country_id", data.country_id);
        }
        if (data.state_id) {
            formData.append("state_id", data.state_id);
        }
        if (data.city_id) {
            formData.append("city_id", data.city_id);
        }
        if (data.zip_id) {
            formData.append("zip_id", data.zip_id);
        }
        if (data.street) {
            formData.append("location", data.street);
        }
        if (userData.is_driver_completed == 0) {
            formData.append("is_driver_completed", 1);
        }

        await UpdateProfile(userObject.id, formData).then((data) => {
            setIsLoading(false)
            if (data.status) {
                toast.success(data.message);
                console.log(data, "data user----------------")
                localStorage.setItem(constants.LOCAL_STORAGE_USER, JSON.stringify(data.data))
                setIsCustomerLogin(data.data)
                navigate("/driver/truck-vehicle-type");

            } else {
                toast.error(data.message);

            }
        }).catch((error) => {
            setIsLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()

                navigate("/");
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const handleSelectLicenseType = (licenseType) => {
        setSelectedLicenseType(licenseType)
    }

    const handleSelectCompanyType = (companyType) => {
        setSelectedCompanyType(companyType);
    }

    const handleSelectTrailerType = (checkbox_id) => {
        setSelectedTrailerType(checkbox_id)
    }

    const handleLicenseFileChange = (file) => {
        setLicenseTypeFile(file.target.files[0]);
        setLicenseTypeFileName(file.target.files[0].name)
    }

    const resetLicenseFile = () => {
        licenseFileRef.current.value = null;
        setLicenseTypeFile(null);
        setLicenseTypeFileName(null);
    }

    const handleInsuranceFileChange = (file) => {
        setInsuranceFile(file.target.files[0])
        setInsuranceFileName(file.target.files[0].name)
    }

    const handleProfileFileChange = (file) => {
        setProfileFile(file.target.files[0])
        setProfileFileName(file.target.files[0].name)
    }

    const resetInsuranceFile = () => {
        insuranceFileRef.current.value = null;
        setInsuranceFile(null);
        setInsuranceFileName(null);
    }

    const resetProfileFile = () => {
        profileFileRef.current.value = null;
        setProfileFile(null);
        setProfileFileName(null);
    }

    const handleChange = (v) => {
        let val = value.values
        setValues({
            values: v
        })
        setValue("travel", val)
    }

    const handleReset = () => {
        setSelectedCompanyType(0)

        setSelectedLicenseType(0)

        setSelectedTrailerType(0)
    }

    const maskedValue = (mask, value) => {
        let maskedValue = ""
        if (value.length > 0) {
            let masked = IMask.createMask({
                mask
            });
            maskedValue = (masked.resolve(value)).toString()
        }
        return maskedValue
    }

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const handleOnChangeCountry = (e) => {
        fetchStates(e.target.value)
    }

    const handleOnChangeState = (e) => {
        fetchCities(e.target.value)
    }

    const handleOnChangeCity = (e) => {
        fetchZipCodes(e.target.value)
    }

    return (
        <div className="setup-profile-container">
            <ToastContainer />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-card p-3">
                    <h5 className="mb-0"><span className="text-red">*</span> Select license type</h5>
                    <div className="d-flex flex-wrap align-items-center">
                        {
                            licenseTypes ?
                                licenseTypes.map((lt, index) => {
                                    return (
                                        <div key={index} className="position-div d-flex align-items-center">
                                            <Form.Check
                                                onClick={() => handleSelectLicenseType(lt.id)}
                                                name="licenseType"
                                                type="radio"
                                                id={"radio" + lt.id}
                                                value={lt.id}

                                                {...register("licenseType",
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.LICENSE_TYPE_REQUIRED
                                                        },
                                                    })
                                                }
                                                checked={selectedLicenseType == lt.id}
                                            />
                                            <Form.Label
                                                className={selectedLicenseType == lt.id ? "mt-2 me-2 py-1 checked-checkbox" : "py-1 mt-2 me-2"}
                                                htmlFor={"radio" + lt.id}>
                                                {lt.name}
                                            </Form.Label>
                                        </div>

                                    )
                                }) : " "
                        }
                    </div>

                    <div className="mt-2 d-flex  flex-wrap align-items-center">
                        <div className="upload-btn-wrapper">
                            <button className="btn"><ImUpload3 className="icon" /> Add file</button>
                            <input
                                type="file"
                                name="licenseFile"
                                accept="image/png, image/jpeg, .pdf, .docx"
                                {...register("licenseFile")}
                                onChange={(licenseFile) => handleLicenseFileChange(licenseFile)}
                                ref={licenseFileRef}
                            />
                        </div>

                        <div className="mt-2">
                            {
                                licenseTypeFileName !== null &&
                                <div className="d-flex align-items-center">
                                    {/*<span className="d-inline-block"><BiFile /></span>*/}
                                    <span className="d-inline-block ms-2"
                                        style={{ fontSize: "14px" }}>{licenseTypeFileName ? truncate(licenseTypeFileName, 25) : " "}</span>
                                    <FaTimesCircle className="ms-3 text-dark icons" onClick={resetLicenseFile} />
                                    {/*<RiDeleteBin5Line className="ms-3 text-dark icons" onClick={resetLicenseFile} />*/}
                                </div>
                            }
                        </div>
                        {/*<span>{licenseTypeFileName ? truncate(licenseTypeFileName, 25) : " "}</span>
                        <RiDeleteBin5Line title={"Remove File"} className="ms-3 icons" onClick={resetLicenseFile}/>*/}
                    </div>
                    <div>
                        {
                            userData?.driver_detail?.license_file ?
                                <div style={{ marginTop: "10px" }}>
                                    <a href={userData.driver_detail.license_file_path} target="_blank">
                                        <img style={{ width: "100px" }} src={userData.driver_detail.license_file_path} alt="-"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = constants.IMAGENOTFOUND;
                                            }} className="truck-img me-3 mb-2" />
                                    </a>
                                </div> : ""
                        }
                    </div>
                </div>
                {(errors.licenseType) && <small className="text-red">{errors.licenseType.message}</small>}

                <Form.Select
                    aria-label="Default select example"
                    className="mb-2 mt-2"
                    {...register("endorsement")}
                >
                    <option value="" disabled={true}>Select CDL Endorsement</option>
                    {
                        endorsements ? endorsements.map((end, index) => {
                            return (
                                <option selected={userData?.driver_detail?.endorsement_id == end.id} key={index}
                                    value={end.id}>{end.name}</option>
                            )
                        }) : " "
                    }
                </Form.Select>



                <div className="form-card p-3 mt-2 mb-2">
                    <h5 className="mb-0">Select company type</h5>
                    <div className="d-flex flex-wrap align-items-center">
                        {
                            companyTypes ?
                                companyTypes.map((ct, index) => {
                                    return (
                                        <div key={index} className="position-div d-flex align-items-center">
                                            <Form.Check
                                                onClick={() => handleSelectCompanyType(ct.id)}
                                                name="companyType"
                                                type="radio"
                                                id={"radioc" + ct.id}
                                                value={ct.id}
                                                checked={selectedCompanyType == ct.id}
                                                {...register("companyType")}
                                            />
                                            <Form.Label
                                                className={selectedCompanyType == ct.id ? "py-1 mt-2 me-2 checked-checkbox" : "py-1 mt-2 me-2"}
                                                htmlFor={"radioc" + ct.id}>
                                                {ct.name}
                                            </Form.Label>
                                        </div>
                                    )

                                }) : " "
                        }
                    </div>
                </div>

                {/*<div className="form-card p-3 mb-2">*/}
                {/*    <h5 className="mb-0">Select trailer type</h5>*/}
                {/*    <div className="d-flex flex-wrap align-items-center">*/}
                {/*        {*/}
                {/*            trailerTypes ?*/}
                {/*                trailerTypes.map((tt, index) => {*/}
                {/*                    return (*/}
                {/*                        <div key={index} className="position-div d-flex align-items-center">*/}
                {/*                            <Form.Check*/}
                {/*                                onClick={() => handleSelectTrailerType(tt.id)}*/}
                {/*                                name="trailerType"*/}
                {/*                                type="radio"*/}
                {/*                                id={"radiot" + tt.id}*/}
                {/*                                value={tt.id}*/}
                {/*                                checked={selectedTrailerType == tt.id}*/}
                {/*                                {...register("trailerType")}*/}
                {/*                            />*/}
                {/*                            <Form.Label*/}
                {/*                                className={selectedTrailerType == tt.id ? "py-1 mt-2 me-2 checked-checkbox" : "py-1 mt-2 me-2"}*/}
                {/*                                htmlFor={"radiot" + tt.id}>*/}
                {/*                                {tt.name}*/}
                {/*                            </Form.Label>*/}
                {/*                        </div>*/}
                {/*                    )*/}

                {/*                }) : " "*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Form.Select
                    aria-label="Default select example-1"
                    className="mb-2"

                    {...register("country_id")}
                    onChange={handleOnChangeCountry}
                >
                    <option value="" selected={true} disabled={true}>Select Country</option>
                    {
                        countries ? countries.map((item, index) => {
                            return (
                                <option selected={userData?.country_id == item.id} key={index} value={item.id}>{item.name}</option>
                            )
                        }) : " "
                    }

                </Form.Select>

                <Form.Select
                    aria-label="Default select example-1"
                    className="mb-2"
                    {...register("state_id")}
                    onChange={handleOnChangeState}
                >
                    <option value="" selected={true} disabled={true}>Select State</option>
                    {
                        states ? states.map((item, index) => {
                            return (
                                <option selected={userData?.state_id == item.id} key={index}
                                    value={item.id}>{item.name}</option>
                            )
                        }) : " "
                    }

                </Form.Select>

                <Form.Select
                    aria-label="Default select example-1"
                    className="mb-2"
                    {...register("city_id")}
                    onChange={handleOnChangeCity}
                >
                    <option value="" selected={true} disabled={true}>Select City</option>
                    {
                        cities ? cities.map((item, index) => {
                            return (
                                <option selected={userData?.city_id == item.id} key={index}
                                    value={item.id}>{item.name}</option>
                            )
                        }) : " "
                    }

                </Form.Select>

                <Form.Select
                    aria-label="Default select example-1"
                    className="mb-2"
                    {...register("zip_id")}
                >
                    <option value="" selected={true} disabled={true}>Select Zip Code</option>
                    {
                        zipCodes ? zipCodes.map((item, index) => {
                            return (
                                <option selected={userData?.zip_id == item.id} key={index}
                                    value={item.id}>{item.zip}</option>
                            )
                        }) : " "
                    }

                </Form.Select>

                <Form.Control
                    placeholder="Street"
                    className="mt-2 py-2"
                    type="text"
                    {...register("street")}
                />
                {errors.street && <small className="text-red">{errors.street.message}</small>}

                <div className="div-travel mt-2 px-3 d-flex justify-content-between align-items-center">
                    <span className="span-travel me-3 text-dark">Travel Length</span>
                    <div className='slider w-100 custom-labels'>
                        <Slider
                            className="unselectable"
                            min={1}
                            max={100}
                            step={0.5}
                            // labels={value.values.toString()}
                            handleLabel={value.values.toString()}
                            value={value.values}
                            onChange={(value) => handleChange(value)}
                        />
                    </div>
                </div>

                <Form.Control
                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                    name="vinNumber"
                    type="number"
                    placeholder="Vin number"
                    className="mt-2 py-2"
                    defaultValue={userData?.driver_detail?.vn_number}
                    {...register("vinNumber",
                        {
                            pattern: {
                                value: /^[0-9]+$/,
                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                            },
                            minLength: {
                                value: VALIDATIONS.VIN_NUMBER_MIN,
                                message: VALIDATIONS_TEXT.VIN_NUMBER_MIN
                            },
                            maxLength: {
                                value: VALIDATIONS.VIN_NUMBER_MAX,
                                message: VALIDATIONS_TEXT.VIN_NUMBER_MAX
                            },
                        })
                    }
                />
                {errors.vinNumber && <small className="text-red">{errors.vinNumber.message}</small>}

                <div className="div-white d-flex align-items-center mt-2 px-3">
                    <span className="text-red">*</span>
                    {/*<Controller*/}
                    {/*    name="socialSecurityNumber"*/}
                    {/*    render={({field}) => <MaskedInput defaultValue={(userData && userData?.driver_detail) ? userData.driver_detail.ssn_number : ""}*/}
                    {/*                                      field={field}/>}*/}
                    {/*    control={control}*/}
                    {/*    rules={{*/}
                    {/*        required: {*/}
                    {/*            value: true,*/}
                    {/*            message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_REQUIRED*/}
                    {/*        },*/}
                    {/*        minLength: {*/}
                    {/*            value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MIN,*/}
                    {/*            message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MIN*/}
                    {/*        },*/}
                    {/*        maxLength: {*/}
                    {/*            value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MAX,*/}
                    {/*            message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MAX*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {
                        userData?.driver_detail?.ssn_number == null &&
                        <Controller
                            name="socialSecurityNumber"
                            render={({ field }) => <MaskedInput field={field} />}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_REQUIRED
                                },
                                minLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MIN,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MAX,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MAX
                                }
                            }}
                        />
                    }

                    {
                        userData?.driver_detail?.ssn_number &&
                        <Controller
                            name="socialSecurityNumber"
                            render={({ field }) => <MaskedInput defaultValue={userData.driver_detail.ssn_number}
                                field={field} />}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_REQUIRED
                                },
                                minLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MIN,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MAX,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MAX
                                }
                            }}
                        />
                    }

                    {/*{
                        userData?.driver_detail?.ssn_number &&
                        <Controller
                            name="socialSecurityNumber"
                            render={({field}) => <MaskedInput defaultValue={userData?.driver_detail?.ssn_number} field={field}/>}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_REQUIRED
                                },
                                minLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MIN,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MIN
                                },
                                maxLength: {
                                    value: VALIDATIONS.SOCIAL_SECURITY_NUMBER_MAX,
                                    message: VALIDATIONS_TEXT.SOCIAL_SECURITY_NUMBER_MAX
                                }
                            }}
                        />
                    }*/}

                </div>
                {errors.socialSecurityNumber &&
                    <small className="text-red">{errors.socialSecurityNumber.message}</small>}

                <div className="div-white custom-padding d-flex align-items-center px-3 pe-2 mt-2">
                    <span className="text-red">*</span>
                    <Controller
                        rules={{ required: true }}
                        name="dateOfBirth"
                        control={control}
                        // defaultValue={userData?.dob}
                        render={
                            ({ field }) => (
                                <DatePicker
                                    maxDate={new Date()}
                                    className="date-picker"
                                    placeholderText="Date of birth"
                                    // onChange={(e) => field.onChange(e)}
                                    onChange={(e) => {
                                        setDobDate(e)
                                        field.onChange(e)
                                    }}
                                    //selected={"2022-06-13T19:00:00.000Z"}
                                    // selected={dobDate}
                                    selected={field.value}
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {"<"}
                                            </button>
                                            <select
                                                value={getYear(date)}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={months[getMonth(date)]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {">"}
                                            </button>
                                        </div>
                                    )}
                                />
                            )
                        }
                    />
                </div>
                {errors.dateOfBirth && <small
                    className="text-red">{errors.dateOfBirth.message = VALIDATIONS_TEXT.DATE_OF_BIRTH_REQUIRED}</small>}

                <Form.Control
                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                    type="number"
                    placeholder="MC number"
                    className="py-2 mt-2"
                    name="mcNumber"
                    defaultValue={userData?.driver_detail?.mc_number}
                    {...register("mcNumber",
                        {
                            pattern: {
                                value: /^[0-9]+$/,
                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                            },
                            minLength: {
                                value: VALIDATIONS.MC_NUMBER_MIN,
                                message: VALIDATIONS_TEXT.MC_NUMBER_MIN
                            },
                            maxLength: {
                                value: VALIDATIONS.MC_NUMBER_MAX,
                                message: VALIDATIONS_TEXT.MC_NUMBER_MAX
                            },
                        })
                    }
                />
                {errors.mcNumber && <small className='text-red'>{errors.mcNumber.message}</small>}

                <Form.Control
                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                    type="number"
                    name="dotNumber"
                    placeholder="DOT number"
                    className="mt-2 py-2"
                    defaultValue={userData?.driver_detail?.dot_number}
                    {...register("dotNumber",
                        {
                            // pattern: {
                            //     value: /^[0-9]+$/,
                            //     message: VALIDATIONS_TEXT.INVALID_FORMAT,
                            // },
                            minLength: {
                                value: VALIDATIONS.DOT_NUMBER_MIN,
                                message: VALIDATIONS_TEXT.DOT_NUMBER_MIN
                            },
                            maxLength: {
                                value: VALIDATIONS.DOT_NUMBER_MAX,
                                message: VALIDATIONS_TEXT.DOT_NUMBER_MAX
                            },
                        })
                    }
                />
                {errors.dotNumber && <small className='text-red'>{errors.dotNumber.message}</small>}


                <div className="form-card mt-2 p-3 mb-2">
                    <h5 className="mb-0">Insurance policy</h5>
                    <Row>
                        <Col sm={6}>
                            <div className="border mt-2">
                                <Form.Control
                                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                    className="w-100 insurance-form-control px-2"
                                    type="text"
                                    placeholder="Insurance policy number"
                                    name="insurancePolicyNumber"
                                    defaultValue={userData?.driver_detail?.insurance_policy_number}
                                    {...register("insurancePolicyNumber",
                                        {
                                            minLength: {
                                                value: VALIDATIONS.INSURANCE_POLICY_NUMBER_MIN,
                                                message: VALIDATIONS_TEXT.INSURANCE_POLICY_NUMBER_MIN
                                            },
                                            maxLength: {
                                                value: VALIDATIONS.INSURANCE_POLICY_NUMBER_MAX,
                                                message: VALIDATIONS_TEXT.INSURANCE_POLICY_NUMBER_MAX
                                            },
                                        })
                                    }
                                />
                            </div>
                            {errors.insurancePolicyNumber &&
                                <small className='text-red'>{errors.insurancePolicyNumber.message}</small>}
                        </Col>

                        <Col sm={6}>
                            <div className="border mt-2">
                                <Controller
                                    name="insuranceExpiryDate"
                                    control={control}
                                    defaultValue={null}
                                    render={
                                        ({ field }) => (
                                            <DatePicker
                                                minDate={new Date()}
                                                className="date-picker"
                                                placeholderText="Expiry date"
                                                onChange={(e) => field.onChange(e)}
                                                selected={field.value}
                                                defaultDate={moment(userData?.driver_detail?.insurance_policy_expiry).format("M/d/Y")}
                                            />
                                        )
                                    }
                                />
                            </div>
                        </Col>
                    </Row>

                    <div className="mt-3 d-flex align-items-center">
                        <div className="upload-btn-wrapper">
                            <button className="btn"><ImUpload3 className="icon" /> Add file</button>
                            <input
                                type="file"
                                name="insurancePolicyFile"
                                accept=".pdf, .docx, .png, .jpg"
                                {...register("insurancePolicyFile")}
                                onChange={handleInsuranceFileChange}
                                ref={insuranceFileRef}
                            />

                        </div>

                        {
                            insuranceFileName !== null &&
                            <div className="d-flex align-items-center">
                                <span className="d-inline-block ms-2"
                                    style={{ fontSize: "14px" }}>{insuranceFileName ? truncate(insuranceFileName, 25) : " "}</span>
                                <FaTimesCircle className="ms-3 text-dark icons" onClick={resetInsuranceFile} />
                            </div>
                        }
                        {/*<span>{insuranceFileName ? truncate(insuranceFileName, 25) : " "}</span>
                        <RiDeleteBin5Line title={"Remove File"} className="ms-3 icons"
                                          onClick={resetInsuranceFile}/>*/}
                    </div>

                    <div className="mt-3">
                        {
                            userData?.driver_detail?.insurance_policy_file ?
                                <div>
                                    <a href={userData.driver_detail.insurance_policy_file_path} target="_blank">
                                        <img style={{width: "100px"}}
                                             src={userData.driver_detail.insurance_policy_file_path} alt="-"
                                             onError={({currentTarget}) => {
                                                 currentTarget.onerror = null; // prevents looping
                                                 currentTarget.src = constants.IMAGENOTFOUND;
                                             }} className="truck-img me-3 "/>
                                    </a>

                                </div> : ""
                        }
                    </div>
                </div>

                <div className="form-card p-3 mb-3">
                    <h5 className="mb-0">Profile Picture</h5>

                    <div className="mt-3 d-flex align-items-center">
                        <div className="upload-btn-wrapper">
                            <button className="btn"><ImUpload3 className="icon" /> Add file</button>
                            <input
                                type="file"
                                name="profileImage"
                                accept=".pdf, .docx, .png, .jpg"
                                {...register("profileImage")}
                                onChange={handleProfileFileChange}
                                ref={profileFileRef}
                            />

                        </div>

                        {
                            profileFileName !== null &&
                            <div className="d-flex align-items-center">
                                <span className="d-inline-block ms-2"
                                    style={{ fontSize: "14px" }}>{profileFileName ? truncate(profileFileName, 25) : " "}</span>
                                <FaTimesCircle className="ms-3 text-dark icons" onClick={resetProfileFile} />
                            </div>
                        }
                    </div>

                    <div>
                        {
                            userData?.driver_detail?.image ?
                                <div style={{ marginTop: "10px" }}>
                                    <img style={{ width: "100px" }}
                                        src={userData.driver_detail.image} alt="-"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = constants.IMAGENOTFOUND;
                                        }} className="truck-img me-3 mb-2" />
                                </div> : ""
                        }
                    </div>

                </div>

                <Form.Check
                     {...register("termsAndConditions",{
                        required:{
                            value:true,
                            message:"Please confirm terms and conditons"
                        }
                     })}
                    label={
                        <NavLink style={{textDecoration:"none"}} className={"text-white"} to="/driver/carrier-documentation">*Terms and Conditions</NavLink>}
                    className="me-3 text-white"
                    type="checkbox"
                />
                {(errors.termsAndConditions) && <small className="text-red">{errors.termsAndConditions.message}</small>}

                {
                    !isLoading ?
                        <Button type="submit" onClick={handleRequestForReg} className="mt-3 btn-red">Request for registration</Button>
                        :
                        <Button type="button" className="btn-red">Requesting...</Button>
                }

            </Form>
        </div>
    )
}

export default SetupProfileForm
