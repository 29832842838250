import {constants, placeholders} from "./constants";
import S3 from "react-aws-s3";

window.Buffer = window.Buffer || require("buffer").Buffer;

export const handleBrokenImageOnError = (e, type) => {
    switch (type) {
        case "USER_PLACEHOLDER":
            e.target.src = placeholders.USER_IMAGE
            break
        case "PRODUCT_PLACEHOLDER":
            e.target.src = placeholders.PRODUCT_IMAGE
            break
        case "COMPANY_PLACEHOLDER":
            e.target.src = placeholders.COMPANY_PLACEHOLDER
            break
        default:
            e.target.src = placeholders.PRODUCT_IMAGE
    }
}

export const UploadImageToS3 = async (fileInput) => {
    const file = fileInput.current.files[0];
    const newFileName = file.name;
    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    const config = {
        bucketName: constants.S3CREDENTIAL.bucketName,
        dirName: constants.S3CREDENTIAL.dirName,
        region: constants.S3CREDENTIAL.region,
        accessKeyId: constants.S3CREDENTIAL.accessKeyId,
        secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
        s3Url: constants.S3CREDENTIAL.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: constants.ERRORMESSAGES.fileSuccess,
                data: data.location
            }
        } else {
            responseData = {
                success: false,
                message: constants.ERRORMESSAGES.fileError
            }
        }
    })

    return responseData;
}

export const UploadFileToS3 = async (file) => {
    const newFileName = file.name;
    const fileSize = file.size;

    //Check filesize
    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    const config = {
        bucketName: constants.S3CREDENTIAL.bucketName,
        dirName: constants.S3CREDENTIAL.dirName,
        region: constants.S3CREDENTIAL.region,
        accessKeyId: constants.S3CREDENTIAL.accessKeyId,
        secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
        s3Url: constants.S3CREDENTIAL.s3EndPoint,
    };

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(file, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: constants.ERRORMESSAGES.fileSuccess,
                url: constants.S3CREDENTIAL.dirName + "/" + newFileName,
                mime_type: file.type
            }
        } else {
            responseData = {
                success: false,
                message: constants.ERRORMESSAGES.fileError
            }
        }
    })

    return responseData;
}