import React, {useContext, useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {DriverSidebar, IsCustomerLogin} from "../../App";
import {FaTimes} from "react-icons/fa";
import {EmptyLocalStorage, Logout} from "../../services/auth/auth.service";
import {signOut} from "firebase/auth";
import {auth} from "../../config/firebase";
import {GetProfile, IsDriverApproved} from "../../services/general.service";
import {IsDriverApprovedContext} from "../../hooks/GlobalContext";
import {toast, ToastContainer} from "react-toastify";
import {orderStatus} from "../../utils/constants";

const DriverProfileModal = () => {
    const navigate = useNavigate();
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [screen, setScreen] = useState({width: window.innerWidth});
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [user, setUser] = useState([]);
    const [isDriverVerified, setIsDriverVerified] = useContext(IsDriverApprovedContext);

    const closeDriverSidebar = () => {
        setShowDriverSidebar(false)
        document.body.classList.remove("handleProfilemodal");
    }

    const logout = async () => {
        setShowDriverSidebar(false)
        document.body.classList.remove("handleProfilemodal");

        await Logout().then(async (res) => {
            if (res.status) {

                if (isCustomerLogin.is_social_login) {
                    const result = await signOut(auth).then((res) => {
                        // Sign-out successful.
                        console.log(auth, "driver signout-------")
                    }).catch((error) => {
                        // An error happened.
                        console.log(error, "driver logout result-------")
                    });

                }

                setIsCustomerLogin(null);
                EmptyLocalStorage()
                document.body.classList.remove("handleProfilemodal");
                navigate("/")
            }
        })
    }


    const openDriverSidebar = () => {
        setShowDriverSidebar(true)
        // document.body.classList.add("handleProfilemodal");
    }

    const handleResize = () => {
        setScreen({
            width: window.innerWidth
        })
        if (screen.width >= 768) {
            openDriverSidebar(true)
        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => handleResize())
    }, [screen.width])

    useEffect(() => {
        getProfile()
    }, [])

    async function getProfile() {
        await GetProfile().then((result) => {
            if (result.status && result.data) {
                let user = result?.data?.user;
                setUser(user)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    const isApproved = async (e) => {
        // screen.width <= 768 && closeDriverSidebar(false)
        await IsDriverApproved().then((result) => {
            if (result.status && result.data) {
                setIsDriverVerified(1)
                navigate(e.target.getAttribute("href"))
            }
            else{
                toast.error(result.message)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    return (
        <div className="driver-sidebar">
            <ToastContainer/>
            <div className="d-block d-md-none text-end py-2 pe-4">
                <FaTimes className="text-white" onClick={() => closeDriverSidebar(false)}/>
            </div>
            <div className="profile-sidebar-container p-3">
                {/* <div className="mb-4 border-bottom pb-3">
                    <div className="d-flex align-items-center">
                        <img src={isCustomerLogin?.image_path} alt="headerAvatar"
                             onError={({currentTarget}) => {
                                 currentTarget.onerror = null; // prevents looping
                                 currentTarget.src = constants.USERIMAGENOTFOUND;
                             }}
                             className="me-3 img-thumbnail driverProfileImg"/>
                        <div>
                            <h5 className="m-0 p-0 text-capitalize">{isCustomerLogin?.first_name + " " + isCustomerLogin?.last_name}</h5>
                            <NavLink to="/driver/profile-setup" className="view-profile">View profile</NavLink>
                        </div>
                    </div>
                </div> */}

                <div className="div-bottom">
                    <h5 className="mb-3">Summary & Trips</h5>
                    <NavLink className="mb-3" to="/driver/orders" state={{page: orderStatus.ORDERED}} onClick={isApproved}>New Orders</NavLink>
                    <NavLink className="mb-3" to="/driver/current-orders" state={{page: orderStatus.ACCEPTED}} onClick={isApproved}>Current Orders</NavLink>
                    <NavLink className="mb-3" to="/driver/past-orders" state={{page: orderStatus.DISPATCHED}} onClick={isApproved}>Dispatched Orders</NavLink>
                    <NavLink className="mb-3" to="/driver/completed-orders" state={{page: orderStatus.DELIVERED}} onClick={isApproved}>Completed Orders</NavLink>
                    {/*<NavLink to="/driver/new-orders">New Orders</NavLink>*/}
                    {/*<NavLink to="/driver/current-orders">Current</NavLink>*/}
                    {/*<NavLink to="/driver/past-orders">Past</NavLink>*/}
                    {/*<NavLink to="/driver/return-orders">Returns</NavLink>*/}
                </div>

                <div className="mb-4 pb-3">
                    <NavLink
                        className={(navData) => navData.isActive ? "active mb-3" : "mb-3"}
                        onClick={() => screen.width <= 768 && closeDriverSidebar(false)} to="/driver/profile-setup">Setup
                        Profile</NavLink>
                    <NavLink
                        className={(navData) => navData.isActive ? "active mb-3" : "mb-3"}
                        onClick={() => screen.width <= 768 && closeDriverSidebar(false)}
                        to="/driver/truck-vehicle-type">Truck/Vehicle Type</NavLink>
                    <NavLink
                        className={(navData) => navData.isActive ? "active mb-3" : "mb-3"}
                        onClick={isApproved}
                        to="/driver/banking-documentation">Banking Documentation</NavLink>
                    {/*<NavLink*/}
                    {/*    className={(navData) => navData.isActive ? "active " : ""}*/}
                    {/*    onClick={() => screen.width <= 768 && closeDriverSidebar(false)}*/}
                    {/*    to="/driver/carrier-documentation">Carrier Documentation</NavLink>*/}
                    <NavLink
                        className={(navData) => navData.isActive ? "active mb-3" : "mb-3"}
                        onClick={() => screen.width <= 768 && closeDriverSidebar(false)} to="/driver/invite-friend">Invite
                        Friend</NavLink>

                    {/*<NavLink
                        className={(navData) => navData.isActive ? "active mb-3" : "mb-3"}
                        onClick={() => screen.width <= 768 && closeDriverSidebar(false)} to="/withdrawl">Withdrawl
                        </NavLink>*/}

                    <div className="">
                        <div>
                            <NavLink className="d-inline-block" style={{paddingBottom: "100px"}} to="/"
                                     onClick={() => logout()}>Log out</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverProfileModal
