import Assets from "../constants/images";
// Developer Account
//export const AUTHORIZE_NET_LOGIN_ID = "26J9pvhp5LY";
//export const AUTHORIZE_NET_PUBLIC_KEY = "9mxFpCU7J236TkzFh3cX25Lg3uawCuQ2u2Kz3De7r2FHvtU39jdVKU6VY7bCry9a";
//export const AUTHORIZE_NET_ENV = "sandbox"; //production||sandbox
//Client Account
export const AUTHORIZE_NET_LOGIN_ID = "669vkHGULZz";
export const AUTHORIZE_NET_PUBLIC_KEY = "8Yp59nz7eSd5tvjCfa2rbB98nX4uB2d33wCS9R4jHf9kycemRvvY5eUwub35ZQWy";
export const AUTHORIZE_NET_ENV = "PRODUCTION"; //production||sandbox

const roles = {
    ROLE_ADMIN: 10,
    ROLE_USER: 20,
    ROLE_DRIVER: 30,
}

export const productsSortBy = {
    PRICE_LOW_TO_HIGH: 10,
    PRICE_HIGH_TO_LOW: 20,
    RATING_LOW_TO_HIGH: 30,
    RATING_HIGH_TO_LOW: 40,
}

export const orderStatus = {
    ORDERED: 10,
    ACCEPTED: 20,
    DISPATCHED: 30,
    DELIVERED: 40,
    RETURNED: 50,
    CANCELED: 60
}

const orderStatusKey = {
    [orderStatus.ORDERED]: "ORDERED",
    [orderStatus.ACCEPTED]: "ACCEPTED",
    [orderStatus.DISPATCHED]: "DISPATCHED",
    [orderStatus.DELIVERED]: "DELIVERED",
    [orderStatus.RETURNED]: "RETURNED",
    [orderStatus.CANCELED]: "CANCELED",
}

const page = {
    PRIVACY_POLICY: "privacy-policy",
    TERMS: "terms-and-conditions"
}

export const placeholders = {
    PRODUCT_IMAGE: Assets.NoImageAvailable,
    COMPANY_PLACEHOLDER: Assets.CompanyLogo,
    USER_IMAGE: Assets.UserPlaceholder
}

const categoryStatus = {
    CUSTOMIZE: 10,
    IN_STOCK: 20,
    DESIGN: 30
}

const categoryStatusKey = {
    [categoryStatus.CUSTOMIZE]: "CUSTOMIZE",
    [categoryStatus.IN_STOCK]: "IN_STOCK",
    [categoryStatus.DESIGN]: "DESIGN"
}

const collections = {
    CHATROOM: "chatRoom_live",
    USERS: "users_live",
    CHATLIST: "chatlist",
    GENERALCHATLIST: "general_chat_users_live",
    GENERALCHATROOM: "general_chatRoom_live",
}

const s3Credential = {
    bucketName: "cmore-media",
    dirName: "images",
    region: "us-east-2",
    accessKeyId: "AKIAVCCQO3O7EYILROW6",
    secretAccessKey: "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",
    s3EndPoint: "https://cmore-media.s3.us-east-2.amazonaws.com",
    fileSize: "5000000"
}

const errorMessages = {
    fileSize: "The file size is too large",
    fileSuccess: "file uploaded successfully",
    fileError: "Error in uploading file",
}

const contentType = {
    "image/jpeg": "image",
    "image/jpg": "image",
    "image/png": "image",
    "image/gif": "image",
    "application/pdf": "doc",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "doc",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "doc",
    "audio/mpeg": "audio",
    "video/mp4": "video",
}

const chatMessage = {
    CONTENT: 0,
    CONTENT_SOFT_DELETE: 1,
    CONTENT_HARD_DELETE: 2,
}

const AdType = {
    IMAGE: "image",
    VIDEO: "video"
}

const stripePK = {
    key: "pk_test_51LALlBDLdNgUxDIyYwAF3WBQunrZELe2e7EMuJMs7KfD6p18NaGjVA3bLaZwiGfnvYje6IiOgSQdXZJxRmQnGMhu00zYFqzIiV"
    //key:"pk_live_51LALlBDLdNgUxDIyuBxmBSd0CjcOT4KB2kActx1HhMjpuQKA9VQwwhnBC3IXjiekem5R02ME7UHiwFCxRMVwlsmm007dL3MMBQ"
}

export const constants = {
    LOCAL_STORAGE_TOKEN: "CMORE_AUTH_ACCESS_TOKEN",
    LOCAL_STORAGE_USER: "CMORE_USER_DATA",
    BASE_URL: process.env.REACT_APP_BASE_URL,
    ROLES: roles,
    ORDERSTATUS: orderStatus,
    ORDERSTATUSKEY: orderStatusKey,
    PRODUCTS_SORT_BY: productsSortBy,
    LOCALE: "en-OM",
    CURRENCY: "OMR",
    PAGES: page,
    PERPAGE: 20,
    IMAGENOTFOUND: "https://imprint.tekstaging.com/not-found.png",
    USERIMAGENOTFOUND: "https://imprint.tekstaging.com/not-found-user.png",
    CATEGORYSTATUS: categoryStatus,
    CATEGORYSTATUSKEY: categoryStatusKey,
    COLLECTION: collections,
    S3CREDENTIAL: s3Credential,
    ERRORMESSAGES: errorMessages,
    CONTENTTYPE: contentType,
    CHATMESSAGE: chatMessage,
    ADTYPE: AdType,
    STRIPEPK: stripePK
};

export const apiUrl = {
    login: "login",
    social_login: "social-login",
    logout: "logout",
    forgot_password: "forgot-password",
    verify_otp: "verify-otp",
    reset_password: "reset-password",
    resend_otp: "resend-otp",
    change_password: "change-password",
    pages: "pages",
    countries: "countries",
    states: "states",
    cities: "cities",
    zip_codes: "zip-codes",
    licenseType: "license-types",
    companyType: "company-types",
    trailerType: "trailer-types",
    endorsements: "endorsements",
    vehicleType: "vehicle-types",
    vehicle: "vehicle",
    equipment: "equipment",
    stores: "stores",
    is_store_available: "is-store-available",
    blogs: "blogs",
    orders: "orders",
    transactions: "transactions",
    updateStatus: "update-status",
    dashboard: "dashboard-data",
    products: "products",
    product_reviews: "reviews",
    quotations: "quotations",
    checkout: "orders",
    return_order: "orders/return",
    categories: "categories",
    brands: "brands",
    sub_categories: "sub-categories",
    profile: "me",
    get_total_withdrawl: "get-total-withdrawl",
    driver_payments: "driver-payments",
    update_profile: "users",
    notifications: "notifications",
    mark_all_notifications_read: "mark-all-read",
    unread_notifications_count: "get-unread-count",
    users: "users",
    product_types: "product-types",
    vehicles: "vehicles",
    certifications: "certifications",
    register: "register",
    product_variants: "product-variants",
    order_product_variants: "order-product-variants",
    update_order_product_variants: "update-order-product-variant",
    remove_attachment: "remove-attachment",
    product_comparisons: "product-comparisons",
    colors: "colors",
    get_colors: "get-colors",
    ads: "ads",
    create_shipping: "create-shipping",
    track_shipping: "track-shipping",
    order_shipping: "order-shippings",
    update_transaction_status: "update-transaction-status",
    driver_orders: "driver-orders",
    get_shipping_charges: "get-shipping-charges",
    update_order_status: "update-order-status",
    invite_friend: "invite-friend",
    post_vehicle_equipments: "post-vehicle-equipments",
    faqs: "faqs",
    banking_documentations: "banking-documentations",
    is_driver_approved: "is-driver-approved",
}

export const customStyles = {
    rows: {
        style: {
            minHeight: "50px",
        },
    },
    headCells: {
        style: {
            color: "#000",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "19px",
            fontFamily: "Poppins",
            padding: "5px",
        },
    },
    cells: {
        style: {
            padding: "5px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "19px",
            fontFamily: "Poppins",
        },
    },
}
