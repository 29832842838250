import React from "react";
import { NavLink } from "react-router-dom";

const NavComponent = () => {
    return (
        <div className="link-container my-5 p-4">
            <ul>
                <li><NavLink to="/faq/order-information" className="py-2 mb-1">Order Information</NavLink></li>
                {/*<li><NavLink to="/faq/shipping" className="py-2 mb-1">Shipping</NavLink></li>*/}
                <li><NavLink to="/faq/return-and-cancellation" className="py-2">Return & Cancellation</NavLink></li>
            </ul>
        </div>
    )
}

export default NavComponent;