// export const BASE_URL = "http://127.0.0.1:3333/api/v1";
export const BASE_URL = "https://ampd.tekstaging.com/api/v1";
export const ENDPOINTS = {
  LOGIN: "/store-login",
  GET_BUSINESS_TYPES: "/business-types",
  GET_TIMEZONES: "/timezones",
  REGISTER: "/store-registration",
  REFRESH_TOKEN: "/refresh-token",
};

export const ADMIN_EMAIL = {
  email: "info@ampd.com",
};

export const VALIDATIONS = {
  NAME: 50,
  OFFER_NAME: 20,
  EMAIL: 255,
  PASSWORD_MIN: 8,
  PASSWORD_MAX: 255,
  ADDRESS: 255,
  PHONE: 15,
  PHONE_MIN: 10,
  PHONE_MAX: 16,
  ORDER_NOTES:500,
  DESCRIPTION: 500,
  JOB_TITLE: 10,
  ZIP:150,
  ZIP_MIN:5,
  ZIP_MAX:10,
  VIN_NUMBER_MIN:7,
  VIN_NUMBER_MAX:9,
  SOCIAL_SECURITY_NUMBER_MIN:9,
  SOCIAL_SECURITY_NUMBER_MAX:9,
  MC_NUMBER_MIN:7,
  MC_NUMBER_MAX:8,
  DOT_NUMBER_MIN:6,
  DOT_NUMBER_MAX:8,
  ACCOUNT_NUMBER_MAX:50,
  ROUTING_NUMBER_MIN:9,
  ROUTING_NUMBER_MAX:9,
  INSURANCE_POLICY_NUMBER_MIN:9,
  INSURANCE_POLICY_NUMBER_MAX:9,
  ORDER_VERIFICATION_CODE:4,
  BANK_ACCOUNT_MIN:10,
  BANK_ACCOUNT_MAX:12,
  CITY_STATE_ZIP_MAX : 255,
  DOLLAR_MAX:6,
};

export const VALIDATIONS_TEXT = {
  NAME_REQUIRED: "Name is required",
  COMPANY_NAME_REQUIRED: "Company name is required",
  CITY_REQUIRED: "City is required",
  ADDRESS_REQURIED:"Address is required",
  LOCATION_REQUIRED:"Location is required",
  DIGIT_ROUTING_REQUIRED:"Routing number is required",
  IMAGE_REQUIRED:"Image is required",
  ACCOUNT_NUMBER_REQUIRED:"Account number is required",
  ACCOUNT_TYPE_REQUIRED:"Account type is required",
  PHONE_REQURIED:"Phone number is required ",
  EMAIL_REQUIRED: "Email is required",
  ONLY_EMAIL_REQUIRED: "Email is required",
  PASSWORD_REQUIRED: "Password is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  VERIFICATION_CODE_REQUIRED: "Verification code is requried",
  ZIP_CODE_REQUIRED: "Zip code is requried",
  DESCRIPTION_REQUIRED: "SKU, item no., product name and/or vendor is requried",
  REVIEW_REQUIRED:"Review is required",
  FIRST_NAME_REQUIRED: "First name is required",
  LAST_NAME_REQUIRED: "Last name is required",
  COUNTRY_REQUIRED: "Country is required",
  STREET_ADDRESS_REQUIRED: "Street address is required",
  TOWN_OR_CITY_REQUIRED: "Town / City name is required",
  PAYMENT_METHOD_REQUIRED: "Please select a payment method",
  TRUCK_TYPE_REQUIRED: "Truck type is required",
  DELIVERY_DATE_AND_TIME_REQUIRED: "Delivery time is required",
  LICENSE_TYPE_REQUIRED: "License type is required",
  SOCIAL_SECURITY_NUMBER_REQUIRED: "Social security number is required",
  DATE_OF_BIRTH_REQUIRED: "Date of birth is required",
  EQUIPMENT_TYPE_REQUIRED: "Equipment type is required",
  SELECT_CLASS_REQUIRED: "Select class is required",
  EMAIL_FORMAT: "Invalid email format",
  INVALID_FORMAT: "Invalid format",
  ORDER_VERIFICATION_CODE_REQUIRED:"Verification code is required",
  EXCLUDE_SPECIAL_CHARACTERS: "Should not contain any special character" ,


  ORDER_VERIFICATION_CODE_MAX: "Max length is "+ VALIDATIONS.ORDER_VERIFICATION_CODE,
  ORDER_VERIFICATION_CODE_MIN: "Min length is "+ VALIDATIONS.ORDER_VERIFICATION_CODE,
  ORDER_NOTES_MAX: "Max length is "+ VALIDATIONS.ORDER_NOTES,
  DESCRIPTION_MAX: "Max length is "+ VALIDATIONS.DESCRIPTION,
  NAME_MAX: "Max length is "+ VALIDATIONS.NAME ,
  // ZIP_MAX: "Max length is "+ VALIDATIONS.ZIP ,
  ADDRESS_MAX:"Max length is "+ VALIDATIONS.ADDRESS,
  PHONE_MIN:"Min length is "+ VALIDATIONS.PHONE_MIN,
  PHONE_MAX:"Max length is "+ VALIDATIONS.PHONE_MAX,
  EMAIL_MAX: "Max length is "+VALIDATIONS.EMAIL,
  PASSWORD_MIN: "Min length is "+VALIDATIONS.PASSWORD_MIN,
  PASSWORD_MAX: "Max length is "+VALIDATIONS.PASSWORD_MAX,
  VIN_NUMBER_MAX:"Max length is "+VALIDATIONS.VIN_NUMBER_MAX,
  VIN_NUMBER_MIN:"Min length is "+VALIDATIONS.VIN_NUMBER_MIN,
  SOCIAL_SECURITY_NUMBER_MAX:"Max length is "+VALIDATIONS.SOCIAL_SECURITY_NUMBER_MAX,
  SOCIAL_SECURITY_NUMBER_MIN:"Min length is "+VALIDATIONS.SOCIAL_SECURITY_NUMBER_MIN,
  MC_NUMBER_MAX:"Max length is "+VALIDATIONS.MC_NUMBER_MAX,
  MC_NUMBER_MIN:"Min length is "+VALIDATIONS.MC_NUMBER_MIN,
  DOT_NUMBER_MIN:"Min length is "+VALIDATIONS.DOT_NUMBER_MIN,
  DOT_NUMBER_MAX:"Max length is "+VALIDATIONS.DOT_NUMBER_MAX,
  ADDRESS: "Max length is "+VALIDATIONS.ADDRESS,
  FIRST_NAME_MAX_LENGTH: "Max length is "+ VALIDATIONS.NAME,
  LAST_NAME_MAX_LENGTH: "Max length is "+ VALIDATIONS.NAME,
  COUNTRY_NAME_MAX_LENGTH: "Max length is "+ VALIDATIONS.NAME,
  STREET_ADDRESS_MAX_LENGTH: "Max length is "+ VALIDATIONS.ADDRESS,
  TOWN_OR_CITY_MAX_LENGTH: "Max length is "+ VALIDATIONS.NAME,
  ACCOUNT_NUMBER_MAX: "Max length is "+ VALIDATIONS.ACCOUNT_NUMBER_MAX ,
  ROUTING_NUMBER_MIN: "Min length is "+ VALIDATIONS.ROUTING_NUMBER_MIN ,
  ROUTING_NUMBER_MAX: "Max length is "+ VALIDATIONS.ROUTING_NUMBER_MAX ,
  INSURANCE_POLICY_NUMBER_MAX: "Max length is "+ VALIDATIONS.INSURANCE_POLICY_NUMBER_MAX ,
  INSURANCE_POLICY_NUMBER_MIN: "Min length is "+ VALIDATIONS.INSURANCE_POLICY_NUMBER_MIN ,
  BANK_ACCOUNT_MIN: "Min length is "+ VALIDATIONS.BANK_ACCOUNT_MIN ,
  BANK_ACCOUNT_MAX: "Max length is "+ VALIDATIONS.BANK_ACCOUNT_MAX ,
  ZIP_MIN: "Min length is "+ VALIDATIONS.ZIP_MIN ,
  ZIP_MAX: "Max length is "+ VALIDATIONS.ZIP_MAX ,
  CITY_STATE_ZIP_MAX: "Max length is "+ VALIDATIONS.CITY_STATE_ZIP_MAX,
  DOLLAR_MAX: "Max length is "+ VALIDATIONS.DOLLAR_MAX
};

export const OFFER_TYPES = {
  AMOUNT: 10,
  PERCENTAGE: 20,
};
export const OFFER_ACTIVITY_STATUS = {
  INACTIVE: 10,
  ACTIVE: 20,
};
export const OFFER_STATUSES = {
  DRAFT: 10,
  PUBLISHED: 20,
  EXPIRED: 30,
};

export const SPECIAL_CHARACTER_ARRAY = [
  "~","`","!","@","#","$","%","^","&","*","(",")","-","_","+","=","{","}","[","]",";",":","'",'"',"\\","|","<",",",">",".","?","/","0"
]

export const SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS = [
  "~","`","!","@","#","$","%","^","&","*","(",")","_","+","=","{","}","[","]",";",":","'",'"',"\\","|","<",">",".","?","/"
]

export const CHARACTER_ARRAY_NUMBER_INPUT = [
  "e", "E", "-", "+","."
]

export const CHARACTER_ARRAY_WHOLE_NUMBER_INPUT = [
  "e", "E", "-", "+"
]
