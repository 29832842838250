import React, { useContext, useState, useEffect } from "react";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import Footer from "../../components/app-footer/Footer";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import TruckAndVehicleTypeForm from "../../components/truck-and-vehicle-type/TruckAndVehicleTypeForm";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import { NavLink } from "react-router-dom";
import Assets from "../../constants/images";
import { IoMdSettings } from "react-icons/io";
import { DriverSidebar } from "../../App";
import { BiPlusMedical } from "react-icons/bi";
import { ImMinus } from "react-icons/im";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";
import { useForm } from "react-hook-form"


const NewTruckAndVehicleTypePage = () => {
  const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);

  const { register, unregister, control, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    mode: "onChange"
  });

  const handleDriverSidebar = () => {
    setShowDriverSidebar(true);
    document.body.classList.add("handleProfilemodal");
  };

  const [moveNext, setMoveNext] = useState(false)
  const [showError, setShowError] = useState(false)

  const [truckList, setTruckList] = useState([
    {
      img: Assets.NewTruckImg1,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg2,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg3,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg4,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg5,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg6,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg7,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg8,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg9,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg10,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg11,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg12,
      title: "Any Title Goes Here",
      value: 0
    },
  ])

  const [truckEquipment, setTruckEquipment] = useState([
    {
      img: Assets.NewTruckImg13,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg14,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg15,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg16,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg17,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg18,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg19,
      title: "Any Title Goes Here",
      value: 0
    },
    {
      img: Assets.NewTruckImg20,
      title: "Any Title Goes Here",
      value: 0
    },
  ])

  const Increament = (index, type) => {
    if (type === "truckList") {
      truckList[index].value = truckList[index].value + 1;

      setTruckList([
        ...truckList,
      ])
    }
    else {
      truckEquipment[index].value = truckEquipment[index].value + 1;
      setTruckEquipment([
        ...truckEquipment,
      ])
    }
  }

  const Decreament = (index, type) => {
    if (type === "truckList") {
      if (truckList[index].value > 0) {
        truckList[index].value = truckList[index].value - 1;
        setTruckList([
          ...truckList,
        ])
      }
    }
    else {
      if (truckEquipment[index].value > 0) {
        truckEquipment[index].value = truckEquipment[index].value - 1;
        setTruckEquipment([
          ...truckEquipment,
        ])
      }
    }
  }

  const TruckListItem = () => {
    return (
      <>
        {
          truckList.map((data, index) => {
            return (
              <Col key={index} sm={12} xs={12} md={12} lg={6} xl={3} xxl={3}>
                <div className="trucks-div text-center mb-4">
                  <div className="bg-white img-container mb-2 p-4">
                    <img className="w-100" src={data.img} alt="" />
                  </div>
                  <span className="text-white">{data.title}</span>
                  <div className="mt-2 align-items-center d-flex">
                    <Button className="p-2 m-0 bg-white btn-add-sub" onClick={() => Decreament(index, "truckList")}>-</Button>
                    <input readOnly name="value" className="text-white text-center count-div h-100 w-100" type="number" value={data.value} />
                    <Button className="p-2 m-0 bg-white btn-add-sub" onClick={() => Increament(index, "truckList")}>+</Button>
                  </div>
                </div>
              </Col>
            );
          })
        }
      </>
    )
  }

  const EquipmentListItem = () => {
    return (
      <>
        {
          truckEquipment.map((data, index) => {
            return (
              <Col key={index} sm={12} xs={12} md={12} lg={6} xl={3} xxl={3}>
                <div className="trucks-div text-center mb-4">
                  <div className="bg-white img-container mb-2 p-4">
                    <img className="w-100" src={data.img} alt="" />
                  </div>
                  <span className="text-white">{data.title}</span>
                  <div className="mt-2 align-items-center d-flex">
                    <Button className="p-2 m-0 bg-white btn-add-sub" onClick={() => Decreament(index, "equipmentList")}>-</Button>
                    <input readOnly className="text-white text-center count-div h-100 w-100" type="number" value={data.value} />
                    <Button className="p-2 m-0 bg-white btn-add-sub" onClick={() => Increament(index, "equipmentList")}>+</Button>
                  </div>
                </div>
              </Col>
            );
          })
        }
      </>
    )
  }

  const handleMoveNext = () => {
    let list = truckList.filter(function (e) {
      return e.value > 0;
    });

    if (list.length > 0) {
      setMoveNext(true)
      setShowError(false)
    }
    else{
      setShowError(true)
    }
  }

  const onSubmit = ()=>{

  }

  return (
    <>
      <NewDriverHeader />
      <div className="NewTruckDriverPage truck-and-vehicle-type setup-profile">
        <div className="d-flex">
          <div className="left">
            {showDriverSidebar === true && <DriverProfileModal />}
          </div>

          <div className="right px-2 px-md-5 py-5">
            <Row>
              <Col className="d-block d-md-none" xs={12}>
                <div className="text-end">
                  <IoMdSettings
                    onClick={() => handleDriverSidebar(true)}
                    className="text-white icon-setting"
                  />
                  {showDriverSidebar == false && <hr />}
                </div>
              </Col>

              <Col xs={12} className="mb-4">
                <h1>Truck/Vehicle Type</h1>
              </Col>

              <Col xs={12} xxl={12} xl={12} lg={12} md={12}>
                <Container>
                  <Row className="justify-content-xl-center mb-4">
                    <Col xs={12} xl={10}>
                      <div className="step-div d-flex align-items-center justify-content-between">
                        <div className="me-3">
                          <small>Step 1</small>
                          <h5 className="m-0"><span className="text-red">Class</span> Details</h5>

                          <div className="darkDiv px-4 py-3 mt-3">
                            <h6 className="m-0">Group A (Combination Vehicle)</h6>
                          </div>

                          <div className="mt-3">
                            <p className="p-0 m-0">
                              Any combination of vehicle with a gross combination weight(GCWR) of
                              26,001 pounds or more, provided with gross vehicle weight training (GVWR)
                              of the vechicle(s) being towed is in excess of 10,000 pounds.
                            </p>
                          </div>
                        </div>

                        <div>
                          <small>Step 1</small>
                          <h5 className="m-0"><span className="text-red">Equipment Details</span> Details</h5>

                          <div className="darkDiv px-4 py-3 mt-3">
                            <h6 className="m-0">Group A (Combination Vehicle)</h6>
                          </div>

                          <div className="mt-3">
                            <p className="p-0 m-0">
                              Any combination of vehicle with a gross combination weight(GCWR) of
                              26,001 pounds or more, provided with gross vehicle weight training (GVWR)
                              of the vechicle(s) being towed is in excess of 10,000 pounds.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    {
                      moveNext === false ?
                        <>
                          <Row>
                            {<TruckListItem />}
                          </Row>

                          <Row>
                            <Col xs={12}>
                              {
                                showError && 
                                <small className="text-red">Select atleast one</small>
                              }
                            </Col>
                            <Col xs={12}>
                              <div className="text-end">
                                <Button className="mt-2 px-4 next-step-btn d-block ms-auto" id="myButton1" onClick={() => handleMoveNext()}>
                                  Next Step
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </> :

                        <>
                          <Row>
                            {<EquipmentListItem />}
                          </Row>

                          <Row>
                            <Col xs={12}>
                              <div className="d-flex align-items-center justify-content-between">
                                <Button className="mt-2 px-4 previous-btn d-block" id="myButton1" onClick={() => setMoveNext(false)}>
                                  Previous
                                </Button>

                                <Button className="mt-2 px-4 next-step-btn d-block" id="myButton1">
                                  Save
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </>
                    }
                  </Form>
                </Container>
              </Col>
            </Row>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewTruckAndVehicleTypePage;
