import React, {useContext, useState, useEffect} from "react";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import Footer from "../../components/app-footer/Footer";
import {Col, Container, Form, Row, Button} from "react-bootstrap";
import SetupProfileForm from "../../components/setup-profile/SetupProfileForm";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Assets from "../../constants/images";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar, IsCustomerLogin} from "../../App";
import {EmptyLocalStorage, Logout, UpdateProfile} from "../../services/auth/auth.service";
import {toast} from "react-toastify";
import {constants} from "../../utils/constants";
import FooterNew from "../../components/app-footer/FooterNew";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";
import {signOut} from "firebase/auth";
import {auth} from "../../config/firebase";

const ProfileSetupPage = () => {
    const navigate = useNavigate();
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);

    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    useEffect(() => {
        setShowDriverSidebar(true)
    },[])

    const logout = async () => {
        setShowDriverSidebar(false)
        document.body.classList.remove("handleProfilemodal");

        await Logout().then(async (res) => {
            if (res.status) {

                if (isCustomerLogin.is_social_login) {
                    const result = await signOut(auth).then((res) => {
                        // Sign-out successful.
                        console.log(auth, "driver signout-------")
                    }).catch((error) => {
                        // An error happened.
                        console.log(error, "driver logout result-------")
                    });

                }

                setIsCustomerLogin(null);
                EmptyLocalStorage()
                document.body.classList.remove("handleProfilemodal");
                navigate("/")
            }
        })
    }

    return (
        <>
            <NewDriverHeader/>
            <div className="setup-profile">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>
                    <div className="right py-5 px-2 px-sm-5">
                        <Row>
                            <Col className="d-block d-md-none" xs={12}>
                                <div className="text-end">
                                    {/* <Button type={"button"} className={"btn btn-link d-sm-block d-md-none"} onClick={()=> logout()}>
                                        Logout
                                    </Button> */}
                                    <IoMdSettings onClick={() => handleDriverSidebar()}
                                                  className="text-white icon-setting"/>
                                    {
                                        showDriverSidebar == false &&
                                        <hr/>
                                    }
                                </div>
                            </Col>

                            <Col xs={12} md={{span: 12, offset: 1}} className="mb-4">
                                <h1>Setup your profile</h1>
                            </Col>

                            <Col xs={12} xxl={{span: 7, offset: 1}} xl={{span: 7, offset: 1}}
                                 lg={{span: 9, offset: 1}}
                                 md={{span: 9, offset: 1}}>
                                <SetupProfileForm/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default ProfileSetupPage