import React from "react";
import { Container, Row, Col, Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { BsCheckLg } from "react-icons/bs";

const Package = () => {
  const data = [
    {
      title: "Storage",
      personal: "2 GB",
      team: "2,000 GB"
    },
    {
      title: "Users",
      personal: "1 user",
      team: "3 users"
    },
    {
      title: "Best-in-class sync technology",
      personal: <BsCheckLg className="icon-check" />,
      team: <BsCheckLg className="icon-check" />
    },
    {
      title: "Anytime, anywhere access",
      personal: <BsCheckLg className="icon-check" />,
      team: <BsCheckLg className="icon-check" />
    },
    {
      title: "Computer backup",
      personal: <BsCheckLg className="icon-check" />,
      team: <BsCheckLg className="icon-check" />
    },
    {
      title: "Easy & secure sharing",
      personal: <BsCheckLg className="icon-check" />,
      team: <BsCheckLg className="icon-check" />
    },
    {
      title: "256-bit AES and SSL/TLS encryption",
      personal: <BsCheckLg className="icon-check" />,
      team: <BsCheckLg className="icon-check" />
    },
  ]

  return (
    <div className="package py-5">
      <div className="div py-5">
        <Container>
          <Row className="justify-content-center text-center">
            <Col xs={12}>
              <h1>Packages</h1>
            </Col>
          </Row>

          <Row>
            <Col xs={12} >
              <div className="package-container mt-2">
                <Table variant="dark" responsive="sm">
                  <thead>
                    <tr className="text-center">
                      <th></th>
                      <th>Personal</th>
                      <th>Team</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <div className="text-center d-flex flex-column">
                          <span className="my-1"><span className="span-bold">Plus</span></span>
                          <Button className="my-1 mx-auto">Buy Now</Button>
                        </div>
                      </td>

                      <td>
                        <div className="text-center d-flex flex-column">
                          <span className="my-1"><span className="span-bold">Professional</span></span>
                          <Button className="my-1 mx-auto">Buy Now</Button>
                          <span className="my-1">or purchase now</span>
                        </div>
                      </td>
                    </tr>

                    {
                      data.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <div className="d-flex justify-content-between align-items-center">
                                <span>{v.title}</span>
                                <OverlayTrigger
                                  key="right"
                                  placement="right"
                                  overlay={
                                    <Tooltip>
                                      <span>{v.title}</span>
                                    </Tooltip>
                                  }
                                >
                                  <span className="d-none d-md-inline" type="button"><FaInfoCircle className="icon-info" /></span>
                                </OverlayTrigger>
                              </div>
                            </td>
                            <td className="text-center">{v.personal}</td>
                            <td className="text-center">{v.team}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Package