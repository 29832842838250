import React, {useContext, useEffect, useState} from "react";
import Assets from "../../constants/images";
import CartCard from "../../components/cart/CartCard";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import {Col, Container, Row} from "react-bootstrap";
import ProductCard from "../../components/categories-section/ProductCard";
import {NavLink} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {GetProducts} from "../../services/products.service";
import {Cart} from "../../App"
import FooterNew from "../../components/app-footer/FooterNew";

const CartPage = () => {

    const [products, setProducts] = useState([]);
    const [cart, setCart] = useContext(Cart)

    async function fetchProducts() {
        const params = {
            "per-page": 8,
            is_essential: 1
        }

        await GetProducts(params).then((result) => {
            if (result.status && result.data.data) {
                setProducts(result.data.data)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <div className="cart-page">
            <AppHeader/>
            <div className="header py-5">
                <ToastContainer/>
                <Container>
                    <Row>
                        {/*<Col xs={12}>*/}
                        {/*    <nav aria-label="breadcrumb">*/}
                        {/*        <ol className="breadcrumb">*/}
                        {/*            <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>*/}
                        {/*            <li className="breadcrumb-item active" aria-current="page"><NavLink*/}
                        {/*                to="/products">All products</NavLink></li>*/}
                        {/*            <li className="breadcrumb-item active" aria-current="page">Cart</li>*/}
                        {/*        </ol>*/}
                        {/*    </nav>*/}
                        {/*</Col>*/}
                        <Col xs={12}>
                            {(cart && cart.length > 0) ? <h3 className="text-white">Cart</h3> : <></>}
                        </Col>
                    </Row>
                </Container>
            </div>

            {
                (cart && cart.length > 0)
                ?  <CartCard/>

                    :
                    <>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <h2 className="text-white">No items found in the cart!</h2>
                                </Col>
                            </Row>
                        </Container>
                    </>
            }



            <div className="project-essential pb-5">
                <div className="category-page category-section">
                    <div className="category-container">

                        {
                            (products.length > 0) ?

                                <>

                                    <Container>
                                        <Row>
                                            <Col xs={12} className="mt-3 mb-4">
                                                <h5 className="text-white">Product Essentials</h5>
                                            </Col>
                                        </Row>

                                        <div className="suggested">
                                            <Row>
                                                {products &&
                                                products.map((v, i) => {
                                                    return <ProductCard id={v.id} key={i} index={i}
                                                                        total_reviews={v.meta.total_reviews}
                                                                        rating={(v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0}
                                                                        attachments={v.attachments}
                                                                        image={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : Assets.NoImageAvailable}
                                                                        title={v.name} buy={v.buy} price={v.price}
                                                                        sku={v.sku} weight={v.weight}
                                                                        description={v.description}/>
                                                })
                                                }
                                            </Row>
                                        </div>
                                    </Container>

                                </>

                                : <></>
                        }
                    </div>
                </div>
            </div>

            <FooterNew />
        </div>
    )
}

export default CartPage;