import React, {useContext, useState} from "react";
import {Container, Row, Col} from "react-bootstrap"
import {NavLink} from "react-router-dom";
// import imgCard from "../../assets/images/home-page/new/imgcard.png"
import Assets from "../../constants/images"
import {ArrowIcon} from "../../constants/svgs";
import {CategoriesContext} from "../../hooks/GlobalContext";
import {handleBrokenImageOnError} from "../../utils/helper";
import { LazyLoadImage } from "react-lazy-load-image-component";

const WeOffer = () => {
    const categories = useContext(CategoriesContext)
    const [data, setData] = useState([
        {
            img: Assets.Image1,
            title: "Paint"
        },
        {
            img: Assets.Image2,
            title: "Heating & Cooling"
        },
        {
            img: Assets.Image3,
            title: "Hardware"
        },
        {
            img: Assets.Image4,
            title: "Cleaning"
        },
        {
            img: Assets.Image5,
            title: "Electrical"
        },
        {
            img: Assets.Image6,
            title: "Blinds & Windows"
        },
        {
            img: Assets.Image7,
            title: "Appliances"
        },
        {
            img: Assets.Image8,
            title: "Ice Makers"
        },
        {
            img: Assets.Image9,
            title: "Bath & Faucets"
        },
        {
            img: Assets.Image10,
            title: "Cook Tops"
        },
        {
            img: Assets.Image11,
            title: "Ovens"
        }
    ])

    return (
        <div className={"weOffer"}>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <div className={"mx-auto text-center"}>
                            <h2>What We Offer</h2>
                            <p>
                                Browse our product catalog of over 200,000+ products and project essentials
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    {
                        categories.slice(0, 7).map((category, i) => (
                            <Col xs={6} sm={6} md={4} lg={3} xl={3} className="mb-4 text-center" key={i}>
                                <NavLink to="/products" state={{selected_category_id: category.id}}>
                                    <div className={"offerCard"}>
                                        <div className="imgContainer">
                                            <LazyLoadImage effect="opacity" src={category.image_path} alt={""} className={"w-100"}
                                                 onError={(e) => handleBrokenImageOnError(e, "PRODUCT_PLACEHOLDER")}
                                            />
                                        </div>
                                        <div className="offerdesc bg-white p-2">
                                            <h6 className="m-0">{category.name}</h6>
                                        </div>
                                    </div>
                                </NavLink>
                            </Col>
                        ))
                    }

                    <Col xs={6} sm={6} md={4} lg={3} xl={3} className="mb-4 text-center">
                        <NavLink to="/all-categories">
                            <div
                                className="w-100 blankCard d-flex flex-column align-items-center justify-content-center h-100">
                                <h5>All Categories</h5>
                                <ArrowIcon/>
                            </div>
                        </NavLink>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default WeOffer;