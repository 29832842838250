import React, { useEffect, useState } from "react"
import OrderInformation from "../../../components/faq/OrderInformation";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import DriverPublicHeader from "../../../components/app-header/driver-public-header";
import { constants } from "../../../utils/constants";
import { GetCurrentUser } from "../../../services/auth/auth.service";
import FooterNew from "../../../components/app-footer/FooterNew";

const OrderInformationPage = () => {
    const user = GetCurrentUser();
    const [isDriver, setIsDriver] = useState(false);

    useEffect(() => {
        setIsDriver(user?.roles[0].id === constants.ROLES.ROLE_DRIVER)
    }, [])

    return (
        <>
            {
                isDriver ? <DriverPublicHeader /> : <AppHeader />
            }
            <OrderInformation />
            <FooterNew />
        </>
    )
}

export default OrderInformationPage;
