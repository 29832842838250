import React, {useState} from "react";
import {FaRegStar, FaStar} from "react-icons/fa";
import {NavLink} from "react-router-dom";
import {handleBrokenImageOnError} from "../../utils/helper";

// const Search = ({products, showSearchSuggestion, setShowSearchSuggestion}) => {
//     const [overlayState, setOverlayState] = useState(false);
//     const onClickOverlay = () => {
//         setShowSearchSuggestion(false);
//     }

//     return (
//         <div className="search">
//             {
//                 showSearchSuggestion && <div className="overlay-navbars" onClick={() => onClickOverlay()}></div>
//             }
//             {
//                 products &&
//                 products.slice(0, 5).map((item, index) => (
//                     <NavLink
//                         key={index}
//                         to={`/product/${item.id}`}
//                         state={{
//                             pid: item.id,
//                             image: item.image,
//                             title: item.name,
//                             rating: (item.meta.rating) ? Math.round(item.meta.rating) : 0,
//                             buy: 1,
//                             price: item.price,
//                             weight: item.weight,
//                             sku: item.sku,
//                             attachments: item.attachments,
//                             total_reviews: item.meta.total_reviews,
//                             description: item.description
//                         }}

//                     >
//                         <div className="border-bottom">
//                             <div className="search-dropdown d-flex p-3">
//                                 <img src={(item.attachments.length > 0) ? item.attachments[0].mediaUrl : ""}
//                                      alt="ProductImage1" onError={(e) => handleBrokenImageOnError(e)}/>
//                                 <div className="ms-2">
//                                     <span className="title">{item.name}</span>
//                                     <div className="d-flex align-items-center">
//                                         {
//                                             Array.from(Array((item.meta.rating) ? item.meta.rating : 0), (_, i) => {
//                                                 return <FaStar key={i} className="icon me-1"/>
//                                             })
//                                         }
//                                         {
//                                             Array.from(Array(5 - ((item.meta.rating) ? item.meta.rating : 0)), (_, i) => {
//                                                 return <FaRegStar key={i} className="icon me-1"/>
//                                             })
//                                         }
//                                         <span className="text-grey">({item.meta.total_reviews})</span>
//                                     </div>
//                                     <div><p className="p-0 m-0 me-2"><span className="text-red">$ </span>{item.price}
//                                     </p></div>
//                                 </div>
//                             </div>
//                         </div>
//                     </NavLink>
//                 ))
//             }


//         </div>
//     )
// }


const Search = ({products, showSearchSuggestion, setShowSearchSuggestion, searchRecordsExists}) => {
    return (
        <>
            {!searchRecordsExists ?
                <div className="search-dropdown d-flex align-items-center"><p>No Products Found</p></div> : null}
            {
                products &&
                products.slice(0, 5).map((item, index) => (
                    <NavLink
                        key={index}
                        to={`/product/${item.id}`}
                        state={{
                            pid: item.id,
                            image: (item.attachments.length > 0) ? item.attachments[0].mediaUrl : "",
                            title: item.name,
                            rating: (item.meta.rating) ? Math.round(item.meta.rating) : 0,
                            buy: 1,
                            price: item.price,
                            weight: item.weight,
                            sku: item.sku,
                            attachments: item.attachments,
                            total_reviews: item.meta.total_reviews,
                            description: item.description
                        }}
                    >
                        <div className="search-dropdown d-flex align-items-center">
                            <div>
                                <img src={(item.attachments.length > 0) ? item.attachments[0].mediaUrl : ""}
                                     alt="ProductImage1" onError={(e) => handleBrokenImageOnError(e)}/>
                            </div>

                            <div className="ms-2">
                                <span className="title">{item.name}</span><br/>
                                <span className="title"><span
                                    className="text-red">Brand:</span> {item?.brand?.name || "N/A"}</span>
                                {/*<div className="d-flex align-items-center">*/}
                                {/*    {*/}
                                {/*        Array.from(Array((item.meta.rating) ? item.meta.rating : 0), (_, i) => {*/}
                                {/*            return <FaStar key={i} className="icon me-1"/>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*    {*/}
                                {/*        Array.from(Array(5 - ((item.meta.rating) ? item.meta.rating : 0)), (_, i) => {*/}
                                {/*            return <FaRegStar key={i} className="icon me-1"/>*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*    <span className="text-grey">({item.meta.total_reviews})</span>*/}
                                {/*</div>*/}
                                <div><p className="p-0 m-0 me-2"><span className="text-red">$ </span>{item.price}
                                </p></div>
                            </div>
                        </div>
                    </NavLink>
                ))
            }
        </>
    )
}

export default Search