import React from "react";
import ReactHtmlParser from "react-html-parser";

const Description = ({description}) => {
    return (
        <div className="description">
            <div className="mb-5">
                <p>{ReactHtmlParser(description)}</p>
            </div>
        </div>
    )
}

export default Description