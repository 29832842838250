import React, {useContext, useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {Cart} from "../../App";

const CartCardBody = ({item, index}) => {
    const [cart, setCart] = useContext(Cart);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    const handleIncrement = (index) => {
        let temp = [...cart];
        temp[index].quantity += 1;
        temp[index].price = temp[index].actualPrice * temp[index].quantity;
        setCart(temp);
    }

    const handleDecrement = (index) => {
        if (cart[index].quantity > 1) {
            let temp = [...cart];
            temp[index].quantity -= 1;
            temp[index].price = temp[index].actualPrice * temp[index].quantity;
            setCart(temp);
        }
    }

    const removeFromCart = (index) => {
        cart.splice(index, 1);
        setCart([...cart]);
    }

    const getFixedTwoDigits = (price) => {
        price = parseInt(price)
        return price.toFixed(2);
    }

    return (
        <Row className="mb-3 mb-sm-4 pb-4 border-bottom">
            <Col xs={12} className="d-block d-sm-none">
                <span className="ms-sm-3 d-inline-block description mb-2">{item.product.name}</span>
            </Col>

            <Col xs={2} sm={6}>
                <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                    <img src={(item.product.attachments) ? item.product.attachments[0].mediaUrl : ""} alt="Product Image"/>
                    <span className="ms-sm-3 d-none d-sm-block description">{item.product.name}</span>
                </div>
            </Col>

            <Col xs={9} sm={2} className="margin-custom">
                <span className="d-block d-sm-none"> <span
                    className="small-span ms-1 d-inline-block d-sm-none">Price : </span> ${getFixedTwoDigits(item.price)}</span>
                <span className="d-block d-sm-none"> <span className="small-span ms-1 d-inline-block d-sm-none">Quantity : </span> x2</span>
                <span className="d-block d-sm-none"> <span className="small-span ms-1 d-inline-block d-sm-none">Total Price : </span> ${getFixedTwoDigits(item.price)}</span>
                <div className="text-sm-center mt-sm-0 d-none d-sm-block">
                    <div className="text-sm-center mt-3 mt-sm-0">
                        <span><span className="d-inline d-sm-none">Quantity : </span> x{item.quantity}</span>
                    </div>
                </div>
            </Col>

            <Col sm={2} className="d-none d-sm-block">
                <div className="text-sm-center mt-3 mt-sm-0">
                    <span>${getFixedTwoDigits(item.price)}</span>
                </div>
            </Col>

            <Col xs={2} className="d-none d-sm-block margin-custom">
                <div className="text-start text-sm-center mt-4 mt-sm-0">
                    <span>${getFixedTwoDigits(item.total)}</span>
                </div>
            </Col>
        </Row>
    )
}

export default CartCardBody