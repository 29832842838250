import {apiUrl} from "../utils/constants";
import {GET, POST} from "./api.service.wrapper";


export const GetNotifications = async () => {
    return await GET(apiUrl.notifications);
}

export const GetUnreadNotificationsCount = async () => {
    return await GET(apiUrl.unread_notifications_count);
}

export const MarkAllNotificationsRead = async () => {
    return await POST(apiUrl.mark_all_notifications_read);
}