import {initializeApp} from "firebase/app";
import {FacebookAuthProvider, getAuth, GithubAuthProvider, GoogleAuthProvider, TwitterAuthProvider} from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyB0dBJq2DX6xh-zkdMyg1xN2eFdPRDjGSk",
    authDomain: "cmore-e0060.firebaseapp.com",
    projectId: "cmore-e0060",
    storageBucket: "cmore-e0060.appspot.com",
    messagingSenderId: "1092161160477",
    appId: "1:1092161160477:web:b57af5706084ef9fda0df4",
    measurementId: "G-W4FTJZB4H0"
};

// Initialize Firebase
initializeApp(firebaseConfig);

export const auth = getAuth()
export const googleProvider = new GoogleAuthProvider()
export const facebookProvider = new FacebookAuthProvider()