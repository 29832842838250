import React from "react";
import ProductDescription from "../../components/product-description/ProductDescription";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import { useLocation } from "react-router-dom";
import FooterNew from "../../components/app-footer/FooterNew";

const ProductDescriptionPage = () => {
  const location = useLocation();
  const state = location.state

  return (
    <>
      <AppHeader />
      <ProductDescription pid={state.pid} upc={state.upc} category={state.category} sku={state.sku} image={state.image} description={state.description} title={state.title} rating={state.rating} total_reviews={state.total_reviews} buy={state.buy} price={state.price} weight={state.weight} attachments={state.attachments} />
      <FooterNew />
    </>
  )
}

export default ProductDescriptionPage