import React from "react";
import OrderDetail from "../../components/order-detail/OrderDetail";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import FooterNew from "../../components/app-footer/FooterNew";

const OrderDetailPage = () => {
    return (
        <>
            <AppHeader/>
            <OrderDetail />
            <FooterNew />
        </>
    )
}

export default OrderDetailPage