import {apiUrl} from "../utils/constants";
import {GET} from "./api.service.wrapper";


export const GetCountries = async (args) => {
     args = {
        "order-column": "name",
        "order": "asc",
        "is-paginate": 0
    }
    return await GET(apiUrl.countries, args);
}

export const GetStates = async (args) => {
    args["order-column"] = "name"
    args["order"] = "asc"
    args["is-paginate"] = 0
    return await GET(apiUrl.states, args);
}

export const GetCities = async (args) => {
    args["order-column"] = "name"
    args["order"] = "asc"
    args["is-paginate"] = 0
    return await GET(apiUrl.cities, args);
}

export const GetZipCodes = async (args) => {
    args["order-column"] = "zip"
    args["order"] = "asc"
    args["is-paginate"] = 0
    return await GET(apiUrl.zip_codes, args);
}

export const GetStores = async (args) => {
    return await GET(apiUrl.stores, args);
}

export const CheckStoreAvailability = async (args) => {
    return await GET(apiUrl.is_store_available, args);
}