import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {AiOutlineUser} from "react-icons/ai";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import Assets from "../../constants/images";
import {BsFillCalendarWeekFill, BsFillCheckCircleFill} from "react-icons/bs";
import {useForm} from "react-hook-form";
import {success} from "../../constants/msg";
import {toast, ToastContainer} from "react-toastify";
import {HostedForm, AcceptHosted} from "react-acceptjs";
import {
    CHARACTER_ARRAY_NUMBER_INPUT,
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT
} from "../../constants/app-constants";
import Calendar from "react-calendar";
import {FiChevronDown} from "react-icons/fi";
import "react-calendar/dist/Calendar.css";
import {Checkout, GetShippingCharges} from "../../services/orders.service";
import {Cart, IsCustomerLogin} from "../../App";
import {GetCurrentUser, ResendOtp, VerifyOtpApi} from "../../services/auth/auth.service";
// import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import Moment from "moment";
import {AUTHORIZE_NET_ENV, AUTHORIZE_NET_LOGIN_ID, AUTHORIZE_NET_PUBLIC_KEY} from "../../utils/constants";
import Loading from "react-fullscreen-loading";

const CheckoutForm = () => {
    const location = useLocation()
    /* const stripe = useStripe();
     const elements = useElements();*/
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const [deliveryTimeSlot, setDeliveryTimeSlot] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const navigate = useNavigate();
    const [value, onChange] = useState(new Date());
    const [showOrderVerificationModal, setShowOrderVerificationModal] = useState(false)
    const [showOrderVerificationPhoneModal, setShowOrderVerificationPhoneModal] = useState(false)
    const [showStripeModal, setShowStripeModal] = useState(false)
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [shipToDifferentAddress, setShipToDifferentAddress] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [cart, setCart] = useContext(Cart);
    const [email, setEmail] = useState(null);
    const [verificationCode, setVerificationCode] = useState("");
    const [is_verified, setIsVerified] = useState("");
    const [formData, setFormData] = useState(null)
    // const [weight, setWeight] = useState(location.state.weight)
    const [shippingCost, setShippingCost] = useState(0.00)
    const [cartSummary, setCartSummary] = useState({
        total: 0.00,
        sub_total: 0.00
    })

    setValue("date", value);

    const handleEmailOnChange = (e) => {
        setEmail(e.target.value)
    }

    const showVerificationModal = () => {
        setShowOrderVerificationModal(true)
    }

    const handleVerificationOnKeyPress = (e) => {
        // (e.target.value.length >= VALIDATIONS.ORDER_VERIFICATION_CODE) ||
        // ( (CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key))) && e.preventDefault()
        setVerificationCode(e.target.value)
    }

    const calculateTotalWeight = () => {
        let weight = 0;
        for (let i = 0; i < cart.length; i++) {
            weight += cart[i].weight * cart[i].quantity
        }
        return parseInt(weight)
    }

    const handleOnViewOrderClick = async () => {

        await ResendOtp({email}).then((res) => {
            if (res.status) {

                setShowOrderVerificationModal(true)
                // setError(false)
                success("OTP has been sent to your email")
            }
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    const handleOnVerifyClick = async () => {
        await VerifyOtpApi(verificationCode, email).then((res) => {
            if (res.status) {
                setIsVerified("verified")
                setShowOrderVerificationModal(false)
                // setError(false)
                success("verified")
            }
        }).catch((err) => {
            toast.error(err.response.data.message);
        })
    }

    const onSubmit = (data) => {
        // setShowStripeModal(true)
        let cart = JSON.parse(localStorage.getItem("cart"))
        let product_items = []

        cart && cart.map((item) => {
            product_items.push({id: item.id, quantity: item.quantity})
        })

        let dt = {
            billing_first_name: data.billing_first_name,
            billing_last_name: data.billing_last_name,
            billing_country: data.billing_country,
            billing_city: data.billing_city,
            billing_phone: data.billing_phone,
            billing_street: data.billing_street,
            billing_zip: data.billing_zip,

            shipping_city: data.shipping_city,
            shipping_country: data.shipping_country,
            shipping_street: data.shipping_street,
            shipping_zip: data.shipping_zip,
            shipping_date: Moment(data.date).format("YYYY-MM-DD"),
            delivery_time: data.delivery_time,
            cart: product_items,
            email: data.email,
            description: data.description,
            is_shipping: data.shipDifferentAddress,
            is_create_account: data.createAccount,
            truck_type_id: location.state.truck_type_id ?? 0,
            payment_method: data.paymentMethod,
        }
        setFormData(dt)
        localStorage.setItem("order_details", JSON.stringify(dt))
        const element = document.getElementById("root");
        element.scrollIntoView({behavior: "smooth"});
        let divElement = document.getElementsByClassName("AcceptUI")
        if (divElement && divElement.length > 0 && divElement[0].nextElementSibling) {
            divElement[0].nextElementSibling.remove()
        }
        setTimeout(() => {
            document.getElementsByClassName("AcceptUI")[0].click();
        }, 1000)

    }

    const handlePopupNoUser = () => {
        setShowOrderVerificationPhoneModal(false)
        setShowOrderVerificationModal(true)
    }

    const placeOrder = async (data) => {
        setIsLoading(true)
        await Checkout(data).then((res) => {
            if (res.status) {
                setShowStripeModal(false)
                toast.success("Your order has been placed!")
                localStorage.removeItem("cart")
                localStorage.removeItem("order_details")
                localStorage.removeItem("time_slot")
                setCart([])
                let divElement = document.getElementsByClassName("AcceptUI")
                if (divElement && divElement.length > 0 && divElement.tagName == "DIV") {
                    divElement[0].nextElementSibling.remove()
                }
                /*setTimeout(function () {
                    // navigate("/")
                    window.location.href = "/"
                }, 1000)*/
            }

            setIsLoading(false)
        }).catch((err) => {
            toast.error(err.response.data.message)
            setIsLoading(false)
        })
    }

    const onSubmitPay = (event) => {
        /*event.preventDefault();

        if (elements == null) {
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
        }).then((response) => {
            let data = {...formData}
            data.payment_method_id = response.paymentMethod.id
            placeOrder(data)
        }).catch((error) => {
            toast.error(error.message)
        });
*/

    }

    const getCartSummary = (cart) => {
        if (!cart) return
        let total = 0.00
        cart.map((item, i) => {
            total += parseFloat(item.price)
        })
        total = total.toFixed(2)
        setCartSummary({
            total: total,
            sub_total: total
        })
    }

    const onDeliveryTimeChange = async (e) => {
        let time_slot = e.target.value
        localStorage.setItem("time_slot", time_slot)
        let weight = calculateTotalWeight()
        let params = {
            time_slot: time_slot,
            weight: weight
        }
        await GetShippingCharges(params).then((res) => {
            if (res.status && res.data) {
                let total = parseFloat(cartSummary.total) + res.data.charge_price
                let sub_total = cartSummary.sub_total
                setCartSummary({
                    sub_total: sub_total,
                    total: total.toFixed(2)
                })
                setShippingCost(res.data.charge_price)
            }
        }).catch((err) => {
            console.log(err)
        })
        setDeliveryTimeSlot(e.target.value)
    }
    useEffect(() => {
        let cart = JSON.parse(localStorage.getItem("cart"))
        getCartSummary(cart)
        setCart(cart)
        if (!isCustomerLogin) {

            let user = GetCurrentUser()
            setIsCustomerLogin(user)
        }

        if (isCustomerLogin) {
            setEmail(isCustomerLogin.email)
        }

        if (isCustomerLogin) {
            reset({
                billing_first_name: isCustomerLogin.first_name,
                billing_last_name: isCustomerLogin.last_name,
                billing_country: isCustomerLogin?.country?.name,
                billing_street: isCustomerLogin?.location,
                billing_zip: isCustomerLogin?.zip?.zip,
                billing_city: isCustomerLogin?.city?.name,
                billing_phone: isCustomerLogin.phone,
                email: isCustomerLogin.email
            })
        }

    }, [isCustomerLogin])


    const onSubmitHandler = (response) => {
        console.log("response:", response)
        let data = localStorage.getItem("order_details")
        let time_slot = localStorage.getItem("time_slot")
        if (data) {
            data = JSON.parse(data)
            data.opaqueData = response.opaqueData
            data.delivery_time = time_slot
            placeOrder(data)
            localStorage.removeItem("order_details")
        }
    }

    const authData = {
        apiLoginID: AUTHORIZE_NET_LOGIN_ID,
        clientKey: AUTHORIZE_NET_PUBLIC_KEY,
    };
    return (
        <div className="checkout-form py-5">
            <Loading loading={isLoading} background="#222222" loaderColor="#c32025"/>
            <Form onSubmit={handleSubmit(onSubmitPay)}>
                <Modal
                    className=" stripe-popup"
                    show={showStripeModal}
                    size=" md"
                    aria-labelledby=" contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <div className=" d-flex w-100 border-bottom pb-2 align-items-center justify-content-between">
                            <div>
                                <h5>Stripe</h5>
                            </div>
                            <div>
                                <span className=" text-dark" style={{cursor: " pointer"}}
                                      onClick={() => setShowStripeModal(false)}>Close</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <div className=" p-3">
                                    {/* <CardElement className=" text-white"/>*/}
                                    {/*  <HostedForm authData={authData} onSubmit={onSubmitHandler}
                                                environment={AUTHORIZE_NET_ENV}
                                                buttonText={" Pay"}/>*/}
                                    {/*{
                                        (isLoading)
                                            ?

                                            <Button className=" mt-4 w-100 btn-pay">
                                                Processing payment...
                                            </Button>

                                            :

                                            <Button className=" mt-4 w-100 btn-pay" type=" submit" onClick={onSubmitPay}
                                                    disabled={!stripe || !elements}>
                                                Pay
                                            </Button>
                                    }*/}

                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </Form>


            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal
                    show={showOrderVerificationModal}
                    size=" md"
                    aria-labelledby=" contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <div className=" d-flex w-100 border-bottom pb-2 align-items-center justify-content-between">
                            <div>
                                <h5>Order verification</h5>
                            </div>
                            <div>
                                <span className=" text-white" style={{cursor: " pointer"}}
                                      onClick={() => setShowOrderVerificationModal(false)}>Close</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <div className=" px-3">
                                    <p className=" text-grey" style={{fontSize: "16px", color: "#8e8e8e"}}>Your
                                        verification has been sent. Code will expire in 10 minutes</p>

                                </div>
                            </Col>
                            <Col xs={8}>
                                <div className=" p-3 pt-0">
                                    <Form className=" form-verify">
                                        <Form.Control
                                            className=" py-3"
                                            type=" number"
                                            placeholder=" Please enter your verification code"
                                            {...register(" verificationCode", {
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                },
                                                minLength: {
                                                    value: VALIDATIONS.ORDER_VERIFICATION_CODE,
                                                    message: VALIDATIONS_TEXT.ORDER_VERIFICATION_CODE_MIN
                                                },
                                                maxLength: {
                                                    value: VALIDATIONS.ORDER_VERIFICATION_CODE,
                                                    message: VALIDATIONS_TEXT.ORDER_VERIFICATION_CODE_MAX
                                                },
                                            })}
                                            onChange={e => handleVerificationOnKeyPress(e)}
                                        />
                                        {errors.verificationCode &&
                                        <small className='text-red'>{errors.verificationCode.message}</small>}
                                        <Button className=" mt-4 btn-red" onClick={handleOnVerifyClick}>Verify</Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>


                <Modal
                    show={showOrderVerificationPhoneModal}
                    size=" md"
                    aria-labelledby=" contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <div className=" d-flex w-100 border-bottom pb-2 align-items-center justify-content-between">
                            <div>
                                <h5>Order verification</h5>
                            </div>
                            <div>
                                <span className=" text-white" style={{cursor: " pointer"}}
                                      onClick={() => setShowOrderVerificationPhoneModal(false)}>Close</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <div className=" px-3">
                                    <p className=" text-grey" style={{fontSize: "16px", color: "#8e8e8e"}}>Enter your
                                        phone number</p>

                                </div>
                            </Col>
                            <Col xs={8}>
                                <div className=" p-3 pt-0">
                                    <Form className=" form-verify">
                                        <Form.Control
                                            className=" py-3"
                                            onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                            placeholder=" Contact number"
                                            type=" number"
                                            {...register(" orderVerificatoinPhone",
                                                {
                                                    pattern: {
                                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                    },
                                                    minLength: {
                                                        value: VALIDATIONS.PHONE_MIN,
                                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.PHONE_MAX,
                                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                                    },
                                                })
                                            }
                                        />
                                        {errors.orderVerificatoinPhone &&
                                        <small className='text-red'>{errors.orderVerificatoinPhone.message}</small>}
                                        <Button onClick={() => handlePopupNoUser()}
                                                className=" mt-4 btn-red">Verify</Button>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    id=" calendar-modal"
                    show={showCalendarModal}
                    size=" md"
                    aria-labelledby=" contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <div
                            className=" p-3 pb-4 border-bottom d-flex w-100 align-items-center justify-content-between">
                            <div>
                                <h5>Set delivery schedule</h5>
                            </div>
                            <div>
                                <span className=" text-white" style={{cursor: " pointer"}}
                                      onClick={() => setShowCalendarModal(false)}>Close</span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className=" p-3">
                            <Calendar
                                name=" calendar"
                                onChange={onChange}
                                value={value}
                                minDate={new Date()}
                            />
                        </div>

                        <div className=" p-4 border-top">
                            <Form.Label style={{fontSize: "14px"}}>Select your time slot</Form.Label>
                            <div className=" d-flex align-items-center ">
                                <Form.Select
                                    className=" text-white py-2 p-0"
                                    style={{background: " transparent", width: "180px", fontSize: "18px"}}
                                    aria-label=" Default select example"

                                    {...register(" delivery_time",
                                        {
                                            required: {
                                                value: true,
                                                message: VALIDATIONS_TEXT.DELIVERY_DATE_AND_TIME_REQUIRED
                                            },
                                        })
                                    }
                                    onChange={(e) => onDeliveryTimeChange(e)}
                                >
                                    <option selected={true} disabled={true} value="">Select Time Slot</option>
                                    <option value="6:00 AM">6:00 AM</option>
                                    <option value="8:00 AM">8:00 AM</option>
                                    <option value="10:00 AM">10:00 AM</option>
                                    <option value="12:00 PM">12:00 PM</option>
                                    <option value="1:00 PM">1:00 PM</option>
                                    <option value="3:00 PM">3:00 PM</option>
                                    <option value="6:00 PM">6:00 PM</option>
                                    <option value="7:00 PM">7:00 PM</option>
                                    <option value="9:00 PM">9:00 PM</option>
                                </Form.Select>
                                <FiChevronDown className=" text-white"/>

                            </div>
                            <Button className=" mt-4 btn-red w-100" onClick={() => setShowCalendarModal(false)}>Set
                                Delivery Time</Button>
                        </div>
                    </Modal.Body>
                </Modal>

                <div className=" checkout-form-container">
                    <ToastContainer/>
                    <Container>
                        <Row>
                            <Col xs={12} className=" mb-5">
                                <nav aria-label=" breadcrumb">
                                    <ol className=" breadcrumb">
                                        <li className=" breadcrumb-item"><NavLink to="/">Home</NavLink>
                                        </li>
                                        {/*<li className="breadcrumb-item active" aria-current="page"><NavLink*/}
                                        {/*    to="/products">Products</NavLink></li>*/}
                                        <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                                    </ol>
                                </nav>
                                <h1>Checkout</h1>
                            </Col>

                            {
                                !isCustomerLogin ?
                                    (
                                        <Col xs={12} className="mb-4">
                                            <div className="d-flex align-items-center w-100 header p-4">
                                                <AiOutlineUser className="icon"/>
                                                <h5 className="p-0 m-0 mt-1">Returning customer? <NavLink to="/login">Click
                                                    here to login</NavLink></h5>
                                            </div>
                                        </Col>
                                    )
                                    : <></>
                            }

                            <Col xs={12} className="mb-4">
                                <div className="form-checkout p-4 p-lg-5">
                                    <Row>
                                        <Col xs={12} xl={7} lg={7} className="mb-4">
                                            <h5 className="mb-4 weight-400">Billing details</h5>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>First name: <small>*</small></Form.Label>
                                                    <Form.Control
                                                        onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                        type="text"
                                                        placeholder="First Name"
                                                        {...register("billing_first_name",
                                                            {
                                                                maxLength: {
                                                                    value: VALIDATIONS.NAME,
                                                                    message: VALIDATIONS_TEXT.FIRST_NAME_MAX_LENGTH
                                                                },
                                                                pattern: {
                                                                    value: /^[a-z0-9]+$/gi,
                                                                    message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                                },
                                                                required: {
                                                                    value: true,
                                                                    message: VALIDATIONS_TEXT.FIRST_NAME_REQUIRED
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {errors.billing_first_name &&
                                                    <small
                                                        className='text-red'>{errors.billing_first_name.message}</small>}
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="formGridPassword">
                                                    <Form.Label>Last name: <small>*</small></Form.Label>
                                                    <Form.Control
                                                        onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                        type="text"
                                                        placeholder="last Name"
                                                        {...register("billing_last_name",
                                                            {
                                                                maxLength: {
                                                                    value: VALIDATIONS.NAME,
                                                                    message: VALIDATIONS_TEXT.LAST_NAME_MAX_LENGTH
                                                                },
                                                                pattern: {
                                                                    value: /^[a-z0-9 ]+$/gi,
                                                                    message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                                },
                                                                required: {
                                                                    value: true,
                                                                    message: VALIDATIONS_TEXT.LAST_NAME_REQUIRED
                                                                },
                                                            })
                                                        }
                                                    />
                                                    {errors.billing_last_name &&
                                                    <small
                                                        className='text-red'>{errors.billing_last_name.message}</small>}
                                                </Form.Group>
                                            </Row>

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Country: <small>*</small></Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    placeholder="Country"
                                                    type="text"
                                                    {...register("billing_country",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.COUNTRY_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                value: /^[a-z0-9 ]+$/gi,
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.COUNTRY_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.billing_country &&
                                            <small className='text-red'>{errors.billing_country.message}</small>}

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Street address: <small>*</small></Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                    type="text"
                                                    placeholder="House number and street named"
                                                    {...register("billing_street",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.STREET_ADDRESS_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                value: /^[a-z0-9-, ]+$/gi,
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.STREET_ADDRESS_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.billing_street &&
                                            <small className='text-red'>{errors.billing_street.message}</small>}

                                            <Form.Group className="mt-3 w-100 d-block" controlId="formGridAddress1">
                                                <Form.Label>Postcode / Zip (optional)</Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                                    className="w-100 border-radius-input"
                                                    placeholder="Postcode / Zip"
                                                    type="number"
                                                    {...register("billing_zip",
                                                        {
                                                            pattern: {
                                                                value: /^[0-9]+$/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                            minLength: {
                                                                value: VALIDATIONS.ZIP_MIN,
                                                                message: VALIDATIONS_TEXT.ZIP_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.ZIP_MAX,
                                                                message: VALIDATIONS_TEXT.ZIP_MAX
                                                            },
                                                            required: {
                                                                value: false,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.billing_zip &&
                                            <small className='text-red'>{errors.billing_zip.message}</small>}

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Town / City: <small>*</small></Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    placeholder="Town / City name"
                                                    type="text"
                                                    {...register("billing_city",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.TOWN_OR_CITY_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                value: /^[a-z0-9-, ]+$/gi,
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.TOWN_OR_CITY_REQUIRED
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.billing_city &&
                                            <small className='text-red'>{errors.billing_city.message}</small>}

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Phone: <small>*</small></Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                                    placeholder="Contact number"
                                                    type="number"
                                                    {...register("billing_phone",
                                                        {
                                                            pattern: {
                                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                            minLength: {
                                                                value: VALIDATIONS.PHONE_MIN,
                                                                message: VALIDATIONS_TEXT.PHONE_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.PHONE_MAX,
                                                                message: VALIDATIONS_TEXT.PHONE_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.PHONE_REQURIED
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.billing_phone &&
                                            <small className='text-red'>{errors.billing_phone.message}</small>}

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Email address: <small>*</small></Form.Label>
                                                <Form.Control
                                                    placeholder="Email address"
                                                    type="email"
                                                    {...register("email",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.EMAIL,
                                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                                            },
                                                            required: {
                                                                value: true,
                                                                message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                            }
                                                        }
                                                    )
                                                    }
                                                    onChange={e => {
                                                        handleEmailOnChange(e);
                                                    }}
                                                />
                                            </Form.Group>
                                            {errors.email && <small className='text-red'>{errors.email.message}</small>}

                                            {/* <Form.Group className="mt-3" controlId="formGridAddress1">
<Form.Label>Street address: <small>*</small></Form.Label>
<Form.Control
placeholder="House number and street name"
type="text"
{...register("streetAddress2",
{
maxLength: {
value: VALIDATIONS.NAME,
message: VALIDATIONS_TEXT.STREET_ADDRESS_MAX_LENGTH
},
required: {
value: true,
message: VALIDATIONS_TEXT.STREET_ADDRESS_REQUIRED
},
})
}
/>
</Form.Group>
{errors.streetAddress2 && <small className='text-red mb-3 d-inline-block'>{errors.streetAddress2.message}</small>} */}

                                            <Form.Check
                                                className="mt-3"
                                                type="checkbox"
                                                onClick={() => setCreateAccount(!setCreateAccount)}
                                                id=""
                                                label="Create an account?"
                                                {...register("createAccount")}
                                            />
                                            <Form.Check
                                                onClick={() => setShipToDifferentAddress(!shipToDifferentAddress)}
                                                type="checkbox"
                                                id=""
                                                label="Ship to a different address?"
                                                {...register("shipDifferentAddress")}
                                            />
                                            {
                                                shipToDifferentAddress &&
                                                <>
                                                    <Form.Group className="mt-3" controlId="formGridAddress1">
                                                        <Form.Label>Country: <small>*</small></Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY.includes(e.key) && e.preventDefault()}
                                                            placeholder="Country"
                                                            type="text"
                                                            {...register("shipping_country",
                                                                {
                                                                    maxLength: {
                                                                        value: VALIDATIONS.NAME,
                                                                        message: VALIDATIONS_TEXT.COUNTRY_NAME_MAX_LENGTH
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-z0-9 ]+$/gi,
                                                                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                                    },
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.COUNTRY_REQUIRED
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {errors.shipping_country && <small
                                                        className='text-red'>{errors.shipping_country.message}</small>}

                                                    <Form.Group className="mt-3" controlId="formGridAddress1">
                                                        <Form.Label>Street address: <small>*</small></Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                            type="text"
                                                            placeholder="House number and street name"
                                                            {...register("shipping_street",
                                                                {
                                                                    maxLength: {
                                                                        value: VALIDATIONS.NAME,
                                                                        message: VALIDATIONS_TEXT.STREET_ADDRESS_MAX_LENGTH
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-z0-9-, ]+$/gi,
                                                                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                                    },
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.STREET_ADDRESS_REQUIRED
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {errors.shipping_street && <small
                                                        className='text-red'>{errors.shipping_street.message}</small>}

                                                    <Form.Group className="mt-3 w-100 d-block"
                                                                controlId="formGridAddress1">
                                                        <Form.Label>Postcode / Zip (optional)</Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                                            className="w-100 border-radius-input"
                                                            placeholder="Postcode / Zip"
                                                            type="number"
                                                            {...register("shipping_zip",
                                                                {
                                                                    pattern: {
                                                                        value: /^[0-9]+$/,
                                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                                    },
                                                                    minLength: {
                                                                        value: VALIDATIONS.ZIP_MIN,
                                                                        message: VALIDATIONS_TEXT.ZIP_MIN
                                                                    },
                                                                    maxLength: {
                                                                        value: VALIDATIONS.ZIP_MAX,
                                                                        message: VALIDATIONS_TEXT.ZIP_MAX
                                                                    },
                                                                    required: {
                                                                        value: false,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {errors.shipping_zip &&
                                                    <small className='text-red'>{errors.shipping_zip.message}</small>}

                                                    <Form.Group className="mt-3" controlId="formGridAddress1">
                                                        <Form.Label>Town / City: <small>*</small></Form.Label>
                                                        <Form.Control
                                                            onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                            placeholder="Town / City name"
                                                            type="text"
                                                            {...register("shipping_city",
                                                                {
                                                                    maxLength: {
                                                                        value: VALIDATIONS.NAME,
                                                                        message: VALIDATIONS_TEXT.TOWN_OR_CITY_MAX_LENGTH
                                                                    },
                                                                    pattern: {
                                                                        value: /^[a-z0-9-, ]+$/gi,
                                                                        message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                                    },
                                                                    required: {
                                                                        value: true,
                                                                        message: VALIDATIONS_TEXT.TOWN_OR_CITY_REQUIRED
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {errors.shipping_city &&
                                                    <small className='text-red'>{errors.shipping_city.message}</small>}
                                                </>
                                            }

                                            <Form.Group className="mt-3" controlId="formGridAddress1">
                                                <Form.Label>Order notes (optional)</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={10}
                                                    {...register("description",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.ORDER_NOTES,
                                                                message: VALIDATIONS_TEXT.ORDER_NOTES_MAX
                                                            },
                                                            required: {
                                                                value: false,
                                                            },
                                                        })
                                                    }
                                                />
                                            </Form.Group>
                                            {errors.description &&
                                            <small className='text-red'>{errors.description.message}</small>}
                                        </Col>

                                        <Col xs={12} xl={{span: 4, offset: 1}} lg={5}>
                                            {/*<h5 className="mb-4 mb-lg-5 mt-3 weight-400">Billing details</h5>*/}
                                            <div className="right-div">
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <h5 className="mt-1">Product</h5>
                                                    <h5>Total</h5>
                                                </div>

                                                <div className="bg-theme-text-grey p-4">
                                                    {
                                                        cart &&
                                                        cart.map((item, i) => (
                                                            <div
                                                                key={i}
                                                                className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                                                                <p>{item.title}</p>
                                                                <span className="px-3"> (x{item.quantity}) </span>
                                                                <h5 className="m-0">${Number(item.price).toFixed(2)}</h5>
                                                            </div>
                                                        ))
                                                    }

                                                    {/*<div*/}
                                                    {/*    className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">*/}
                                                    {/*    <p>Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black</p>*/}
                                                    {/*    <span className="px-3"> (x1) </span>*/}
                                                    {/*    <h5 className="m-0">$3.90</h5>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="d-flex align-items-center justify-content-between">*/}
                                                    {/*    <p>Grohe Uniset 3'' Distance Pipe For Rapid SL Frame Black</p>*/}
                                                    {/*    <span className="px-3"> (x1) </span>*/}
                                                    {/*    <h5 className="m-0">$3.90</h5>*/}
                                                    {/*</div>*/}
                                                </div>

                                                <div className="sub-total mt-3">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mb-3">
                                                        <h5 className="first">Subtotal</h5>
                                                        <h5>${cartSummary.sub_total}</h5>
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h5 className="first">Shipping</h5>
                                                        <h5>${shippingCost}</h5>
                                                    </div>
                                                </div>

                                                <div className="below border-bottom mt-3">
                                                    <div
                                                        className="d-flex align-items-center justify-content-between mb-2">
                                                        <h5 className="weight-500">Total</h5>
                                                        <h5>${cartSummary.total}</h5>
                                                    </div>
                                                </div>

                                                <div className="p-4 mt-4 bg-theme-text-grey checkout-method mb-4">
                                                    <h5>Select Payment</h5>
                                                    <div
                                                        className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                                        <div className="d-flex align-items-center mt-2 mt-sm-2">
                                                            <Form.Check
                                                                name="paymentMethod"
                                                                type="radio"
                                                                id="visacard"
                                                                value="10"
                                                                {...register("paymentMethod",
                                                                    {
                                                                        required: VALIDATIONS_TEXT.PAYMENT_METHOD_REQUIRED
                                                                    })
                                                                }
                                                            />
                                                            <img className="ms-2" src={Assets.VisaCard} alt="VisaCard"/>
                                                        </div>

                                                        <div className="d-flex align-items-center mt-3 mt-sm-2">
                                                            <Form.Check
                                                                name="paymentMethod"
                                                                type="radio"
                                                                id="amazonpay"
                                                                value="20"
                                                                {...register("paymentMethod",
                                                                    {
                                                                        required: VALIDATIONS_TEXT.PAYMENT_METHOD_REQUIRED
                                                                    })
                                                                }
                                                            />
                                                            <img className="mt-1 ms-2" src={Assets.AmazonPay}
                                                                 alt="AmazonPay"/>
                                                        </div>

                                                        <div className="d-flex align-items-center mt-3 mt-sm-2">
                                                            <Form.Check
                                                                name="paymentMethod"
                                                                type="radio"
                                                                id="mastercard"
                                                                value="30"
                                                                {...register("paymentMethod",
                                                                    {
                                                                        required: VALIDATIONS_TEXT.PAYMENT_METHOD_REQUIRED
                                                                    })
                                                                }
                                                            />
                                                            <img src={Assets.MasterCard} className="ms-2"
                                                                 alt="MasterCard"/>
                                                        </div>
                                                    </div>
                                                    {errors.paymentMethod && <small
                                                        className='text-red d-inline-block'>{errors.paymentMethod.message}</small>}

                                                </div>

                                                <div className="border-bottom">
                                                    <div
                                                        className="p-4 mt-4 mt-lg-5 bg-theme-text-grey  checkout-method mb-4">
                                                        <h5>Schedule delivery</h5>
                                                        <div className="mt-3 d-flex align-items-center">
                                                            <BsFillCalendarWeekFill className="text-white"/>
                                                            <span className="mt-1 me-2 text-grey"
                                                                  onClick={() => setShowCalendarModal(true)}>{value.toString().slice(0, 3)} {value.toString().slice(8, 10)} at {(deliveryTimeSlot) ? deliveryTimeSlot : "--:--"}</span>
                                                            <FiChevronDown className="text-white"/>
                                                        </div>
                                                        {errors.delivery_time && <small
                                                            className='text-red'>{errors.delivery_time.message}</small>}
                                                    </div>
                                                </div>

                                                {/*<div className="mt-3">
                                                    <div className=" d-flex align-items-center justify-content-between">
                                                        <Button className="m-0 p-0 btn-view-order"
                                                                onClick={() => handleOnViewOrderClick()}>View
                                                            Order</Button>
                                                        {
                                                            is_verified ?
                                                                <div>
                                                                    <BsFillCheckCircleFill className="text-white"/>
                                                                    <span className="text-white">{is_verified}</span>
                                                                </div>
                                                                :
                                                                <></>
                                                        }

                                                    </div>
                                                </div>*/}

                                                <div className="mt-4 bottom-div">
                                                    <p className="w-100">
                                                        Your personal data will be used to process your order, support
                                                        your experience throughout this website, and for other purposes
                                                        described in our
                                                        <NavLink to="/privacy-policy" target={"_blank"}> privacy
                                                            policy.</NavLink>
                                                    </p>
                                                    <div className="mt-2">
                                                        <Form.Check
                                                            type="checkbox"
                                                            id=""
                                                            label="I have read and agree to the CMORE"
                                                            {...register("termsAndConditions",
                                                                {
                                                                    required: true
                                                                })
                                                            }
                                                        />
                                                        <NavLink to="/terms-and-conditions" className="ms-2"
                                                                 target={"_blank"}>terms and
                                                            conditions{errors.termsAndConditions &&
                                                            <small className='text-red'> *</small>}</NavLink>
                                                    </div>


                                                    <Button type="submit" className="d-block w-100 mt-4 btn-red">Place
                                                        and Order</Button>
                                                    <HostedForm authData={authData} onSubmit={onSubmitHandler}
                                                                environment={AUTHORIZE_NET_ENV}
                                                                errorTextStyle={{
                                                                    background: "#c32126",
                                                                    color: "white",
                                                                    position: "absolute",
                                                                    top: "10px",
                                                                    right: "10px",
                                                                    padding: "10px",
                                                                    borderRadius: "5px"
                                                                }}
                                                                buttonText={""}/>


                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Form>
        </div>
    )
}

export default CheckoutForm