import React, {useContext, useEffect, useState} from "react";
import {AppLogo} from "../../constants/svgs";
import {LocationModal, CustomItemModal, ProfileModals, IsCustomerLogin} from "../../App";
import {AiOutlineUser} from "react-icons/ai";
import {NavLink, useNavigate} from "react-router-dom";
import {FaBars, FaArrowRight} from "react-icons/fa";
import {StickyNavbar} from "../../App";
import {constants} from "../../utils/constants";
import Logo from "../../assets/images/home-page/logo.png"

const DriverPublicHeader = () => {
    const [showSignupOverlay, setShowSignupOverlay] = useState(false);
    const navigate = useNavigate();
    const [stickyNavbar, setStickyNavbar] = useContext(StickyNavbar);
    const [state, setState] = useState(false)
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [disabled, setDisabled] = useState(false)

    const openSignupModal = () => {
        setShowSignupOverlay(true)
        document.body.classList.add("handleSignupModal");
    }

    const closeSignupModal = () => {
        setShowSignupOverlay(false)
        document.body.classList.remove("handleSignupModal");
    }

    const [handleBar, setHandleBar] = useState(false)
    const handleClick = () => {
        if (handleBar == false) {
            document.body.classList.add("handleFabars");
            setHandleBar(true)

            setDisabled(true)
        } else {
            document.body.classList.remove("handleFabars");
            setHandleBar(false)

            setDisabled(false)
        }
    }

    return (
        <>
            <nav className={`navbar navbar-expand-xl driver-navbar ${stickyNavbar ? "position-fixed w-100 zindex-9999" : "static"}`}>
                <NavLink className="navbar-brand d-block d-xl-none mb-3" to="/">
                    <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"}/>
                </NavLink>
                <button
                    className={`navbar-toggler ${disabled ? "collapsed" : ""}`}
                    // type="button"
                    // data-toggle="collapse"
                    // data-target="#navbarToggle"
                    // aria-controls="navbarToggle"
                    // aria-expanded="false"
                    // aria-label="Toggle navigation"

                    disabled={disabled}
                >
                    <FaBars onClick={() => handleClick()} className="icon" />
                </button>

                <div className={`collapse navbar-collapse ${disabled ? "show" : ""} justify-content-between`} id="navbarToggle">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <NavLink className="nav-link ps-0 " to="/driver/profile-setup">Home</NavLink>
                        </li>

                        <li className="nav-item dropdown">
                            <NavLink className="nav-link ps-0" to="/about-us">About </NavLink>
                        </li>

                        <li className="nav-item dropdown">
                            <NavLink className="nav-link ps-0" to="/faq/order-information">FAQ</NavLink>
                        </li>
                    </ul>

                    <NavLink className="navbar-brand d-none d-xl-block" to="/">
                        <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"}/>
                    </NavLink>

                    {
                        !isCustomerLogin ?
                            <ul className="navbar-nav">
                                <li className="nav-item dropdown mt-2">
                                    <NavLink className="nav-link" to="/login"><AiOutlineUser
                                        className="me-2 icon"/>Login</NavLink>
                                </li>
                                <li className="nav-item dropdown mt-2">
                                    <NavLink className="nav-link" to="#" onClick={() => openSignupModal()}>Sign
                                        Up</NavLink>
                                </li>
                            </ul> : " "
                    }
                </div>
            </nav>
            {
                showSignupOverlay &&
                <div className="signup-overlay">
                    <div className="overlay-content w-100">
                        <div className="w-100">
                            <div className="text-end text-white mb-5 custom-margin" style={{cursor: "pointer"}}
                                 onClick={() => closeSignupModal()}>Close
                            </div>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()} to="/signup"
                                state={{role: constants.roles.ROLE_USER}}>
                                Sign up as a customer
                                <FaArrowRight
                                    className="icon"/>
                            </NavLink>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup" state={{role: constants.ROLES.ROLE_DRIVER}}>
                                Sign up as a driver
                                <FaArrowRight className="icon"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DriverPublicHeader;
