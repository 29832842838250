import React, { useState, useEffect } from "react";
import { Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Row, Col } from "react-bootstrap";

const ImageSlider = ({ images }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [screen, setScreen] = useState({ width: window.innerWidth });

    const handleResize = () => {
        setScreen({
            width: window.innerWidth
        })
    }

    useEffect(() => {
        window.addEventListener("resize", () => handleResize())
    }, [screen.width])

    return (
        <Row>
            <Col xxl={2} xl={3} lg={12} className="order-2 order-xl-1">
                <Swiper
                    spaceBetween={8}
                    direction={screen.width > 1200 ? "vertical" : "horizontal"}
                    slidesPerView={3} navigation={false} modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper} className={"swiperChild"}>
                    {
                        images &&
                        images.map((image, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div className="swiper-zoom-container">
                                        <img src={image.mediaUrl} className={"w-100 img-thumbnail"} />
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Col>

            <Col xxl={9} xl={9} lg={12} className="order-1 order-xl-2">
                <Swiper className={"swiperParent"} slidesPerView={1} zoom={true} navigation={false} modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}>
                    {
                        images &&
                        images.map((image, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <img src={image.mediaUrl} className={"image-slide"} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </Col>
        </Row>
    )
}

export default ImageSlider