import React, {useContext, useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import Footer from "../../components/app-footer/Footer";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import BankingDocumentationForm from "../../components/banking-documentation/BankingDocumentationForm";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../App";
import {GetPage} from "../../services/pages.service";
import ReactHtmlParser from "react-html-parser";
import FooterNew from "../../components/app-footer/FooterNew";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";

const CarrierDocumentationPage = () => {
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);
    const [data, setData] = useState([])

    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    useEffect(() => {
        getPage()
    }, [])

    async function getPage() {
        await GetPage({slug: "carrier-documentation"}).then((result) => {
            if (result.status && result.data) {
                setData(result.data)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    return (
        <>
            <NewDriverHeader/>
            <div className="carrier-documentation setup-profile">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right w-100">
                        <div className="banner py-5">
                            <Col className="d-block d-md-none" xs={12}>
                                <div className="text-end" style={{marginRight: "10px"}}>
                                    <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                  className="text-white icon-setting"/>
                                    {
                                        showDriverSidebar == false &&
                                        <hr/>
                                    }
                                </div>
                            </Col>
                        </div>
                        <div className="banner-bottom py-5">
                            <div className="py-4">
                                <Container>
                                    <Row className="justify-content-center">
                                        <Col xs={12} md={10}>
                                            <div className="text-center mb-5">
                                                <div>
                                                    <h1>{data?.title || "Carrier Documentation"}</h1>
                                                    <p className="mt-3">
                                                        {ReactHtmlParser(data?.content) || "No Data Found"}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default CarrierDocumentationPage;
