import React, { useContext, useState, useEffect } from "react";
import InviteFriendCard from "../../components/invite-friend/InviteFriendCard";
import { Container, Row, Col } from "react-bootstrap";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import Footer from "../../components/app-footer/Footer";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import { IoMdSettings } from "react-icons/io";
import { DriverSidebar } from "../../App";
import FooterNew from "../../components/app-footer/FooterNew";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";

const InviteFriendPage = () => {
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);

    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    return (
        <>
            <NewDriverHeader/>
            <div className="invite-friend setup-profile">
                <div className="d-flex">
                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal />
                        }
                    </div>

                    <div className="right px-2 py-5">
                        <Row>
                            <Col className="d-block d-md-none" xs={12}>
                                <div className="text-end">
                                    <IoMdSettings onClick={() => handleDriverSidebar(true)} className="text-white icon-setting" />
                                    {
                                        showDriverSidebar == false &&
                                        <hr />
                                    }
                                </div>
                            </Col>

                            <Col xs={12} md={{span:12, offset:1}} className="mb-4">
                                <h1>Invite your friends</h1>
                            </Col>

                            <Col xs={12} md={{span:10, offset:1}}>
                                <div className="invite-friend-card">
                                    <InviteFriendCard />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default InviteFriendPage