import React from "react";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import CloudStorage from "../../components/cloud-storage/CloudStorage";
import FooterNew from "../../components/app-footer/FooterNew";

const CloudStorageDashboardPage = () => {
    return (
        <>
            <AppHeader />
            <CloudStorage/>
            <FooterNew />
        </>
    )
}

export default CloudStorageDashboardPage;