import React from "react";
import AppHeader from "../../../components/app-header/public-header";
import BulkDeal from "../../../components/bulk-deal/BulkDeal";
import Footer from "../../../components/app-footer/Footer";
import {Container, Row, Col, Card} from "react-bootstrap";
import Offer from "../../../components/services/Offer";
import SectorCoverage from "../../../components/services/SectorCoverage";
import ThemeCard from "../../../components/card-section/ThemeCard"
import Assets from "../../../constants/images";
import CardSection from "../../../components/card-section/CardSection";
import FooterNew from "../../../components/app-footer/FooterNew";

const ShippingAndLogisticsPage = () => {
  const data = [
    {
      image: Assets.SAndLImage1,
      title: "Heavy Duty Equipment",
      description: "In our bulk deals category, you can order the necessary equipment needed for your project."
    },
    {
      image: Assets.SAndLImage2,
      title: "What is a CMORE Supply Project Liaison?",
      description: "Our Project Liaison serves as a median between your project and your procurement process, producing a stress-free work environment."
    },
    {
      image: Assets.SAndLImage3,
      title: "Interstate Shipping & Logistics",
      description: "We are currently operating in Florida, Georgia, Texas, North Carolina, South Carolina, Tennessee, and Louisiana. New territories updated bi-weekly."
    }
  ];

  return (
    <>
      <AppHeader />
      <div className="shipping-logistics">
        <div className="banner pt-5">
          <Container>
            <Row>
              <Col>
                <h1 className="pt-5">Shipping & Logistics</h1>
              </Col>
            </Row>
          </Container>
        </div>
        {/*here removed component*/}
        <div className="home-page card-section py-5">
          <div className="card-container py-5">
            <Container>
              <Row className="justify-content-lg-center">
                {
                  data &&
                  data.map((blog,i)=>{
                    return(
                        <Col xs={12} md={6} sm={6} lg={4} className="mb-4" key={i}>
                          <Card>
                            <Card.Img variant="top" src={blog.image} />
                            <Card.Body className="px-md-4 py-5">
                              <Card.Title>{blog.title}</Card.Title>
                              <Card.Text className="mt-3">{(blog.description.length > 150) ? blog.description.slice(0, 150) + "..." :  blog.description}</Card.Text>
                            </Card.Body>
                          </Card>
                        </Col>
                    )
                  })
                }
              </Row>
            </Container>
          </div>
        </div>
        {/*here removed component*/}
        <Offer/>
        <SectorCoverage/>
      </div>
      <FooterNew />
    </>
  )
}

export default ShippingAndLogisticsPage