import React from "react";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import BulkDeal from "../../../components/bulk-deal/BulkDeal";
import Work from "../../../components/Work/Work";
import BudgetSection from "../../../components/budget-section/BudgetSection";
import { StorageIcon, ServerIcon, BackupIcon } from "../../../constants/svgs";
import Package from "../../../components/services/Package";
import Assets from "../../../constants/images";
import FooterNew from "../../../components/app-footer/FooterNew";

const CloudStoragePage = () => {
  return (
    <>
      <AppHeader />

      <BulkDeal
        isBtnVisible={false}
        title="Cloud Storage"
        text="This is dummy copy. It is not meant to be read. 
          It has been placed here solely to demonstrate the look and 
          feel of finished, typeset text. Only for show. He who searches 
          for meaning here will be sorely disappointed."
        id="cloud-storage"
      />

      <Work
        Icon1={StorageIcon}
        Icon2={ServerIcon}
        Icon3={BackupIcon}
        text1="Secure Storage"
        text2="No Size Limits"
        text3="Secure Backups"
      />

      <Package />

      <BudgetSection
        link="/contact-us"
        isBtnVisible={true}
        title="Join Us!"
        text="This is dummy copy. It is not meant to be read. It has been placed 
        here solely to demonstrate the look and feel of finished, typeset text. 
        Only for show. He who searches for meaning here will be sorely disappointed."
        id="cloud-storage"
      />

      <FooterNew />

    </>
  )
}

export default CloudStoragePage