import React, {useContext, useEffect, useState} from "react";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import {Col, Container, Pagination, Row} from "react-bootstrap";
import ProductCard from "../../components/categories-section/ProductCard";
import ProductCardListView from "../../components/categories-section/ProductCardListView";
import Header from "../../components/cmore-supply/Header";
import Filter from "../../components/cmore-supply/Filter";
import {FiChevronUp, FiFilter} from "react-icons/fi";
import {FilterSidebar} from "../../App";
import {GetProducts} from "../../services/products.service"
import {useLocation, useSearchParams} from "react-router-dom";
import {BrandsContext, CategoriesContext, ProductTypesContext} from "../../hooks/GlobalContext";
import FooterNew from "../../components/app-footer/FooterNew";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import {BsChevronDown} from "react-icons/bs";

const CmoreSupplyPage = () => {
    const [view, setView] = useState("grid")
    const [showFilterSidebar, setShowFilterSidebar] = useContext(FilterSidebar)
    const [products, setProducts] = useState([])
    const [pagination, setPagination] = useState(null)
    const [page, setPage] = useState(null)
    const [categoryIds, setCategoryIds] = useState([])
    const [brandIds, setBrandIds] = useState([])
    const [productTypeIds, setProductTypeIds] = useState([])
    const [sortBy, setSortBy] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showMoveTop, setShowMoveTop] = useState(false)
    const [isRequested, setIsRequested] = useState(false)
    const productTypesData = useContext(ProductTypesContext)
    const categoriesData = useContext(CategoriesContext)
    const brandsData = useContext(BrandsContext)
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyword, setKeyword] = useState(searchParams.get("keyword"))
    const [price, setPrice] = useState({
        min: 0,
        max: 0
    });
    const [rating, setRating] = useState([])

    const handleOnChangeCategory = (event, categoryId) => {
        if (categoryId === -1 && event.target.checked) {
            const rows = []
            categoriesData.forEach((item) => {
                rows.push(item.id)
            })
            setCategoryIds(rows)
            return true
        } else if (categoryId === -1 && !event.target.checked) {
            setCategoryIds([])
            return true
        }

        if (location.state && location.state.selected_category_id === categoryId) {
            location.state.selected_category_id = ""
        }
        if (event.target.checked) {
            setCategoryIds([...categoryIds, categoryId])
        } else {
            setCategoryIds(
                categoryIds.filter(function (id) {
                    return id !== categoryId
                })
            )
        }
    }

    const handleOnChangeBrand = (event, brandId) => {

        if (brandId === -1 && event.target.checked) {
            const rows = []
            brandsData.forEach((item) => {
                rows.push(item.id)
            })
            setBrandIds(rows)
            return true
        } else if (brandId === -1 && !event.target.checked) {
            setBrandIds([])
            return true
        }

        if (event.target.checked) {
            setBrandIds([...brandIds, brandId])
        } else {
            setBrandIds(
                brandIds.filter(function (id) {
                    return id !== brandId
                })
            )
        }
    }

    const handleOnChangePriceRange = (e) => {
        let value = e.target.value
        if (value.includes(",")) {
            setPrice({
                min: value.split(",")[0],
                max: value.split(",")[1]
            })
        } else {
            if (e.target.value) {
                setPrice({
                    ...price,
                    [e.target.name]: e.target.value
                })
            }
        }


    }

    const handleRatingOnChange = (e) => {
        if (e.target.checked) {
            setRating([...rating, e.target.value])
        } else {
            setRating(rating.filter((i) => {
                return i !== e.target.value
            }))
        }
    }

    const handleOnChangeProductType = (event, productTypeId) => {

        if (productTypeId === -1 && event.target.checked) {
            const rows = []
            productTypesData.forEach((item) => {
                rows.push(item.id)
            })
            setProductTypeIds(rows)
            return true
        } else if (productTypeId === -1 && !event.target.checked) {
            setProductTypeIds([])
            return true
        }
        if (location.state && location.state.product_type_id === productTypeId) {
            location.state.product_type_id = ""
        }

        if (event.target.checked) {
            setProductTypeIds([...productTypeIds, productTypeId])
        } else {
            setProductTypeIds(
                productTypeIds.filter(function (id) {
                    return id !== productTypeId
                })
            )
        }
    }

    const handleChange = (views) => {
        setView(views);
    }

    function handleOnPrevClick(prevPage) {
        window.scrollTo(0, 0);
        if (!prevPage) return
        setPage(prevPage.split("=")[1])
    }

    function handleOnNextClick(nextPage) {
        window.scrollTo(0, 0);
        if (!nextPage) return
        setPage(nextPage.split("=")[1])
    }

    async function fetchProducts(page, category_ids, product_type_ids, brand_ids, ratings, price_range, sort_by) {
        // let keyword = location?.state?.searchKeyword
        const params = {
            page,
            category_ids,
            product_type_ids,
            brand_ids,
            ratings,
            sort_by,
            is_essential: 0
        }
        if (keyword) {
            // delete location.state.searchKeyword
            params.keyword = keyword
        }

        if ((price_range.min > 0 && price_range.max > 0)) {
            params.price_range = price_range
        }

        if (location.state && location.state.product_type_id) {
            params.product_type_ids = [location.state.product_type_id]
        }
        if (location.state && location.state.selected_category_id && category_ids.length === 0) {
            params.category_ids = [location.state.selected_category_id]
        }
        setIsLoading(true)
        setIsRequested(true);
        await GetProducts(params).then((result) => {
            setIsRequested(false);
            if (result.status && result.data.data) {
                setProducts(result.data.data)
                setPagination(result.data.meta)
                setIsLoading(false)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const handleSortByOnChange = (e) => {
        setSortBy(e.target.value)
    }

    useEffect(() => {
        if (!isRequested) {
            fetchProducts(page, categoryIds, productTypeIds, brandIds, rating, price, sortBy)
        }
    }, [page, categoryIds, productTypeIds, brandIds, rating, price, sortBy, location])
    useEffect(() => {

        if (location && location.state && location.state.hasOwnProperty("product_type_id")) {
            setProductTypeIds([
                location.state.product_type_id
            ])

            delete location.state.product_type_id
        }

        if (location && location.state && location.state.hasOwnProperty("selected_category_id")) {
            setCategoryIds([
                ...categoryIds, location.state.selected_category_id
            ])

            delete location.state.selected_category_id
        }

    }, [location])


    useEffect(() => {
        document.body.addEventListener("scroll", function () {
            if (document.body.scrollTop > 400) {
                setShowMoveTop(true)
            } else {
                setShowMoveTop(false)
            }
        })
    }, [document.body.scrollTop])


    useEffect(() => {
        if (showFilterSidebar) {
            document.body.classList.add("handleFilterOpen")
        } else {
            document.body.classList.remove("handleFilterOpen")
        }
    }, [showFilterSidebar])


    return (
        <>
            <ScrollToTopOnMount/>
            <div className="cmore-supply category-page category-section">
                <AppHeader/>
                <div className="cmore-supply-container category-container py-5">
                    <Container>
                        <Row>
                            {/*<Header onClick={handleChange}*/}
                            {/*        heading={(location.state && location.state.product_type_name) ? location.state.product_type_name : ""}/>*/}

                            <Header handleSortByOnChange={handleSortByOnChange} onClick={handleChange}
                                    heading={((products) ? products.length : 0) + " of " + ((pagination) ? pagination.total : 0) + " Results"}/>
                        </Row>

                        <Row className="d-block d-md-none">
                            <Col xs={12}>
                                <div style={{cursor: "pointer"}} onClick={() => setShowFilterSidebar(true)}>
                                    <FiFilter className="text-white"/>
                                    <span className="text-white ms-3 d-inline-block">Filters</span>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={3} className="mb-4 mt-0 mt-md-4">
                                <Filter
                                    handleOnChangeCategory={handleOnChangeCategory}
                                    handleOnChangeBrand={handleOnChangeBrand}
                                    handleRatingOnChange={handleRatingOnChange}
                                    handleOnChangePriceRange={handleOnChangePriceRange}
                                    handleOnChangeProductType={handleOnChangeProductType}
                                    //selected_product_type_id={(location.state && location.state.product_type_id) ? location.state.product_type_id : ""}
                                    selected_product_type_id={productTypeIds}
                                    selected_category_id={categoryIds}
                                    selected_brand_id={brandIds}
                                    //selected_category_id={(location.state && location.state.selected_category_id) ? location.state.selected_category_id : ""}
                                />
                            </Col>
                            {
                                (products.length > 0)
                                    ?
                                    <>
                                        <Col xs={12} md={9}>
                                            <Row className="mt-0 mt-md-4">
                                                {
                                                    (isLoading)
                                                        ?
                                                        <>
                                                            <h2 className="text-white">Loading...</h2>
                                                        </>
                                                        :

                                                        view === "grid" ?
                                                            products.map((v, i) => {

                                                                return <ProductCard id={v.id} key={i} index={i}
                                                                                    total_reviews={v.meta.total_reviews}
                                                                                    rating={(v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0}
                                                                                    attachments={v.attachments}
                                                                                    image={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : "http://cmore.theservtech.com/static/media/img1.6dcbb0ccc3eeb7ffe993.png"}
                                                                                    title={v.name} buy={v.buy}
                                                                                    price={Number(v.price).toFixed(2)}
                                                                                    sku={v.sku} weight={v.weight}
                                                                                    description={v.description}
                                                                                    category={v.category}
                                                                                    upc={v.upc}
                                                                />
                                                            }) :

                                                            products.map((v, i) => {
                                                                return <ProductCardListView id={v.id} key={i} index={i}
                                                                                            total_reviews={v.meta.total_reviews}
                                                                                            rating={(v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0}
                                                                                            attachments={v.attachments}
                                                                                            image={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : "http://cmore.theservtech.com/static/media/img1.6dcbb0ccc3eeb7ffe993.png"}
                                                                                            title={v.name} buy={v.buy}
                                                                                            price={Number(v.price).toFixed(2)}
                                                                                            sku={v.sku}
                                                                                            weight={v.weight}
                                                                                            description={v.description}
                                                                                            category={v.category}
                                                                                            upc={v.upc}
                                                                />
                                                            })
                                                }
                                            </Row>

                                            <Row className="text-center justify-content-center mt-5">
                                                <Col xs={12} sm={3}>
                                                    <Pagination>
                                                        <Pagination.Prev className="btn-prev"
                                                                         onClick={() => handleOnPrevClick(pagination.previous_page_url)}/>
                                                        <Pagination.Item
                                                            className="first">{(pagination) ? pagination.current_page : 0}</Pagination.Item>
                                                        <Pagination.Item
                                                            className="last">{(pagination) ? pagination.last_page : 0}</Pagination.Item>
                                                        <Pagination.Next className="btn-next"
                                                                         onClick={() => handleOnNextClick(pagination.next_page_url)}/>
                                                    </Pagination>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col xs={12} md={9}>
                                            <Row className="mt-0 mt-md-4">
                                                {
                                                    (isLoading)
                                                        ?
                                                        <>
                                                            <h2 className="text-white">Loading...</h2>
                                                        </>
                                                        :
                                                        <>
                                                            <h2 className="text-white">No Items Found</h2>
                                                        </>
                                                }

                                            </Row>
                                        </Col>
                                    </>
                            }

                        </Row>
                    </Container>
                </div>
                <FooterNew/>
            </div>

            <div className={`move-top-button ${showMoveTop && "visible"}`} onClick={() => document.body.scrollTop = 0}>
                <FiChevronUp/>
            </div>
        </>
    )
}

export default CmoreSupplyPage