const Assets = {
  headerAvatar: require("../assets/images/home-page/square.png"),
  Avatar: require("../assets/images/home-page/avatar.png"),
  CompanyLogo: require("../assets/images/home-page/logo.png"),
  homepageBannerOne: require("../assets/images/home-page/Mask Group 17.png"),
  CardImage1: require("../assets/images/home-page/Mask Group 6.png"),
  CardImage2: require("../assets/images/home-page/Group 1009.png"),
  CardImage3: require("../assets/images/home-page/Mask Group 8.png"),

  FooterImage: require("../assets/images/home-page/footer.png"),
  AmazonPay: require("../assets/images/home-page/Amazon_Pay-Logo.wine.png"),
  MasterCard: require("../assets/images/home-page/Mastercards-Logo.png"),
  VisaCard: require("../assets/images/home-page/visa-logo-png-transparent.png"),
  homepageCategorySectionLogos: require("../assets/images/home-page/logos.png"),
  homepageCategoryBath: require("../assets/images/home-page/Mask Group 21.png"),
  homepageCategoryAppliances: require("../assets/images/home-page/Mask Group 19.png"),
  homepageCategoryBlinds: require("../assets/images/home-page/Mask Group 22.png"),
  homepageCategoryBuildingMaterial: require("../assets/images/home-page/Mask Group 23.png"),
  homepageCategoryCleaningterial: require("../assets/images/home-page/Mask Group 24.png"),
  homepageCategoryWindow: require("../assets/images/home-page/Mask Group 25.png"),
  homepageCategoryElectrical: require("../assets/images/home-page/Mask Group 26.png"),
  homepageCategoryHardware: require("../assets/images/home-page/Mask Group 27.png"),

  Logos: require("../assets/images/home-page/logos.png"),

  AboutImage1: require("../assets/images/about-us-page/Rectangle 259.png"),
  AboutImage2: require("../assets/images/about-us-page/Rectangle 261.png"),
  AboutImage3: require("../assets/images/about-us-page/Rectangle 262.png"),
  AboutImage4: require("../assets/images/about-us-page/Rectangle 263.png"),

  ProductImage1: require("../assets/images/products/img1.png"),
  ProductImage2: require("../assets/images/products/img2.png"),
  ProductImage3: require("../assets/images/products/img3.png"),
  ProductImage4: require("../assets/images/products/img4.png"),
  ProductImage5: require("../assets/images/products/img5.png"),
  ProductImage6: require("../assets/images/products/img6.png"),
  ProductImage7: require("../assets/images/products/img7.png"),
  ProductImage8: require("../assets/images/products/img8.png"),
  PlaceHolderImage: require("../assets/images/products/placeholder-iamge.png"),

  MapImage: require("../assets/images/location/map.jpg"),
  NoImageAvailable: require("../assets/images/profile-page/No_Image_Available.jpg"),

  BulkImage1: require("../assets/images/bulk-deals/image1.PNG"),
  BulkImage2: require("../assets/images/bulk-deals/image2.PNG"),
  BulkImage3: require("../assets/images/bulk-deals/image3.PNG"),
  BulkImage4: require("../assets/images/bulk-deals/image4.PNG"),

  TruckImage: require("../assets/images/bulk-deals/truck.png"),
  TruckImage1: require("../assets/images/bulk-deals/truck1.PNG"),
  TruckImage2: require("../assets/images/bulk-deals/truck2.PNG"),
  TruckImage3: require("../assets/images/bulk-deals/truck3.PNG"),
  TruckImage4: require("../assets/images/bulk-deals/truck4.PNG"),

  LgTruckImage: require("../assets/images/bulk-deals/lgtruck.PNG"),

  GroupAImg1: require("../assets/images/truck-and-vehicle-type-page/groupA_img1.png"),
  GroupAImg2: require("../assets/images/truck-and-vehicle-type-page/groupA_img2.png"),
  GroupAImg3: require("../assets/images/truck-and-vehicle-type-page/groupA_img3.png"),
  GroupAImg4: require("../assets/images/truck-and-vehicle-type-page/groupA_img4.png"),
  GroupAImg5: require("../assets/images/truck-and-vehicle-type-page/groupA_img5.png"),

  GroupBImg1: require("../assets/images/truck-and-vehicle-type-page/groupB_img1.png"),
  GroupBImg2: require("../assets/images/truck-and-vehicle-type-page/groupB_img2.png"),
  GroupBImg3: require("../assets/images/truck-and-vehicle-type-page/groupB_img3.png"),
  GroupBImg4: require("../assets/images/truck-and-vehicle-type-page/groupB_img4.png"),

  GroupCImg1: require("../assets/images/truck-and-vehicle-type-page/groupC_img1.png"),
  GroupCImg2: require("../assets/images/truck-and-vehicle-type-page/groupC_img2.png"),
  GroupCImg3: require("../assets/images/truck-and-vehicle-type-page/groupC_img3.png"),

  EquipmentTypeImg1: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img1.png"),
  EquipmentTypeImg2: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img2.png"),
  EquipmentTypeImg3: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img3.png"),
  EquipmentTypeImg4: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img4.png"),

  EquipmentTypeImg5: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img5.png"),
  EquipmentTypeImg6: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img6.png"),
  EquipmentTypeImg7: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img7.png"),
  EquipmentTypeImg8: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img8.png"),

  EquipmentTypeImg9: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img9.png"),
  EquipmentTypeImg10: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img10.png"),
  EquipmentTypeImg11: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img11.png"),
  EquipmentTypeImg12: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img12.png"),
  EquipmentTypeImg13: require("../assets/images/truck-and-vehicle-type-page/equipment_type_img13.png"),

  ShippingAndLogisticCardImage1: require("../assets/images/services/image1.png"),
  ShippingAndLogisticCardImage2: require("../assets/images/services/image2.png"),
  ShippingAndLogisticCardImage3: require("../assets/images/services/image3.png"),


  TruckImageNoBg1: require("../assets/images/bulk-deals/truck1-no-bg.png"),
  TruckImageNoBg2: require("../assets/images/bulk-deals/truck2-no-bg.png"),
  TruckImageNoBg3: require("../assets/images/bulk-deals/truck3-no-bg.png"),
  TruckImageNoBg4: require("../assets/images/bulk-deals/truck4-no-bg.png"),
  TruckImageNoBg5: require("../assets/images/bulk-deals/my_truck.png"),

  NewTruckImageNoBg: require("../assets/images/bulk-deals/NewTruck.png"),

  FooterLogos: require("../assets/images/home-page/footerlogos.png"),
  AmazonPayFooter: require("../assets/images/home-page/amazonfooter.png"),
  MasterCardFooter: require("../assets/images/home-page/mastercardfooter.png"),
  VisaCardFooter: require("../assets/images/home-page/visacardfooter.png"),

  VisaCardLogo: require("../assets/images/payment-methods/visa-logo.png"),
  AmazonPayLogo: require("../assets/images/payment-methods/amazon-pay-logo.png"),
  MasterCardLogo: require("../assets/images/payment-methods/master-card-logo.png"),

  //  ************************* New Images *************************

  Image1: require("../assets/images/home-page/new/img1.png"),
  Image2: require("../assets/images/home-page/new/img2.png"),
  Image3: require("../assets/images/home-page/new/img3.png"),
  Image4: require("../assets/images/home-page/new/img4.png"),
  Image5: require("../assets/images/home-page/new/img5.png"),
  Image6: require("../assets/images/home-page/new/img6.png"),
  Image7: require("../assets/images/home-page/new/img7.png"),
  Image8: require("../assets/images/home-page/new/img8.png"),
  Image9: require("../assets/images/home-page/new/img9.png"),
  Image10: require("../assets/images/home-page/new/img10.png"),
  Image11: require("../assets/images/home-page/new/img11.png"),

  Logo1: require("../assets/images/home-page/new/logo1.png"),
  Logo2: require("../assets/images/home-page/new/logo2.png"),
  Logo3: require("../assets/images/home-page/new/logo3.png"),
  Logo4: require("../assets/images/home-page/new/logo4.png"),
  Logo5: require("../assets/images/home-page/new/logo5.png"),
  Logo6: require("../assets/images/home-page/new/logo6.png"),
  Logo7: require("../assets/images/home-page/new/logo7.png"),

  CardImg1: require("../assets/images/home-page/new/cardImg1.png"),
  CardImg2: require("../assets/images/home-page/new/cardImg2.png"),
  CardImg3: require("../assets/images/home-page/new/cardImg3.png"),

  LargeImg1: require("../assets/images/home-page/new/largeImg1.png"),
  LargeImg2: require("../assets/images/home-page/new/largeImg2.png"),

  CarImage1: require("../assets/images/home-page/new/car1.png"),
  CarImage2: require("../assets/images/home-page/new/car2.png"),
  CarImage3: require("../assets/images/home-page/new/car3.png"),
  CarImage4: require("../assets/images/home-page/new/car4.png"),
  CarImage5: require("../assets/images/home-page/new/car5.png"),

  BottomBanner: require("../assets/images/home-page/new/bottomBanner.png"),
  BtnGoogle: require("../assets/images/home-page/new/btnGoogle.png"),
  BtnApple: require("../assets/images/home-page/new/btnApple.png"),

  UserPlaceholder: require("../assets/images/home-page/new/userplaceholder.png"),

  NewTruckImg1: require("../assets/images/home-page/new/truckImg1.png"),
  NewTruckImg2: require("../assets/images/home-page/new/truckImg2.png"),
  NewTruckImg3: require("../assets/images/home-page/new/truckImg3.png"),
  NewTruckImg4: require("../assets/images/home-page/new/truckImg4.png"),
  NewTruckImg5: require("../assets/images/home-page/new/truckImg5.png"),
  NewTruckImg6: require("../assets/images/home-page/new/truckImg6.png"),
  NewTruckImg7: require("../assets/images/home-page/new/truckImg7.png"),
  NewTruckImg8: require("../assets/images/home-page/new/truckImg8.png"),
  NewTruckImg9: require("../assets/images/home-page/new/truckImg9.png"),
  NewTruckImg10: require("../assets/images/home-page/new/truckImg10.png"),
  NewTruckImg11: require("../assets/images/home-page/new/truckImg11.png"),
  NewTruckImg12: require("../assets/images/home-page/new/truckImg12.png"),

  NewTruckImg13: require("../assets/images/home-page/new/truckImg13.png"),
  NewTruckImg14: require("../assets/images/home-page/new/truckImg14.png"),
  NewTruckImg15: require("../assets/images/home-page/new/truckImg15.png"),
  NewTruckImg16: require("../assets/images/home-page/new/truckImg16.png"),
  NewTruckImg17: require("../assets/images/home-page/new/truckImg17.png"),
  NewTruckImg18: require("../assets/images/home-page/new/truckImg18.png"),
  NewTruckImg19: require("../assets/images/home-page/new/truckImg19.png"),
  NewTruckImg20: require("../assets/images/home-page/new/truckImg20.png"),

  SAndLImage1: require("../assets/images/new-images/SAndLImage1.jpg"),
  SAndLImage2: require("../assets/images/new-images/SAndLImage2.png"),
  SAndLImage3: require("../assets/images/new-images/SAndLImage3.png"),

  BlogImage1: require("../assets/images/new-images/BlogImage1.jpg"),
  BlogImage2: require("../assets/images/new-images/BlogImage2.jpg"),
  BlogImage3: require("../assets/images/new-images/BlogImage3.jpg"),

  AllVehicleTypesIcon: require("../assets/images/new-images/AllVehicleTypesIcon.png"),
  DispatchICon: require("../assets/images/new-images/DispatchICon.png"),
  OwnerLeaseIcon: require("../assets/images/new-images/OwnerLeaseIcon.png"),
  EasySignUpIcon: require("../assets/images/new-images/EasySignUpIcon.png"),

  LimitedTimeIcon3: require("../assets/images/new-images/LimitedTimeIcon3.png"),
  PartnersIcon1: require("../assets/images/new-images/PartnersIcon1.png"),
  SupportICon2: require("../assets/images/new-images/SupportICon2.png"),
};

export default Assets;
