import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Assets from "../../constants/images"
import { ArrowIcon } from "../../constants/svgs"
import {NavLink, useNavigate} from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DealCards = () => {
    const navigate = useNavigate()
    return (
        <div className="DealsCard py-5">
            <Container fluid className="m-0 p-0">
                <Row>
                    <Col xs={{span: 12, order:2}} md={{span: 12, order:2}} xl={{span: 4, order:0}} className="my-auto">
                        <div className="box-container1">
                            <div className="box bg-white">
                                <h4>Bulk Deals</h4>
                                <p>
                                    Our bulk deals are made to supply your project needs at wholesale price. Products ranging from Yards of Sod, Pallets of Mortar and Concrete, or 1000’s of square feet of Flooring. Our bulk catalog is updated daily, no membership required.
                                    <br/><br/>
                                    While Supplies Last!
                                </p>
                                <div className="text-end mt-5 mb-4">
                                   <NavLink className="dealButton" to="/products">All Deals <ArrowIcon /></NavLink>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12} xl={8}>
                        <div>
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.LargeImg1} alt="" />
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5">
                    <Col xs={12} md={12} xl={8} className="my-auto">
                        <div>
                            <LazyLoadImage effect="opacity" className="w-100" src={Assets.LargeImg2} alt="" />
                        </div>

                    </Col>

                    <Col xs={12} md={12} xl={4} className="my-auto">
                        <div className="box-container2">
                            <div className="box bg-white">
                                <h4>Our Technology</h4>
                                <p>
                                    Your Project Sourcing and Delivery Liaison!
                                    <br/> <br/>
                                    We provide project supply with delivery options quick as 30 minutes or less, reshaping the concept of accessibility of project materials. CMORE Supply aims to emphasize convenience, giving you the power at your fingertips to get what your project needs on-demand.
                                </p>

                                <div className="text-end mt-5 mb-4">
                                   <NavLink className="dealButton" to="/products">All Deals <ArrowIcon /></NavLink>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default DealCards