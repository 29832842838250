import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { FaStar, FaRegStar } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Cart } from "../../App";
import { success, error } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import Assets from "../../constants/images";
import { handleBrokenImageOnError } from "../../utils/helper";
import AddToCartPopup from "./AddToCartPopup";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductCardListView = ({ id, upc, image, title, rating, buy, total_reviews, price, sku, weight, attachments, description }) => {
    const [cart, setCart] = useContext(Cart);
    const [addToCartPopup, setAddToCartPopup] = useState(false)

    const closeAddToCartPopup = () => {
        setAddToCartPopup(false)
    }

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    let bool = false;
    let index = 0;
    const addToCart = (id, image, title, rating, buy, price, weight) => {
        if (cart.length === 0) {
            setCart([
                {
                    id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight
                }
            ])
        }
        else {
            cart.map((item, i) => {
                if (item.id === id) {
                    bool = true
                    index = i
                    return
                }
            })
            if (bool === true) {
                let temp = [...cart];
                temp[index].quantity += 1;
                temp[index].price = temp[index].actualPrice * temp[index].quantity;
                setCart(temp);
                bool = false
            }
            else {
                setCart([
                    ...cart,
                    {
                        id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight
                    }
                ])
            }
        }

        setAddToCartPopup(true)
        setTimeout(() => {
            setAddToCartPopup(false)
        }, 2000)
    }

    return (
        <Col xs={12} className="mb-4">
            <AddToCartPopup image={image} title={title} addToCartPopup={addToCartPopup} closeAddToCartPopup={closeAddToCartPopup} />
            <div className="product-card list-card">
                <Card className="">
                    <Row>
                        <Col xs={12} sm={4} md={4} lg={3}>
                            <NavLink
                                to={`/product/${id}`}
                                state={{ pid: id, upc, image, title, rating, buy, price, sku, weight, attachments, total_reviews, description }}
                            >
                                <LazyLoadImage effect="opacity" variant="top" className="w-100" src={image} onError={(e) => handleBrokenImageOnError(e)} />
                            </NavLink>
                        </Col>

                        <Col xs={12} sm={8} md={8} lg={9} className="my-auto">
                            <Card.Body>
                                <div>
                                    <span className="title">{(title.length > 40) ? title.slice(0, 40) + "..." : title}</span>
                                    {/* <div className="d-flex align-items-center mt-2">
                                        {
                                            Array.from(Array(rating), (_, i) => {
                                                return <FaStar key={i} className="icon me-1" />
                                            })
                                        }
                                        {
                                            Array.from(Array(5 - rating), (_, i) => {
                                                return <FaRegStar key={i} className="icon me-1" />
                                            })
                                        }
                                        <span className="text-grey">({total_reviews})</span>
                                    </div> */}
                                    <div className="mt-2 d-flex justify-content-between align-items-center">
                                        <p className="p-0 m-0"><span className="text-red">$ </span>{price}</p>
                                        <Button className="btn-add-cart me-md-3" onClick={() => addToCart(id, image, title, rating, buy, price, weight)}>Add to cart</Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Col>
    )
}

export default ProductCardListView;