import React, {useEffect, useState} from "react";
import ReturnAndCancellation from "../../../components/faq/ReturnAndCancellation";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import {GetCurrentUser} from "../../../services/auth/auth.service";
import {constants} from "../../../utils/constants";
import DriverPublicHeader from "../../../components/app-header/driver-public-header";
import FooterNew from "../../../components/app-footer/FooterNew";

const ReturnAndCancellationPage = () => {
    const user = GetCurrentUser();
    const [isDriver, setIsDriver] = useState(false);

    useEffect(() => {
        setIsDriver(user?.roles[0].id === constants.ROLES.ROLE_DRIVER)
    }, [])

    return (
        <>
            {isDriver ? <DriverPublicHeader/> : <AppHeader/>}
            <ReturnAndCancellation/>
            <FooterNew />
        </>
    )
}

export default ReturnAndCancellationPage;
