import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import { AiFillPlusCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
    return (
        <div className="sidebar p-4">
            <div className="mb-4">
                <Button className="w-100 text-center justify-content-center btn-red d-flex align-items-center py-2 px-3">
                    <AiFillPlusCircle className="icon me-2" />
                    Create New
                </Button>
            </div>

            <div className="border-bottom mb-3">
                <NavLink className={(navData) => navData.isActive ? "active" : ""} to="">My Cloud</NavLink>
                <NavLink className={(navData) => navData.isActive ? "active" : ""} to="/recent-uploads">Recent Uploads</NavLink>
                <NavLink className={(navData) => navData.isActive ? "active" : ""} to="/trash">Trash</NavLink>
            </div>

            <div>
                <h5>Storage Details</h5>
                <ProgressBar className="my-2" now={60} />
                <div className="text-end">
                    <span>4 GB of 15 GB used</span>
                </div>
            </div>
        </div>
    )
}

export default Sidebar