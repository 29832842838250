import React, {useState} from "react";
import {Row, Col, Form, Button, Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FaFacebookF, FaTwitter} from "react-icons/fa";
import {success, error} from "../../constants/msg";
import {toast, ToastContainer} from "react-toastify";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../constants/app-constants";
import {NavLink} from "react-router-dom";
import {InviteFriend} from "../../services/general.service";

const InviteFriendCard = () => {
    const [showModal, setShowModal] = useState(false);
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm();
    const [counter, setCounter] = useState(1);
    const [textToCopy, setTextToCopy] = useState("");

    const onSubmit = async (data) => {
        await InviteFriend(data).then((result) => {
            if (result.status) {
                toast.success("Email successfully sent")
                reset()
                setShowModal(false)
            }
        }).catch((error) => {
            toast.success("Error in sending email")
            console.log("error", error.message);
        })
    }

    return (
        <>
            <ToastContainer/>
            <Modal
                show={showModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modal-invite"
            >
                <Modal.Header>
                    <div className="d-flex w-100 p-2 pb-3 align-items-center border-bottom justify-content-between">
                        <div>
                            <h5>Invite friends</h5>
                        </div>
                        <div>
                            <span className="text-white" style={{cursor: "pointer"}}
                                  onClick={() => setShowModal(false)}>close</span>
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className="p-4 pb-2 pt-0">
                        <span className="text d-inline-block mb-3">Invite your friends to CMORE via their email addresses.</span>
                        <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xs={6} className="mb-4">
                                    <Form.Control
                                        type="email"
                                        className="py-2"
                                        placeholder="Enter email address"
                                        {...register("email",
                                            {
                                                maxLength: {
                                                    value: VALIDATIONS.EMAIL,
                                                    message: VALIDATIONS_TEXT.EMAIL_MAX
                                                },
                                                required: {
                                                    value: true,
                                                    message: VALIDATIONS_TEXT.ONLY_EMAIL_REQUIRED
                                                },
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                }
                                            })
                                        }
                                    />
                                    {errors.email && <small className='text-red'>{errors.email.message}</small>}
                                </Col>

                                <Col xs={4}>
                                    <Button type="submit" className="w-100 btn-send-invite">Send invite</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="card-container py-4 p-5">
                <Row>
                    {/*<Col xs={12} xl={6}>
                        <div className="ps-xl-5">
                            <h5 className="border-bottom pb-3">Invite Friends</h5>
                            <div className="mt-4">
                                <h1>Invite with a code</h1>
                                <p>Send a unique code to your friend</p>
                                <div className="mt-4 d-flex align-items-center flex-wrap">
                                    <div className="me-5">
                                        <span>Copy code here</span>
                                        <div className="box align-items-center px-1 ps-2 d-flex justify-content-between">
                                            <Form.Control
                                                onChange={(e)=>setTextToCopy(e.target.value)}
                                                value={textToCopy}
                                                type="text"
                                                // readOnly
                                                placeholder="ABC12KJY"
                                            />
                                            <Button onClick={() => {navigator.clipboard.writeText(textToCopy)}} className="btn-red-small px-4">Copy</Button>
                                        </div>
                                    </div>

                                    <div>
                                        <span>Share code</span>
                                        <div className="d-flex align-items-center">
                                            <NavLink to="#">
                                                <div className="div-facebook me-2">
                                                    <FaFacebookF className="icon" />
                                                </div>
                                            </NavLink>

                                            <NavLink to="#">
                                                <div className="div-twitter">
                                                    <FaTwitter className="icon" />
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>*/}

                    <Col xs={12} xl={{span: 5, offset: 1}} className="mb-5">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mt-4">
                                <h1 className="">Invite with Gmail</h1>
                                <p>Invite friend via email</p>
                                <Button className="btn-red mt-4 px-5" onClick={() => setShowModal(true)}>Invite
                                    friends</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InviteFriendCard
