import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";

const ErrorPage = ({ errorType, errorTitle }) => {
  return (
    <>
      <AppHeader />
      <section className="Dashboard page_404 text-center mb-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={10} className="text-center">
              <div className="mt-5">
                <div className="four_zero_four_bg ">
                  <h1 className="text-center heading">{errorType}</h1>
                </div>
                <div className="contant_box_404">
                  <h3 className="h2"> Look like you're lost</h3>
                  <p>{errorTitle}</p>
                  <NavLink to="/" className="link_404">Go to Home</NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ErrorPage;