import React, {useEffect, useState} from "react"
import { Container, Row, Col, Nav } from "react-bootstrap";
import NavComponent from "./NavComponent";
import {GetPage} from "../../services/pages.service";
import ReactHtmlParser from "react-html-parser";

const ReturnAndCancellation = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getPage()
    }, [])

    async function getPage() {
        await GetPage({slug: "return-cancelation"}).then((result) => {
            if (result.status && result.data) {
                setData(result.data)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    return (
        <>
            <div className="faq-page">
                <div className="faq-container">
                    <div className="banner-img"></div>
                    <Container>
                        <Row className="justify-content-center py-5">
                            <Col xs={12} md={12} xl={3}>
                                <NavComponent />
                            </Col>

                            <Col xs={12} md={12} xl={9}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="w-100 pb-4 py-xl-5 text-center">
                                            {/*<h1>Return & Cancellation</h1>*/}
                                            <h1>{data?.title || "Return & Cancellation"}</h1>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <div className="ps-xl-5">
                                            <p className="mt-3">
                                                {ReactHtmlParser(data?.content) || "No Data Found"}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ReturnAndCancellation;
