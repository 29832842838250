import React, {useEffect, useState} from "react"
import {Col, Container, Row} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import {GetPage} from "../../services/pages.service";
import ReactHtmlParser from "react-html-parser";
import FooterNew from "../../components/app-footer/FooterNew";

const HowItWorksPage = () => {
    const [page, setPage] = useState(null)
    async function fetchPage() {
        await GetPage({slug: "how-it-works"}).then((result) => {
            if (result.status && result.data) {
                setPage(result.data)
            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        fetchPage()
    }, [])

    return (
        <>
            <AppHeader/>
            <div className="faq-page">
                <div className="faq-container">
                    <div className="banner-img"></div>
                    <Container>
                        <Row className="justify-content-center py-5">
                            <Col xs={12} md={12} xl={3}>
                                <div className="link-container my-5 p-4">
                                    <ul>
                                        <li><NavLink to="/help-center" className="py-2 mb-1">Help Center</NavLink></li>
                                        <li><NavLink to="/how-it-works" className="py-2 mb-1">How It Works</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </Col>

                            <Col xs={12} md={12} xl={9}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="w-100 pb-4 py-xl-5 text-center">
                                            <h1>HOW IT WORKS</h1>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12}>
                                        <div className="mb-4 ps-xl-5">
                                            {
                                                (page) ?
                                                    <p>{ReactHtmlParser(page.content)}</p>
                                                    : <></>
                                            }
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default HowItWorksPage