import React, {useEffect, useState} from "react"
import {Container, Row, Col, Nav} from "react-bootstrap";
import NavComponent from "./NavComponent";
import {GetFaq} from "../../services/pages.service";

const OrderInformation = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        getFaq()
    }, [])

    async function getFaq() {
        await GetFaq({slug: "carrier-documentation"}).then((result) => {
            if (result.status && result.data) {
                console.log("==result faq===", result.data);
                setData(result.data.data)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    return (
        <>
            <div className="faq-page">
                <div className="faq-container">
                    <div className="banner-img"></div>
                    <Container>
                        <Row className="justify-content-center py-5">
                            <Col xs={12} md={12} xl={3}>
                                <NavComponent/>
                            </Col>

                            <Col xs={12} md={12} xl={9}>
                                <Row>
                                    <Col xs={12}>
                                        <div className="w-100 pb-4 py-xl-5 text-center">
                                            <h1>FAQs</h1>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    {
                                        data && data.length > 0 ?
                                            data.map((v) => {
                                                return (
                                                    <Col xs={12} md={6}>
                                                        <div className="mb-4 ps-xl-5">
                                                            <h5>{v?.question || "N/A"}</h5>
                                                            <p className="mt-4">
                                                                { v?.answer || "N/A"}
                                                            </p>
                                                        </div>
                                                    </Col>
                                                )
                                            })
                                            : <div>No Data Found</div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default OrderInformation
