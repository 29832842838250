import React from "react"
import { Container, Button, Row, Col, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import Assets from "../../constants/images";
import { RiDeleteBin5Line } from "react-icons/ri";
import FooterNew from "../../components/app-footer/FooterNew";

const PaymentMethodPage = () => {
    return (
        <>
            <AppHeader />
            <div className="payment-method-page py-5">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">Payment Methods</li>
                                </ol>
                            </nav>
                            <h1>Payment Methods</h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={8} lg={8} xl={6}>
                            <div className="payment-card mb-4 px-4 py-3 d-flex align-items-center justify-content-between">
                                <div className="me-3 me-sm-0">
                                    <Form.Check
                                        type="radio"
                                        name="payment-method"
                                    />
                                </div>

                                <div className="me-3"> 
                                    <img className="img-fluid" src={Assets.VisaCardLogo} alt="VisaCardLogo" />
                                </div>

                                <div className="me-3">
                                    <h6>xxxx-xxxx-xxxx</h6>
                                    <small>Marks Anthony</small>
                                    <small>Validity: 11/22</small>
                                </div>

                                <div>
                                    <RiDeleteBin5Line className="icon" />
                                </div>
                            </div>

                            <div className="payment-card mb-4 px-4 py-3 d-flex align-items-center justify-content-between">
                                <div className="me-3 me-sm-0"> 
                                    <Form.Check
                                        type="radio"
                                        name="payment-method"
                                    />
                                </div>

                                <div className="me-3">
                                    <img className="img-fluid" src={Assets.AmazonPayLogo} alt="AmazonPayLogo" />
                                </div>

                                <div className="me-3">
                                    <h6>xxxx-xxxx-xxxx</h6>
                                    <small>Marks Anthony</small>
                                    <small>Validity: 11/22</small>
                                </div>

                                <div>
                                    <RiDeleteBin5Line className="icon" />
                                </div>
                            </div>

                            <div className="payment-card mb-4 px-4 py-3 d-flex align-items-center justify-content-between">
                                <div className="me-3 me-sm-0">
                                    <Form.Check
                                        type="radio"
                                        name="payment-method"
                                    />
                                </div>

                                <div className="me-3">
                                    <img className="img-fluid" src={Assets.MasterCardLogo} alt="MasterCardLogo" />
                                </div>

                                <div className="me-3">
                                    <h6>xxxx-xxxx-xxxx</h6>
                                    <small>Marks Anthony</small>
                                    <small>Validity: 11/22</small>
                                </div>

                                <div>
                                    <RiDeleteBin5Line className="icon" />
                                </div>
                            </div>

                            <Button className="w-100 btn-done py-2 mb-4">Done</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterNew />
        </>
    )
}

export default PaymentMethodPage