import React from "react";
import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {handleBrokenImageOnError} from "../../utils/helper";

const CategoryCard = ({ id, image, title }) => {
  return (
    <Col xs={6} sm={6} md={4} lg={3} className="mt-4" >
      <div className="box productBox" style={{height:"186px"}}>
        <NavLink to="/products" state={{selected_category_id: id}}>
          <img className="w-100 h-100" src={image} alt={title} onError={(e) => handleBrokenImageOnError(e)}/>
          <div className="overlay text-center">
            <h5>{title}</h5>
          </div>
        </NavLink>
      </div>
    </Col>
  )
}

export default CategoryCard