import React from "react";
import OrderHistory from "../../components/order-history/OrderHistory";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import FooterNew from "../../components/app-footer/FooterNew";

const OrderHistoryPage = () => {
    return (
        <>
            <AppHeader/>
            <OrderHistory />
            <FooterNew />
        </>
    )
}

export default OrderHistoryPage