import React, {useEffect, useState} from "react"
import Assets from "../../constants/images";
import CartCard from "../../components/order-detail/CartCard";
import {Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {toast, ToastContainer} from "react-toastify";
import {constants} from "../../utils/constants"
import {GetOrderDetail, ReturnOrder} from "../../services/orders.service";

const OrderDetail = () => {
    const {register, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate();
    const location = useLocation()

    const [showReturnOrderModal, setShowReturnOrderModal] = useState(false);

    const onSubmit = async (data) => {
        if(!data.agree){
            toast.error("Kindly agree to the terms!")
            return
        }
        await ReturnOrder({id: location.state.order.id, return_note: data.return_note}).then((res) => {
            if(res.status){
                navigate("/order-history")
            }
        }).catch((err) => {
            console.log(err)
        })
        reset();
    }

    const [orderDetail, setOrderDetail] = useState(null)
    const fetchOrderDetail = async (id) => {
        await GetOrderDetail(id).then((res) => {
            if (res.status && res.data) {
                setOrderDetail(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchOrderDetail(location.state.order_id)
    }, [location])

    return (
        <>
            <Modal
                className="return-order-modal"
                show={showReturnOrderModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100 border-bottom pb-2 align-items-center justify-content-between">
                        <div>
                            <h5>Return order</h5>
                        </div>
                        <div>
                            <span className="text-white" style={{cursor: "pointer"}}
                                  onClick={() => setShowReturnOrderModal(false)}>Close</span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            <div className="px-3">
                                <p className="text-grey" style={{fontSize: "16px", color: "#8e8e8e"}}>Please list down
                                    the material that you want to return</p>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Control className="py-3" {...register("return_note")} as="textarea" rows={3}></Form.Control>

                                    <Form.Check
                                        name="agree"
                                        {...register("agree")}
                                        className="my-4"
                                        type="checkbox"
                                        label="I agree to pay the 15% fee for returning the material."
                                    />

                                    <Button type="submit" className="btn-send mb-4 w-100 py-3">Send</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <div className="order-detail">
                <div className="cart-page">
                    <div className="header pt-5 pb-4">
                        <ToastContainer/>
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page"><NavLink
                                                to="/order-history">Order history</NavLink></li>
                                            <li className="breadcrumb-item active" aria-current="page">Order details
                                            </li>
                                        </ol>
                                    </nav>
                                </Col>
                                <Col xxl={9} xl={9} lg={9} md={12} xs={12} className="mb-4">
                                    <h1>Order ID: {(orderDetail) ? orderDetail.order_number : 0}</h1>
                                </Col>

                                {
                                    location.state && location.state.status === constants.ORDERSTATUS.DELIVERED
                                        ?

                                        <Col xxl={3} xl={3} lg={3} md={12} xs={12} className="text-end">
                                            <Button className="w-100 py-3 btn-return-order"
                                                    onClick={() => setShowReturnOrderModal(true)}>Return Order</Button>
                                        </Col>

                                        : <></>
                                }


                            </Row>
                        </Container>
                    </div>
                    {
                        (orderDetail) ? <CartCard order={orderDetail}/>
                        : <><p>Loading ...</p></>
                    }

                </div>
            </div>

        </>
    )
}

export default OrderDetail