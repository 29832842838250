import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Col} from "react-bootstrap";
import {FaRegStar, FaStar} from "react-icons/fa";
import {NavLink} from "react-router-dom";
import {Cart} from "../../App";
import {handleBrokenImageOnError} from "../../utils/helper";
import AddToCartPopup from "./AddToCartPopup";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProductCard = ({id, image, upc, category, title, rating, sku, buy, total_reviews, price, weight, attachments, description}) => {
    const [cart, setCart] = useContext(Cart);
    const [addToCartPopup, setAddToCartPopup] = useState(false)

    const closeAddToCartPopup = () => {
        setAddToCartPopup(false)
    }

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    let bool = false;
    let index = 0;
    const addToCart = (id, image, title, rating, buy, price, weight, category_id) => {
        if (cart.length === 0) {
            setCart([
                {
                    id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight, category_id
                }
            ])
        } else {
            cart.map((item, i) => {
                if (item.id === id) {
                    bool = true
                    index = i
                    return
                }
            })
            if (bool === true) {
                let temp = [...cart];
                temp[index].quantity += 1;
                temp[index].price = temp[index].actualPrice * temp[index].quantity;
                setCart(temp);
                bool = false
            } else {
                setCart([
                    ...cart,
                    {
                        id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight, category_id
                    }
                ])
            }

        }

        setAddToCartPopup(true)
        setTimeout(() => {
            setAddToCartPopup(false)
        }, 2000)
    }
    return (
        <Col xs={6} md={6} sm={6} lg={4} xl={3} className="mb-3">
            <AddToCartPopup image={image} title={title} addToCartPopup={addToCartPopup}
                            closeAddToCartPopup={closeAddToCartPopup}/>
            <div className="product-card">
                <Card>
                    <NavLink
                        to={`/product/${id}`}
                        state={{
                            pid: id,
                            upc,
                            image,
                            category,
                            title,
                            rating,
                            buy,
                            price,
                            weight,
                            sku,
                            attachments,
                            total_reviews,
                            description
                        }}
                    >
                        {/*<Card.Img variant="top" className="w-100" src={image} onError={(e) => handleBrokenImageOnError(e)} />*/}
                        <LazyLoadImage effect="opacity" className="card-img-top w-100" src={image} onError={(e) => handleBrokenImageOnError(e)}/>
                    </NavLink>

                    <Card.Body>
                        <div>
                            <span className="title">{(title.length > 40) ? title.slice(0, 40) + "..." : title}</span>
                            {/* <div className="d-flex align-items-center mt-2">
                                {
                                    (rating => 0) &&
                                    Array.from(Array(rating), (_, i) => {
                                        return <FaStar key={i} className="icon me-1"/>
                                    })
                                }

                                {
                                    (rating => 0) &&
                                    Array.from(Array(5 - rating), (_, i) => {
                                        return <FaRegStar key={i} className="icon me-1"/>
                                    })
                                }

                                <span className="text-grey">({total_reviews})</span>
                            </div> */}
                            <div className="mt-2 flex-wrap d-flex justify-content-between align-items-center">
                                <p className="p-0 m-0 me-2"><span className="text-red">$ </span>{price}</p>
                                <Button
                                    className="btn-add-cart mt-1"
                                    onClick={() => addToCart(id, image, title, rating, buy, price, weight, category?.id)}
                                >Add to cart</Button>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

            </div>
        </Col>
    )
}

export default ProductCard;