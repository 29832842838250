import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Assets from "../../constants/images";
import { VanIcon } from "../../constants/svgs";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Offer = () => {
    return (
        <div className="home-page work-section we-offer py-5">
            <div className="work-container we-offer-container text-center py-5">
                <Container>
                    <Row className="justify-content-lg-center">
                        <Col xs={12}>
                            <h1>What We Offer</h1>
                            <p>Shipping and Logistics Support and Management.</p>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col xs={12} md={6} sm={6} className="mb-4" >
                            <div className="box vanbox d-flex align-items-center">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="p-4 vanIcon">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.EasySignUpIcon} alt="" />
                                    </div>

                                    <div className="p-4 pt-0 mt-sm-4">
                                        <h5>Simple On-Boarding Registration Process.</h5>
                                        <p>
                                            Click the sign-up tab in the menu section as a driver and provide your fleet information and request for registration in as quick as 15 Minutes. Active cargo insurance needed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={6} sm={6} className="mb-4" >
                            <div className="box vanbox d-flex align-items-center">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="p-4 vanIcon">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.DispatchICon} alt="" />
                                    </div>

                                    <div className="p-4 pt-0 mt-sm-4">
                                        <h5>Dispatching Services</h5>
                                        <p>
                                            Whether you are last mile delivering or long-haul trucking, we provide dispatching services to assist your business with the coordination of the pickup and delivery of goods.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={6} sm={6} className="mb-4" >
                            <div className="box vanbox d-flex align-items-center">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="p-4 vanIcon">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.OwnerLeaseIcon} alt="" />
                                    </div>

                                    <div className="p-4 pt-0 mt-sm-4">
                                        <h5>Owner Operator Lease on Program.</h5>
                                        <p>
                                            Interested in joining but do not have your own trucking authority or cargo insurance? We can assist. Bring your vehicles and equipment to CMORE Supply and we’ll connect you with a trusted partner to qualify your equipment for registration.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={6} sm={6} className="mb-4" >
                            <div className="box vanbox d-flex align-items-center">
                                <div className="d-flex flex-column flex-lg-row align-items-center">
                                    <div className="p-4 vanIcon">
                                        <LazyLoadImage effect="opacity" className="w-100" src={Assets.AllVehicleTypesIcon} alt="" />
                                    </div>

                                    <div className="p-4 pt-0 mt-sm-4">
                                        <h5>All Vehicle Types Are Welcome.</h5>
                                        <p>
                                            We have project materials and essentials of all dimensions that requires transportation. No matter the size of your vehicle and desired transport radius we have well-paying deliveries for you.
                                            <br /><br />
                                            Cars. Cargo and Sprinter Vans. Pick Up Trucks. Semi-Trucks. Join Us !

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Offer