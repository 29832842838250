import React, { useEffect, useState } from "react";
import Shipping from "../../../components/faq/Shipping";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import { GetCurrentUser } from "../../../services/auth/auth.service";
import { constants } from "../../../utils/constants";
import DriverPublicHeader from "../../../components/app-header/driver-public-header";
import FooterNew from "../../../components/app-footer/FooterNew";

const ShippingPage = () => {
    const user = GetCurrentUser();
    const [isDriver, setIsDriver] = useState(false);

    useEffect(() => {
        setIsDriver(user?.roles[0].id === constants.ROLES.ROLE_DRIVER)
    }, [])

    return (
        <>
            {isDriver ? <DriverPublicHeader /> : <AppHeader />}
            <Shipping />
            <FooterNew />
        </>
    )
}

export default ShippingPage;
