import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, } from "react-bootstrap";
import { FaMinus, FaPlus, FaRegStar, FaStar } from "react-icons/fa";
import Assets from "../../constants/images";
import "pure-react-carousel/dist/react-carousel.es.css";
import ImageSlider from "./ImageSlider";
import { Cart } from "../../App";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { success } from "../../constants/msg";
import { ToastContainer } from "react-toastify";
import AddToCartPopup from "../categories-section/AddToCartPopup";

const DescriptionCard = ({ pid, image, category, title, rating, buy, price, weight, sku, attachments, total_reviews, upc }) => {
    const images = [
        Assets.ProductImage1,
        Assets.ProductImage2,
        Assets.ProductImage3,
    ]
    const { id } = useParams();
    const ref = useRef();
    const [count, setCount] = useState(0);
    const [cart, setCart] = useContext(Cart);
    const [inputQuantity, setInputQuantity] = useState(1);
    const [addToCartPopup, setAddToCartPopup] = useState(false)
    const navigate = useNavigate();

    const closeAddToCartPopup = () => {
        setAddToCartPopup(false)
    }

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));

    }, [cart, count])

    useEffect(() => {
        let index = 0;
        let bool = false;

        if (cart.length > 0) {
            cart.map((item, i) => {
                if (item.id === id) {
                    bool = true
                    index = i

                }
            })

            if (bool === true) {
                setInputQuantity(cart[index].quantity)
            }
        }

    }, [cart, count])


    let pindex = 0;
    let pbool = false;
    const handleIncrement = (id) => {
        if (cart.length === 0) {
            setInputQuantity(inputQuantity + 1);
        } else if (cart.length > 0) {
            cart.map((item, i) => {
                if (item.id === id) {
                    pbool = true
                    pindex = i

                }
            })
            if (pbool === true) {
                let temp = [...cart];
                temp[pindex].quantity = inputQuantity + 1;
                temp[pindex].price = temp[pindex].actualPrice * temp[pindex].quantity;
                setCart(temp);
                pbool = false
            } else {
                setInputQuantity(inputQuantity + 1);
            }
        }
    }

    let pdindex = 0;
    let pdbool = false;
    const handleDecrement = (id) => {
        if (cart.length === 0) {
            if (inputQuantity > 1) {
                setInputQuantity(inputQuantity - 1);
            }
        } else if (cart.length > 0) {
            cart.map((item, i) => {
                if (item.id === id) {
                    pdbool = true
                    pdindex = i

                }
            })
            if (pdbool === true) {
                let temp = [...cart];
                if (temp[pdindex].quantity > 1) {
                    temp[pdindex].quantity = inputQuantity - 1;
                    temp[pdindex].price = temp[pdindex].actualPrice * temp[pdindex].quantity;
                    setCart(temp);
                    pdbool = false
                }
            } else {
                if (inputQuantity > 1) {
                    setInputQuantity(inputQuantity - 1);
                }
            }
        }
    }

    let index = 0;
    let bool = false;

    const addToCart = (pid, image, title, rating, buy, price, weight, is_buy_now = false) => {
        setCount(count + 1)
        if (cart.length === 0) {
            setCart([
                {
                    id: pid, image, title, rating, buy, price, actualPrice: price, quantity: inputQuantity, weight, category_id:category?.id
                }
            ])
        } else {
            cart.map((item, i) => {
                if (item.id === id) {
                    bool = true
                    index = i

                }
            })
            if (bool === true) {
                let temp = [...cart];
                temp[index].quantity = inputQuantity + 1;
                temp[index].price = temp[index].actualPrice * temp[index].quantity;
                setCart(temp);
                bool = false
            } else {
                setCart([
                    ...cart,
                    {
                        id: pid, image, title, rating, buy, price, actualPrice: price, quantity: inputQuantity, weight, category_id:category?.id
                    }
                ])
            }

        }
        if (is_buy_now) {
            navigate("/cart");
        }

        setAddToCartPopup(true)
        setTimeout(() => {
            setAddToCartPopup(false)
        }, 2000)
    }

    return (
        <Col xs={12}>
            <div className="bg-dark-grey pt-5 pb-4">
                <AddToCartPopup image={image} title={title} addToCartPopup={addToCartPopup} closeAddToCartPopup={closeAddToCartPopup} />
                <Row>
                    <Col xs={12} lg={5} className="mb-4">
                        <div className="px-4">
                            <ImageSlider images={attachments} />
                        </div>
                    </Col>

                    <Col xs={12} lg={7}>
                        <div style={{ height: "95%" }} className="px-lg-5 px-4 border-left">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page"><NavLink
                                        to="/all-categories">product</NavLink></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                            </nav>

                            <div>
                                <h5>{title}</h5>
                                {/* <div className="d-flex align-items-center">
                                    {
                                        Array.from(Array((rating) ? rating : 0), (_, i) => (
                                            <FaStar key={i} className="icon me-1" />
                                        ))
                                    }
                                    {
                                        Array.from(Array(5 - ((rating) ? rating : 0)), (_, i) => (
                                            <FaRegStar key={i} className="icon me-1" />
                                        ))
                                    }
                                    <span>({(total_reviews) ? total_reviews : 0})</span>
                                </div> */}
                                <p className="mt-2"><span className="text-red">$ </span>{price}</p>
                            </div>

                            <div>
                                <span className="text-white">Quantity</span>
                                <div className="text-center mt-1 border-bottom pb-4">
                                    <Row>
                                        <Col xs={12} sm={4}>
                                            <div className="d-flex align-items-center mb-2">
                                                <Button className="btns btn-minus" onClick={() => handleDecrement(pid)}><FaMinus
                                                    className="icons" /></Button>
                                                {/* <Form.Control readOnly ref={ref} value={cart[id] && cart[id].quantity} className="text-center text-dark" type="number" placeholder="1" /> */}
                                                <Form.Control readOnly ref={ref} value={inputQuantity}
                                                    className="text-center text-dark" type="number"
                                                    placeholder="1" />
                                                <Button className="btns btn-plus"
                                                    onClick={() => handleIncrement(pid)}><FaPlus className="icons" /></Button>
                                            </div>
                                        </Col>

                                        <Col xs={12} sm={4}>
                                            <Button className="btn-red-transparent mb-2"
                                                onClick={() => addToCart(pid, image, title, rating, buy, price, weight)}>Add
                                                to Cart</Button>
                                        </Col>

                                        <Col xs={12} sm={4}>
                                            <Button className="btn-red"
                                                onClick={() => addToCart(pid, image, title, rating, buy, price, weight, true)}>Buy Now</Button>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="bottom-div mt-4">
                                    <p>Weight: <span>{weight} lbs</span></p>
                                    <p>SKU: <span>{sku}</span></p>
                                    <p>UPC: {upc || "N/A"}</p>
                                    {/*<p>Category: <NavLink to="/all-products/appliances">Appliances</NavLink></p>*/}
                                    <p>Category: {category?.name || "N/A"}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

export default DescriptionCard