import React from "react";
import SignupFrom from "../../../components/authentication/SignupFrom";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import FooterNew from "../../../components/app-footer/FooterNew";

const SignupPage = () => {
  return (
    <>
        <AppHeader />
        <SignupFrom/>
        <FooterNew />
    </>
  )
}

export default SignupPage