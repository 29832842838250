import React from "react";
import ResetPasswordForm from "../../../components/authentication/ResetPasswordForm";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import FooterNew from "../../../components/app-footer/FooterNew";

const ResetPasswordPage = () => {
  return (
    <>
        <AppHeader />
        <ResetPasswordForm/>
        <FooterNew />
    </>
  )
}

export default ResetPasswordPage;