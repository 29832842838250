import React from "react";
import {Navigate, Outlet} from "react-router";
import {constants} from "./utils/constants";

const DriverProtectedRoute = ({isLogged}) => {
    return (
        isLogged && isLogged.roles[0].id === constants.ROLES.ROLE_DRIVER ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default DriverProtectedRoute;
