import React from "react";
import {Row, Col} from "react-bootstrap";

const CartCardHeader = () => {
    return (
        <div className="d-none d-sm-block cart-table pt-3 px-4">
            <Row>
                <Col xs={6} className="text-left">
                    <h5>Product</h5>
                </Col>
                <Col xs={3}>
                    <h5 className="text-left">Quantity</h5>
                </Col>
                <Col xs={2}>
                    <h5 className="text-left">Price</h5>
                </Col>
                <Col xs={1}>
                    <h5 className="text-left"></h5>
                </Col>
            </Row>
        </div>
    )
}

export default CartCardHeader