import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const BudgetSection = ({title, text, isBtnVisible, link, id}) => {
    return (
        <div className="home-page budget-section py-5 py-sm-0 d-sm-flex align-items-sm-center" id={id}>
            <Container>
                <Row>
                    <Col xs={12} md={{ span:8, offset:4}} lg={{ span:8, offset:4}} xl={{ span:5, offset:7}}>
                        <div className="budget-section-container p-5">
                            <h1>{title}</h1>
                            <p>{text}</p>
                            {
                                isBtnVisible &&
                                <NavLink to={link} className="mt-3 btn-red">Contact Us</NavLink>                        
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BudgetSection