import React, {useEffect, useState} from "react"
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import {Col, Container, Row} from "react-bootstrap";
import ProfileCard from "../../components/about-page/ProfileCard";
import Assets from "../../constants/images";
import BudgetSection from "../../components/budget-section/BudgetSection";
import ReactHtmlParser from "react-html-parser";
import {GetPage} from "../../services/pages.service";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import {GetCurrentUser} from "../../services/auth/auth.service";
import {constants} from "../../utils/constants";
import FooterNew from "../../components/app-footer/FooterNew";

const AboutPage = () => {
    const user = GetCurrentUser();
    const [isDriver, setIsDriver] = useState(false);
    const [page, setPage] = useState(null)

    useEffect(() => {
        setIsDriver(user?.roles[0].id === constants.ROLES.ROLE_DRIVER)
    }, [])

    const team = [
        {
            image: Assets.AboutImage1,
            name: "Steve Smith",
            post: "CEO & Co-Founder",
            description: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.",
            position: "left"
        },
        {
            image: Assets.AboutImage2,
            name: "Steve Smith",
            post: "CEO & Co-Founder",
            description: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.",
            position: "right"
        },
        {
            image: Assets.AboutImage3,
            name: "Steve Smith",
            post: "CEO & Co-Founder",
            description: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.",
            position: "left"
        },
        {
            image: Assets.AboutImage4,
            name: "Steve Smith",
            post: "CEO & Co-Founder",
            description: "This is dummy copy. It is not meant to be read. It has been placed here solely to demonstrate the look and feel of finished, typeset text. Only for show. He who searches for meaning here will be sorely disappointed. These words are here to provide the reader with a basic impression of how actual text will appear in its final presentation. Think of them merely as actors on a paper stage, in a performance devoid of content yet rich in form.",
            position: "right"
        }
    ]

    async function fetchPage() {
        await GetPage({slug: "about-us"}).then((result) => {
            if (result.status && result.data) {
                setPage(result.data)
            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        fetchPage()
    }, [])

    return (
        <>
            {
                isDriver ? <DriverPublicHeader /> : <AppHeader />
            }
            <div className="about-page bulk-deal">
                <div className="about-container bulk-deal-container">
                    <div className="banner-img"></div>
                    <div className="banner pb-5">
                        <Container>
                            <Row className="justify-content-center pt-5">
                                <Col xs={12} lg={8}>
                                    <div className="banner py-5 text-center text-white">
                                        <h1 className="mb-3">About Us</h1>
                                        {
                                            (page) ?
                                                ReactHtmlParser(page.content)
                                                : <></>
                                        }
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center">
                                <Col xs={12} lg={8}>
                                    <div className="our-team text-center py-5 pb-4">
                                        <div className="header">
                                            <h2 className="mb-3">Our Team</h2>
                                            <p>
                                                This is dummy copy. It is not meant to be read.
                                                It has been placed here solely to demonstrate the
                                                look and feel of finished, typeset text.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="pb-5">
                                {
                                    team.map((v, i) => {
                                        return <ProfileCard key={i} image={v.image} name={v.name} post={v.post}
                                                            description={v.description} position={v.position}/>
                                    })
                                }
                            </Row>
                        </Container>
                    </div>

                    <div className="bottom-banner">
                        <BudgetSection
                            id="about-budget"
                            link="/contact-us"
                            isBtnVisible={true}
                            title="Join Us"
                            text="This is dummy copy. It is not meant to be read.
                                It has been placed here solely to demonstrate
                                the look and feel of finished, typeset text.
                                Only for show. He who searches for meaning
                                here will be sorely disappointed."
                        />
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default AboutPage
