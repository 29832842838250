import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap";
import {NavLink} from "react-router-dom";

const BulkDeal = ({title, text, isBtnVisible, id, link}) => {
    return (
        <div className="home-page bulk-deal py-5 py-sm-0 d-sm-flex align-items-sm-center" id={id}>
            <Container>
                <Row>
                    <Col xs={12} md={8} lg={8} xl={5}>
                        <div className="bulk-deal-container p-5">
                            <h1>{title}</h1>
                            <p>{text}</p>
                            {
                                isBtnVisible &&
                                <NavLink to={link} className="mt-3 btn-red">View All Deals</NavLink>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BulkDeal