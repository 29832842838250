import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AppLogo } from "../../constants/svgs";
import CheckLocation from "../../components/check-location/CheckLocationCard";
import CustomItem from "../../components/custom-item/CustomItemCard";
import {
    Cart,
    CustomItemModal,
    IsCustomerLogin,
    LocationModal,
    ProfileModals,
    StickyNavbar,
} from "../../App";
import ProfileModal from "../../components/profile-modal/ProfileModal";
import { BiSearch } from "react-icons/bi";
import { AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { FaArrowRight, FaBars, FaRegBell } from "react-icons/fa";
import Search from "../search/Search";
import Notification from "../notification/Notification";
import { ProductTypesContext } from "../../hooks/GlobalContext";
import { GetProducts } from "../../services/products.service";
import { constants } from "../../utils/constants";
import { GetCurrentUser } from "../../services/auth/auth.service";
import { handleBrokenImageOnError } from "../../utils/helper";
import {
    GetNotifications,
    GetUnreadNotificationsCount,
    MarkAllNotificationsRead,
} from "../../services/notifications.service";
import { FiSearch } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../assets/images/home-page/logo.png"

const PublicHeader = () => {
    const [cart, setCart] = useContext(Cart);
    const [show, setShow] = useState(false);
    const [showSearchSuggestion, setShowSearchSuggestion] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [showLocationModal, setShowLocationModal] = useContext(LocationModal);
    const [showCustomItemModal, setShowCustomItemModal] =
        useContext(CustomItemModal);
    const [showProfileModal, setShowProfileModal] = useContext(ProfileModals);
    const [showSignupOverlay, setShowSignupOverlay] = useState(false);
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [stickyNavbar, setStickyNavbar] = useContext(StickyNavbar);
    const [products, setProducts] = useState([]);
    const [searchRecordsExists, setSearchRecordsExists] = useState(true);
    const productTypeContext = useContext(ProductTypesContext);
    const [productTypes, setProductTypes] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyword, setKeyword] = useState(searchParams.get("keyword"))
    const handleProfileModal = () => {
        setShowProfileModal(!showProfileModal);
        document.body.classList.add("handleProfilemodal");
    };

    const openSignupModal = () => {
        setShowSignupOverlay(true);
        document.body.classList.add("handleSignupModal");
    };

    const closeSignupModal = () => {
        setShowSignupOverlay(false);
        document.body.classList.remove("handleSignupModal");
    };

    const [handleBar, setHandleBar] = useState(false);

    const handleClick = () => {
        if (handleBar == false) {
            document.body.classList.add("handleFabars");
            setHandleBar(true);

            setDisabled(true)

        } else {
            document.body.classList.remove("handleFabars");
            setHandleBar(false);

            setDisabled(false)

        }
    };

    const handleSearchOnChange = (e) => {
        const value = e.target.value;
        setKeyword(value)
        if (value != "" && value.length >= 3) {
            searchProducts(value);
            setIsTyping(true)
        } else {
            setProducts([]);
            setIsTyping(false)
            setSearchRecordsExists(true)
        }
    };

    const handleSearchOnEnter = (e) => {
        const value = e.target.value;

        if (value != "" && value.length >= 3 && e.key === "Enter") {
            navigate("/products?keyword=" + value, { state: { searchKeyword: value } })
        }
    };


    useEffect(() => {
        if (products.length > 0) {
            setIsTyping(false)
        }
    }, [products])


    const searchProducts = async (keyword) => {
        let params = {
            keyword,
            is_essential: 0,
        };
        setSearchRecordsExists(true)
        await GetProducts(params)
            .then((result) => {
                if (result.status && result.data.data) {
                    setProducts(result.data.data);
                    setIsTyping(false)
                    if (result.data.data.length == 0) {
                        setSearchRecordsExists(false)
                    }
                }
            })
            .catch((error) => {
            });
    };

    const getNotifications = async () => {
        await GetNotifications()
            .then((result) => {
                if (result.status && result.data.data) {
                    setNotifications(result.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getUnreadNotificationCount = async () => {
        await GetUnreadNotificationsCount()
            .then((result) => {
                if (result.status && result.data) {
                    setNotificationCount(result.data.unread_count);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleNotificationOnClick = async () => {
        console.log("clicked notification");
        await MarkAllNotificationsRead()
            .then((result) => {
                if (result.status) {
                    setNotificationCount(0);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setProductTypes(productTypeContext);
        const _user = GetCurrentUser();

        if (_user) {
            setIsCustomerLogin(_user);
        }
    }, [productTypeContext]);

    useEffect(() => {
        const _user = GetCurrentUser();
        if (_user) {
            getUnreadNotificationCount();
            getNotifications();
        }
    }, []);
    const formenu = () => {
        return (
            <div className="NewSearchContainer">
                <div className="search-div pe-3 py-2 d-flex align-items-center">
                    <Form.Control
                        className="ps-4 text-dark"
                        type="text"
                        value={keyword}
                        placeholder="Search here..."
                        onFocus={() => setShowSearchSuggestion(true)}
                        onChange={handleSearchOnChange}
                        onKeyDown={handleSearchOnEnter}
                    />
                    {
                        isTyping ?
                            <CircularProgress />
                            :
                            <BiSearch
                                className="inner-search-icon"
                            />
                    }
                </div>

                <div className="menu-search ps-4">
                    {showSearchSuggestion === true && (
                        <Search
                            showSearchSuggestion={showSearchSuggestion}
                            setShowSearchSuggestion={setShowSearchSuggestion}
                            products={products}
                            searchRecordsExists={searchRecordsExists}
                        />
                    )}
                </div>

            </div>
        );
    };
    return (
        <>
            {
                isSearchOpen &&
                <>
                    {document.body.classList.add("handleSearch")}
                    <div className="Newoverlay" onClick={() => {
                        setIsSearchOpen(false)
                        document.body.classList.remove("handleSearch")
                    }}></div>
                </>
            }

            <nav
                id="mynav"
                className={`navbar navbar-expand-xl ${stickyNavbar ? "position-fixed w-100 zindex-9999" : "static"
                    }`}
            >
                <NavLink className="navbar-brand d-block d-xl-none mb-3" to="/">
                    <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"} />
                </NavLink>
                <div className="d-flex align-items-center flex-row-reverse">
                    <button
                        className={`navbar-toggler ${disabled ? "collapsed" : ""}`}
                        // type="button"
                        // data-toggle="collapse"
                        // data-target="#navbarToggle"
                        // aria-controls="navbarToggle"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"
                        disabled={disabled}
                    >
                        <FaBars onClick={() => {
                            handleClick()
                        }} className="icon" />
                    </button>
                    {isCustomerLogin && (
                        <div style={{ marginTop: "-10px" }} className="nav-item d-block d-xl-none">
                            <div
                                className="py-2 d-flex align-items-center me-3"
                                id="navbarDropdown4"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                onClick={() => handleNotificationOnClick()}
                            >
                                <div className={`cart-counter notifCount ${isCustomerLogin && " ms-3"} `}>
                                    <FaRegBell
                                        style={{ fontSize: "23px", marginTop: "0.45rem" }}
                                        className="icon icon-notification"
                                    />
                                    {/*<AiOutlineShoppingCart className="icon shopping-cart-icon"/>*/}
                                    <span className="badge">{notificationCount}</span>
                                </div>
                            </div>
                            <div
                                className="dropdown-menu menu-search"
                                aria-labelledby="navbarDropdown4"
                            >
                                <Notification notifications={notifications} />
                            </div>
                        </div>
                    )}

                    <NavLink className="nav-link dropdown d-block d-xl-none" to="/cart">
                        <div className="cart-counter">
                            <AiOutlineShoppingCart className="icon shopping-cart-icon" />
                            <span className="badge">{cart.length}</span>
                        </div>
                    </NavLink>
                    <ul className="paddingUl">
                        <li className="nav-item me-xl-3 d-block d-xl-none">
                            <FiSearch
                                style={{
                                    fontSize: "23px",
                                    marginTop: "0.85rem",
                                    cursor: "pointer",
                                }}
                                className="icon icon-search "
                                onClick={() => setIsSearchOpen(!isSearchOpen)}
                            />
                            {isSearchOpen ? formenu() :  document.body.classList.remove("handleSearch")}
                        </li>
                    </ul>
                </div>


                <div
                    className={`collapse navbar-collapse ${disabled ? "show" : ""} justify-content-between`}
                    id="navbarToggle"
                >
                    <NavLink className="navbar-brand d-none d-xl-block" to="/">
                        <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"} />
                    </NavLink>

                    <ul className="navbar-nav">
                        {/*<li className="nav-item dropdown me-5 mt-1">*/}
                        {/*  {isCustomerLogin ? (*/}
                        {/*    <>*/}
                        {/*      <img*/}
                        {/*        src={isCustomerLogin ? isCustomerLogin.image_path : ""}*/}
                        {/*        alt="user profile"*/}
                        {/*        className="header-profile-image"*/}
                        {/*        onClick={() => handleProfileModal()}*/}
                        {/*        onError={(e) => handleBrokenImageOnError(e)}*/}
                        {/*      />*/}
                        {/*    </>*/}
                        {/*  ) : (*/}
                        {/*    <></>*/}
                        {/*  )}*/}
                        {/*</li>*/}

                        <li className="nav-item dropdown mt-2">
                            <a
                                className="mb-0 nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                CMORE Supply
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {productTypes.map((item, key) => (
                                    <NavLink
                                        className="dropdown-item"
                                        key={key}
                                        to="/products"
                                        state={{
                                            product_type_id: item.id,
                                            product_type_name: item.name,
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                ))}
                            </div>
                        </li>

                        <li className="nav-item dropdown mt-xl-2">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown2"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                Services
                            </a>
                            <div className="dropdown-menu" style={{ overflowX: "hidden" }}
                                aria-labelledby="navbarDropdown2">
                                {/*<NavLink className="dropdown-item" to="/services/cloud-storage">*/}
                                {/*    Cloud Storage*/}
                                {/*</NavLink>*/}
                                <NavLink
                                    className="dropdown-item"
                                    to="/services/shipping-and-logistics"
                                >
                                    Shipping & Logistics
                                </NavLink>
                            </div>
                        </li>

                        <li className="nav-item ">
                            <Button className="mt-xl-2  new-check-btn" onClick={() => setShowLocationModal(true)}>Check
                                My location</Button>
                        </li>

                        <li className="nav-item ms-2">
                            <Button className="text-start mt-2 new-custom-btn"
                                onClick={() => setShowCustomItemModal(true)}>Custom Item</Button>
                        </li>

                        <li style={{ paddingTop: "14px", fontWeight: "500" }} className="nav-item ms-0 ms-xl-2">
                            <NavLink to="/faq/order-information"
                                className="text-start mt-5 new-custom-btn">FAQs</NavLink>
                        </li>
                    </ul>

                    <ul className="navbar-nav inlineList">
                        <li className="nav-item d-none d-xl-block">
                            <FiSearch
                                style={{
                                    fontSize: "23px",
                                    marginTop: "1.57rem",
                                    cursor: "pointer",
                                }}
                                className={`icon icon-notification ${isCustomerLogin && "icon-margin"}`}
                                onClick={() => setIsSearchOpen(!isSearchOpen)}
                            />
                            {isSearchOpen ? formenu() : ""}
                        </li>
                        {/* <li className="nav-item me-3">
            </li> */}

                        {!isCustomerLogin ? (
                            <>
                                <li className="nav-item dropdown me-0 me-xl-3 mt-2 d-none d-xl-block">
                                    <NavLink className="nav-link dropdown " to="/cart">
                                        <div className="cart-counter ps-3">
                                            <AiOutlineShoppingCart className="icon shopping-cart-icon" />
                                            <span className="badge">{cart.length}</span>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className="nav-item dropdown mt-2 btn-nav">
                                    <Button
                                        className="mt-2 me-0 me-xl-3 log-in"
                                        onClick={() => navigate("/login")}
                                    >
                                        Login
                                    </Button>
                                </li>
                                <li className="nav-item dropdown mt-2 btn-nav">
                                    <Button className="me-2 mt-2" onClick={() => openSignupModal()}>
                                        Sign Up
                                    </Button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <NavLink className="me-0 nav-link dropdown d-none d-xl-block" to="/cart">
                                        <div className="cart-counter NewCart">
                                            <AiOutlineShoppingCart className="icon ms-3 shopping-cart-icon" />
                                            <span className="badge">{cart.length}</span>
                                        </div>
                                    </NavLink>
                                </li>

                                <li className="nav-item d-none d-xl-block">
                                    <div
                                        className="py-2 d-flex align-items-center me-3"
                                        id="navbarDropdown4"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={() => handleNotificationOnClick()}
                                    >
                                        <div className={`cart-counter notifCount ${isCustomerLogin && " ms-3"} `}>
                                            <FaRegBell
                                                style={{ fontSize: "23px", marginTop: "0.45rem" }}
                                                className="icon icon-notification"
                                            />
                                            {/*<AiOutlineShoppingCart className="icon shopping-cart-icon"/>*/}
                                            <span className="badge">{notificationCount}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="dropdown-menu menu-search"
                                        aria-labelledby="navbarDropdown4"
                                    >
                                        <Notification notifications={notifications} />
                                    </div>
                                </li>

                                <li className="nav-item dropdown mt-xl-0 mt-3 ms-3">
                                    {isCustomerLogin ? (
                                        <>
                                            <div onClick={() => handleProfileModal()}
                                                className="d-flex align-items-center ">
                                                <div>
                                                    <img
                                                        src={isCustomerLogin ? isCustomerLogin.image_path : ""}
                                                        alt="user profile"
                                                        className="header-profile-image"
                                                        onError={(e) => handleBrokenImageOnError(e)}
                                                    />
                                                </div>

                                                {/*<div className="ms-2">*/}
                                                {/*    <span style={{ fontSize: "12px", cursor: "pointer" }} className="d-inline-block text-white">{isCustomerLogin.first_name + " " + isCustomerLogin.last_name}</span>*/}
                                                {/*</div>*/}
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </li>

                                <div className="d-block d-xl-none mb-4"></div>
                            </>
                        )}
                    </ul>
                </div>
                {showLocationModal && <CheckLocation />}
                {showCustomItemModal && <CustomItem />}

                {showProfileModal && <ProfileModal />}
            </nav>
            {showSignupOverlay && (
                <div className="signup-overlay">
                    <div className="overlay-content w-100">
                        <div className="w-100">
                            <div
                                className="text-end text-white mb-5 custom-margin"
                                style={{ cursor: "pointer" }}
                                onClick={() => closeSignupModal()}
                            >
                                Close
                            </div>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup"
                                state={{ role: constants.ROLES.ROLE_USER }}
                            >
                                Sign up as a customer
                                <FaArrowRight className="icon" />
                            </NavLink>
                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup"
                                state={{ role: constants.ROLES.ROLE_DRIVER }}
                            >
                                Sign up as a driver
                                <FaArrowRight className="icon" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PublicHeader;
