import {apiUrl} from "../utils/constants";
import {GET, POST} from "./api.service.wrapper";


export const GetLicenseTypes = async (args) => {
    return await GET(apiUrl.licenseType, args);
}

export const GetCompanyTypes = async (args) => {
    return await GET(apiUrl.companyType, args);
}

export const GetTrailerTypes = async (args) => {
    return await GET(apiUrl.trailerType, args);
}

export const GetEndorsements = async (args) => {
    return await GET(apiUrl.endorsements, args);
}

export const GetVehicleTypes = async (args) => {
    return await GET(apiUrl.vehicleType, args);
}

export const GetEquipment = async (args) => {
    return await GET(apiUrl.equipment, args);
}

export const GetProfile = async () => {
    return await GET(apiUrl.profile);
}

export const GetTotalWithDrawl = async () => {
    return await GET(apiUrl.get_total_withdrawl);
}

export const GetDriverPaymentHistory = async (data) => {
    return await GET(apiUrl.driver_payments, data);
}

export const InviteFriend = async (args) => {
    return await POST(apiUrl.invite_friend, args);
}

export const PostVehicleEquipment = async (args) => {
    return await POST(apiUrl.post_vehicle_equipments, args);
}

export const PostBankingDocumentaion = async (args) => {
    return await POST(apiUrl.banking_documentations, args);
}

export const IsDriverApproved = async (args) => {
    return await GET(apiUrl.is_driver_approved, args);
}
