import React, {useContext, useState} from "react";
import {Button, Col, Form, FormControl, Modal, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FaMinus, FaPlus} from "react-icons/fa";
import {CustomItemModal} from "../../App";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../constants/app-constants";
import {error, success} from "../../constants/msg";
import {ToastContainer} from "react-toastify";
import {PostQuotation} from "../../services/quotations.service";
import {UploadFileToS3} from "../../utils/helper";

const CustomItem = () => {
    const [showCustomItemModal, setShowCustomItemModal] = useContext(CustomItemModal);
    const {register, handleSubmit, setValue, reset, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const [counter, setCounter] = useState(1);

    const onSubmit = async (data) => {
        let dt = {
            email: data.email,
            description: data.description,
            quantity: data.quantity,
            phone: data.phone
        }

        if (data.document[0]) {
            let document = await UploadFileToS3(data.document[0]);
            if (document.success) {
                dt.document = document.url
            }
        }

        await PostQuotation(dt).then((result) => {
            if (result.status) {
                success("Your request has been sent")
            }
        }).catch((err) => {
            console.log(err)
            error(err.response.data.message)
        })
        reset();
    }

    const addCounter = () => {
        setCounter(counter + 1)
        setValue("quantity", counter + 1)
    }

    const subtractCounter = () => {
        if (counter > 1) {
            setCounter(counter - 1)
            setValue("quantity", counter - 1)
        }
    }

    return (
        <>
            <ToastContainer/>
            <Modal
                show={showCustomItemModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <div className="d-flex w-100 p-2 pb-3 align-items-center border-bottom justify-content-between">
                        <div>
                            <h5>Request Item</h5>
                        </div>
                        <div>
                            <span className="text-white" style={{cursor: "pointer"}}
                                  onClick={() => setShowCustomItemModal(false)}>Close</span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} className="p-4 pb-2 pt-0">
                        <Row className="mb-4">
                            <Col xs={12}>
                                <div className="">
                                    <span className="text d-inline-block mb-3">Please include a SKU, item no, product name and/or vendor.</span>

                                    <Form.Group className="mt-3" controlId="formGridAddress1">
                                        <Form.Control
                                            className="py-2"
                                            placeholder="Email address*"
                                            type="email"
                                            {...register("email",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.EMAIL,
                                                        message: VALIDATIONS_TEXT.EMAIL_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                                    },
                                                    pattern: {
                                                        value: /\S+@\S+\.\S+/,
                                                        message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.email && <small className='text-red'>{errors.email.message}</small>}

                                    <Form.Group className=" mt-2">
                                        <FormControl
                                            type="number"
                                            name="phone"
                                            className="py-2"
                                            placeholder="Phone*"
                                            {...register("phone",
                                                {
                                                    pattern: {
                                                        value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                        message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                    },
                                                    minLength: {
                                                        value: VALIDATIONS.PHONE_MIN,
                                                        message: VALIDATIONS_TEXT.PHONE_MIN
                                                    },
                                                    maxLength: {
                                                        value: VALIDATIONS.PHONE_MAX,
                                                        message: VALIDATIONS_TEXT.PHONE_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.PHONE_REQURIED
                                                    },
                                                })
                                            }
                                        />
                                    </Form.Group>
                                    {errors.phone && <small className='text-red'>{errors.phone.message}</small>}

                                    <Form.Group className="mt-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Control
                                            className="text-dark weight-500"
                                            name="description"
                                            as="textarea"
                                            placeholder="Tell us what you need!*"
                                            rows={4}
                                            {...register("description",
                                                {
                                                    maxLength: {
                                                        value: VALIDATIONS.DESCRIPTION,
                                                        message: VALIDATIONS_TEXT.DESCRIPTION_MAX
                                                    },
                                                    required: {
                                                        value: true,
                                                        message: VALIDATIONS_TEXT.DESCRIPTION_REQUIRED
                                                    },
                                                }
                                            )}
                                        />
                                    </Form.Group>
                                    {errors.description &&
                                    <small className='text-red'>{errors.description.message}</small>}

                                    <Form.Group controlId="formFileSm" className="mt-3">
                                        <Form.Label className="d-none">&nbsp;</Form.Label>
                                        <Form.Control
                                            type="file"
                                            size="sm"
                                            {...register("document", {})}
                                        />
                                    </Form.Group>
                                </div>
                            </Col>

                            <Col xs={12} md={6}>
                                <div className="w-100 mt-3 d-flex justify-content-sm-center align-items-center ">
                                    <Button className="btns btn-minus" onClick={() => subtractCounter()}><FaMinus
                                        className="icon"/></Button>
                                    <Form.Control
                                        readOnly
                                        className="text-center text-dark input weight-500"
                                        type="number"
                                        value={counter}
                                        placeholder="1"
                                        {...register("quantity")}
                                    />
                                    <Button className="btns btn-plus" onClick={() => addCounter()}><FaPlus
                                        className="icon"/></Button>
                                </div>
                            </Col>

                            <Col xs={12} md={6}>
                                <div className="mt-3">
                                    <Button type="submit" className="w-100 btn-red">Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CustomItem;