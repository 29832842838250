import React, { useEffect, useState } from "react";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap";
import Assets from "../../constants/images";
import { useForm } from "react-hook-form";
import { success } from "../../constants/msg";
import { toast, ToastContainer } from "react-toastify";
import { GetEquipment, GetProfile, GetVehicleTypes, PostVehicleEquipment } from "../../services/general.service";
import { constants } from "../../utils/constants";
import { ImUpload3 } from "react-icons/im";
import { FaTimesCircle } from "react-icons/fa";
import { UploadFileToS3 } from "../../utils/helper";

const TruckAndVehicleComponent = ({ data, index, setVehicles, vehicles, selectedUserVehicle }) => {
    const [checked, setChecked] = useState(false);
    const [count, setCount] = useState(0);
    const [countSelected, setCountSelected] = useState("");

    useEffect(() => {
        setData()
    }, [selectedUserVehicle])

    const setData = async () => {
        selectedUserVehicle.filter(async (v) => {
            if (data.id == v.vehicle_id) {
                setCount(v.quantity);
                setChecked(true);
            }
        })
    }

    return (
        <Col sm={6} xs={12} md={6} lg={4} xl={3} xxl={3} className="mb-4">
            <label className={"d-block"} htmlFor={`a-label-${data.id}`}>
                <div className="trucks-div text-center mb-4">
                    <Form.Check
                        onChange={(e) => {
                            // if (e.target.checked && count < 1) {
                            //     toast.error("Quantity must be greater than 0");
                            //     return false;
                            // }
                            setChecked(e.target.checked)
                            if (e.target.checked) {
                                setCount(1)
                                setVehicles([...vehicles, {
                                    vehicle_id: data.id,
                                    quantity: count + 1,
                                }]);
                            } else {
                                setCount(0)
                                setVehicles(vehicles.filter((vehicle, index) => vehicle.vehicle_id !== data.id));
                            }
                        }}
                        checked={checked}
                        className="d-none"
                        type="checkbox"
                        id={`a-label-${data.id}`}
                        label=""
                        value={data.id}
                        name="selectClass"
                    />
                    <div className={`bg-white img-container mb-2 p-4 ${checked && "BorderBox"}`}>
                        <img src={data?.image_path} alt="-"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }} className="w-100" />
                    </div>

                    <span className="text-white">{data?.name}</span>
                    <div className="mt-2 align-items-center d-flex counterComponent">
                        <Button className="p-2 m-0 bg-white btn-add-sub btnSub"
                            onClick={() => {
                                setCount(count - 1)
                                if ((count - 1) > 0) {
                                    if (checked) {
                                        const newState = vehicles.map(obj => {
                                            if (obj.vehicle_id === data.id) {
                                                return { ...obj, quantity: count - 1 };
                                            }
                                            return obj;
                                        });
                                        setVehicles(newState)
                                    }
                                } else {
                                    setCount(0)
                                    setChecked(false)
                                    setVehicles(vehicles.filter((vehicle, index) => vehicle.vehicle_id !== data.id));
                                }
                            }}
                        >-</Button>
                        <input readOnly name="value" className="text-white text-center count-div h-100 w-100"
                            type="number" value={count} />
                        <Button className="p-2 m-0 bg-white btn-add-sub btnAdd"
                            onClick={() => {
                                setCount(count + 1)
                                if (checked) {
                                    const newState = vehicles.map(obj => {
                                        if (obj.vehicle_id === data.id) {
                                            return { ...obj, quantity: count + 1 };
                                        }
                                        return obj;
                                    });
                                    setVehicles(newState)
                                } else {
                                    setChecked(true)
                                    setVehicles([...vehicles, {
                                        vehicle_id: data.id,
                                        quantity: count + 1,
                                    }]);
                                }
                            }}
                        >+</Button>
                    </div>
                </div>
            </label>
        </Col>
    )
}

const VehicleEquiment = ({ data, index, equipmentData, setEquipmentData, selectedUserEquipment }) => {
    const [equipmentCheck, setEquipmentCheck] = useState(false);

    useEffect(() => {
        setData()
    }, [selectedUserEquipment])

    const setData = async () => {
        await selectedUserEquipment.filter(async (v) => {
            if (data.id == v.equipment_id) {
                await setEquipmentCheck(true);
            }
        })
    }

    return (
        <Col sm={6} xs={12} md={6} lg={4} xl={3} xxl={3}>
            <label id="label-1" className="d-block">
                <div className="trucks-div text-center mb-4">
                    <Form.Check
                        className="d-none"
                        type="checkbox"
                        onChange={(e) => {
                            setEquipmentCheck(e.target.checked)
                            if (e.target.checked) {
                                setEquipmentData([...equipmentData, data.id]);
                            } else {
                                setEquipmentData(equipmentData.filter((id, index) => id !== data.id));
                            }
                        }}
                        checked={equipmentCheck}
                        name="group"
                        id="label-1"
                        label=""
                        value="bottom or side dump trailer"
                    />
                    <div className={`bg-white img-container mb-2 p-4 ${equipmentCheck && "BorderBox"}`}>

                        <img src={data?.imageUrl || Assets.EquipmentTypeImg1} alt="-"
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            className="w-100" />
                    </div>

                    <span className="text-white">{data?.name}</span>
                    {/*<div className="mt-2 align-items-center d-flex">*/}
                    {/*    <Button className="p-2 m-0 bg-white btn-add-sub"*/}
                    {/*        // onClick={() => {*/}
                    {/*        //     if (count > 0) {*/}
                    {/*        //         setCount(count - 1)*/}
                    {/*        //     }*/}
                    {/*        // }}*/}
                    {/*    >-</Button>*/}
                    {/*    <input readOnly name="value" className="text-white text-center count-div h-100 w-100"*/}
                    {/*           type="number" value="0"/>*/}
                    {/*    <Button className="p-2 m-0 bg-white btn-add-sub"*/}
                    {/*        // onClick={() => {*/}
                    {/*        //     setCount(count + 1)*/}
                    {/*        // }}*/}
                    {/*    >+</Button>*/}
                    {/*</div>*/}
                </div>
            </label>
        </Col>
    )
}

const NewTruckAndDriverTypeForm = () => {
    const [step, setStep] = useState(0);
    const { register, unregister, setValue, reset, handleSubmit, formState: { errors } } = useForm();
    const [equipmentTypeCheck, setEquipmentTypeCheck] = useState(false);
    const [isSelectCar, setIsSelectCar] = useState(false);
    const [showError, setShowError] = useState(false);
    const [vehicleTypes, setVehicleTypes] = useState(false);
    const [equipment, setEquipment] = useState([]);
    const [equipmentData, setEquipmentData] = useState([]);
    const [groups, setGroups] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedUserVehicle, setSelectedUserVehicle] = useState([]);
    const [selectedUserEquipment, setSelectedUserEquipment] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [vinFile, setVinFile] = useState(null)
    const [vinFilePath, setVinFilePath] = useState(null)

    useEffect(() => {
        fetchVehicleTypes();
        fetchEquipments();
        getProfile()
    }, [])

    async function getProfile() {
        await GetProfile().then(async (result) => {
            if (result.status && result.data) {
                let user = result?.data?.user;
                await user?.user_vehicles.forEach((v) => {
                    setVehicles(vehicles => [...vehicles, {
                        vehicle_id: v.vehicle_id,
                        quantity: v.quantity,
                    }]);
                })
                await user?.user_equipments.forEach((v) => {
                    setEquipmentData(equipmentData => [...equipmentData, v.equipment_id]);
                })

                await setSelectedUserVehicle(user.user_vehicles)
                await setSelectedUserEquipment(user.user_equipments)
                console.log(user?.driver_detail?.vin_file_path, "vin file path")
                setVinFilePath(user?.driver_detail?.vin_file_path)
            }
        }).catch((error) => {
            console.log("error", error.message);
        })
    }

    async function fetchVehicleTypes() {
        await GetVehicleTypes({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                let response = result.data.data
                setGroups(response);
            }
        }).catch((error) => {
            console.log("error", error.message)
        })
    }

    async function fetchEquipments() {
        await GetEquipment({ "order-column": "id", "order": "asc" }).then((result) => {
            if (result.status && result.data) {
                setEquipment(result.data.data)
            }
        }).catch((error) => {
        })
    }



    const handleNext = () => {
        if (vehicles && vehicles.length > 0) {
            setStep(step + 1)
        } else {
            toast.error("please select any one")
        }
    }

    const submitForm = async () => {
        setIsLoading(true)

        let dt = {
            user_vehicles: vehicles,
            user_equipments: equipmentData,
            is_driver_completed: 2
        }

        if (vinFile) {
            let file = await UploadFileToS3(vinFile);
            if (file.success) {
                dt.vin_file = file.url
            }
        }

        PostVehicleEquipment(dt).then((result) => {
            setIsLoading(false)
            if (result.status && result.data) {
                setVinFilePath(result?.data?.driver_detail?.vin_file_path)
                setVinFile(null)
                toast.success(result.message)
            }
        }).catch((error) => {
            console.log("error", error.message);
            setIsLoading(false)
        })
    }

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

    const handleVinFileOnChange = async (e) => {
        setVinFile(e.target.files[0])
    }

    const resetVinFile = async (e) => {

        setVinFile(null)
    }
    console.log(vinFilePath, "setVinFilePath")
    return (
        <div>
            <ToastContainer />

            <div> {/* onSubmit={handleSubmit(onSubmit)}*/}
                <div className={`groups ${step === 0 ? "d-block" : "d-none"}`}>
                    <Row className="justify-content-xl-center mb-4">
                        <Col xs={12} xl={10}>
                            <Row className="step-div">
                                <Col xs={12}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="HeightDiv">
                                            <small>Step 1</small>
                                            <h5 className={`m-0 ${step === 0 ? "text-white" : "text-grey"}`}><span className={`m-0 ${step === 0 ? "text-red" : "text-grey"}`}>Class</span> Details</h5>
                                        </div>

                                        <ProgressBar className="w-50" now={100} />

                                        <div>
                                            <div className="HeightDiv HeightDiv2">
                                                <small>Step 2</small>
                                                <h5 className={`m-0 ${step !== 0 ? "text-white" : "text-grey"}`}><span className={`m-0 ${step !== 0 ? "text-red" : "text-grey"}`}>Equipment</span> Details</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {
                                    groups && groups?.map((data, index) => (
                                        <Col xs={6}>
                                            <div className="darkDiv text-center px-4 py-3 mt-3">
                                                <h6 className="m-0">{data.name}</h6>
                                            </div>

                                            <div className="mt-3">
                                                <p className="p-0 m-0">{data.description}</p>
                                            </div>
                                        </Col>
                                    ))
                                }


                                {/*<Col xs={6}>*/}
                                {/*    <div className="darkDiv text-center px-4 py-3 mt-3">*/}
                                {/*        <h6 className="m-0">Group B (Heavy Straight Vehicle)</h6>*/}
                                {/*    </div>*/}

                                {/*    <div className="mt-3">*/}
                                {/*        <p className="p-0 m-0">*/}
                                {/*            Any single vehicle with a GVWR of 26,001 pounds or more, or any*/}
                                {/*            such vehicle towing a vehicle not in excess of 10,000 pounds GVWR.*/}
                                {/*        </p>*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={{span:10, offset: 1}} xl={{span:10, offset: 1}}>
                            <Row>
                                {

                                    groups && groups?.map((data, index) => {
                                        return (
                                            data && data?.vehicles.map((v, index) => {
                                                return (
                                                    <TruckAndVehicleComponent
                                                        data={v}
                                                        index={index}
                                                        vehicles={vehicles}
                                                        setVehicles={setVehicles}
                                                        selectedUserVehicle={selectedUserVehicle}
                                                    />
                                                )
                                            })
                                        )
                                    })
                                }
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            {
                                isSelectCar === true && showError === true &&
                                <small className="text-red mb-2">Please select a class</small>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <div className="d-flex align-items-center">
                            <div className="upload-btn-wrapper">
                                <button className="btns"><ImUpload3 className="icon" /> Upload Vin Number file</button>
                                <input
                                    type="file"
                                    name="vinFile"
                                    accept=".pdf, .docx"
                                    {...register("insurancePolicyFile")}
                                    onChange={handleVinFileOnChange}
                                // ref={insuranceFileRef}
                                />


                            </div>
                            {
                                (!vinFile && vinFilePath != "") ?
                                    <span className="d-inline-block ms-2 text-theme-red" style={{ fontSize: "14px" }}>
                                            <a href={vinFilePath} target="_blank" download={"vin-file"}>Download Vin File</a>
                                        </span>
                                    : ""
                            }

                            {
                                vinFile !== null &&
                                <div className="d-flex align-items-center">
                                    <span className="d-inline-block ms-2 text-theme-red"
                                        style={{ fontSize: "14px" }}>{vinFile ? truncate(vinFile.name, 25) : " "}</span>


                                    {
                                        vinFile ?
                                            <>
                                                <FaTimesCircle className="ms-3 text-theme-red icons"
                                                               onClick={resetVinFile}
                                                />

                                            </>

                                            : ""
                                    }


                                </div>
                            }
                        </div>
                    </Row>
                    <div className="text-end">
                        <Button className="btn-red btn-next" onClick={() => handleNext()}>Next</Button>
                    </div>
                </div>

                <div className={`groups ${step === 1 ? "d-block" : "d-none"}`}>
                    <Row className="justify-content-xl-center mb-4">
                        <Col xs={12} xl={10}>
                            <Row className="step-div">
                                <Col xs={12}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="HeightDiv">
                                            <small>Step 1</small>
                                            <h5 className={`m-0 ${step === 0 ? "text-white" : "text-grey"}`}><span className={`m-0 ${step === 0 ? "text-red" : "text-grey"}`}>Class</span> Details</h5>
                                        </div>

                                        <ProgressBar className="w-50" now={100} />

                                        <div>
                                            <div className="HeightDiv HeightDiv2">
                                                <small>Step 2</small>
                                                <h5 className={`m-0 ${step !== 0 ? "text-white" : "text-grey"}`}><span className={`m-0 ${step !== 0 ? "text-red" : "text-grey"}`}>Equipment</span> Details</h5>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs={6}>
                                    <div className="darkDiv text-center px-4 py-3 mt-3">
                                        <h6 className="m-0">Group A (Combination Vehicle)</h6>
                                    </div>

                                    <div className="mt-3">
                                        <p className="p-0 m-0">
                                            Any combination of vehicle with a gross combination weight(GCWR) of
                                            26,001 pounds or more, provided with gross vehicle weight training (GVWR)
                                            of the vechicle(s) being towed is in excess of 10,000 pounds.
                                        </p>
                                    </div>
                                </Col>

                                <Col xs={6}>
                                    <div className="darkDiv text-center px-4 py-3 mt-3">
                                        <h6 className="m-0">Group B (Heavy Straight Vehicle)</h6>
                                    </div>

                                    <div className="mt-3">
                                        <p className="p-0 m-0">
                                            Any single vehicle with a GVWR of 26,001 pounds or more, or any
                                            such vehicle towing a vehicle not in excess of 10,000 pounds GVWR.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        {
                            equipment && equipment?.map((data, index) => {
                                return (
                                    <VehicleEquiment
                                        data={data}
                                        index={index}
                                        equipmentData={equipmentData}
                                        setEquipmentData={setEquipmentData}
                                        selectedUserEquipment={selectedUserEquipment}
                                    />
                                )
                            })
                        }
                    </Row>

                    <Row>
                        <Col xs={12}>
                            {(errors.equipmentType) &&
                                <small className='text-red mb-2'>{errors.equipmentType.message}</small>}
                        </Col>
                    </Row>

                    <div className="d-flex align-items-center justify-content-between">
                        <Button onClick={() => setStep(0)}
                            className="btn-transparent text-center btn-previous">Previous</Button>


                        {
                            !isLoading ?
                                <Button onClick={() => submitForm()} className="btn-red btn-save">Save</Button>
                                :
                                <Button className="btn-red btn-save">Saving...</Button>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NewTruckAndDriverTypeForm;
