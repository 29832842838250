import React, { useContext, useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { BsStarFill } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { MdOutlineClose } from "react-icons/md";
import { FilterSidebar } from "../../App";
import { BrandsContext, CategoriesContext, ProductTypesContext } from "../../hooks/GlobalContext";


const ProductTypesComponent = ({ data, onChange, selected_product_type_id }) => {
    return (
        <Form.Check type="checkbox" key={data.id} id={"product-type-" + data.id}
            // checked={(selected_product_type_id == data.id)}
            checked={selected_product_type_id.includes(data.id)}
            name={data.name} label={data.name}
            onChange={(e) => onChange(e, data.id)}
        />
    )
}

const CategoriesComponent = ({ data, selected_category_id, onChange }) => {
    return (
        <Form.Check type="checkbox" key={data.id} id={"product-type-" + data.id}
            //defaultChecked={(selected_category_id == data.id)}
            checked={selected_category_id.includes(data.id)}
            name={data.name} label={data.name}
            onChange={(e) => onChange(e, data.id)}
        />
    )
}

const BrandsComponent = ({ data, onChange, selected_brand_id }) => {
    return (
        <Form.Check type="checkbox" key={data.id} id={"product-type-" + data.id}
            checked={selected_brand_id.includes(data.id)}
            name={data.name} label={data.name}
            onChange={(e) => onChange(e, data.id)}
        />
    )
}

const Filter = ({
    handleOnChangeCategory, handleOnChangeBrand,
    handleOnChangePriceRange, handleRatingOnChange, handleOnChangeProductType,
    handleSetOnChecked, handleChecked, selected_product_type_id, selected_category_id, selected_brand_id
}) => {
    const [showFilterSidebar, setShowFilterSidebar] = useContext(FilterSidebar);

    const minMaxData = [
        {
            min: 0,
            max: 50
        },
        {
            min: 50,
            max: 100
        },
        {
            min: 100,
            max: 200
        },
        {
            min: 200,
            max: 500
        },
        {
            min: 500,
            max: 1000
        }
    ]

    const [value, setValue] = useState({
        min: "",
        max: ""
    })
    const { register, handleSubmit } = useForm({
        mode: "onChange"
    });

    /* Brands Context */
    const [brandsData, setBrandsData] = useState([]);
    const brandsContext = useContext(BrandsContext)

    /* Product Types Context */
    const [productTypesData, setProductTypesData] = useState([]);
    const productTypesContext = useContext(ProductTypesContext)

    /* Categories Context */
    const [categoriesData, setCategoriesData] = useState([]);
    const categoriesContext = useContext(CategoriesContext)

    useEffect(() => {
        setBrandsData(brandsContext)
        setProductTypesData(productTypesContext)
        setCategoriesData(categoriesContext)
    }, [brandsContext, productTypesContext, categoriesContext, selected_product_type_id])

    const onSubmit = (data) => {
        console.log(data)
    }

    const handleChange = (e) => {
        if (!Number(e.target.value) && e.target.value !== "") {
            return;
        } else {
            setValue({
                ...value,
                [e.target.name]: e.target.value
            })
        }
    };

    const [subCategory, setSubCategory] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
    })


    const [filters, setFilters] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
    })

    const [price, setPrice] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
    })

    const [brands, setBrands] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
        checkbox8: false,
        checkbox9: false,
        checkbox10: false,
        checkbox11: false,
        checkbox12: false,
    })

    const handleSubCategory = (e) => {
        if (e.target.checked === true) {
            setSubCategory({
                ...subCategory,
                checkbox1: true,
                checkbox2: false,
                checkbox3: false,
                checkbox4: false,
                checkbox5: false,
                checkbox6: false,
                checkbox7: false,
            })
        } else {
            setSubCategory({
                ...subCategory,
                checkbox1: false
            })
        }
    }


    const handleFilters = (e) => {
        if (e.target.checked === true) {
            setFilters({
                ...filters,
                checkbox1: true,
                checkbox2: false,
                checkbox3: false,
                checkbox4: false,
                checkbox5: false,
                checkbox6: false,
            })
        } else {
            setFilters({
                ...filters,
                checkbox1: false
            })
        }
    }

    const handlePrice = (e) => {
        if (e.target.checked === true) {
            setPrice({
                ...price,
                checkbox1: true,
                checkbox2: false,
                checkbox3: false,
                checkbox4: false,
                checkbox5: false,
                checkbox6: false,
            })
        } else {
            setPrice({
                ...price,
                checkbox1: false
            })
        }
    }

    const handleBrands = (e) => {
        if (e.target.checked === true) {
            setBrands({
                ...filters,
                checkbox1: true,
                checkbox2: false,
                checkbox3: false,
                checkbox4: false,
                checkbox5: false,
                checkbox6: false,
                checkbox7: false,
                checkbox8: false,
                checkbox9: false,
                checkbox10: false,
                checkbox11: false,
                checkbox12: false,
            })
        } else {
            setBrands({
                ...filters,
                checkbox1: false
            })
        }
    }

    const [categoryFilterState, setCategoryFilterState] = useState()
    const [productTypeFilterState, setProductTypeFilterState] = useState()
    const [brandDataFilterState, setBrandDataFilterState] = useState()

    const [seeMoreCategory, setSeeMoreCategory] = useState(false)
    const [seeMoreBrand, setSeeMoreBrand] = useState(false)


    // console.log(brandsData)

    const fillFilters = () => {
        let obj1 = {}
        let obj2 = {}
        let obj3 = {}

        for (let i = 0; i < categoriesData.length; i++) {
            obj1[categoriesData[i].name.toLowerCase().split(" ").join("")] = false;
        }

        for (let j = 0; j < productTypesData.length; j++) {
            obj2[productTypesData[j].name.toLowerCase().split(" ").join("")] = false;
        }

        for (let k = 0; k < brandsData.length; k++) {
            obj3[brandsData[k].name.toLowerCase().split(" ").join("")] = false;
        }

        obj1.all = false
        obj2.all = false
        obj3.all = false

        setCategoryFilterState(obj1)
        setProductTypeFilterState(obj2)
        setBrandDataFilterState(obj3)
    }

    useEffect(() => {
        fillFilters()
    }, [categoriesData, productTypesData, brandsData, selected_product_type_id])

    // Category filter for check on All
    const handleCategoryFilters = (e) => {
        if (e.target.checked === true) {
            for (const key in categoryFilterState) {
                categoryFilterState[key] = true
            }

            setCategoryFilterState({
                ...categoryFilterState
            })
        } else {
            for (const key in categoryFilterState) {
                categoryFilterState[key] = false
            }

            setCategoryFilterState({
                ...categoryFilterState,
            })
        }
    }

    // Product type filter for check on All
    const handleProductTypeFilters = (e) => {
        if (e.target.checked === true) {
            for (const key in productTypeFilterState) {
                productTypeFilterState[key] = true
            }

            setProductTypeFilterState({
                ...productTypeFilterState
            })
        } else {
            for (const key in productTypeFilterState) {
                productTypeFilterState[key] = false
            }

            setProductTypeFilterState({
                ...productTypeFilterState,
            })
        }
    }


    // Brand data filter for check on All
    const handleBrandDataFilters = (e) => {
        if (e.target.checked === true) {
            for (const key in brandDataFilterState) {
                brandDataFilterState[key] = true
            }

            setBrandDataFilterState({
                ...brandDataFilterState
            })
        } else {
            for (const key in brandDataFilterState) {
                brandDataFilterState[key] = false
            }

            setBrandDataFilterState({
                ...brandDataFilterState,
            })
        }
    }

    return (
        <div className={showFilterSidebar ? "filter-wrapper show" : "d-none d-md-block FilterOverflow"}>
            <div className="text-end mb-4 px-2 ">
                {
                    showFilterSidebar &&
                    <MdOutlineClose className="icon-cross text-white" onClick={() => setShowFilterSidebar(false)} />
                }
            </div>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item className="mb-2" eventKey="0">
                            <Accordion.Header onClick={()=> setSeeMoreCategory(false)}>Categories</Accordion.Header>
                            <Accordion.Body>
                                {
                                    <>
                                        <Form.Check type="checkbox"
                                            //onChange={(e) => handleCategoryFilters(e)} id="sub-categories-all"
                                            onChange={(e) => handleOnChangeCategory(e, -1)}
                                            name="sub-categories-all" label="All" />

                                        {
                                            categoriesData &&
                                            categoriesData.slice(0, !seeMoreCategory ? 10 : categoriesData.length).map((category, index) => {
                                                return (
                                                    <CategoriesComponent
                                                        data={category}
                                                        key={index}
                                                        selected_category_id={selected_category_id}
                                                        onChange={(e) => handleOnChangeCategory(e, category.id)}
                                                    />
                                                )
                                            })
                                        }
                                        <span className="see-more" onClick={() => {
                                            !seeMoreCategory ? 
                                            setSeeMoreCategory(true)
                                            :
                                            setSeeMoreCategory(false)
                                        }}>
                                            {!seeMoreCategory ? "see more" : "see less"}
                                        </span>
                                    </>
                                }
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className="mb-2" eventKey="1">
                            <Accordion.Header>Product Types</Accordion.Header>
                            <Accordion.Body>
                                {
                                    <>
                                        <Form.Check type="checkbox" id="filters-all" name="filters-all" label="All"
                                            // onChange={(e) => handleProductTypeFilters(e)}
                                            onChange={(e) => handleOnChangeProductType(e, -1)}
                                        />
                                        {
                                            productTypesData &&
                                            productTypesData.map((product_type, index) => {
                                                return (
                                                    <ProductTypesComponent
                                                        data={product_type}
                                                        key={index}
                                                        selected_product_type_id={selected_product_type_id}
                                                        onChange={(e) => handleOnChangeProductType(e, product_type.id)}
                                                    />
                                                )
                                            })

                                        }
                                    </>
                                }
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className="mb-2" eventKey="2">
                            <Accordion.Header>Price Range</Accordion.Header>
                            <Accordion.Body>
                                {
                                    <>
                                        <Form.Check type="radio" id="price-range-all" name="price" label="None" value="0,0"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <Form.Check type="radio" id="under-50" name="price" label="Under $50" value="1,50"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <Form.Check type="radio" id="50-100" name="price" label="$50 - $100" value="50,100"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <Form.Check type="radio" id="100-200" name="price" label="$100 - $200" value="100,200"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <Form.Check type="radio" id="200-500" name="price" label="$200 - $500" value="200,500"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <Form.Check type="radio" id="500-1000" name="price" label="$500 - $1000" value="500,1000"
                                            onChange={(e) => handleOnChangePriceRange(e)} />
                                        <div className="mt-2">
                                            <div className="d-flex align-items-center">
                                                <span className="text-grey">$</span>
                                                <Form.Control className="ms-1" type="phone" maxLength={5} name="min"
                                                    onChange={(e) => handleOnChangePriceRange(e)} placeholder="Min" />
                                                <span className="text-grey mx-1"> to </span>
                                                <span className="text-grey">$</span>
                                                <Form.Control className="ms-1" type="phone" maxLength={5} name="max"
                                                    onChange={(e) => handleOnChangePriceRange(e)} placeholder="Max" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item className="mb-2" eventKey="3">
                            <Accordion.Header onClick={()=> setSeeMoreBrand(false)}>Brands</Accordion.Header>
                            <Accordion.Body>
                                {
                                    <>
                                        <Form.Check type="checkbox" id="brands-all" name="brands-all" label="All"
                                            onChange={(e) => handleOnChangeBrand(e, -1)}
                                        />
                                        {
                                            brandsData &&
                                            brandsData.slice(0, !seeMoreBrand ? 10 : brandsData.length).map((brand, index) => {
                                                return (
                                                    <BrandsComponent
                                                        data={brand}
                                                        key={index}
                                                        selected_brand_id={selected_brand_id}
                                                        onChange={(e) => handleOnChangeBrand(e, brand.id)}
                                                    />
                                                )

                                            })

                                        }
                                          <span className="see-more" onClick={() => {
                                            !seeMoreBrand ? 
                                            setSeeMoreBrand(true)
                                            :
                                            setSeeMoreBrand(false)
                                        }}>
                                            {!seeMoreBrand ? "see more" : "see less"}
                                        </span>
                                    </>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>


                {/* <div className="mb-3">
                    <h5 className="sub-heading">Rating & Reviews</h5>
                    <div>
                        <Form.Check
                            className="d-inline-block me-2"
                            type="checkbox"
                            name="five-star"
                            value={5}
                            onChange={handleRatingOnChange}
                            id="four-star"
                        />
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <span className="text-grey">& up</span>
                    </div>
                    <div>
                        <Form.Check
                            className="d-inline-block me-2"
                            type="checkbox"
                            name="four-star"
                            value={4}
                            onChange={handleRatingOnChange}
                            id="four-star"
                        />
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <span className="text-grey">& up</span>
                    </div>

                    <div>
                        <Form.Check
                            className="d-inline-block me-2"
                            type="checkbox"
                            name="three-star"
                            value={3}
                            onChange={handleRatingOnChange}
                            id="three-star"
                        />
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <span className="text-grey">& up</span>
                    </div>

                    <div>
                        <Form.Check
                            className="d-inline-block me-2"
                            type="checkbox"
                            name="two-star"
                            value={2}
                            onChange={handleRatingOnChange}
                            id="two-star"
                        />
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <span className="text-grey">& up</span>
                    </div>

                    <div>
                        <Form.Check
                            className="d-inline-block me-2"
                            type="checkbox"
                            name="one-star"
                            value={1}
                            onChange={handleRatingOnChange}
                            id="one-star"
                        />
                        <BsStarFill className="me-1 icon"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <BsStarFill className="me-1 icon text-grey"/>
                        <span className="text-grey">& up</span>
                    </div>
                </div> */}
            </Form>
        </div>
    )
}

export default Filter