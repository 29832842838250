import React from "react"
import Assets from "../../constants/images"
import { LazyLoadImage } from "react-lazy-load-image-component";

const BottomBanner = () => {
  return (
    <div className="py-4 BottomBanner">
        <LazyLoadImage effect="opacity" className="w-100" src={Assets.BottomBanner} alt="" />
    </div>
  )
}

export default BottomBanner