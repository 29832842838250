import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { LocationModal } from "../../App";
import {
    CheckStoreAvailability,
    GetCities,
    GetCountries,
    GetStates,
    GetStores,
    GetZipCodes
} from "../../services/states.service";
import { toast, ToastContainer } from "react-toastify";
import { Map, Marker, ZoomControl } from "pigeon-maps";
import Select from "react-select"

const CheckLocationCard = () => {
    const [showLocationModal, setShowLocationModal] = useContext(LocationModal);
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [zipCodes, setZipCodes] = useState([])
    const [stores, setStores] = useState([])
    const [center, setCenter] = useState([28.06085227377535, -82.41651494728065])
    const [hue, setHue] = useState(0);
    const color = `hsl(${hue % 360}deg 39% 70%)`;
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });
    const [zipCodeArray, setZipCodeArray] = useState([])


    const onSubmit = async (data) => {
        await CheckStoreAvailability(data).then((result) => {
            if (result.status && result.data) {
                setCenter([result.data.latitude, result.data.longitude])
                setStores([result.data])
            } else {
                toast.success("Not found")
            }
        }).catch((error) => {
        })
    }

    async function fetchStoreLocations() {
        await GetStores().then((result) => {
            if (result.status && result.data.data) {
                setStores(result.data.data)
            }
        }).catch((error) => {
        })
    }

    const getCountries = async () => {
        await GetCountries().then((res) => {
            if (res.status) {
                setCountries(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStates = async (country_id) => {
        await GetStates({ country_id: country_id }).then((res) => {
            if (res.status) {
                setStates(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getCities = async (state_id) => {
        await GetCities({ state_id: state_id }).then((res) => {
            if (res.status) {
                setCities(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getZipCodes = async (city_id) => {
        await GetZipCodes({ city_id: city_id }).then((res) => {
            if (res.status) {
                setZipCodes(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    const handleOnChangeCountry = (e) => {
        getStates(e.target.value)
    }

    const handleOnChangeState = (e) => {
        getCities(e.target.value)
    }

    const handleOnChangeCity = (e) => {
        getZipCodes(e.target.value)
    }

    useEffect(() => {
        fetchStoreLocations()
        getCountries()
    }, [])


    useEffect(() => {
        let array = []
        zipCodes.map((v, i) => {
            array.push({
                label: v.zip,
                value: v.id
            })
        })

        setZipCodeArray(array)
    }, [zipCodes])

    console.log(zipCodeArray)

    return (
        <Modal
            show={showLocationModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ToastContainer autoClose={1000} />
            <Modal.Header className="border-bottom px-4">
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div>
                        <h5>Check Your Location </h5>
                    </div>
                    <div>
                        <span className="text-white" style={{ cursor: "pointer" }}
                            onClick={() => setShowLocationModal(false)}>Close</span>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={12} xl={4} lg={6}>
                        <div className="p-4">
                            <p>
                                Check your location down below
                                <br /> <br />
                                CMORE Supply is currently operating in select territories. If your zip code, city, and state is unavailable, sign up and we will add your territory.

                            </p>
                        </div>

                        <div className="p-3 px-4 pt-0">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                {/*<Form.Group controlId="formFile" className="mt-4">*/}
                                {/*    <Form.Label>City/State</Form.Label>*/}
                                {/*    <Form.Select*/}
                                {/*        aria-label="Default select example"*/}
                                {/*        name="city"*/}
                                {/*        className="py-2 text-dark"*/}
                                {/*        {...register("city_id",*/}
                                {/*            {*/}
                                {/*                required: {*/}
                                {/*                    value: true,*/}
                                {/*                    message: VALIDATIONS_TEXT.EMAIL_REQUIRED*/}
                                {/*                },*/}
                                {/*            }*/}
                                {/*        )}*/}
                                {/*    >*/}
                                {/*        <option disabled selected={true}>Select city, states</option>*/}
                                {/*        {*/}
                                {/*            cities &&*/}
                                {/*            cities.map((item, i) => (*/}
                                {/*                <option key={i} value={item.id}>{item.name}</option>*/}
                                {/*            ))*/}
                                {/*        }*/}
                                {/*    </Form.Select>*/}
                                {/*</Form.Group>*/}
                                {/*{errors.state_id && <small className='text-red'>{errors.state_id.message}</small>}*/}

                                <Form.Group className=" mt-2">
                                    <Form.Select
                                        className="py-2"

                                        {...register("country_id", {})}
                                        onChange={handleOnChangeCountry}
                                    >
                                        <option value="" disabled={true} selected={true}>Select Country</option>
                                        {
                                            countries && countries.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                {errors.country &&
                                    <small className='text-red'>{errors.country.message}</small>}

                                <Form.Group className=" mt-2">
                                    <Form.Select
                                        className="py-2"
                                        {...register("state_id", {})}
                                        onChange={handleOnChangeState}
                                    >
                                        <option value="" disabled={true} selected={true}>Select State</option>
                                        {
                                            states && states.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                {errors.state &&
                                    <small className='text-red'>{errors.state.message}</small>}

                                <Form.Group className=" mt-2">
                                    <Form.Select
                                        className="py-2"
                                        {...register("city_id", {})}
                                        onChange={handleOnChangeCity}
                                    >
                                        <option value="" disabled={true} selected={true}>Select City</option>
                                        {
                                            cities && cities.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                {errors.city &&
                                    <small className='text-red'>{errors.city.message}</small>}

                                {/* <Form.Group className=" mt-2">
                                    <Form.Select
                                        className="py-2"
                                        {...register("zip_id", {})}
                                    >
                                        <option value="" disabled={true} selected={true}>Select Zip Code</option>
                                        {
                                            zipCodes && zipCodes.map((item) => (
                                                <option value={item.id}>{item.zip}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                                {errors.zip_id &&
                                    <small className='text-red'>{errors.zip_id.message}</small>} */}
                                <div className="py-2">
                                    <Select
                                        placeholder="Select Zip Code"
                                        onChange={(e) => setValue("zip_id", e.value.toString())}
                                        defaultValue={""}
                                        isClearable={true}
                                        isSearchable={true}
                                        name="zip_id"
                                        options={zipCodeArray}
                                    />
                                </div>

                                {/*<Form.Group controlId="formFile" className="mt-4">*/}
                                {/*    <Form.Label>Zip code</Form.Label>*/}
                                {/*    <Form.Control*/}
                                {/*        onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}*/}
                                {/*        name="zip"*/}
                                {/*        type="number"*/}
                                {/*        placeholder="Enter ZIP code"*/}
                                {/*        className="py-2 text-dark"*/}
                                {/*        {...register("zip",*/}
                                {/*            {*/}
                                {/*                pattern: {*/}
                                {/*                    value: /^[0-9]+$/,*/}
                                {/*                    message: VALIDATIONS_TEXT.INVALID_FORMAT,*/}
                                {/*                },*/}
                                {/*                minLength: {*/}
                                {/*                    value: VALIDATIONS.ZIP_MIN,*/}
                                {/*                    message: VALIDATIONS_TEXT.ZIP_MIN*/}
                                {/*                },*/}
                                {/*                maxLength: {*/}
                                {/*                    value: VALIDATIONS.ZIP_MAX,*/}
                                {/*                    message: VALIDATIONS_TEXT.ZIP_MAX*/}
                                {/*                },*/}
                                {/*                required: {*/}
                                {/*                    value: false,*/}
                                {/*                },*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    />*/}
                                {/*</Form.Group>*/}
                                {/*{errors.zip && <small className='text-red'>{errors.zip.message}</small>}*/}

                                <Button type="submit" className="btn-red mt-4 mb-4">Check</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={12} xl={8} lg={6}>
                        {/* <iframe width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=[{52.70967533219885, -8.020019531250002}]&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe><br /> */}
                        <div className="map-container h-100">
                            <Map
                                center={center}
                                defaultZoom={10}
                                mouseEvents={true}
                                touchEvents={true}

                            >
                                <ZoomControl />

                                {/*<Marker*/}
                                {/*    width={50}*/}
                                {/*    anchor={[25.3838198404483, 68.33701587389616]}*/}
                                {/*    color={color}*/}
                                {/*    onClick={() => setHue(hue + 20)}*/}
                                {/*/>*/}
                                {
                                    stores &&
                                    stores.map((item, i) => (
                                        <Marker
                                            width={50}
                                            anchor={[item.latitude * 1, item.longitude * 1]}
                                            position={[item.latitude * 1, item.longitude * 1]}
                                            color={color}
                                            onClick={() => setHue(hue + 20)}
                                        />
                                    ))
                                }
                            </Map>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default CheckLocationCard;