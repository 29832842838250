import React from "react"
import { Col, Container, Row, Nav } from "react-bootstrap"
import { AppLogo } from "../../constants/svgs"
import { RiFacebookFill, RiTwitterFill, RiInstagramFill, RiPhoneLine, RiHome5Line } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import Assets from "../../constants/images";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container pt-5 pb-4">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={3} className="mb-4">
                            <div className="footer-logo-div">
                                <AppLogo />
                                <p className="mt-4 mb-1 mb-lg-4">
                                    This is dummy. It is not meant to be read.
                                    It is placed here solely to demonstrate the look and feel.
                                </p>
                                <div>
                                    <span className="stay-connected">Stay connected : </span>
                                    <span><Nav.Link className="d-inline-block" href="#"><RiFacebookFill className="icon mx-2" /></Nav.Link></span>
                                    <span><Nav.Link className="d-inline-block" href="#"><RiTwitterFill className="icon mx-2" /></Nav.Link></span>
                                    <span><Nav.Link className="d-inline-block" href="#"><RiInstagramFill className="icon mx-2" /></Nav.Link></span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={3} className="mb-4">
                            <h5 className="sub-heading">Useful Links</h5>
                            <ul>
                                <li><Nav.Link href="/about-us">About Us</Nav.Link></li>
                                <li><Nav.Link href="#">Contact Us</Nav.Link></li>
                                <li><Nav.Link href="/order-history">Track your order</Nav.Link></li>
                                <li><Nav.Link href="#">Career</Nav.Link></li>
                                <li><Nav.Link href="#">Policy</Nav.Link></li>
                                <li><Nav.Link href="/faq/order-information">FAQ</Nav.Link></li>
                            </ul>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={3} className="mb-4">
                            <h5 className="sub-heading">Help Center</h5>
                            <ul>
                                <li><Nav.Link href="#">Payments</Nav.Link></li>
                                <li><Nav.Link href="/faq/shipping">Shipping</Nav.Link></li>
                                <li><Nav.Link href="/faq/return-and-cancellation">Returns</Nav.Link></li>
                                {/*<li><Nav.Link href="/checkout">Checkout</Nav.Link></li>*/}
                                <li><Nav.Link href="#">Other</Nav.Link></li>
                            </ul>
                        </Col>

                        <Col xs={12} sm={12} md={4} lg={3} className="mb-4">
                            <h5 className="sub-heading">Contact Us</h5>
                            <ul>
                                <li><RiPhoneLine className="me-3" />Hotline 24/7 :<br /><h4><Nav.Link className="phone-number" href="#"> (+1) 2345-5678</Nav.Link></h4></li>
                                <li><RiHome5Line className="me-3" /><Nav.Link href="#">123 Business Street, West Town, NYC</Nav.Link></li>
                                <li><MdOutlineEmail className="me-3" /><Nav.Link href="#">john@cmore.com</Nav.Link></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={4}>
                            <span>© 2021 CMORE. All rights reserved</span>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/*<div className="footer-container py-5 border-top">*/}
            {/*    <Container>*/}
            {/*        */}

            {/*            <Col sm={12} md={12} lg={4} className="mb-4">*/}
            {/*                <div className="text-lg-center">*/}
            {/*                    <span className="d-inline-block me-2"><img src={Assets.VisaCardFooter} alt="VisaCard" /></span>*/}
            {/*                    <span className="d-inline-block mx-2"><img src={Assets.MasterCardFooter} alt="MasterCard" /></span>*/}
            {/*                    <span className="d-inline-block mx-2"><img src={Assets.AmazonPayFooter} alt="AmazonPay" /></span>*/}
            {/*                </div>*/}
            {/*            </Col>*/}

            {/*            <Col sm={12} md={12} lg={4} className="mb-4">*/}
            {/*                <div className="text-lg-center">*/}
            {/*                    <span>Designed & developed by Tekrevol</span>*/}
            {/*                </div>*/}
            {/*            </Col>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</div>*/}
        </footer>
    )
}

export default Footer