import React, { useState } from "react";
import { Col, Form } from "react-bootstrap"
import { FaBars } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import {productsSortBy} from "../../utils/constants";

const Header = ({ onClick, heading, handleSortByOnChange }) => {
    const [view, setView] = useState("grid");

    const handleClick = (views) => {
        onClick(views)
        setView(views)
    }

    return (
        <>
            <Col xs={12} md={3}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                        {/*<li className="breadcrumb-item"><NavLink to="/products">products</NavLink></li>*/}
                        <li className="breadcrumb-item">Products</li>
                        {/*<li className="breadcrumb-item active" aria-current="page">{heading}</li>*/}
                    </ol>
                </nav>
                <h5 className="text-white">{heading}</h5>
            </Col>

            <Col xs={12} md={9} className="mt-5">
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-end mb-3">
                    <div className="d-flex align-items-center justify-content-between ">
                        <label className="d-inline-block text-white label-1" htmlFor="sort">Sort by: </label>
                        <Form.Select aria-label="Default select example" onChange={handleSortByOnChange}>
                            <option>Open this select menu</option>
                            <option value={productsSortBy.PRICE_LOW_TO_HIGH}>Price low to high</option>
                            <option value={productsSortBy.PRICE_HIGH_TO_LOW}>Price high to low</option>
                            <option value={productsSortBy.RATING_LOW_TO_HIGH}>Rating low to high</option>
                            <option value={productsSortBy.RATING_HIGH_TO_LOW}>Rating high to low</option>
                        </Form.Select>
                    </div>

                    <div className="mb-div mt-3 mb-md-3 mt-lg-0 mb-lg-0">
                        {
                            (view === "grid") ?
                                <>
                                    <label htmlFor="view" className="label-2 text-white">View: </label>
                                    <FaBars className="icon-bar text-grey me-3" onClick={() => handleClick("list")} />
                                    <BsFillGrid3X3GapFill className="icon-grid text-theme-red" onClick={() => handleClick("grid")} />
                                </> :
                                <>
                                    <label htmlFor="view" className="label-2 text-white">View: </label>
                                    <FaBars className="icon-bar text-theme-red me-3" onClick={() => handleClick("list")} />
                                    <BsFillGrid3X3GapFill className="icon-grid text-grey" onClick={() => handleClick("grid")} />
                                </>
                        }
                    </div>
                </div>
            </Col>
        </>
    )
}

export default Header