import React, {useContext} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import {IsCustomerLogin, ProfileModals} from "../../App";
import {EmptyLocalStorage, Logout} from "../../services/auth/auth.service";
import {handleBrokenImageOnError} from "../../utils/helper";
import {auth} from "../../config/firebase"
import {signOut} from "firebase/auth";

const ProfileModal = () => {
    const [showProfileModal, setShowProfileModal] = useContext(ProfileModals);
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout()
    }

    const handleProfileModal = () => {
        setShowProfileModal(false);
        document.body.classList.remove("handleProfilemodal");
    }

    async function logout() {
        await Logout().then(async (res) => {
            if (res.status) {

                if (isCustomerLogin.is_social_login) {
                    const result = await signOut(auth).then((res) => {
                        // Sign-out successful.
                        console.log(auth, "signout-------")
                    }).catch((error) => {
                        // An error happened.
                        console.log(error, "logout result-------")
                    });

                }

                setShowProfileModal(false);
                setIsCustomerLogin(null);
                await EmptyLocalStorage()
                document.body.classList.remove("handleProfilemodal");
                navigate("/")
            }
        })
    }

    return (
        <>
            <div className="overlay-profile-sidebar"></div>
            <div className="profile-sidebar">
                <div className="mb-3 text-end">
                    <Button className="btn-closed" onClick={() => handleProfileModal(false)}>Close</Button>
                </div>
                <div className="profile-sidebar-container py-2 p-4">
                    <div className="mb-4 border-bottom pb-3">
                        <div className="d-flex align-items-center">
                            <img height="50" width="50"
                                 src={(isCustomerLogin) ? isCustomerLogin.image_path : ""}
                                 alt="headerAvatar" className="profile-sidebar-icon me-3"
                                 onError={(e) => handleBrokenImageOnError(e,)}/>
                            <div>
                                <h5 className="m-0 p-0">{(isCustomerLogin) ? isCustomerLogin.first_name + " " + isCustomerLogin.last_name : ""}</h5>
                                <NavLink onClick={() => handleProfileModal(false)} to="/profile"
                                         className="view-profile">View profile</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4 border-bottom pb-2">
                        <span className="mb-2">Your orders</span>
                        <div>
                            <NavLink onClick={() => handleProfileModal(false)} to="/order-history">Track your
                                order</NavLink>
                            <NavLink onClick={() => handleProfileModal(false)} to="/order-history">Order
                                history</NavLink>
                        </div>
                    </div>

                    {/*<div className="mb-4 border-bottom pb-2">*/}
                    {/*    <span className="mb-2">Storage</span>*/}
                    {/*    <div>*/}
                    {/*        <NavLink onClick={() => handleProfileModal(false)} to="/cloud-storage">Cloud Storage</NavLink>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="mb-4 border-bottom pb-2">
                        <span className="mb-2">Settings</span>
                        <div>
                            <NavLink onClick={() => handleProfileModal(false)} to="/profile">My Profile</NavLink>
                        </div>
                    </div>

                    <div className="mb-4 border-bottom pb-2">
                        <span className="mb-2">Your payments</span>
                        <div>
                            <NavLink onClick={() => handleProfileModal(false)} to="/payment-methods">Manage payment
                                methods</NavLink>
                            <NavLink onClick={() => handleProfileModal(false)} to="/view-all-transactions">View all
                                transactions</NavLink>
                        </div>
                    </div>

                    <div className="mb-4 border-bottom pb-2">
                        <span className="mb-2">Support</span>
                        <div>
                            <NavLink onClick={() => handleProfileModal(false)} to="/faq/order-information">FAQs</NavLink>

                            {/*<NavLink onClick={() => handleProfileModal(false)} to="/help-center">Help center</NavLink>*/}
                            {/*<NavLink onClick={() => handleProfileModal(false)} to="/how-it-works">How it works</NavLink>*/}
                        </div>
                    </div>

                    <div className="mb-4">
                        <div>
                            <NavLink className="d-inline-block" style={{paddingBottom: "100px"}}
                                     onClick={() => handleLogout()} to="/">Log out</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileModal