import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Assets from "../../constants/images";
import { BuildingIcon } from "../../constants/svgs";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SectorCoverage = () => {
    return (
        <div className="home-page work-section sector-coverage">
            <div className="work-container sector-coverage-container d-flex">
                <div className="left px-4 py-5">
                    <Container className="py-5">
                        <Row>
                            <Col xs={12} lg={{span:10, offset:2}}>
                                <div>
                                    <h1>Become a Partner</h1>
                                    <p className="mb-5">
                                        No Financial Commitment. No Risk.
                                        It costs no money to become a CMORE Supply partner.
                                        <br/><br/>
                                        Partners can earn up to 60% commission per secured last-mile delivery. By diversifying your
                                        vehicle type, you will be able to diversify your availability and
                                        maximize your bottom line.

                                    </p>

                                    <div className="box mt-4 d-flex align-items-center">
                                        <LazyLoadImage style={{width: "60px", height:"60px"}} src={Assets.LimitedTimeIcon3} alt="" />
                                        <h5>Limited Time Offer!</h5>
                                    </div>

                                    <div className="box mt-4 d-flex align-items-center">
                                        <LazyLoadImage style={{width: "60px", height:"60px"}} src={Assets.PartnersIcon1} alt="" />
                                        <h5>Submit your inquiries to Partner@CMORESupply.com </h5>
                                    </div>

                                    <div className="box mt-4 d-flex align-items-center">
                                        <LazyLoadImage style={{width: "60px", height:"60px"}} src={Assets.SupportICon2} alt="" />
                                        <h5>Submit your support request to Support@CMORESupply.com</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="right"></div>
            </div>
        </div>
    )
}

export default SectorCoverage