import React from "react";
import { Route, Navigate, Outlet } from "react-router";
import {constants} from "./utils/constants";

const CustomerProtectedRoute = ({isLogged}) => {
    return (
        isLogged && isLogged.roles[0].id == constants.ROLES.ROLE_USER  ? <Outlet/> : <Navigate to="/login" />
    )
}

export default CustomerProtectedRoute;
