import {constants, apiUrl} from "../../utils/constants";
import {GET, POST, PUT} from "../api.service.wrapper";

const storageKey = constants.LOCAL_STORAGE_TOKEN;
const storageUser = constants.LOCAL_STORAGE_USER;


export const Login = async (email, password, role_id, device_type, device_token) => {
    const response = await POST(apiUrl.login, {email, password, device_type, device_token});
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data.user));
    return response;
}

export const socialLogin = async (data) => {
    const response = await POST(apiUrl.social_login, data);
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data.user));
    return response;
}

export const Register = async (data) => {
    data.device_type = "web"
    data.device_token = "dummy"
    data.latitude = "1111"
    data.longitude = "1111"
    const response = await POST(apiUrl.register, data);
    await SetToken(response.data.access_token?.token);
    await SetAuthUser(JSON.stringify(response?.data.user));
    return response;
}

export const SetToken = (token) => {
    if (token) localStorage.setItem(storageKey, token);
    else localStorage.removeItem(storageKey);
}

export const GetToken = () => {
    return localStorage.getItem(storageKey);
}

export const GetProfile = async () => {
    return await GET(apiUrl.profile);
}

export const UpdateProfile = async (id, data) => {
    return await PUT(apiUrl.update_profile, id, data);
}

export const SetAuthUser = (user_data) => {
    if (user_data) localStorage.setItem(storageUser, user_data);
    else localStorage.removeItem(storageUser);
}

export const GetAuthUser = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.user;
}

export const GetAccessToken = () => {
    return JSON.parse(localStorage.getItem(storageUser))?.access_token;
}

export const Logout = async () => {
    return await POST(apiUrl.logout);
}

export const ResendOtp = async (email) => {
    return await POST(apiUrl.resend_otp, email);
}

export const EmptyLocalStorage = async () => {
    await localStorage.removeItem(storageUser)
    await localStorage.removeItem(storageKey)
}

export const GetCurrentUser = () => {
    return JSON.parse(localStorage.getItem(storageUser));
}

export const ForgetPasswordApi = async (email) => {
    return await POST(apiUrl.forgot_password, {email});
}

export const VerifyOtpApi = async (otp_code, email) => {
    return await POST(apiUrl.verify_otp, {otp_code, email});
}

export const ResetPasswordApi = async (email, otp_code, password, password_confirmation) => {
    return await POST(apiUrl.reset_password, {email, otp_code, password, password_confirmation});
}

export const ChangePasswordApi = async (current_password, password, password_confirmation) => {
    return await POST(apiUrl.change_password, {current_password, password, password_confirmation});
}

export const GetUserRole = () => {
    const user = GetAuthUser();
    let role;
    if (user && user.roles) {
        let role_id = user.roles[0].id;
        if (role_id === constants.ROLES.ROLE_USER) {
            role = constants.ROLES.ROLE_USER;
        }
        else {
            role = constants.ROLES.ROLE_DRIVER;
        }
    }

    return role;
}
