import React, {useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CartCardBody from "./CartCardBody";
import CartCardHeader from "./CartCardHeader";
import {Cart} from "../../App";
import {format} from "date-fns";

const CartCard = ({order}) => {

    return (
        <div className="cart-card">
            <Container>
                <Row className="justify-content-center">
                    <Col xxl={9} xl={9} lg={9} md={12} xs={12} className="mb-4">
                        <CartCardHeader/>
                        <div className="cart-item-container p-4">
                            {
                                order &&
                                order.order_products.map((item, i) => {
                                    return (
                                        <CartCardBody key={i} item={item} index={i}/>
                                    )
                                })
                            }
                        </div>

                        <Row className="mt-4">
                            <Col xs={12} md={6} className="mb-4">
                                <div className="bg-theme-dark p-3">
                                    <h5 className="m-0 p-0 title py-2 border-bottom">Customer & order details</h5>
                                    <div
                                        className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                        <h5 className="m-0 p-0 first">Customer name</h5>
                                        <h5 className="m-0 p-0">{order.billing_first_name + " " + order.billing_last_name}</h5>
                                    </div>
                                    <div
                                        className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                        <h5 className="first m-0 p-0">Phone number</h5>
                                        <h5 className="m-0 p-0">{order.billing_phone}</h5>
                                    </div>
                                    <div
                                        className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                        <h5 className="first m-0 p-0">Weight</h5>
                                        <h5 className="m-0 p-0">{order.weight} lbs</h5>
                                    </div>
                                    <div className="d-flex py-2 align-items-center justify-content-between">
                                        <h5 className="first m-0 p-0">Truck type</h5>
                                        <h5 className="m-0 p-0">{(order && order.vehicle) ? order.vehicle.name : "-"}</h5>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} md={6}>
                                <div className="bg-theme-dark p-3">
                                    <h5 className="m-0 p-0 title py-2 border-bottom">Delivery address</h5>
                                    <div
                                        className="py-2 border-bottom d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 p-0 first">Country</h5>
                                        <h5 className="m-0 p-0">{order.shipping_address.country}</h5>
                                    </div>
                                    <div
                                        className="py-2 border-bottom d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 p-0 first">City</h5>
                                        <h5 className="m-0 p-0">{order.shipping_address.city}</h5>
                                    </div>
                                    <div
                                        className="py-2 border-bottom d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 p-0 first">Street name</h5>
                                        <h5 className="m-0 p-0">{order.shipping_address.street}</h5>
                                    </div>
                                    <div className="py-2 d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 p-0 first">Zip code</h5>
                                        <h5 className="m-0 p-0">{order.shipping_address.zip}</h5>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col xxl={3} xl={3} lg={3} md={12} xs={12} className="mb-5">
                        <div className="bg-theme-dark p-3">
                            <h5 className="m-0 p-0 title py-2 border-bottom">Order summary</h5>
                            <div className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                <h5 className="m-0 p-0 first">Order created</h5>
                                <h5 className="m-0 p-0">{format(new Date(order.created_at), "MMM, dd yyyy")}</h5>
                            </div>
                            <div className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                <h5 className="m-0 p-0 first">Sub total</h5>
                                <h5 className="m-0 p-0">${(order.sub_total_amount) ? order.sub_total_amount.toFixed(2) : "0.00"}</h5>
                            </div>
                            <div className="d-flex py-2 border-bottom align-items-center justify-content-between">
                                <h5 className="m-0 p-0 first">Shipping</h5>
                                <h5 className="m-0 p-0">${(order.shipping_fees) ? order.shipping_fees.toFixed(2) : "0.00"}</h5>
                            </div>
                        </div>

                        <div className="bg-theme-dark mt-4 p-3">
                            <div className="d-flex py-2 align-items-center justify-content-between">
                                <h5 className="m-0 p-0 first">Total</h5>
                                <h5 className="m-0 p-0">${(order.total_amount) ? order.total_amount.toFixed(2) : "0.00"}</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CartCard
