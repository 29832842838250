import React, { useState } from "react"
import { Modal, Button, Row, Col, Form } from "react-bootstrap"
import { FaTimes, FaMinus, FaPlus } from "react-icons/fa";
import Assets from "../../constants/images";

const OrderSummaryModal = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="order-summary-modal"
        >
            <Modal.Header >
                <div className="d-flex w-100 align-items-center justify-content-between">
                    <div>
                        <h5>Order Number <span>#24831</span></h5>
                    </div>
                    <div>
                        <span className="text-white" style={{ cursor: "pointer" }}><FaTimes className="icon" onClick={()=>setShowModal(false)} /></span>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="px-3 pb-3 bg-white">
                    <Row className="mx-1 border-row">
                        <Col xs={12} className="mb-4">
                            <div className="d-none d-sm-block cart-table pt-3 px-4">
                                <Row>
                                    <Col xs={5}>
                                        <h5>Item summary</h5>
                                    </Col>
                                    <Col xs={2}>
                                        <h5 className="text-center">Qty</h5>
                                    </Col>
                                    <Col xs={2}>
                                        <h5 className="text-center">Price</h5>
                                    </Col>
                                    <Col xs={3}>
                                        <h5 className="text-center">Total price</h5>
                                    </Col>
                                </Row>
                            </div>

                            <div className="pt-2 border-bottom">
                                <Row className="mb-2">
                                    <Col xs={12} sm={5}>
                                        <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                            <img src={Assets.ProductImage2} className="img-order ms-sm-4" alt="Product Image" />
                                            <span className="ms-sm-2 description mt-2 mt-sm-0">Grohe Uniset 3' Distance Pipe for Rapid SL Fram Black</span>
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={2}>
                                        <div className="text-sm-center mt-2 mt-sm-0">
                                            <span><span className="d-inline-block d-sm-none">Qty:</span>  x 5</span>
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={2}>
                                        <div className="text-sm-center mt-2 mt-sm-0">
                                            <span><span className="d-inline-block d-sm-none">Price:</span> $230.00</span>
                                        </div>
                                    </Col>

                                    <Col xs={6} sm={2}>
                                        <div className="text-sm-center mt-2 mt-sm-0">
                                            <span><span className="d-inline-block d-sm-none">Total price:</span> $320.0</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} md={6} className="mb-4">
                            <div className="border p-2">
                                <h5>Customer & order details</h5>
                                <div>
                                    <div className="border-bottom border-top py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Customer name</span>
                                        <span>John Doe</span>
                                    </div>

                                    <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Phone number</span>
                                        <span>John Doe</span>
                                    </div>

                                    <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Weight</span>
                                        <span>2240 lbs</span>
                                    </div>

                                    <div className="py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Truck Type</span>
                                        <span>Small pickup truck</span>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={6} className="mb-4">
                            <div className="border p-2">
                                <h5>Order summary</h5>
                                <div>
                                    <div className="border-bottom border-top py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Order created</span>
                                        <span>22/12/21</span>
                                    </div>

                                    <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Sub total</span>
                                        <span>$29.98</span>
                                    </div>

                                    <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Shipping</span>
                                        <span>10.0$</span>
                                    </div>

                                    <div className="py-2 d-flex align-items-center justify-content-between">
                                        <span className="heading">Total</span>
                                        <span>$39.98</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="border p-2">
                                <h5>Delivery address</h5>
                                <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <span className="heading">Flat name</span>
                                    <span>14 Sun Set Boulivard</span>
                                </div>

                                <div className="border-bottom py-2 d-flex align-items-center justify-content-between">
                                    <span className="heading">Flat/building name</span>
                                    <span>Pheonix Mansion</span>
                                </div>

                                <div className="py-2 d-flex align-items-center justify-content-between">
                                    <span className="heading">Building name</span>
                                    <span>213FhY</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OrderSummaryModal