import React, {useContext, useState, useEffect} from "react";
import DriverPublicHeader from "../../components/app-header/driver-public-header";
import Footer from "../../components/app-footer/Footer";
import {Col, Container, Row} from "react-bootstrap";
import TruckAndVehicleTypeForm from "../../components/truck-and-vehicle-type/TruckAndVehicleTypeForm";
import DriverProfileModal from "../../components/profile-modal/DriverProfileModal";
import {NavLink} from "react-router-dom";
import Assets from "../../constants/images";
import {IoMdSettings} from "react-icons/io";
import {DriverSidebar} from "../../App";
import NewTruckAndDriverTypeForm from "../../components/truck-and-vehicle-type/NewTruckAndDriverTypeForm";
import FooterNew from "../../components/app-footer/FooterNew";
import NewDriverHeader from "../../components/app-header/NewDriverHeader";


const TruckAndVehicleTypePage = () => {
    const [showDriverSidebar, setShowDriverSidebar] = useContext(DriverSidebar);

    const handleDriverSidebar = () => {
        setShowDriverSidebar(true)
        document.body.classList.add("handleProfilemodal");
    }

    return (
        <>
            <NewDriverHeader/>
            <div className="NewTruckDriverPage truck-and-vehicle-type setup-profile">
                <div className="d-flex">

                    <div className="left">
                        {
                            showDriverSidebar === true &&
                            <DriverProfileModal/>
                        }
                    </div>

                    <div className="right px-2 px-md-5 py-5">
                        <Row>
                            <Col className="d-block d-md-none" xs={12}>
                                <div className="text-end">
                                    <IoMdSettings onClick={() => handleDriverSidebar(true)}
                                                  className="text-white icon-setting"/>
                                    {
                                        showDriverSidebar == false &&
                                        <hr/>
                                    }
                                </div>
                            </Col>

                            <Col xs={12} className="mb-4">
                                <h1>Truck/Vehicle Type</h1>
                            </Col>

                            <Col xs={12} xxl={12} xl={12} lg={12} md={12}>
                                {/* <TruckAndVehicleTypeForm/> */}
                                <NewTruckAndDriverTypeForm/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <FooterNew />
        </>
    )
}

export default TruckAndVehicleTypePage