import React from "react";
import {Button, Col, Container, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AppLogo, AuthLogo} from "../../constants/svgs";
import {NavLink, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {success, error} from "../../constants/msg";
import {ToastContainer} from "react-toastify";
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../constants/app-constants";
import {ForgetPasswordApi} from "../../services/auth/auth.service";

const ForgotPasswordForm = () => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate();

    const onSubmit = (data) => {
        forgotPassword(data.email)
    }

    const forgotPassword = async (email) => {
        await ForgetPasswordApi(email).then((res) => {
            if (res.status) {
                success(res.message)
                setTimeout(() => {
                    navigate("/verification", {state: {email: email}});
                }, 1500);
            }
            else{
                error(res.message)
            }
        }).catch(err => {
            error(err.response.data.message)
            console.log(err.response.data.message)
        })
    }

    return (
        <div className="auth-page py-5" id="forgot-password">
            <ToastContainer/>
            <Container>
                <Row>
                    <Col xs={12} className="mb-5">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">My Account</li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={10}>
                        <div className="form-container login-form-container p-4 px-lg-5 py-lg-4">
                            <Row>
                                <Col xs={{span: 12, order: 2}} md={12} lg={{span: 5, order: 1}} xl={6}>
                                    <span className="d-block d-lg-none"><AppLogo/></span>
                                    <h5 className="mt-4 mt-lg-0 pb-3 border-bottom">Forgot Password?</h5>
                                    <span className="d-inline-block my-3">Please enter your registered email to receive OTP</span>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <InputGroup>
                                            <FormControl
                                                type="email"
                                                name="email"
                                                className="py-2"
                                                placeholder="Email address"
                                                {...register("email",
                                                    {
                                                        maxLength: {
                                                            value: VALIDATIONS.EMAIL,
                                                            message: VALIDATIONS_TEXT.EMAIL_MAX
                                                        },
                                                        required: {
                                                            value: true,
                                                            message: VALIDATIONS_TEXT.ONLY_EMAIL_REQUIRED
                                                        },
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                        }
                                                    })
                                                }
                                            />
                                        </InputGroup>
                                        {errors.email && <small className="text-red">{errors.email.message}</small>}

                                        <Button className="w-100 btn-red my-3 mt-4" type="submit">Continue</Button>
                                    </Form>
                                </Col>

                                <Col className="text-center d-none d-lg-block" xs={{span: 12, order: 1}} md={12}
                                     lg={{span: 6, offset: 1, order: 2}} xl={{span: 5, offset: 1}}>
                                    <AuthLogo/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ForgotPasswordForm;