import React, { useState } from "react";
import { Col, Form } from "react-bootstrap"
import { FaBars } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const SearchResultHeader = ({ onClick, heading }) => {
    const [view, setView] = useState("grid");

    const handleClick = (views) => {
        onClick(views)
        setView(views)
    }

    return (
        <>
           <Col xs={12} lg={3}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">{heading}</li>
                    </ol>
                </nav>
                <h1 className="d-none">{heading}</h1>
            </Col>

            <Col xs={12} lg={9} className="mt-5">
                <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
                    <div>
                        <span className="text">716 items found for "DeWalt"</span>
                    </div>
                    <div className="mt-4 mt-lg-0 mb-lg-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-end ">
                        <div className="d-flex align-items-center justify-content-between">
                            <label className="d-inline-block text-white label-1" htmlFor="sort">Sort by: </label>
                            <Form.Select aria-label="Default select example">
                                <option>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </div>

                        <div className="mb-div mt-3 mt-lg-0 mb-3 mb-lg-0">
                            {
                                (view === "grid") ?
                                    <>
                                        <label htmlFor="view" className="label-2 text-white">View: </label>
                                        <FaBars className="icon-bar text-grey me-3" onClick={() => handleClick("list")} />
                                        <BsFillGrid3X3GapFill className="icon-grid text-theme-red" onClick={() => handleClick("grid")} />
                                    </> :
                                    <>
                                        <label htmlFor="view" className="label-2 text-white">View: </label>
                                        <FaBars className="icon-bar text-theme-red me-3" onClick={() => handleClick("list")} />
                                        <BsFillGrid3X3GapFill className="icon-grid text-grey" onClick={() => handleClick("grid")} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}

export default SearchResultHeader