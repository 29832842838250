import React, {useEffect, useState} from "react";
import {Navigate, Outlet} from "react-router";
import {constants} from "./utils/constants";
import {GetProfile} from "./services/general.service";

const DriverVerifiedRoute = ({isLogged}) => {
    return (
        isLogged && isLogged === 1 ? <Outlet/> : <Navigate to="/driver/profile-setup"/>
    )
}

export default DriverVerifiedRoute;
