import React from "react";
import AppHeader from "../../components/app-header/public-header";
import Footer from "../../components/app-footer/Footer";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import FooterNew from "../../components/app-footer/FooterNew";

const Checkout = () => {
    return (
        <>
            <AppHeader />
            <CheckoutForm />
            <FooterNew />
        </>
    )
}

export default Checkout