import React from "react";
import OrderSummaryModal from "../../components/order-summary/OrderSummaryModal";

const OrderAndSummaryPage = () => {
    return (
        <div className="order-summary">
            <OrderSummaryModal />
        </div>
    )
}

export default OrderAndSummaryPage