import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Assets from "../../constants/images";
import { Cart } from "../../App";
import { success } from "../../constants/msg";
import { GetProducts } from "../../services/products.service";
import { handleBrokenImageOnError } from "../../utils/helper";

const SuggestemItemCard = () => {

    const [cart, setCart] = useContext(Cart);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    let bool = false;
    let index = 0;

    const addToCart = (id, image, title, rating, buy, price, weight, category_id) => {
        if (cart.length === 0) {
            setCart([
                {
                    id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight, category_id
                }
            ])
        } else {
            cart.map((item, i) => {
                if (item.id === id) {
                    bool = true
                    index = i
                    return
                }
            })
            if (bool === true) {
                let temp = [...cart];
                temp[index].quantity += 1;
                temp[index].price = temp[index].actualPrice * temp[index].quantity;
                setCart(temp);
                bool = false
            } else {
                setCart([
                    ...cart,
                    {
                        id, image, title, rating, buy, price, actualPrice: price, quantity: 1, weight, category_id
                    }
                ])
            }

        }
        // setTimeout(() => {
        //     success("Product added in cart");
        // }, 1500);
    }

    const [products, setProducts] = useState([]);

    async function fetchProducts() {
        let cat_ids = []
        let except_product_ids = []
        cart.forEach((item) => {
            if(cat_ids.indexOf(item.category_id) < 0) {
                cat_ids.push(item.category_id)
            }
            except_product_ids.push(item.id)
        })

        const params = {
            "per-page": 8,
            is_essential: 0
        }

        if(cat_ids.length > 0){
            params.category_ids = cat_ids
        }
        if(except_product_ids.length > 0){
            params.except_product_ids = except_product_ids
        }

        await GetProducts(params).then((result) => {
            if (result.status && result.data.data) {
                setProducts(result.data.data)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <div className="right-div p-3 py-4 pb-0">
            <Row>
                <Col xs={12} className="mb-3">
                    <h5 className="pb-3 border-bottom">Suggested Items</h5>
                </Col>

                {
                    products &&
                    products.map((v, i) => {
                        return (
                            <Col xs={6} sm={4} md={4} lg={6} key={i} className="mb-4">
                                <div className="suggested-item-img-div">
                                    <div className="overlay d-flex align-items-center justify-content-center"><Button
                                        className="btn-add"
                                        onClick={() => addToCart(
                                            v.id,
                                            (v.attachments.length > 0) ? v.attachments[0].mediaUrl : Assets.NoImageAvailable,
                                            v.name,
                                            (v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0,
                                            v.buy, v.price,
                                            v.weight,
                                            v.category?.id
                                        )}>Add</Button>
                                    </div>
                                    <img className="w-100"
                                        src={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : Assets.NoImageAvailable}
                                        onError={(e) => handleBrokenImageOnError(e)}
                                    />
                                </div>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

export default SuggestemItemCard