import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row, Tab} from "react-bootstrap";
import Assets from "../../constants/images";
import ProductCard from "../categories-section/ProductCard";
import {useParams} from "react-router-dom";
import Description from "./Description";
import Reviews from "./Reviews";
import Navigation from "./Navigation";
import DescriptionCard from "./DescriptionCard";
import {GetProductReviews, GetProducts} from "../../services/products.service";

const ProductDescription = ({pid, image, upc, category, title, rating, sku, buy, price, weight, attachments, total_reviews, description}) => {
    const {id} = useParams();
    const [products, setProducts] = useState([]);
    const [reviews, setReviews] = useState({})
    const [ratingData, setRatingData] = useState([])

    const fetchReviews = async () => {
        await GetProductReviews({product_id: pid}).then((result) => {
            if (result.status && result.data.reviews) {
                setReviews(result.data.reviews)
                setRatingData(result.data.ratings)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const fetchProducts = async () => {
        await GetProducts({"per-page": 8, "category_ids":[category.id], "except_product_ids":[pid]}).then((result) => {
            if (result.status && result.data.data) {
                setProducts(result.data.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        fetchReviews()
        fetchProducts()
    }, [pid])

    return (
        <div className="product-description py-4 category-page category-section">
            <div className="product-description-container category-container">
                <Container>
                    <Row className="mb-4 mb-lg-5">
                        <DescriptionCard pid={pid} image={image} sku={sku} title={title} rating={rating}
                                         category={category}
                                         upc={upc}
                                         total_reviews={total_reviews} buy={buy} price={price} weight={weight}
                                         attachments={attachments}/>
                    </Row>

                    <Row>
                        <Col xs={12} className="mt-4">
                            <div className="link-container">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                    <Row>
                                        <Col lg={3} className="mb-4">
                                            <Navigation/>
                                        </Col>
                                        <Col lg={9}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <Description rating={rating} total_reviews={total_reviews}
                                                                 description={description}/>
                                                </Tab.Pane>

                                                {/*<Tab.Pane eventKey="second">*/}
                                                {/*    <Reviews reviews={reviews} ratingData={ratingData}*/}
                                                {/*             total_rating={rating}/>*/}
                                                {/*</Tab.Pane>*/}
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                    <div className="suggested">
                        <Row>

                            {
                                products.length ?

                                    <Col xs={12} className="mt-5">
                                        <div className="suggestion border-top pt-3">
                                            <h2 className="pt-5 mb-4">Suggested Products</h2>
                                        </div>
                                    </Col>

                                    : ""
                            }

                            {
                                products &&
                                products.map((v, i) => {
                                    return <ProductCard id={v.id} key={i} index={i}
                                                        total_reviews={v.meta.total_reviews}
                                                        rating={(v.meta && v.meta.rating) ? Math.round(v.meta.rating) : 0}
                                                        attachments={v.attachments}
                                                        // image={(v.attachments.length > 0) ? v.attachments[0].mediaUrl : Assets.NoImageAvailable}
                                                        image={image}
                                                        title={v.name} buy={v.buy} price={v.price}
                                                        sku={v.sku} weight={v.weight}
                                                        category={category}
                                                        description={v.description}/>
                                })
                            }
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default ProductDescription