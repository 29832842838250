import React from "react";
import AppHeader from "../../../components/app-header/public-header";
import LoginFrom from "../../../components/authentication/LoginForm";
import Footer from "../../../components/app-footer/Footer";
import FooterNew from "../../../components/app-footer/FooterNew";

export default function LoginPage(props) {

    return (
        <>
            <AppHeader/>
            <LoginFrom/>
            <FooterNew />
        </>
    );
}
