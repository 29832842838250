import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./Sidebar";
import FolderCard from "./FolderCard";
import { NavLink } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { BsThreeDots } from "react-icons/bs";
import { AiFillFolder } from "react-icons/ai";

const rows = [
  {
    id: "1",
    name: "This is a dummy file name.pdf",
    size: "12 MB",
    last_modified: "Jan 22, 2022",
    action: "",
  },
  {
    id: "2",
    name: "96CA3F89-E82A-4493-945C-3FE1B7BF8192.jpg",
    size: "12 MB",
    last_modified: "Jan 22, 2022",
    action: "",
  }
]

const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 500,
    flex: 1,
    renderCell: (params) => (
      <div className="d-flex align-items-center">
        <AiFillFolder />
        <span className="ms-2">{params.value}</span>
      </div>
    ),
  },
  {
    field: "size",
    headerName: "Size",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "last_modified",
    headerName: "Last Modified",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "action",
    headerName: "",
    minWidth: 100,
    flex: 1,
    renderCell: (params) => (
      <div className="d-flex align-items-center">
        <BsThreeDots />
        <span className="">{params.value}</span>
      </div>
    ),
  },
];

const CloudStorage = () => {
  return (
    <div className="cloud-storage-dashboard py-5">
      <Container>
        <Row>
          <Col xs={12} className="mb-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">Cloud Storage</li>
              </ol>
            </nav>
            <h1>Cloud Storage</h1>
          </Col>

          <Col xs={12}>
            <div className="d-flex flex-column flex-lg-row w-100">
              <div className="left-panel">
                <Sidebar />
              </div>
              <div className="right-panel p-4">
                <Row>
                  <Col xs={12} className="mb-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5>Folders</h5>
                      <NavLink to="">View All</NavLink>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <FolderCard />
                  <FolderCard />
                  <FolderCard />
                  <FolderCard />
                </Row>

                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-center justify-content-between">
                      <h5>Files</h5>
                      <NavLink to="">View All</NavLink>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} className="mb-2">
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        className="data-table"
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CloudStorage;