import React from "react"
import { Col, Container, Row, Nav } from "react-bootstrap"
import { AppLogo, LocationIcon, MessageIcon, PhoneIcon } from "../../constants/svgs"
import { RiFacebookFill, RiTwitterFill, RiInstagramFill, RiPhoneLine, RiHome5Line } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";
import Assets from "../../constants/images";
import { NavLink } from "react-router-dom";

const FooterNew = () => {
    return (
        <footer className="footer">
            <div className="footer-container pt-5 pb-4">
                <Container>
                    <Row className="justify-content-lg-center">
                        <Col xs={4} sm={4} md={4} lg={3} className="mb-4">
                            <h5 className="sub-heading">Useful Links</h5>
                            <ul>
                                {/*<li><Nav.Link href="/about-us">About Us</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Contact Us</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Track your order</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Career</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Policy</Nav.Link></li>*/}
                                <li><Nav.Link href="/faq/order-information">FAQs</Nav.Link></li>
                            </ul>
                        </Col>

                        <Col xs={4} sm={4} md={4} lg={3} className="mb-4">
                            <h5 className="sub-heading">Help Center</h5>
                            <ul>
                                {/*<li><Nav.Link href="#">Payments</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="/faq/shipping">Shipping</Nav.Link></li>*/}
                                <li><Nav.Link href="/faq/return-and-cancellation">Returns</Nav.Link></li>
                                {/*<li><Nav.Link href="#">Other</Nav.Link></li>*/}
                            </ul>
                        </Col>

                        <Col xs={4} sm={4} md={4} lg={3} className="mb-4">
                            {/*<h5 className="sub-heading">Support</h5>*/}
                            <ul>
                                {/*<li><Nav.Link href="#">Product | Login</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Support Request</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Contact us</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Guides</Nav.Link></li>*/}
                                {/*<li><Nav.Link href="#">Product Finder</Nav.Link></li>*/}
                            </ul>
                        </Col>
                    </Row>
                </Container>

                <div className="py-5 border-top">
                    <Container>
                        <Row className="justify-content-lg-center">
                            <Col xs={6} lg={{offset:1, span:3}} className="mb-4">
                                <div className="d-flex">
                                    <div><PhoneIcon /></div>
                                    <div className="ms-3">
                                        <h5 className="m-0 p-0 sub-heading">Phone Number: </h5>
                                        <span>(833) 854-3889</span>
                                        <div className={"mt-2"}>
                                            <h5 className="m-0 p-0 sub-heading">Text Only Number:</h5>
                                            <span>(954) 361-6002</span>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                                <div className="d-flex">
                                    <div><MessageIcon /></div>
                                    <div className="ms-3">
                                        <h5 className="m-0 p-0 sub-heading">Write us</h5>
                                        <span>support@cmoresupply.com</span>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} sm={6} md={6} lg={3} className="mb-4">
                                <div className="d-flex">
                                    <div><LocationIcon /></div>
                                    <div className="ms-3">
                                        <h5 className="m-0 p-0 sub-heading">Address</h5>
                                        <span>14280 S. Military Trail Suite 8199
                                            Delray Beach, Fl 33482
                                        </span>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={6} lg={2} className="mb-4"></Col>

                            <Col xs={6} lg={3}></Col>

                            <Col xs={12}></Col>

                            <Col className={"p-0"} xs={{ span: 10, offset: 1 }}>
                                <div className="download-div">
                                    <span>Download our mobile apps</span>
                                    <div className="mt-3">
                                        <NavLink className="btns-stores" to="#">
                                            <img className="w-100" src={Assets.BtnApple} alt="" />
                                        </NavLink>
                                        <NavLink className="btns-stores" to="#">
                                            <img className="w-100" src={Assets.BtnGoogle} alt="" />
                                        </NavLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </footer>
    )
}

export default FooterNew