import React,{useContext,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import SuggestemItemCard from "./SuggestemItemCard";
import CartCardBody from "./CartCardBody";
import CartCardHeader from "./CartCardHeader";
import { Cart } from "../../App";
import CartCardFooter from "./CartCardFooter";

const CartCard = () => {
    const [cart, setCart] = useContext(Cart);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    return (
        <div className="cart-card">
            <Container>
                <Row className="justify-content-center">
                    <Col xxl={9} xl={9} lg={9} md={12} xs={12} className="mb-4">
                        <CartCardHeader />
                        <div className="cart-item-container p-4">
                            {
                                cart.map((item,i)=>{
                                    return (
                                        <CartCardBody key={i} item={item} index={i} />
                                    )
                                })
                            }
                            <CartCardFooter cart={cart}/>
                        </div>
                    </Col>

                    <Col xxl={3} xl={3} lg={3} md={12} xs={12} className="mb-5">
                        <SuggestemItemCard />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CartCard