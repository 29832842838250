import React, { useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { AppLogo } from "../../constants/svgs";
import CheckLocation from "../../components/check-location/CheckLocationCard";
import CustomItem from "../../components/custom-item/CustomItemCard";
import {
    Cart,
    CustomItemModal,
    IsCustomerLogin,
    LocationModal,
    ProfileModals,
    StickyNavbar,
} from "../../App";
import ProfileModal from "../../components/profile-modal/ProfileModal";
import { BiSearch } from "react-icons/bi";
import { AiOutlineShoppingCart, AiOutlineUser } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import { FaArrowRight, FaBars, FaRegBell } from "react-icons/fa";
import Search from "../search/Search";
import Notification from "../notification/Notification";
import { ProductTypesContext } from "../../hooks/GlobalContext";
import { GetProducts } from "../../services/products.service";
import { constants } from "../../utils/constants";
import { GetCurrentUser } from "../../services/auth/auth.service";
import { handleBrokenImageOnError } from "../../utils/helper";
import {
    GetNotifications,
    GetUnreadNotificationsCount,
    MarkAllNotificationsRead,
} from "../../services/notifications.service";
import { FiSearch } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";
import Logo from "../../assets/images/home-page/logo.png"

const NewDriverHeader = () => {
    const [cart, setCart] = useContext(Cart);
    const [show, setShow] = useState(false);
    const [showSearchSuggestion, setShowSearchSuggestion] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [showLocationModal, setShowLocationModal] = useContext(LocationModal);
    const [showCustomItemModal, setShowCustomItemModal] =
        useContext(CustomItemModal);
    const [showProfileModal, setShowProfileModal] = useContext(ProfileModals);
    const [showSignupOverlay, setShowSignupOverlay] = useState(false);
    const navigate = useNavigate();
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin);
    const [showSignupModal, setShowSignupModal] = useState(false);
    const [stickyNavbar, setStickyNavbar] = useContext(StickyNavbar);
    const [products, setProducts] = useState([]);
    const productTypeContext = useContext(ProductTypesContext);
    const [productTypes, setProductTypes] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [disabled, setDisabled] = useState(false)

    const handleProfileModal = () => {
        setShowProfileModal(!showProfileModal);
        document.body.classList.add("handleProfilemodal");
    };

    const openSignupModal = () => {
        setShowSignupOverlay(true);
        document.body.classList.add("handleSignupModal");
    };

    const closeSignupModal = () => {
        setShowSignupOverlay(false);
        document.body.classList.remove("handleSignupModal");
    };

    const [handleBar, setHandleBar] = useState(false);

    const handleClick = () => {
        if (handleBar == false) {
            document.body.classList.add("handleFabars");
            setHandleBar(true);

            setDisabled(true)

        } else {
            document.body.classList.remove("handleFabars");
            setHandleBar(false);

            setDisabled(false)

        }
    };

    const handleSearchOnChange = (e) => {
        const value = e.target.value;

        if (value != "" && value.length >= 3) {
            searchProducts(value);
        } else {
            setProducts([]);
        }
    };

    const searchProducts = async (keyword) => {
        let params = {
            keyword,
            is_essential: 0,
        };
        await GetProducts(params)
            .then((result) => {
                if (result.status && result.data.data) {
                    setProducts(result.data.data);
                }
            })
            .catch((error) => { });
    };

    const getNotifications = async () => {
        await GetNotifications()
            .then((result) => {
                if (result.status && result.data.data) {
                    setNotifications(result.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getUnreadNotificationCount = async () => {
        await GetUnreadNotificationsCount()
            .then((result) => {
                if (result.status && result.data) {
                    setNotificationCount(result.data.unread_count);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleNotificationOnClick = async () => {
        console.log("clicked notification");
        await MarkAllNotificationsRead()
            .then((result) => {
                if (result.status) {
                    setNotificationCount(0);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        setProductTypes(productTypeContext);
        const _user = GetCurrentUser();

        if (_user) {
            setIsCustomerLogin(_user);
        }
    }, [productTypeContext]);

    useEffect(() => {
        const _user = GetCurrentUser();
        if (_user) {
            getUnreadNotificationCount();
            getNotifications();
        }
    }, []);

    const formenu = () => {
        return (
            <div className="NewSearchContainer">
                <div className="search-div pe-3 py-2 d-flex align-items-center">
                    <Form.Control
                        className="ps-4 text-dark"
                        type="text"
                        placeholder="Search here..."
                        onFocus={() => setShowSearchSuggestion(true)}
                        onChange={handleSearchOnChange}
                    />
                    <BiSearch
                        className="inner-search-icon"
                        onClick={() => navigate("/search-result")}
                    />
                </div>

                <div className="menu-search ps-4">
                    {showSearchSuggestion === true && (
                        <Search
                            showSearchSuggestion={showSearchSuggestion}
                            setShowSearchSuggestion={setShowSearchSuggestion}
                            products={products}
                        />
                    )}
                </div>

            </div>
        );
    };
    return (
        <>
            <nav
                id="mynav"
                className={`navbar navbar-expand-xl driverHeader ${stickyNavbar ? "position-fixed w-100 zindex-9999" : "static"
                    }`}
            >
                <NavLink className="navbar-brand d-block d-xl-none mb-3" to="/">
                    <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"} />
                </NavLink>
                <div className="d-flex align-items-center flex-row-reverse">
                    <button
                        className={`navbar-toggler ${disabled ? "collapsed" : ""}`}
                        // type="button"
                        // data-toggle="collapse"
                        // data-target="#navbarToggle"
                        // aria-controls="navbarToggle"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"

                        disabled={disabled}
                    >
                        <FaBars onClick={() => handleClick()} className="icon" />
                    </button>

                    {
                        isCustomerLogin &&
                        <img
                            src={isCustomerLogin ? isCustomerLogin.image_path : ""}
                            alt="user profile"
                            className="d-block me-2 d-xl-none header-profile-image small"
                            onError={(e) => handleBrokenImageOnError(e)}
                        />
                    }

                </div>
                <div
                    className={`collapse navbar-collapse ${disabled ? "show" : ""} justify-content-between`}
                    id="navbarToggle"
                >
                    <NavLink className="navbar-brand d-none d-xl-block" to="/">
                        <img className={"img-fluid"} src={Logo} alt={"CMORE Supply"} title={"CMORE Supply"} />
                    </NavLink>

                    <ul className="navbar-nav">
                        <li className="nav-item dropdown mt-2">
                            <NavLink to="/driver/profile-setup">Home</NavLink>
                        </li>

                        <li className="nav-item dropdown mt-2">
                            <NavLink to="/about-us">About</NavLink>
                        </li>

                        <li className="nav-item dropdown mt-2">
                            <NavLink to="/faq/order-information">FAQ</NavLink>
                        </li>


                    </ul>

                    <ul className="navbar-nav">
                        {!isCustomerLogin ? (
                            <>
                                <li className="nav-item dropdown mt-2">
                                    <Button
                                        className="mt-2 me-0 me-xl-3 log-in"
                                        onClick={() => navigate("/login")}
                                    >
                                        Login
                                    </Button>
                                </li>
                                <li className="nav-item dropdown mt-2">
                                    <Button className="mt-2" onClick={() => openSignupModal()}>
                                        Sign Up
                                    </Button>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="DriverDropDownNew nav-item dropdown mt-xl-0 ms-3 mt-1">
                                    {isCustomerLogin ? (
                                        <>
                                            <div className="mt-3 d-flex align-items-center ">
                                                <div className="d-none d-xl-block">
                                                    <img
                                                        src={isCustomerLogin ? isCustomerLogin.image_path : ""}
                                                        alt="user profile"
                                                        className="header-profile-image"
                                                        onError={(e) => handleBrokenImageOnError(e)}
                                                    />
                                                </div>

                                                {/*<div className="ms-2">*/}
                                                {/*    <span */}
                                                {/*        style={{ fontSize: "12px" }}*/}
                                                {/*        className="d-inline-block text-white">{isCustomerLogin.first_name + " " + isCustomerLogin.last_name}*/}
                                                {/*    </span>*/}
                                                {/*</div>*/}
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </li>

                                <div className="d-block d-xl-none mb-4"></div>
                            </>
                        )}
                    </ul>
                </div>
                {showLocationModal && <CheckLocation />}
                {showCustomItemModal && <CustomItem />}

                {showProfileModal && <ProfileModal />}
            </nav>
            {showSignupOverlay && (
                <div className="signup-overlay">
                    <div className="overlay-content w-100">
                        <div className="w-100">
                            <div
                                className="text-end text-white mb-5 custom-margin"
                                style={{ cursor: "pointer" }}
                                onClick={() => closeSignupModal()}
                            >
                                Close
                            </div>

                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup"
                                state={{ role: constants.ROLES.ROLE_USER }}
                            >
                                Sign up as a customer
                                <FaArrowRight className="icon" />
                            </NavLink>
                            <NavLink
                                className="d-block"
                                onClick={() => closeSignupModal()}
                                to="/signup"
                                state={{ role: constants.ROLES.ROLE_DRIVER }}
                            >
                                Sign up as a driver
                                <FaArrowRight className="icon" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NewDriverHeader;
