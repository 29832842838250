import React, {useEffect, useState} from "react";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import DispatchComponent from "./DispatchComponent";
import OrderComponent from "./OrderComponent";
import {NavLink} from "react-router-dom";
import {GetOrderHistory} from "../../services/orders.service";
import { constants } from "../../utils/constants"

const OrderHistory = () => {

    const [orders, setOrders] = useState([])
    const [status, setStatus] = useState(constants.ORDERSTATUS.ORDERED)

    const handleTabOnClick = (e) => {
        let status = e.target.getAttribute("data-rr-ui-event-key")
        setStatus(status)
    }

    const formatText = (str) => {
        if(!str) return
        return str.charAt(0) + str.slice(1).toLowerCase()
    }

    const fetchOrders = async (status) => {
        await GetOrderHistory({is_mine: 1, status}).then((res) => {
            if (res.status && res.data.data) {
                setOrders(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchOrders(status)
    }, [status])

    return (
        <div className="order-history">
            <div className="order-history-container py-5">
                <Container>
                    <Row>
                        <Col xs={12} className="mb-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><NavLink className="text-white"
                                                                             to="/">Home</NavLink></li>
                                    <li className="breadcrumb-item text-white" aria-current="page">Completed orders</li>
                                </ol>
                            </nav>
                            <h1>Order history</h1>
                        </Col>

                        <Col xs={12} lg={6} md={8}>
                            <div className="bg-theme-dark-grey p-4">
                                <Tabs
                                    defaultActiveKey={constants.ORDERSTATUS.ORDERED}
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                    onClick={handleTabOnClick}
                                >
                                    <Tab eventKey={constants.ORDERSTATUS.ORDERED} title={formatText(constants.ORDERSTATUSKEY[constants.ORDERSTATUS.ORDERED])}>
                                        <OrderComponent status={constants.ORDERSTATUS.ORDERED}  orders={orders}/>
                                    </Tab>
                                    <Tab eventKey={constants.ORDERSTATUS.ACCEPTED} title={formatText(constants.ORDERSTATUSKEY[constants.ORDERSTATUS.ACCEPTED])}>
                                        <OrderComponent status={constants.ORDERSTATUS.ACCEPTED} orders={orders}/>
                                    </Tab>
                                    <Tab eventKey={constants.ORDERSTATUS.DISPATCHED} title={formatText(constants.ORDERSTATUSKEY[constants.ORDERSTATUS.DISPATCHED])}>
                                        <DispatchComponent status={constants.ORDERSTATUS.DISPATCHED} orders={orders} />
                                    </Tab>
                                    <Tab eventKey={constants.ORDERSTATUS.DELIVERED} title={formatText(constants.ORDERSTATUSKEY[constants.ORDERSTATUS.DELIVERED])}>
                                        <OrderComponent status={constants.ORDERSTATUS.DELIVERED} orders={orders}/>
                                    </Tab>
                                    <Tab eventKey={constants.ORDERSTATUS.RETURNED} title={formatText(constants.ORDERSTATUSKEY[constants.ORDERSTATUS.RETURNED])}>
                                        <OrderComponent status={constants.ORDERSTATUS.RETURNED} orders={orders}/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default OrderHistory