import React, { useContext, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Assets from "../../constants/images";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus, FaMinus } from "react-icons/fa";
import { Cart } from "../../App";
import { handleBrokenImageOnError } from "../../utils/helper";

const CartCardBody = ({ item, index }) => {
    const [cart, setCart] = useContext(Cart);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart])

    const handleIncrement = (index) => {
        let temp = [...cart];
        temp[index].quantity += 1;
        temp[index].price = temp[index].actualPrice * temp[index].quantity;
        setCart(temp);
    }

    const handleDecrement = (index) => {
        if (cart[index].quantity > 1) {
            let temp = [...cart];
            temp[index].quantity -= 1;
            temp[index].price = temp[index].actualPrice * temp[index].quantity;
            setCart(temp);
        }
    }

    const removeFromCart = (index) => {
        cart.splice(index, 1);
        setCart([...cart]);
        localStorage.setItem("cart", JSON.stringify(cart));
    }

    const getFixedTwoDigits = (price) => {
        // price = parseInt(price)
        return Number(price).toFixed(2);
    }
console.log(item, "itemrrrrrrrrrrrr")
    return (
        <Row className="mb-3 mb-sm-4 pb-4 border-bottom">
            <Col xs={12} className="d-block d-sm-none">
                <span className="ms-sm-3 d-inline-block description mb-2">{item.title}</span>
            </Col>

            <Col xs={2} sm={6}>
                <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                    <img src={item.image} onError={(e) => handleBrokenImageOnError(e)} />
                    <span className="ms-sm-3 d-none d-sm-block description">{item.title}</span>
                </div>
            </Col>

            <Col xs={7} sm={3} className="margin-custom">
                <span className="d-block d-sm-none">${getFixedTwoDigits(item.price)}</span>
                <div className="text-left mt-sm-0">
                    <div className="d-flex justify-content-sm-left align-items-left">
                        <Button onClick={() => { handleDecrement(index) }} className="btns btn-minus"><FaMinus className="icon" /></Button>
                        <Form.Control readOnly value={item.quantity} className="input-quantity text-center" type="number" placeholder="1" />
                        <Button onClick={() => { handleIncrement(index) }} className="btns btn-plus"><FaPlus className="icon" /></Button>
                    </div>
                </div>
            </Col>

            <Col sm={2} className="d-none d-sm-block">
                <div className="text-sm-left mt-3 mt-sm-0">
                    <span>${getFixedTwoDigits(item.price)}</span>
                </div>
            </Col>

            <Col xs={{span:2, offset:1}} sm={{span:1, offset:0}} className="margin-custom">
                <div className="text-end text-sm-center mt-4 mt-sm-0">
                    <span><RiDeleteBin6Line onClick={() => { removeFromCart(index) }} className="icon-delete" /></span>
                </div>
            </Col>
        </Row>
    )
}

export default CartCardBody