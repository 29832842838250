import React from "react";
import VerificationForm from "../../../components/authentication/VerificationForm";
import AppHeader from "../../../components/app-header/public-header";
import Footer from "../../../components/app-footer/Footer";
import FooterNew from "../../../components/app-footer/FooterNew";

const VerificationPage = () => {
  return (
    <>
        <AppHeader />
        <VerificationForm/>
        <FooterNew />
    </>
  )
}

export default VerificationPage