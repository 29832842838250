import React from "react";
import { AiFillFolder } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import {Col} from "react-bootstrap";

const FolderCard = () => {
    return (
        <Col xs={12} sm={6} md={6} lg={4} xl={3} className="mb-4">
            <div className="folder-card py-2 px-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <AiFillFolder className="folder-icon" />
                    <BsThreeDots className="icon" />
                </div>
                <div>
                    <h5>Construction Updates</h5>
                    <span>29 files</span>
                </div>
            </div>
        </Col>
    )
}

export default FolderCard;