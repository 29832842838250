import React, { useEffect, useState } from "react";
import AppHeader from "../../components/app-header/public-header";
import AppBanners from "../../components/banners/banner";
import BulkDeal from "../../components/bulk-deal/BulkDeal";
import CardSection from "../../components/card-section/CardSection";
import BudgetSection from "../../components/budget-section/BudgetSection";
import Footer from "../../components/app-footer/Footer";
import HomePageCategoriesSection from "../../components/categories-section";
import Work from "../../components/Work/Work";
import { BuildingIcon, SearchIcon, VanIcon } from "../../constants/svgs";
import { GetBlogs } from "../../services/blogs.service";
import { useNavigate } from "react-router-dom";
import {constants, orderStatus} from "../../utils/constants";
import { GetCurrentUser } from "../../services/auth/auth.service";
import FooterNew from "../../components/app-footer/FooterNew";
import NewBanner from "../../components/banners/newBanner";
import WeOffer from "../../components/home-page/WeOffer";
import BrandSection from "../../components/home-page/BrandSection";
import HowItWorks from "../../components/home-page/HowItWorks";
import DealCards from "../../components/home-page/DealCards";
import AboutCmore from "../../components/home-page/AboutCmore";
import Blog from "../../components/home-page/Blog";
import BottomBanner from "../../components/home-page/BottomBanner";



export default function HomeNewPage(props) {
  const navigate = useNavigate();
  const user = GetCurrentUser();

  useEffect(() => {
    if (user && user?.roles[0].id === constants.ROLES.ROLE_DRIVER) {
      if (user.is_driver_completed == 0) {
        navigate("/driver/profile-setup");
      } else if (
        user.is_driver_completed == 1 ||
        user.is_driver_completed == 2
      ) {
        navigate("driver/truck-vehicle-type");
      } else {
        navigate("/driver/orders", {state: {page: orderStatus.ORDERED}});
      }
    }
  }, []);

  const [blogs, setBlogs] = useState([]);

  async function fetchBlogs() {
    await GetBlogs()
      .then((result) => {
        if (result.status && result.data.data) {
          setBlogs(result.data.data);
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <AppHeader />
      <NewBanner />
      <WeOffer />
      <BrandSection />
      <HowItWorks />
      <DealCards />
      <AboutCmore />
      <Blog />
      <BottomBanner />
      <FooterNew />
    </>
  );
}
