import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import Assets from "../../constants/images";
import { useForm } from "react-hook-form";
import { VALIDATIONS_TEXT } from "../../constants/app-constants";
import {GetVehicles} from "../../services/vehicles.service";
import {toast} from "react-toastify";
import {handleBrokenImageOnError} from "../../utils/helper";

const CartCardFooter = ({ cart }) => {
    const [showChangeModal, setShowChangeModal] = useState(false);
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        mode: onchange
    });
    const navigate = useNavigate();
    const [truckType, setTruckType] = useState("Small pickup truck");
    const [vehicles, setVehicles] = useState([]);
    setValue("truckType", truckType)

    const onSubmit = (data) => {
        if(!data){
            toast.error("please select truck type")
        }
        navigate("/checkout", {state:{truck_type_id:data.truckType.id}});
    }

    const calculateTotal = () => {
        let total = 0;
        for (let i = 0; i < cart.length; i++) {
            total += parseFloat(cart[i].price)
        }
        // return parseInt(total).toFixed(2)
        return Number(total).toFixed(2)
    }

    const calculateTotalWeight = () => {
        let weight = 0;
        for (let i = 0; i < cart.length; i++) {
            weight += cart[i].weight * cart[i].quantity
        }
        return parseInt(weight)
    }

    const handleClickedCheckbox = (e, truck)=>{
        setTruckType(truck)
        //setValue("truckType", truckType)
    }

    async function fetchVehicles() {
        await GetVehicles().then((result) => {
            if (result.status && result.data.data) {
                setVehicles(result.data.data)
                if(result.data.data.length > 0){
                    setTruckType(result.data.data[0])
                }

            }
        }).catch((error) => {
        })
    }

    useEffect(() => {
        fetchVehicles()
    }, [])

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)} >

                <Modal
                    className="cart-page-modal"
                    show={showChangeModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <div className="d-flex w-100 align-items-center">
                            <div className="text-center w-100">
                                <h5>Select truck type</h5>
                            </div>
                            <div className="text-end">
                                <span style={{ cursor: "pointer" }} onClick={() => setShowChangeModal(false)}><Button className="btn-closed" type="submit">Close</Button></span>
                            </div>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="px-4 py-5">
                            {
                                vehicles &&
                                vehicles.map((item, i) => (
                                    <div className="d-flex align-items-center mb-4">
                                        <Form.Check
                                            readOnly
                                            checked={(truckType && truckType.id) === item.id ? true : false}
                                            onClick={(e) => handleClickedCheckbox(e, item)}
                                            type="radio"
                                            name="group1"
                                            id={"radio-" + i}
                                            aria-label="radio-1"
                                            value="Small pickup truck"
                                        />
                                        <img className="ms-3" src={item.image_path} alt="TruckImage1"
                                        onError={(e) => handleBrokenImageOnError(e)}
                                        />
                                        <div className="ms-3">
                                            <h5>{item.name}</h5>
                                            <span>Capacity: {item.capacity} ton</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    </Modal.Body>
                </Modal>

                <Row>
                    <Col xs={12}>
                        <div className="d-flex flex-wrap justify-content-between">
                            <span className="pe-5 d-inline-block">Weight:
                                <span className="weight-600">
                                    {
                                        cart && cart.length === 0 ?
                                            <> 0 lbs</> :
                                            <> {calculateTotalWeight()} lbs</>
                                    }
                                </span>
                            </span>
                            <span>TOTAL: <span className="ms-2 weight-500">${calculateTotal()}</span></span>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <div className="bottom-div mt-4 d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center">
                            <div>
                                <span className="mt-5 d-inline-block">Choose truck type</span>
                                <div className="change-type d-flex mt-2">
                                    <img className="me-2 small-truck-pickup" src={(truckType) ? truckType.image_path : ""} alt="TruckImage"
                                    onError={(e) => handleBrokenImageOnError(e)}
                                    />
                                    <div>
                                        <span>{(truckType) ? truckType.name : ""}</span>
                                        <small>Capacity: {(truckType) ? truckType.capacity : "0"} ton <Button className="text-red" onClick={() => setShowChangeModal(true)}>Change</Button></small>
                                        <div>
                                            {errors.truckType && <small className='text-red'>{errors.truckType.message}</small>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-lg-5 mt-4 d-flex flex-column flex-lg-row align-items-sm-center">
                                <NavLink to="/products" ><IoMdArrowBack className="me-2" />Add more items</NavLink>
                                {/* <NavLink to="/all-products/checkout" className="btn-checkout px-4 ms-3">Proceed to Checkout</NavLink> */}
                                <Button type="submit" className="btn-checkout px-4 ms-3">Proceed to Checkout</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default CartCardFooter