import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import "react-rangeslider/lib/index.css";
import AppHeader from "../../components/app-header/public-header";
import {useForm} from "react-hook-form";
import {
    CHARACTER_ARRAY_NUMBER_INPUT,
    SPECIAL_CHARACTER_ARRAY,
    SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS,
    VALIDATIONS,
    VALIDATIONS_TEXT
} from "../../constants/app-constants";
import {GetProfile, UpdateProfile} from "../../services/auth/auth.service";
import {handleBrokenImageOnError, UploadFileToS3} from "../../utils/helper";
import {CameraIcon, EyeIcon} from "../../constants/svgs"
import FooterNew from "../app-footer/FooterNew";
import {GetCities, GetCountries, GetStates, GetZipCodes} from "../../services/states.service";
import {toast, ToastContainer} from "react-toastify";
import {constants} from "../../utils/constants";
import {IsCustomerLogin} from "../../App";
import {useNavigate} from "react-router-dom";
import {FaEye, FaSave} from "react-icons/fa";

const EditProfileCardNew = () => {
    const {register, unregister, control, handleSubmit, reset, setValue, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const navigate = useNavigate()
    const [user, setUser] = useState(null)
    const [profileImage, setProfileImage] = useState(null)
    const [companyImagePreview, setCompanyImagePreview] = useState(null)
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [zipCodes, setZipCodes] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isCustomerLogin, setIsCustomerLogin] = useContext(IsCustomerLogin)

    const getUser = async () => {
        await GetProfile().then((res) => {
            if (res.status && res.data) {
                setUser(res.data.user)
                setIsCustomerLogin(res.data.user)
                localStorage.setItem(constants.LOCAL_STORAGE_USER, JSON.stringify(res.data.user))
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getCountries = async () => {
        await GetCountries().then((res) => {
            if (res.status) {
                setCountries(res.data)
                reset({
                    country_id: user?.country_id
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getStates = async (country_id) => {
        await GetStates({country_id: country_id}).then((res) => {
            if (res.status) {
                setStates(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getCities = async (state_id) => {
        await GetCities({state_id: state_id}).then((res) => {
            if (res.status) {
                setCities(res.data)
                reset({
                    city_id: user?.city_id
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const getZipCodes = async (city_id) => {
        await GetZipCodes({city_id: city_id}).then((res) => {
            if (res.status) {
                setZipCodes(res.data)
                reset({
                    zip_id: user?.zip_id
                })
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleOnChangeCountry = (e) => {
        getStates(e.target.value)
    }

    const handleOnChangeState = (e) => {
        getCities(e.target.value)
    }

    const handleOnChangeCity = (e) => {
        getZipCodes(e.target.value)
    }

    const handleImageOnChange = (e) => {
        let img = e.target.files[0]
        let objectUrl = URL.createObjectURL(img)
        setValue("profile", e.target.files)
        setProfileImage(objectUrl)
    }

    const handleCompanyLogoOnChange = (e) => {
        let img = e.target.files[0]
        let objectUrl = URL.createObjectURL(img)
        setValue("companyLogo", e.target.files)
        setCompanyImagePreview(objectUrl)
    }

    const onSubmit = async (data) => {
        setIsLoading(true)
        let params = {}
        if (data?.profile?.length > 0 && data?.profile[0]) {
            let uploadImage = await UploadFileToS3(data.profile[0]);
            if (uploadImage.success) {
                params.image = uploadImage.url
            }
        }

        if (data.companyLogo?.length > 0 && data.companyLogo[0]) {
            let uploadImage = await UploadFileToS3(data.companyLogo[0]);
            if (uploadImage.success) {
                params.company_logo = uploadImage.url
            }
        }

        if (data.first_name) {
            params.first_name = data.first_name
        }
        if (data.last_name) {
            params.last_name = data.last_name
        }
        params.country_id = data?.country_id || null
        params.state_id = data?.state_id || null
        params.city_id = data?.city_id || null
        params.zip_id = data?.zip_id || null

        if (data.street) {
            params.location = data.street
        }
        if (data.phone) {
            params.phone = data.phone
        }
        if (data.job) {
            params.job_title = data.job
        }
        if (data.company_name) {
            params.company_name = data.company_name
        }
        if (data.profession) {
            params.profession = data.profession
        }
        if (data.experience) {
            params.experience = data.experience
        }
        if (data.facebook_url) {
            params.facebook_url = data.facebook_url
        }
        if (data.linkedin_url) {
            params.linkedin_url = data.linkedin_url
        }
        if (data.instagram_url) {
            params.instagram_url = data.instagram_url
        }
        if (data.twitter_url) {
            params.twitter_url = data.twitter_url
        }

        await UpdateProfile(user.id, params).then((res) => {
            if (res.status) {
                toast.success("Profile updated successfully")
                getUser()
            }
            setIsLoading(false)
        }).catch((err) => {
            toast.error(err.message)
            setIsLoading(false)
        })
    }

    const handleOnCancelClick = async () => {
        setIsLoading(true)
        await getUser()
        setIsLoading(false)
    }

    useEffect(() => {
        getCountries()
        getUser()

    }, [])

    useEffect(() => {
        if (user) {
            getStates(user?.country_id)
            getCities(user?.state_id)
            getZipCodes(user?.city_id)
            reset({
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone: user.phone,
                facebook_url: user?.user_detail?.facebook_url,
                instagram_url: user?.user_detail?.instagram_url,
                linkedin_url: user?.user_detail?.linkedin_url,
                twitter_url: user?.user_detail?.twitter_url,
                country_id: user.country_id,
                state_id: user.state_id,
                city_id: user.city_id,
                zip_id: user.zip_id,
                street: user.location,
                job_title: user.user_detail?.job_title,
                company_name: user.user_detail?.company_name,
                profession: user.user_detail?.profession,
                experience: user.user_detail?.experience
            })

            setProfileImage(user.image_path)
            if (user.user_detail) {
                setCompanyImagePreview(user.user_detail.company_logo_path)
            }

        }
    }, [user])

    return (
        <>
            <AppHeader/>
            {/*<NewDriverHeader/>*/}
            <div className="EditProfileCard">
                <ToastContainer autoClose={1000}/>
                <div className="new-banner py-5 py-sm-0 d-sm-flex align-items-sm-center ">
                    <Container>
                        <Row>
                            <Col xs={12} md={{span: 8, offset: 1}} lg={{span: 8, offset: 1}} xl={{span: 5, offset: 1}}>
                                <div className="banner-container">
                                    <h1>Welcome back</h1>
                                    <p>{user?.first_name + " " + user?.last_name}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="FormContainer py-5">
                    <Container>

                        <Form className="text-white" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col xs={12} md={{span: 10, offset: 1}} lg={{span: 10, offset: 1}}>
                                    <Row>
                                        <Col xs={12} className="mb-4">
                                            <h5 className="text-white"><span>Personal</span> Details</h5>
                                        </Col>
                                        <Col xs={12} className="mb-4">
                                            <div
                                                className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="mb-3 mb-xl-0 d-flex align-items-center me-4">
                                                    <div className="img-container">
                                                        <label htmlFor="profile">
                                                            <input className="d-none"
                                                                   id="profile"
                                                                   type="file"
                                                                   onChange={handleImageOnChange}
                                                                   accept="image/png, image/gif, image/jpeg"/>
                                                            <img src={profileImage}
                                                                 onError={(e) => handleBrokenImageOnError(e)}
                                                                 alt=""/>

                                                            <div className="circle-div">
                                                                <CameraIcon/>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="text-div ms-3">
                                                        <h5 className="m-0">{user?.first_name + " " + user?.last_name}</h5>
                                                        <small>{user?.email}</small>
                                                    </div>
                                                </div>

                                                <div className="centerMobile d-flex align-items-center">
                                                    <Button className="editBtn btn-solid me-2 d-flex justify-content-center align-items-center" onClick={() => {
                                                        navigate("/profile")
                                                    }}><FaEye className="me-2"  /> View Info</Button>
                                                    <Button className="btn-solid BtnSave d-flex justify-content-center align-items-center" type="submit"
                                                            disabled={isLoading}><FaSave className="me-2" /> Save</Button>
                                                </div>

                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    type="text"
                                                    placeholder="First Name"
                                                    {...register("first_name",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.FIRST_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.first_name &&
                                                <small className='text-red'>{errors.first_name.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    type="text"
                                                    placeholder="Last Name"
                                                    {...register("last_name",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.LAST_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS

                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.last_name &&
                                                <small className='text-red'>{errors.last_name.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    disabled={true}
                                                    placeholder="Email Address"
                                                    {...register("email",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.EMAIL,
                                                                message: VALIDATIONS_TEXT.EMAIL_MAX
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                                            }
                                                        })
                                                    }
                                                />
                                                {errors.email &&
                                                <small className='text-red'>{errors.email.message}</small>}

                                            </Form.Group>
                                        </Col>


                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => CHARACTER_ARRAY_NUMBER_INPUT.includes(e.key) && e.preventDefault()}
                                                    placeholder="Phone"
                                                    type="number"
                                                    {...register("phone",
                                                        {
                                                            pattern: {
                                                                value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                            minLength: {
                                                                value: VALIDATIONS.PHONE_MIN,
                                                                message: VALIDATIONS_TEXT.PHONE_MIN
                                                            },
                                                            maxLength: {
                                                                value: VALIDATIONS.PHONE_MAX,
                                                                message: VALIDATIONS_TEXT.PHONE_MAX
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.phone &&
                                                <small className='text-red'>{errors.phone.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Facebook</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Facebook URL"
                                                    {...register("facebook_url",
                                                        {
                                                            pattern: {
                                                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.facebook &&
                                                <small className='text-red'>{errors.facebook.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Instagram</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Instagram URL"
                                                    {...register("instagram_url",
                                                        {
                                                            pattern: {
                                                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.instagram_url &&
                                                <small className='text-red'>{errors.instagram_url.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Twitter</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Twitter URL"
                                                    {...register("twitter_url",
                                                        {
                                                            pattern: {
                                                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.twitter_url &&
                                                <small className='text-red'>{errors.twitter_url.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>LinkedIn</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="LinkedIn URL"
                                                    {...register("linkedin_url",
                                                        {
                                                            pattern: {
                                                                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/,
                                                                message: VALIDATIONS_TEXT.INVALID_FORMAT,
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.linkedin_url &&
                                                <small className='text-red'>{errors.linkedin_url.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} className="mt-5">
                                            <h5 className="text-white"><span>Address</span> Details</h5>
                                        </Col>


                                        <Col xs={12} md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select
                                                    className="py-2"

                                                    {...register("country_id", {})}
                                                    onChange={handleOnChangeCountry}
                                                >
                                                    <option value="" disabled={true}>Select Country</option>
                                                    {
                                                        countries && countries.map((item, i) => (
                                                            <option value={item.id} key={i}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.country_id &&
                                                <small className='text-red'>{errors.country_id.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>State</Form.Label>
                                                <Form.Select
                                                    className="py-2"
                                                    {...register("state_id", {})}
                                                    onChange={handleOnChangeState}
                                                >
                                                    <option value="" disabled={true}>Select State</option>
                                                    {
                                                        states && states.map((item, i) => (
                                                            <option value={item.id} key={i}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.state_id &&
                                                <small className='text-red'>{errors.state_id.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>City</Form.Label>
                                                <Form.Select
                                                    className="py-2"
                                                    {...register("city_id", {})}
                                                    onChange={handleOnChangeCity}
                                                >
                                                    <option value="" disabled={true}>Select City</option>
                                                    {
                                                        cities && cities.map((item, i) => (
                                                            <option value={item.id} key={i}>{item.name}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.city_id &&
                                                <small className='text-red'>{errors.city_id.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Zip Code</Form.Label>
                                                <Form.Select
                                                    className="py-2"
                                                    {...register("zip_id", {})}
                                                >
                                                    <option value="" disabled={true}>Select Zip Code</option>
                                                    {
                                                        zipCodes && zipCodes.map((item, i) => (
                                                            <option value={item.id} key={i}>{item.zip}</option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {errors.zip_id &&
                                                <small className='text-red'>{errors.zip_id.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Street</Form.Label>
                                                <Form.Control
                                                    onKeyPress={(e) => SPECIAL_CHARACTER_ARRAY_FOR_ADDRESS.includes(e.key) && e.preventDefault()}
                                                    type="text"
                                                    placeholder="Address"
                                                    {...register("street",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.STREET_ADDRESS_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.street &&
                                                <small className='text-red'>{errors.street.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col xs={12} className="mt-5">
                                            <div
                                                className="d-flex flex-wrap align-items-center justify-content-between">
                                                <div className="d-flex align-items-center me-4">
                                                    <div className="img-container">
                                                        <label htmlFor="companyLogo">
                                                            <input className="d-none" type="file"
                                                                   id={"companyLogo"}
                                                                   onChange={handleCompanyLogoOnChange}
                                                                   accept="image/png, image/gif, image/jpeg"/>
                                                            <img src={companyImagePreview}
                                                                 onError={(e) => handleBrokenImageOnError(e)}
                                                                 alt=""/>

                                                            <div className="circle-div">
                                                                <CameraIcon/>
                                                            </div>
                                                        </label>

                                                    </div>

                                                    <div className="text-div ms-3">
                                                        <h5 className="m-0">{user?.user_detail?.company_name}</h5>
                                                        {/*<small>emailplaceholder@gmail.com</small>*/}
                                                    </div>
                                                </div>

                                                {/*<div className="">*/}
                                                {/*    <Button className="editBtn btn-solid"><EditIcon /> Edit Info</Button>*/}
                                                {/*</div>*/}

                                            </div>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Job Title</Form.Label>
                                                <Form.Control
                                                    name="job"
                                                    placeholder="Job Title"
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    type="text"
                                                    {...register("job_title",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.FIRST_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.job_title &&
                                                <small className='text-red'>{errors.job_title.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control
                                                    placeholder="Company Name"
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    type="text"
                                                    {...register("company_name",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.FIRST_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.company_name &&
                                                <small className='text-red'>{errors.company_name.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Profession</Form.Label>
                                                <Form.Control
                                                    placeholder="Profession"
                                                    onKeyPress={(e) => (SPECIAL_CHARACTER_ARRAY.includes(e.key) || Number(e.key)) && e.preventDefault()}
                                                    type="text"
                                                    {...register("profession",
                                                        {
                                                            maxLength: {
                                                                value: VALIDATIONS.NAME,
                                                                message: VALIDATIONS_TEXT.FIRST_NAME_MAX_LENGTH
                                                            },
                                                            pattern: {
                                                                message: VALIDATIONS_TEXT.EXCLUDE_SPECIAL_CHARACTERS
                                                            },
                                                        })
                                                    }
                                                />
                                                {errors.profession &&
                                                <small className='text-red'>{errors.profession.message}</small>}
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group className="mt-4">
                                                <Form.Label>Experience</Form.Label>
                                                <Form.Select {...register("experience")}
                                                             aria-label="Default select example"
                                                >
                                                    <option value="1"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 1) ? true : false}
                                                    >
                                                        1 year
                                                    </option>
                                                    <option value="2"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 2) ? true : false}
                                                    >2
                                                        years
                                                    </option>
                                                    <option value="3"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 3) ? true : false}
                                                    >3
                                                        years
                                                    </option>
                                                    <option value="4"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 4) ? true : false}
                                                    >4
                                                        years
                                                    </option>
                                                    <option value="5"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 5) ? true : false}
                                                    >5
                                                        years
                                                    </option>
                                                    <option value="6"
                                                        // selected={(user && user.user_detail && user.user_detail.experience == 6) ? true : false}
                                                    >6
                                                        years
                                                    </option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12} className="mt-5 text-center ms-auto">
                                            <Button className="btn-transparent me-3"
                                                    onClick={handleOnCancelClick}
                                                    disabled={isLoading}>Cancel</Button>
                                            <Button className="btn-solid" type="submit"
                                                    disabled={isLoading}>Save</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
            <FooterNew/>
        </>
    )
}

export default EditProfileCardNew